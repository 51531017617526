<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Evaluar</a></li>
      <li class="breadcrumb-item active">Empresa</li>
    </ol>
    <h1 class="page-header">Evaluar Empresa <small></small></h1>
    <panel title="Formulario de edición de evaluación de empresa">
      <form v-on:submit.prevent="" method="POST" autocomplete="off">
        <div class="mb-3">
          <label class="form-label" id="razonSocial"><strong>Razon Social</strong> </label>
          <input class="form-control" type="text" disabled v-model="form.empresa"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="evaluacion"><strong>Evaluacion</strong> </label>
          <input class="form-control" type="number" v-model="form.evaluacion" min="1" max="10"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="observacion"><strong>Observación</strong> </label>
          <textarea class="form-control" type="text" v-model="form.observacion"/>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-secondary" v-on:click="volver()">Volver</button>
          </div>
          <div class="col-sm-8"></div>
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-primary" v-on:click="editarEvaluacion()">Editar</button>
          </div>
        </div>
      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "EvaluarEmpresa",
  data(){
    return{
      form:{
        "razonSocial": "",
        "evaluacion": "",
        "observacion": "",
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods:{
    loadingSpiner() {
      this.loader = this.$loading.show({
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    async getEmpresa(){
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user/'
      const api = process.env.VUE_APP_IP_BACKEND + "/empresa/evaluarEmpresa/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.form = response.data
          this.loader.hide()
        } else {
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
          this.loader.hide()
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }

    },

    async editarEvaluacion(){
      this.loadingSpiner()

      const api = process.env.VUE_APP_IP_BACKEND + `/empresa/editarEvaluacion/`
      // const api = "http://localhost:8181/ta-ms-users/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      const dataParaEnviar = {
        evaluacion: this.form.evaluacion,
        observacion: this.form.observacion
      }
      try {
        //PETICION
        let response = await axios.put(apiId,dataParaEnviar, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {

          this.showToastSuccess('Evaluacion de la empresa ' + this.form.empresa + " editada.", '¡Éxito!', this.notificationSystem.options.success);
          this.loader.hide();
        } else {
          this.loader.hide();
          this.showToastError('¡Algo salió mal!', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.loader.hide();
        this.error_msg = error;
        if (this.error_msg.request.status == 401) {
          this.showToastError('¡Sesión expirada, ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error);
          this.$expirarToken();
        } else {
          this.showToastError('¡Intente nuevamente más tarde!', 'UPS!', this.notificationSystem.options.error);
        }
      }
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    volver(){
      this.$router.push({path: '/agendamiento/evaluacionEmpresa'})
    },
  },
  mounted() {
    this.getEmpresa();
  },
  created() {
  },
}

</script>
<style scoped>

</style>