<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Estanques Tracto</li>
    </ol>
<!--    <h1 class="page-header">{{ nombreFormulario }}</h1>-->
    <h2>Revisión Mecánica y de Accesorios/Complementos <small>haga click en el item para agregar observaciones</small></h2>
    <panel :title="nombreFormulario">
      <div class="">
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
      >
        <template v-slot:table-row="{ row, column }">
          <div v-if="column.field === 'acciones'"> <!-- Asume que la columna combinada se llama 'acciones' -->
            <!-- Switch de Cumple -->
            <div v-if="!row.valorNoAplica">
              <label class="switch">
                <input type="checkbox"
                       v-model="row.valorEnabled"
                       @change="handleCheckboxChange(row)">
                <span class="slider"></span>
              </label>
            </div>
            <!-- Checkbox de No Aplica debajo del switch -->
            <div v-if="!row.valorEnabled" style="display: flex; align-items: center; margin-top: 4px;">
              <input type="checkbox"
                     v-model="row.valorNoAplica"
                     @change="handleNoAplicaCheckboxChange(row)">
              <span style="margin-left: 8px;">N/A</span>
            </div>
          </div>
          <div v-else-if="column.field === 'nombre'">
            <div @click="toggleObservaciones(row)"
                 :class="{ 'item-malo': row.esMalo }">
              {{ row.nombre }}
            </div>
            <!-- Campo de texto de observaciones debajo del nombre -->
            <div v-if="row.mostrarObservaciones">
               <textarea v-if="row.mostrarObservaciones"
                         v-model="row.observaciones"
                         @blur="guardarObservaciones(row)"
                         class="observaciones-textarea"
                         placeholder="Escribe tus observaciones aquí...">
              </textarea>
            </div>
          </div>
        </template>
      </vue-good-table>

      <template>
        <div>
          <table class="styled-table mt-4">
            <thead>
            <tr>
              <th class="small-column d-none d-sm-table-cell ">Extintor N°</th>
              <th>Sello N°</th>
              <th>Vencimiento</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(extintor, index) in extintores">
              <tr :key="'extintor-' + extintor.id">
                <td @click="toggleObservacionesExtintor(extintor)" :class="{'fecha-vencida': isFechaVencida(extintor.vencimiento)}" class="d-none d-sm-table-cell">{{ index + 1 }}</td>
                <td :class="{'fecha-vencida': isFechaVencida(extintor.vencimiento)}"><input class="centered-input" type="text" v-model="extintor.sello" placeholder="Número de sello"/></td>
                <td :class="{'fecha-vencida': isFechaVencida(extintor.vencimiento)}">
                  <div class="datepicker centered-content">
                    <Datepicker v-model="extintor.vencimiento" :language="es" mondayFirst :format="customFormatter"></Datepicker>
                  </div>
                </td>
              </tr>
              <!-- Agrega una clave única a la fila de observaciones -->
              <tr v-if="extintor.mostrarObservaciones" :key="'observaciones-' + extintor.id" class="observaciones-extintores">
                <td colspan="3">
                  <textarea v-model="extintor.observaciones" placeholder="Escribe tus observaciones aquí..."></textarea>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
          <div class="table-actions">
            <button class="btn btn-add" @click="addExtintor">Agregar Extintor</button>
            <button class="btn btn-remove" @click="removeExtintor" :disabled="extintores.length === 1">Eliminar Extintor</button>
          </div>
        </div>
      </template>

      <button type="button" class="btn btn-primary mt-3" v-on:click="guardarYContinuar()">Guardar y continuar >></button>
    </panel>
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from "vuejs-datepicker/dist/locale";

export default {
  components:{
    Datepicker
  },
  name: "CheckListEstanquesTracto",
  data() {
    return {
      es:es,
      extintores: [
        { id: 1, sello: '', vencimiento: '', observaciones: '', mostrarObservaciones: false }
      ],
      nombreFormulario: '',
      columns: [
        {
          label: 'N°',
          field: 'numero',
          width: '50px',
          sortable: false,
        },
        {
          label: 'ITEM/COMPONENTE INSPECCIONADO',
          field: 'nombre',
          sortable: false,
        },
        {
          label: 'Cumple', // Columna combinada
          field: 'acciones', // Este campo no necesita coincidir con un campo en tus datos, ya que lo manejarás personalizadamente en el slot.
          sortable: false, // Asume que no quieres ordenar por esta columna
          width: '80px',
        },
        // Elimina las columnas individuales 'Cumple' y 'No Aplica' si existían previamente
      ],
      ordenActual: null,
      ultimoOrden: null,
      rows: [],
      checkListEstanquesTracto: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods: {
    isFechaVencida(fechaVencimiento) {
      const fechaActual = new Date();
      fechaActual.setHours(0, 0, 0, 0); // Establece la hora al inicio del día (medianoche)

      const fechaVenc = new Date(fechaVencimiento);
      fechaVenc.setHours(0, 0, 0, 0); // Asegura que la comparación sea solo por fecha

      return fechaVenc < fechaActual; // Devuelve true si la fecha de vencimiento es anterior a la fecha actual
    },
    asignarData() {
      this.rows = this.checkListEstanquesTracto;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    convertirFecha(fechaIso) {
      const fecha = new Date(fechaIso);
      return fecha.toISOString().split('T')[0]; // Esto devuelve la fecha en formato 'YYYY-MM-DD'
    },
    addExtintor() {
      const newId = this.extintores.length + 1;
      const newExtintor = {
        id: newId,
        sello: '',
        vencimiento: '',
        observaciones: '',
        mostrarObservaciones: false
      };

      this.extintores.push(newExtintor);
    },
    removeExtintor() {
      if (this.extintores.length > 1) {
        this.extintores.pop();
      }
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    handleCheckboxChange(item) {
      const index = this.checkListEstanquesTracto.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListEstanquesTracto, index, {
          ...this.checkListEstanquesTracto[index],
          valorEnabled: !this.checkListEstanquesTracto[index].valorEnabled,
          // Desactivar 'No Aplica' si 'Habilitado' está activado
          valorNoAplica: this.checkListEstanquesTracto[index].valorEnabled ? false : this.checkListEstanquesTracto[index].valorNoAplica,
          mostrarObservaciones: this.checkListEstanquesTracto[index].mostrarObservaciones
        });
      }
    },
    handleNoAplicaCheckboxChange(item) {
      const index = this.checkListEstanquesTracto.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListEstanquesTracto, index, {
          ...this.checkListEstanquesTracto[index],
          valorNoAplica: !this.checkListEstanquesTracto[index].valorNoAplica,
          // Desactivar 'Habilitado' si 'No Aplica' está activado
          valorEnabled: this.checkListEstanquesTracto[index].valorNoAplica ? false : this.checkListEstanquesTracto[index].valorEnabled,
          mostrarObservaciones: this.checkListEstanquesTracto[index].mostrarObservaciones,
          ocultarSwitch: this.checkListEstanquesTracto[index].valorNoAplica ? false : true
        });
      }
    },
    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },
    toggleObservacionesExtintor(extintor) {
      extintor.mostrarObservaciones = !extintor.mostrarObservaciones;
    },
    guardarObservaciones(item) {
      const index = this.checkListEstanquesTracto.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListEstanquesTracto[index], 'observaciones', item.observaciones);
      }
    },
    validarChecklist() {
      // Verifica que todos los extintores estén completos
      for (let extintor of this.extintores) {
        if (!extintor.sello || !extintor.vencimiento) {
          return false; // Si algún extintor está incompleto
        }
      }

      return true; // Si todas las validaciones pasan
    },
    async guardarYContinuar() {
      this.loadingSpiner()
      if (!this.validarChecklist()) {
        this.loader.hide();
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/guardarItemsYcontinuar`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let inspeccionId = JSON.parse(localStorage.getItem('inspeccionId'));
      let formularioId = JSON.parse(localStorage.getItem('formularioId'));
      let ordenActual = JSON.parse(localStorage.getItem('ordenActual'));
      let ultimoOrden = JSON.parse(localStorage.getItem('ultimoOrden'));
      const hoy = new Date().toISOString().split('T')[0];
      const extintoresParaEnviar = this.extintores.map((extintor) => {
        const fechaVencimiento = this.convertirFecha(extintor.vencimiento);
        return {
          id: extintor.id,
          sello: extintor.sello,
          vencimiento: fechaVencimiento, // Asegúrate de que la fecha esté en el formato que espera tu backend
          observaciones: extintor.observaciones,
          valorBooleano: fechaVencimiento >= hoy
        };
      });
      const datosParaEnviar = {
        items: this.checkListEstanquesTracto.map(item => ({
          id: item.id,
          valorEnabled: item.valorEnabled,
          valorNoAplica: item.valorNoAplica,
          observaciones: item.observaciones
        })),
        inspeccionId: inspeccionId,
        formularioId: formularioId,
        ordenActual : ordenActual,
        ultimoOrden : ultimoOrden,
        extintores: extintoresParaEnviar,
      };
      try {
        //PETICION
        let response = await axios.post(api,datosParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          localStorage.setItem('ordenActual', response.data.ordenActual);
          localStorage.setItem('formularioId', response.data.formularioId);
          this.asignarData()
          this.$router.push({name: response.data.nombreFormularioSinEspacios, params: {
              formularioId: response.data.formularioId,
              nombreFormularioSinEspacios: response.data.nombreFormularioSinEspacios,
              nombreFormulario: response.data.nombreFormulario,
              ultimoOrden: response.data.ultimoOrden,
              ordenActual: response.data.ordenActual,
              inspeccionId: response.data.inspeccionId,
            }})
          this.showToastSuccess('Checklist Estanques Tracto Creado Exitósamente !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async checkListEstanquesTractoData(formularioId) {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/itemFormulario/${formularioId}`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          const apiMalos = process.env.VUE_APP_IP_BACKEND + '/inspeccion/obtenerItemesMalosCheckListDiarioAnterior/'
          const inspeccionId = this.inspeccionId; // Asume que ya tienes 'inspeccionId' en localStorage
          let responseMalos = await axios.get(apiMalos, {
            params: { formularioId, inspeccionId },
            headers: {"Authorization": `Bearer ${tokenHeader}`}
          });
          const itemsMalos = responseMalos.status === 200 ? responseMalos.data.itemesMalos : [];
          this.checkListEstanquesTracto = response.data.map((item, index) => {
            const esMalo = itemsMalos.some(malo => malo.itemId === item.id);
            return {
              id: item.id,
              numero: index + 1,
              nombre: item.texto,
              mostrarEnabled: item.enabled, // Determina si mostrar el checkbox
              mostrarNoAplica: item.noAplica, // Determina si mostrar el checkbox
              valorEnabled: false, // Estado inicial desmarcado
              valorNoAplica: false, // Estado inicial desmarcado
              mostrarObservaciones: false, // Inicialmente oculto
              observaciones: '',
              esMalo
            };
          });
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },

    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.nombreFormulario = this.$route.params.nombreFormulario;
    this.inspeccionId = this.$route.params.inspeccionId;
    const formularioId = this.$route.params.formularioId;
    if (formularioId) {
      this.checkListEstanquesTractoData(formularioId);
    }
  }
}
</script>

<style scoped>


.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table thead tr th {
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table tbody tr td {
  padding: 12px 15px;
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}

.styled-table input[type="text"],
.styled-table .datepicker input {
  width: 95%;
  padding: 0.4375rem 0.75rem;
  margin: auto;
  display: block;
  background-color: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 600;
  box-shadow: none;
  outline: none;
}


.styled-table input[type="text"]:focus,
.styled-table .datepicker input:focus {
  border-color: #495057;
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
}

.styled-table .datepicker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-actions {
  text-align: right; /* Alinea los botones a la derecha */
  margin-top: 10px; /* Añade un espacio por encima de los botones */
}

.btn {
  padding: 0.5rem 1rem; /* Espaciado dentro del botón */
  margin-left: 10px; /* Espacio entre botones */
  border: none; /* Elimina el borde */
  color: white; /* Texto en color blanco */
  font-weight: bold; /* Fuente en negrita */
  cursor: pointer; /* Cursor como puntero para indicar que es clickeable */
  border-radius: 0.25rem; /* Bordes redondeados */
}

.btn-add {
  background-color: #007bff; /* Color azul para el botón de agregar, similar al de "Guardar y continuar" */
}

.btn-remove {
  background-color: #dc3545; /* Color rojo para el botón de eliminar */
}

.btn-remove:disabled {
  background-color: #6c757d; /* Color gris para el botón deshabilitado */
}

/* Estilos adicionales para cuando los botones están enfocados o activos */
.btn:focus, .btn:active {
  outline: none; /* Elimina el contorno al enfocar */
  box-shadow: none; /* Elimina cualquier sombra */
}

.small-column {
  width: 10%; /* O ajusta según necesites para la columna de Extintor N° */
}

.centered-input, .centered-content {
  text-align: center;
  display: block;
  margin: auto;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-left: 5px;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
.styled-table textarea {
  width: 100%; /* Asegura que el textarea ocupe todo el ancho disponible */
  padding: 10px; /* Añade algo de relleno para mejor legibilidad */
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
  border: 1px solid #cecece; /* Opcional: agrega un borde al textarea */
  border-radius: 4px; /* Opcional: bordes redondeados para el textarea */
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff0000;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 13px;

}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2ecc1d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2ecc1d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
.hidden {
  display: none;
}
.fecha-vencida {
  background-color: rgba(238, 105, 105, 0.9);
  color: white; /* Mantiene el color del texto en blanco para asegurar que sea legible */
}
.observaciones-extintores {
  background-color: rgba(238, 105, 105, 0.9); /* Color de fondo rojo suave */
  color: black; /* Cambia el color del texto para las observaciones si es necesario */
  /* Otros estilos específicos para el textarea de observaciones */
}
.item-malo {
  color: red;
}
.datepicker /deep/ .vdp-datepicker__calendar {
  top: auto; /* Resetea cualquier valor previo */
  bottom: 100%;
}
@media (max-width: 700px) {
  .datepicker /deep/ input[type="text"]  {
    width: 100px !important; /* Usa un valor adecuado */
  }
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -160%; /* Centra horizontalmente */
  }
}
@media (min-width: 701px) and (max-width: 1200px) {
  .datepicker /deep/ input[type="text"]  {
    width: 100px !important; /* Usa un valor adecuado */
  }
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -160%; /* Centra horizontalmente */
  }
}
</style>
