<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Listar</a></li>
      <li class="breadcrumb-item active">Lugar</li>
    </ol>
    <h1 class="page-header">Listado de Lugares <small></small></h1>
    <panel title="">
      <div class="">
        <vue-excel-xlsx class="btn btn-outline-success"
                        :data="rows"
                        :columns="columns"
                        :file-name="'Lugares Agendamiento'"
                        :file-type="'xlsx'"
                        :sheet-name="'Hoja 1'"
        >
          Exportar a excel
        </vue-excel-xlsx>
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true,  position: 'bottom' }"
          :selectOptions="{
					selectOnCheckboxOnly: true,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
					}"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'nombre'">
            <router-link v-bind:to="'/show/lugar/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: rgb(5 180 237);opacity: 2;">
                {{ props.row.nombre }}
              </span>
            </router-link>
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ListaLugar",
  data() {
    return {
      columns: [{
        label: 'Nombre',
        field: 'nombre',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
          label: 'Descripción',
          field: 'descripcion',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },{
          label: 'Latitud',
          field: 'latitud',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },{
          label: 'Longitud',
          field: 'longitud',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },{
        label: 'Comuna',
        field: 'comuna',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
        }
      ],
      rows: [
        {}
      ],
      listLugar: [],
    }
  },
  methods: {
    asignarData() {
      this.rows = this.listLugar;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },

    async listaLugar() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacion'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listLugar = response.data
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.listaLugar()
  }
}
</script>

<style scoped>

</style>
