<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Prueba de Válvulas de Alivio</li>
    </ol>
    <h1 class="page-header">Prueba de Válvulas de Alivio</h1>
    <panel title="">
      <div class="row m-3">
        <h4 class="m-3"><u>Especificaciones Prueba de Válvula de Alivio</u></h4>
        <template>
          <table class="table table-striped">
            <tr v-for="item in itemListInput" :key="item.itemId">
              <td class="col-sm-3">
                <strong>
                  {{item.nombre}}
                </strong>
              </td>
              <td class="col-sm-9">
                <input type="text" class="form-control" v-model="item.valor">
              </td>
            </tr>
            <tr v-if="itemGraduacion">
              <td class="col-sm-3">
                <strong>
                  {{itemGraduacion.nombre}}
                </strong>
              </td>
              <td class="col-sm-9">
                <input type="text" class="form-control" v-model="itemGraduacion.valor" @blur="cambiarDescripcion">
              </td>
            </tr>
            <tr v-if="itemDescripcion">
              <td class="col-sm-3">
                <strong>
                  {{itemDescripcion.nombre}}
                </strong>
              </td>
              <td class="col-sm-9">
                <textarea class="form-control" rows="4" v-model="itemDescripcion.valor"></textarea>
              </td>
            </tr>
            <tr v-if="itemValvulaOperativa">
              <td class="col-sm-3">
                <strong>
                  {{itemValvulaOperativa.nombre}}
                </strong>
              </td>
              <td class="col-sm-9">
                <input type="text" class="form-control" v-model="itemValvulaOperativa.valor">
              </td>
            </tr>
          </table>
        </template>
      </div>
      <hr>
      <div class="row m-3" v-if="itemConclusion">
        <div class="col-sm-6">
          <h4 class="m-3"><u>Conclusión Prueba</u></h4>
          <div class="form-check form-switch">
            <label class="form-label" for="itemConclusion"><strong>Prueba Aprobada</strong></label>
            <input class="form-check-input" id="itemConclusion" type="checkbox" v-model="itemConclusion.valorEnabled">
          </div>
          <label class="form-label" for="observacionesConclusion">Observaciones de Prueba</label>
          <textarea id="observacionesConclusion" class="form-control" rows="4" v-model="itemConclusion.observaciones"></textarea>
        </div>
      </div>
      <div class="row m-3">
        <h4 class="m-3"><u>Fotos</u></h4>
        <div class="col-sm-4 text-center" v-for="(foto,index) in fotoList" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="foto.tieneFotoSubida" type="button" class="btn btn-danger btn-sm mt-2" @click="modalEliminarFoto(foto.archivoId)">
            <i class="fa fa-trash"></i>
          </button>
          <button v-else type="button" class="btn btn-primary btn-sm mt-2" @click="modalSubirFoto('modal-subir-foto', index)">
            <i class="fa fa-camera"></i>
          </button>
        </div>
      </div>
      <button type="button" class="btn btn-secondary m-3" v-on:click="volver()"><i class="fa fa-caret-left"></i> Volver</button>
      <button type="button" class="btn btn-primary m-3" v-on:click="guardarYContinuar()">Guardar y continuar <i class="fa fa-caret-right"></i><i class="fa fa-caret-right"></i></button>
      <modal name="modal-subir-foto"  @before-close="antesDeCerrarModal" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">Subir Foto</h2>
          <div class="form-group">
            <input type="file" accept="image/*" @change="handleFileUpload()" />
            <img v-if="imagenVistaPrevia" :src="imagenVistaPrevia" alt="Vista previa de la imagen" style="max-width: 200px; height: auto; margin-top: 20px;">
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" @click="toggleModal('modal-subir-foto')">Cancelar</button>
            <button class="btn btn-primary" @click="subirFoto">Subir Foto</button>
          </div>
        </div>
      </modal>
      <modal name="modal-borrar-foto" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">¿Eliminar Foto?</h2>
          <div class="form-group">
            <h4>Esta acción es irreversible.</h4>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" style="margin-right: 7px" @click="toggleModal('modal-borrar-foto')">Cancelar</button>
            <button class="btn btn-danger" @click="borrarFoto">Borrar</button>
          </div>
        </div>
      </modal>
    </panel>
  </div>
</template>
<script>
import imageCompression from "browser-image-compression";
import axios from "axios";

export default {
  name: "FormularioHermeticidad",
  data() {
    return {
      itemList: [],
      fotoList: [],
      itemListInput: [],
      itemConclusion: {},
      itemDescripcion: {},
      itemValvulaOperativa: {},
      itemGraduacion: {},
      indiceFotoListSubir: 0,
      nombreFotoSubida: null,
      itemCategoriaIdActual: null,
      fotoIdAEliminar: null,
      nombreItemActual: '',
      imagenVistaPrevia: null,
      especificacionesHermeticidadPorFormato: {
        "cal_2_ejes":[
          {"texto": "Presión Neumática"},
        ],
        "cal_3_ejes":[
          {"texto": "Presión Neumática"},
        ],
        "emulsion_matriz":[
          {"texto": "Presión Neumática"},
        ],
        "nitrato_amonio":[
          {"texto": "Presión Neumática"},
        ],
        "nitrato_amonio_2_ejes":[
          {"texto": "Presión Neumática"},
        ],
        "nitrato_amonio_inoxidable":[
          {"texto": "Presión Neumática"},
        ],
        "solucion_ms_templo":[
          {"texto": "Presión Neumática"},
        ],
        "solucion_randon":[
          {"texto": "Presión Neumática"},
        ],
      },
      especificacionDescripcionPorFormato: {
        "cal_2_ejes":               'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "cal_3_ejes":               'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "emulsion_matriz":          'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "nitrato_amonio":           'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "nitrato_amonio_2_ejes":    'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "nitrato_amonio_inoxidable":'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "solucion_ms_templo":       'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
        "solucion_randon":          'Se instala válvula en banco de prueba y se carga aire controlado a la presión de graduación [GRADUACION]. Luego de verificar que la válvula libera aire comprimido de acuerdo a la presión graduada, se considera como APROBADA.',
      },
      especificacionDescripcionUsar:'',
      especificacionUsar: [],
    }
  },
  methods: {
    storeCurrentValues() {
      this.currentValues = this.itemList.reduce((acc, item) => {
        acc[item.itemId] = item.valor;
        return acc;
      }, {});
      if (this.itemGraduacion) {
        this.currentGraduacionValue = this.itemGraduacion.valor;
      }
      if (this.itemDescripcion) {
        this.currentDescriptionValue = this.itemDescripcion.valor;
      }
      if (this.itemValvulaOperativa) {
        this.currentValvulaOperativaValue = this.itemValvulaOperativa.valor;
      }
      if (this.itemConclusion) {
        this.currentConclusionValues = {
          valorEnabled: this.itemConclusion.valorEnabled,
          observaciones: this.itemConclusion.observaciones
        };
      }
    },
    restoreCurrentValues() {
      if (this.currentValues) {
        this.itemList.forEach(item => {
          if (this.currentValues[item.itemId] !== undefined) {
            item.valor = this.currentValues[item.itemId];
          }
        });
      }
      if (this.itemGraduacion && this.currentGraduacionValue !== undefined) {
        this.itemGraduacion.valor = this.currentGraduacionValue;
      }
      if (this.itemDescripcion && this.currentDescriptionValue !== undefined) {
        this.itemDescripcion.valor = this.currentDescriptionValue;
      }
      if (this.itemValvulaOperativa && this.currentValvulaOperativaValue !== undefined) {
        this.itemValvulaOperativa.valor = this.currentValvulaOperativaValue;
      }
      if (this.itemConclusion && this.currentConclusionValues) {
        this.itemConclusion.valorEnabled = this.currentConclusionValues.valorEnabled;
        this.itemConclusion.observaciones = this.currentConclusionValues.observaciones;
      }
    },
    async volver(){
      this.$irVistaInspeccionProgramada(this.inspeccionId, 'valvulaAlivio', true)
    },

    async guardarYContinuar(){
      let itemListEnvio = this.itemListInput.map(item => ({
        itemId:        item?.itemId,
        valor:         item?.valor,
        valorEnabled:  item?.valorEnabled,
        noAplica:      item?.valorNoAplica,
        observaciones: item?.observaciones
      }))
      itemListEnvio.push(
          {
            itemId:        this?.itemGraduacion?.itemId,
            valor:         this?.itemGraduacion?.valor,
            valorBooleano: this?.itemGraduacion?.valorEnabled,
            valorNoAplica: this?.itemGraduacion?.valorNoAplica,
            observaciones: this?.itemGraduacion?.observaciones
          }
      )
      itemListEnvio.push(
          {
            itemId:        this?.itemDescripcion?.itemId,
            valor:         this?.itemDescripcion?.valor,
            valorBooleano: this?.itemDescripcion?.valorEnabled,
            valorNoAplica: this?.itemDescripcion?.valorNoAplica,
            observaciones: this?.itemDescripcion?.observaciones
          }
      )
      itemListEnvio.push(
          {
            itemId:        this?.itemValvulaOperativa?.itemId,
            valor:         this?.itemValvulaOperativa?.valor,
            valorBooleano: this?.itemValvulaOperativa?.valorEnabled,
            valorNoAplica: this?.itemValvulaOperativa?.valorNoAplica,
            observaciones: this?.itemValvulaOperativa?.observaciones
          }
      )
      let ponderacionId = null
      if (!this.itemConclusion.valorEnabled) {
        ponderacionId = 3
      } else if (this.itemConclusion.valorEnabled) {
        ponderacionId = 1
      }
      itemListEnvio.push(
          {
            itemId:        this?.itemConclusion?.itemId,
            valor:         this?.itemConclusion?.valor,
            valorBooleano: this?.itemConclusion?.valorEnabled,
            valorNoAplica: this?.itemConclusion?.valorNoAplica,
            observaciones: this?.itemConclusion?.observaciones,
            ponderacionId: ponderacionId
          }
      )
      let exito = await this.$guardarItemsInspeccionProgramada(itemListEnvio,this.inspeccionId, 'valvulaAlivio')
      if(exito){
        this.$irVistaInspeccionProgramada(this.inspeccionId, 'valvulaAlivio', false)
      }
    },

    cambiarDescripcion(){
      this.itemDescripcion.valor = this.especificacionDescripcionUsar.replace('[GRADUACION]', this.itemGraduacion.valor)
    },

    async itemListValvulaAlivio(inspeccionId) {
      this.storeCurrentValues();
      let data = await this.$itemListMasInfoFormularioInspProgramada(inspeccionId, 'valvulaAlivio')
      if(data){
        this.itemList = data.itemList.map((item) => {
          return {
            itemId:        item.itemId,
            index:         item.index,
            nombre:        item.texto,
            codigo:        item.codigo,
            enabled:       item.enabled,
            noAplica:      item.noAplica,
            conclusion:    item.conclusion,
            textArea:      item.textArea,
            valor:         item.valor,
            guardado:      item.guardado,
            valorEnabled:  item.valorBooleano,
            observaciones: item.observaciones,
          };
        });
        this.especificacionUsar = this.especificacionesHermeticidadPorFormato[data.formularioTipoCodigo]
        this.especificacionDescripcionUsar = this.especificacionDescripcionPorFormato[data.formularioTipoCodigo]
        this.fotoList = data.fotoList
        this.asignarData()
        this.restoreCurrentValues();
      }
    },


    asignarData() {
      this.itemListInput = this.itemList.filter((item) => item?.conclusion === false && item?.textArea === false && !item?.codigo.includes('valvula_operativa') && !item?.codigo.includes('graduacion'))
      this.itemDescripcion  = this.itemList.find((item) => item?.textArea === true)
      if(!this.itemDescripcion.guardado) this.$set(this.itemDescripcion,'valor', this.especificacionDescripcionUsar)
      this.itemConclusion   = this.itemList.find((item) => item?.conclusion === true)
      this.itemValvulaOperativa = this.itemList.find((item) => item?.codigo.includes('valvula_operativa'))
      this.itemGraduacion = this.itemList.find((item) => item?.codigo.includes('graduacion'))

      for(let i= 0; i < this.itemListInput.length; i++){
        if(this.especificacionUsar[i]){
          if(!this.itemListInput[i].guardado) this.$set(this.itemListInput[i],'valor', this.especificacionUsar[i].texto)
        }
      }
    },
    modalEliminarFoto(fotoId) {
      this.fotoIdAEliminar = fotoId;
      this.toggleModal('modal-borrar-foto');
    },

    async borrarFoto() {
      this.storeCurrentValues();
      this.$loadingSpiner(); // Muestra el spinner de carga

      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/borrarFoto/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId, // Asegúrate de que inspeccionId está definido en tu componente
        imagen: {
          id: this.fotoIdAEliminar // Usa directamente el ID de la foto a eliminar
        },
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          // Encuentra el índice usando el ID para eliminarlo de la lista
          const index = this.fotoList.findIndex(f => f.id === this.fotoIdAEliminar);
          if (index !== -1) this.fotoList.splice(index, 1);
          this.itemListValvulaAlivio(this.inspeccionId);
          this.toggleModal('modal-borrar-foto'); // Cierra el modal
          this.$showToastSuccess('Foto Eliminada Exitósamente!', 'Genial!', this.notificationSystem.options.success);
        } else {
          this.$showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la foto.', 'Error', this.notificationSystem.options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide(); // Oculta el spinner de carga
        }
      }
    },

    antesDeCerrarModal() {
      this.imagenVistaPrevia = null;
    },

   async handleFileUpload() {
      let indiceFoto = this.indiceFotoListSubir
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.fotoList[indiceFoto].nombreFotoSubida = file.name

        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 300, // Compress the image if it's larger than this
          useWebWorker: true
        };
        // this.nombreFotoSubida = file.name
        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagenVistaPrevia = e.target.result; // URL para vista previa
            this.$set(this.fotoList[indiceFoto], 'fotoSubidaBase64', e.target.result); // Asignación reactiva
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error during image compression:', error);
        }
      }
    },

    async subirFoto(){
      this.storeCurrentValues();
      let foto = this.fotoList[this.indiceFotoListSubir]
      const datosFoto = {
        inspeccionId:     this.inspeccionId,
        fotoSubidaBase64: foto.fotoSubidaBase64,
        nombreFotoSubida: foto.nombreFotoSubida,
        categoria:        foto.categoria,
      };
      this.toggleModal('modal-subir-foto')
      let urlImagen = await this.$subirFotoInspeccion(datosFoto)
      if(urlImagen){
        this.$set(this.fotoList[this.indiceFotoListSubir],'tieneFotoSubida', true)
        this.itemListValvulaAlivio(this.inspeccionId);
      }
    },

    modalSubirFoto(modalId, indiceFotoList){
      this.indiceFotoListSubir = indiceFotoList
      this.toggleModal(modalId)
    },

    toggleModal(modalId){
      this.$modal.toggle(modalId)
    }
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.inspeccionId;
    if (this.inspeccionId) {
      this.itemListValvulaAlivio(this.inspeccionId);
    }
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.modal-container{
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
@media (max-width: 768px) {
  .modal-container {
    position: fixed;
    top: 150px;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, 0%);
  }
}
</style>
