<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Listar</a></li>
      <li class="breadcrumb-item active">Empresa Transportista</li>
    </ol>
    <h1 class="page-header">Lista Empresas Transportistas<small></small></h1>
    <panel title="">
      <div class="">
        <vue-excel-xlsx class="btn btn-outline-success"
                        :data="rows"
                        :columns="columns"
                        :file-name="'Empresas'"
                        :file-type="'xlsx'"
                        :sheet-name="'Hoja 1'"
        >
          Exportar a excel
        </vue-excel-xlsx>
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true,  position: 'bottom' }"
          :selectOptions="{
					selectOnCheckboxOnly: true,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
					}"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'razonSocial'">
            <router-link v-bind:to="'/showEmpresa/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: rgb(5 180 237);opacity: 2;">
                {{ props.row.razonSocial }}
              </span>
            </router-link>
          </span>
          <span v-else-if="props.column.field == 'tipoEmpresa'">
            <ul style="margin-left: -10px">
              <li v-for="tipo in props.row.tipoEmpresa" :key="tipo">{{ tipo }}</li>
            </ul>
          </span>
          <span v-else-if="props.column.field == 'rut'">
                {{ formatRut(props.row.rut, props.row.codigoVerificador) }}
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ListaEmpresa",
  data() {
    return {
      columns: [{
        label: 'Razón Social',
        field: 'razonSocial',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      }, {
        label: 'Rut',
        field: 'rut',
        width: '15%',
        tdClass: 'text-left text-nowrap',
        thClass: 'text-left text-nowrap'
      }, {
        label: 'Tipo',
        field: 'tipoEmpresa',
        width: '15%',
        tdClass: 'text-left text-nowrap',
        thClass: 'text-left text-nowrap'
      },
      ],
      rows: [
        {}
      ],
      listEmpresa: [],
    }
  },
  methods: {
    asignarData() {
      this.rows = this.listEmpresa;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    formatRut(rut, dv) {
      if (rut == null || dv == null) {
        return '';
      }
      let rutStr = rut.toString();
      let result = rutStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return `${result}-${dv}`;
    },

    async listaEmpresa() {
      this.loadingSpiner()
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const username = obtenerToken.username
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api =`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaTransportista?username=${username}`

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listEmpresa = response.data
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.listaEmpresa()
  }
}
</script>

<style scoped>

</style>
