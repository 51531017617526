<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">CheckList</li>
    </ol>
    <h1 class="page-header">INSPECCION a EQUIPO de TRANSPORTE</h1>
    <h2>CHECK LIST GENERAL & REGISTRO DE OBSERVACIONES  <small>haga click en el item para agregar observaciones</small></h2>
    <panel title="">
      <div class="">
        <vue-excel-xlsx class="btn btn-outline-success"
                        :data="rows"
                        :columns="columns"
                        :file-name="'CheckList General'"
                        :file-type="'xlsx'"
                        :sheet-name="'Hoja 1'"
        >
          Exportar a excel
        </vue-excel-xlsx>
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
      >
        <template v-slot:table-row="{ row, column }">
    <span v-if="column.field === 'valorEnabled'">
      <input type="checkbox" v-if="!row.valorNoAplica && !row.mejora" v-model="row.valorEnabled" @change="handleCheckboxChange(row)">
    </span>
          <span v-else-if="column.field === 'mejora'">
      <input type="checkbox" v-if="!row.valorEnabled && !row.valorNoAplica" @change="handleCheckboxChangeMejora(row)">
    </span>
          <span v-else-if="column.field === 'valorNoAplica'">
      <input type="checkbox" v-if="!row.valorEnabled && !row.mejora" @change="handleNoAplicaCheckboxChange(row)">
    </span>
          <div v-else-if="column.field === 'nombre'" class="text-as-paragraph">
            <div @click="toggleObservaciones(row)">
              {{ row.nombre }}
            </div>
            <div v-if="row.numero === 24" style="text-align: center;">
              <input class="form-control"  placeholder="Ingrese hubodómetro" type="number" v-model="form.hubodometro" @click.stop style=" width: 100%; ">
            </div>
            <!-- Campo de texto de observaciones debajo del nombre -->
            <div v-if="row.mostrarObservaciones">
             <textarea v-model="row.observaciones"
                       @blur="guardarObservaciones(row)"
                       class="observaciones-textarea"
                       placeholder="Escribe tus observaciones aquí...">
             </textarea>
            </div>
            <!-- Aplicar color de fondo según el estado del ítem -->
            <div :class="{
              'fila-verde': row.fondoVerde && !row.fondoAmarillo && !row.fondoRojo,
              'fila-amarilla': row.fondoAmarillo && !row.fondoVerde && !row.fondoRojo,
              'fila-roja': row.fondoRojo && !row.fondoVerde && !row.fondoAmarillo
              }">
            </div>
          </div>
        </template>
      </vue-good-table>
      <button type="button" class="btn btn-primary mt-3" v-on:click="guardarYContinuar()" :disabled="!isAnyCheckboxChecked">Guardar y continuar >></button>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ChecklistGeneral",
  data() {
    return {
      nombreFormulario: '',
      columns: [
        { label: 'N°', field: 'numero', width: '50px', tdClass: 'd-none d-sm-table-cell',
          thClass: 'd-none d-sm-table-cell', sortable: false, },
        { label: 'ITEM/COMPONENTE INSPECCIONADO', field: 'nombre', sortable: false,},
        { label: 'Cumple', field: 'valorEnabled', sortable: false, width: '60px', responsiveClass: 'd-none d-md-table-cell'},
        { label: 'Requiere Mejora', field: 'mejora', width: '80px',  sortable: false, thClass: 'd-none d-sm-table-cell', tdClass: 'd-none d-sm-table-cell' },
        { label: 'No cumple', field: 'valorNoAplica', width: '60px', sortable: false, responsiveClass: 'd-none d-md-table-cell'},
      ],
      ordenActual: null,
      ultimoOrden: null,
      rows: [],
      form: {
        hubodometro: '',
      },
      checkListGeneral: [
        {
          fondoVerde:false,
          fondoAmarillo:false,
          fondoRojo:false,
        }
      ],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  computed: {
    isAnyCheckboxChecked() {
      return this.checkListGeneral.some(item => item.valorEnabled || item.mejora || item.valorNoAplica);
    }
  },
  methods: {
    asignarData() {
      this.rows = this.checkListGeneral;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    handleCheckboxChange(item) {
      const index = this.checkListGeneral.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListGeneral, index, {
          ...this.checkListGeneral[index],
          valorEnabled: !this.checkListGeneral[index].valorEnabled,
          // Desactivar 'No Aplica' si 'Habilitado' está activado
          valorNoAplica: this.checkListGeneral[index].valorEnabled ? false : this.checkListGeneral[index].valorNoAplica,
          // valorNoAplica: false,
          mejora: this.checkListGeneral[index].valorEnabled ? false : this.checkListGeneral[index].mejora,
          mostrarObservaciones: this.checkListGeneral[index].mostrarObservaciones,
          fondoVerde: !this.checkListGeneral[index].valorEnabled,
          fondoAmarillo: false,
          fondoRojo: false,
        });
      }
    },
    handleNoAplicaCheckboxChange(item) {
      const index = this.checkListGeneral.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListGeneral, index, {
          ...this.checkListGeneral[index],
          valorNoAplica: !this.checkListGeneral[index].valorNoAplica,
          // Desactivar 'Habilitado' si 'No Aplica' está activado
          valorEnabled: this.checkListGeneral[index].valorNoAplica ? false : this.checkListGeneral[index].valorEnabled,
          // valorEnabled: false,
          mejora: this.checkListGeneral[index].valorNoAplica ? false : this.checkListGeneral[index].mejora,
          mostrarObservaciones: this.checkListGeneral[index].mostrarObservaciones,
          fondoVerde: false,
          fondoAmarillo: false,
          fondoRojo: !this.checkListGeneral[index].valorNoAplica,
        });
      }
    },
    handleCheckboxChangeMejora(item) {
      const index = this.checkListGeneral.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListGeneral, index, {
          ...this.checkListGeneral[index],
          mejora: !this.checkListGeneral[index].mejora,
          // Desactivar 'No Aplica' si 'Habilitado' está activado
          valorNoAplica: this.checkListGeneral[index].mejora ? false : this.checkListGeneral[index].valorNoAplica,
          valorEnabled: this.checkListGeneral[index].mejora ? false : this.checkListGeneral[index].valorEnabled,
          // valorNoAplica: false,
          // valorEnabled: false,
          mostrarObservaciones: this.checkListGeneral[index].mostrarObservaciones,
          fondoVerde: false,
          fondoAmarillo: !this.checkListGeneral[index].mejora,
          fondoRojo: false,
        });
      }
    },
    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },
    guardarObservaciones(item) {
      const index = this.checkListGeneral.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListGeneral[index], 'observaciones', item.observaciones);
      }
    },
    async guardarYContinuar() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/guardarYcontinuarProgramadaOriginal`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const datosParaEnviar = {
        items: this.checkListGeneral.map(item => ({
          id: item.id,
          valorEnabled: item.valorEnabled,
          valorNoAplica: item.valorNoAplica,
          observaciones: item.observaciones,
          mejora: item.mejora
        })),
        inspeccionId: this.$route.params.id,
        hubodometro: this.form.hubodometro,
      };
      try {
        //PETICION
        let response = await axios.post(api,datosParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.asignarData()
          this.$router.push({name: 'CuestionarioGeneral', params: {
              inspeccionId: response.data.inspeccionId,
            }})
          this.showToastSuccess('Checklist General Creado Exitósamente !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async checkListGeneralProgramada() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/itemFormularioProgramadaOriginal`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.checkListGeneral = response.data.map((item, index) => {
            return {
              id: item.id,
              numero: index + 1,
              nombre: item.texto,
              mostrarMejora: item.enabled,
              mostrarEnabled: item.enabled, // Determina si mostrar el checkbox
              mostrarNoAplica: item.noAplica, // Determina si mostrar el checkbox
              valorEnabled: false, // Estado inicial desmarcado
              valorNoAplica: false,
              mejora: false,// Estado inicial desmarcado
              mostrarObservaciones: false, // Inicialmente oculto
              observaciones: ''
            };
          });
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },

    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.checkListGeneralProgramada()

  },

}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
.fila-verde {
  background-color: #2ffc2f; /* Fondo verde para la fila */
}
.fila-amarilla {
  background-color: #fdb31a; /* Fondo verde para la fila */
}
.fila-roja {
  background-color: #ff0a0a; /* Fondo verde para la fila */
}
@media (max-width: 768px) {
  .vue-good-table .text-as-paragraph {
    text-align: justify;
    line-height: normal;
    word-wrap: break-word; /* Asegura que las palabras no se desborden */
    padding: 2px 4px; /* Ajuste ligero del padding para mejor manejo del espacio */
    font-size: 8px; /* Ajuste del tamaño de fuente para maximizar espacio */
  }

  /* Estilos adicionales para asegurar que todo el contenido es visible y accesible */
  .vue-good-table .vg-table-row, .vue-good-table .vg-table-header {
    padding: 1px 3px;
    white-space: normal;
    display: block;
    width: 100%;
  }
}

</style>
