<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Ramplas Neumáticos Equipo</li>
    </ol>
<!--    <h1 class="page-header">{{ nombreFormulario }}</h1>-->
    <h2>Neumáticos  <small>haga click en el item para agregar observaciones</small></h2>
    <panel :title="nombreFormulario">
      <div class="">
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
      >
        <template v-slot:table-row="{ row, column }">
          <div v-if="column.field === 'acciones'"> <!-- Asume que la columna combinada se llama 'acciones' -->
            <!-- Switch de Cumple -->
            <div v-if="!row.valorNoAplica">
              <label class="switch">
                <input type="checkbox"
                       v-model="row.valorEnabled"
                       @change="handleCheckboxChange(row)">
                <span class="slider"></span>
              </label>
            </div>
            <!-- Checkbox de No Aplica debajo del switch -->
            <div v-if="!row.valorEnabled" style="display: flex; align-items: center; margin-top: 4px;">
              <input type="checkbox"
                     v-model="row.valorNoAplica"
                     @change="handleNoAplicaCheckboxChange(row)">
              <span style="margin-left: 8px;">N/A</span>
            </div>
          </div>
          <span v-else-if="column.field === 'profundidad' && !row.valorNoAplica">
            <input type="number" v-model="row.profundidad" @input="updateProfundidad($event, row)"
                   style="color: black; background-color: white; border: 1px solid #6c757d; border-radius: 4px;"
                   step="0.1"
                   class="input-profundidad"
                   :style="{ backgroundColor: row.isRowRed ? '#EE6969E5' : 'white' }">
          </span>
          <div v-else-if="column.field === 'nombre'">
            <div @click="toggleObservaciones(row)"
                 :class="{ 'item-malo': row.esMalo }">
              {{ row.nombre }}
            </div>
            <!-- Campo de texto de observaciones debajo del nombre -->
            <div v-if="row.mostrarObservaciones">
               <textarea v-if="row.mostrarObservaciones"
                         v-model="row.observaciones"
                         @blur="guardarObservaciones(row)"
                         class="observaciones-textarea"
                         placeholder="Escribe tus observaciones aquí...">
              </textarea>
            </div>
          </div>
        </template>
      </vue-good-table>
      <button type="button" class="btn btn-primary mt-3" v-on:click="guardarYContinuar()">Guardar y continuar >></button>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "CheckListRamplasNeumaticosEquipo",
  data() {
    return {
      nombreFormulario: '',
      columns: [
        {
          label: 'N°',
          field: 'numero',
          width: '50px',
          tdClass: 'd-none d-sm-table-cell',
          thClass: 'd-none d-sm-table-cell',
          sortable: false,
        },
        {
          label: 'ITEM/COMPONENTE INSPECCIONADO',
          field: 'nombre',
          sortable: false,
        },
        {
          label: 'Profundidad [mm]',
          field: 'profundidad',
          sortable: false,
        },
        {
          label: 'Cumple', // Columna combinada
          field: 'acciones', // Este campo no necesita coincidir con un campo en tus datos, ya que lo manejarás personalizadamente en el slot.
          sortable: false, // Asume que no quieres ordenar por esta columna
          width: '80px',
        },
      ],
      ordenActual: null,
      ultimoOrden: null,
      rows: [],
      checkListRamplasNeumaticosEquipo: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods: {
    asignarData() {
      this.rows = this.checkListRamplasNeumaticosEquipo;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    handleCheckboxChange(item) {
      const index = this.checkListRamplasNeumaticosEquipo.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListRamplasNeumaticosEquipo, index, {
          ...this.checkListRamplasNeumaticosEquipo[index],
          valorEnabled: !this.checkListRamplasNeumaticosEquipo[index].valorEnabled,
          valorNoAplica: this.checkListRamplasNeumaticosEquipo[index].valorEnabled ? false : this.checkListRamplasNeumaticosEquipo[index].valorNoAplica,
          mostrarObservaciones: this.checkListRamplasNeumaticosEquipo[index].mostrarObservaciones
        });
      }
    },
    handleNoAplicaCheckboxChange(item) {
      const index = this.checkListRamplasNeumaticosEquipo.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListRamplasNeumaticosEquipo, index, {
          ...this.checkListRamplasNeumaticosEquipo[index],
          valorNoAplica: !this.checkListRamplasNeumaticosEquipo[index].valorNoAplica,
          valorEnabled: this.checkListRamplasNeumaticosEquipo[index].valorNoAplica ? false : this.checkListRamplasNeumaticosEquipo[index].valorEnabled,
          profundidad: this.checkListRamplasNeumaticosEquipo[index].valorNoAplica ? '' : this.checkListRamplasNeumaticosEquipo[index].profundidad,
          mostrarObservaciones: this.checkListRamplasNeumaticosEquipo[index].mostrarObservaciones,
          ocultarSwitch: this.checkListRamplasNeumaticosEquipo[index].valorNoAplica ? false : true
        });
      }
    },
    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },
    guardarObservaciones(item) {
      const index = this.checkListRamplasNeumaticosEquipo.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListRamplasNeumaticosEquipo[index], 'observaciones', item.observaciones);
      }
    },
    updateProfundidad(event, row) {
      const newValue = parseFloat(event.target.value);  // Convertir el valor del input a número
      const index = this.checkListRamplasNeumaticosEquipo.findIndex(item => item.id === row.id);
      if (index !== -1) {
        // Marcar 'cumple' si el valor es mayor o igual a 2
        let valorEnabled = newValue >= 2;
        let observaciones = this.checkListRamplasNeumaticosEquipo[index].observaciones;
        let mostrarObservaciones = this.checkListRamplasNeumaticosEquipo[index].mostrarObservaciones;
        let isRowRed = newValue < 2;

        // Configurar las observaciones y la visibilidad del panel según el valor
        if (newValue >= 2 && newValue <= 3) {
          // Establecer un texto de observación y mostrar el panel si el valor está entre 2 y 3
          observaciones = 'Aprobado, pero si la profundidad del neumático vuelve a estar entre 2 mm y 3 mm, será Rechazado.';
          mostrarObservaciones = true;
        } else if (newValue > 3) {
          // Limpiar las observaciones y no mostrar el panel si el valor es mayor a 3
          observaciones = '';
          mostrarObservaciones = false;
        } else {
          // Limpiar las observaciones, ocultar el panel y desmarcar 'cumple' si el valor es menor a 2
          observaciones = '';
          mostrarObservaciones = false;
          valorEnabled = false;
        }

        // Actualizar la fila con los nuevos valores
        this.$set(this.checkListRamplasNeumaticosEquipo, index, {
          ...this.checkListRamplasNeumaticosEquipo[index],
          profundidad: newValue,
          valorEnabled: valorEnabled,
          valorNoAplica: false,  // Asegurarse de que 'No Aplica' no se marque
          mostrarObservaciones: mostrarObservaciones,
          observaciones: observaciones,
          isRowRed: isRowRed,

        });
      }
    },
    validarChecklist() {
      for (let item of this.checkListRamplasNeumaticosEquipo) {
        // Verifica que no estén ambos checkboxes seleccionados
        if (item.valorEnabled && item.valorNoAplica) {
          return false; // Ambos seleccionados en la misma fila
        }

        // Verifica que cada fila tenga profundidad válida o uno de los checkboxes seleccionado
        const profundidadValida = item.profundidad !== '' && !isNaN(item.profundidad) ;
        const checkboxSeleccionado = (item.valorEnabled || item.valorNoAplica);

        if (!(profundidadValida || checkboxSeleccionado)) {
          // Si no se cumple ninguna de las condiciones
          return false;
        }
      }
      return true;
    },
    async guardarYContinuar() {
      this.loadingSpiner()
      if (!this.validarChecklist()) {
        // Mostrar mensaje de error si la validación falla
        this.loader.hide();
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return; // Detener la ejecución adicional del método
      }
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/guardarItemsYcontinuar`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let inspeccionId = JSON.parse(localStorage.getItem('inspeccionId'));
      let formularioId = JSON.parse(localStorage.getItem('formularioId'));
      let ordenActual = JSON.parse(localStorage.getItem('ordenActual'));
      let ultimoOrden = JSON.parse(localStorage.getItem('ultimoOrden'));      const datosParaEnviar = {
        neumaticos: this.checkListRamplasNeumaticosEquipo.map(item => ({
          id: item.id,
          valorEnabled: item.valorEnabled,
          valorNoAplica: item.valorNoAplica,
          profundidad: item.profundidad,
          observaciones: item.observaciones
        })),
        inspeccionId: inspeccionId,
        formularioId: formularioId,
        ordenActual : ordenActual,
        ultimoOrden : ultimoOrden,
      };
      try {
        //PETICION
        let response = await axios.post(api,datosParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          localStorage.setItem('ordenActual', response.data.ordenActual);
          localStorage.setItem('formularioId', response.data.formularioId);
          this.asignarData()
          this.$router.push({name: response.data.nombreFormularioSinEspacios, params: {
              formularioId: response.data.formularioId,
              nombreFormularioSinEspacios: response.data.nombreFormularioSinEspacios,
              nombreFormulario: response.data.nombreFormulario,
              ultimoOrden: response.data.ultimoOrden,
              ordenActual: response.data.ordenActual,
              inspeccionId: response.data.inspeccionId,
            }})
          this.showToastSuccess('Checklist Ramplas Neumaticos Equipo Creado Exitósamente !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async checkListRamplasNeumaticosEquipoData(formularioId) {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/itemFormulario/${formularioId}`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          const apiMalos = process.env.VUE_APP_IP_BACKEND + '/inspeccion/obtenerItemesMalosCheckListDiarioAnterior/'
          const inspeccionId = this.inspeccionId; // Asume que ya tienes 'inspeccionId' en localStorage
          let responseMalos = await axios.get(apiMalos, {
            params: { formularioId, inspeccionId },
            headers: {"Authorization": `Bearer ${tokenHeader}`}
          });
          const itemsMalos = responseMalos.status === 200 ? responseMalos.data.itemesMalos : [];
          this.checkListRamplasNeumaticosEquipo = response.data.map((item, index) => {
            const esMalo = itemsMalos.some(malo => malo.itemId === item.id);
            return {
              id: item.id,
              numero: index + 1,
              nombre: item.texto,
              mostrarEnabled: item.enabled, // Determina si mostrar el checkbox
              mostrarNoAplica: item.noAplica, // Determina si mostrar el checkbox
              profundidad: '', // Determina si mostrar el checkbox
              valorEnabled: false, // Estado inicial desmarcado
              valorNoAplica: false, // Estado inicial desmarcado
              mostrarObservaciones: false, // Inicialmente oculto
              observaciones: '',
              esMalo
            };
          });
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },

    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    adjustColumnWidth() {
      const isMobile = window.innerWidth <= 600; // Umbral para dispositivos móviles
      this.columns[1].width = isMobile ? '10px' : 'auto'; // Ajusta el ancho basado en si es móvil
      this.columns = [...this.columns]; // Reactivar la actualización de los datos para Vue
    },
  },
  mounted() {
    this.adjustColumnWidth();
    window.addEventListener('resize', this.adjustColumnWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustColumnWidth);
  },
  created() {
    this.$validarLogin()
    this.nombreFormulario = this.$route.params.nombreFormulario;
    this.inspeccionId = this.$route.params.inspeccionId;
    const formularioId = this.$route.params.formularioId;
    if (formularioId) {
      this.checkListRamplasNeumaticosEquipoData(formularioId);
    }
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(1.5);
  margin-left: 5px;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff0000;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 13px;

}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2ecc1d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2ecc1d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
.hidden {
  display: none;
}
.item-malo {
  color: red;
}
@media (max-width: 600px) {  /* Ajusta el valor de max-width según tus necesidades */
  .input-profundidad {
    width: 70px;  /* Establece el ancho deseado para dispositivos móviles */
  }
}
</style>
