w<template>
	<div class="menu">
		<div class="menu-profile">
			<a href="javascript:;" class="menu-profile-link" v-on:click="expand()">
				<div class="menu-profile-cover with-shadow"></div>
				<div class="menu-profile-image">
					<img src="/assets/img/user/user-13.jpg" alt="" />
				</div>
				<div class="menu-profile-info">
					<div class="d-flex align-items-center">
						<div class="flex-grow-1">
              {{ userName ? userName :'User Name'}}
						</div>
						<div class="menu-caret ms-auto"></div>
					</div>
<!--					<small>Rol</small>-->
				</div>
			</a>
		</div>
		<div id="appSidebarProfileMenu" class="collapse"  v-bind:class="{ 'd-block': this.stat == 'expand' && !this.appOptions.pageSidebarMinified, 'd-none': this.stat == 'collapse' }">
			<div class="menu-item pt-5px">
        <a href="javascript:;" class="menu-link" v-on:click="showUser()">
          <div class="menu-icon"><i class="fa fa-user"></i></div>
          <div class="menu-text" id="username">Perfil</div>
        </a>
			</div>
<!--			<div class="menu-item">
				<a href="javascript:;" class="menu-link">
					<div class="menu-icon"><i class="fa fa-question-circle"></i></div>
					<div class="menu-text">Manual de usuario</div>
				</a>
			</div>-->
			<div class="menu-item pb-5px">
				<a href="javascript:;" class="menu-link">
					<div class="menu-icon"><i class="fa fa-sign-out-alt"></i></div>
					<div class="menu-text" @click="cerrarSesion">Salir</div>
				</a>
			</div>
			<div class="menu-divider m-0"></div>
		</div>
	</div>
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'

export default {
	name: 'SidebarNavProfile',
	data() {
		return {
			stat: '',
      userName : '',
			appOptions: AppOptions
		}
	},
  mounted() {
    this.verUserName()
  },
  methods: {
    showUser(){
      const savedUser = JSON.parse(localStorage.getItem('saveId'));
      const id = savedUser.idUsuario;

      this.$router.push({
        name: "ShowPerfil",
        params: {id: id}
      });
    },
    cerrarSesion(){
      this.$expirarToken()
    },

		expand: function() {
			this.stat = (this.stat == 'expand') ? 'collapse' : 'expand'
		},

    verUserName(){
      let saveUser = JSON.parse(localStorage.getItem('saveUser'))
      if(saveUser){
        this.showNameUser = saveUser
        this.userName = this.showNameUser.username
      }else{
        this.userName = "nombreUsuario"
      }
    },
  }
}
</script>
