<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Ver</a></li>
      <li class="breadcrumb-item active">Empresa</li>
    </ol>
    <h1 class="page-header">Ver Empresa <small></small></h1>
    <panel title="Formulario de edición de empresa">
      <form v-on:submit.prevent="" method="POST" autocomplete="off">
        <div class="mb-3 col-md-12">
          <label class="form-label" id="razonSocial"><strong>Razon Social*</strong> </label>
          <input class="form-control" type="text" v-model="showDataEmpresa.razonSocial"/>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Rut*</label>
            <div>
              <masked-input pattern="11111111" mask="11111111" type="tel" v-model="showDataEmpresa.rut" class="form-control" placeholder="00000000" ></masked-input>
            </div>
          </div>
          <div class="mb-3 col-md-2">
            <label class="form-label">DV*</label>
            <div>
              <input
                  type="text"
                  v-model="showDataEmpresa.codigoVerificador"
                  @input="validateDV"
                  class="form-control"
                  placeholder="0-K">
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" id="fonoEmpresa"><strong>Fono Empresa</strong> </label>
          <input class="form-control" type="number" v-model="showDataEmpresa.fonoEmpresa"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="emailEmpresa"><strong>Email Empresa</strong> </label>
          <input class="form-control" type="text" v-model="showDataEmpresa.emailEmpresa"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="web"><strong>Web</strong> </label>
          <input class="form-control" type="text" v-model="showDataEmpresa.web"/>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="empresaInterna">
            <input class="form-check-input" type="checkbox" id="empresaInterna" v-model="showDataEmpresa.empresaInterna">
            Empresa Interna
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="enabled">
            <input class="form-check-input" type="checkbox" id="enabled" v-model="showDataEmpresa.enabled">
            Empresa Habilitada
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="accountExpired">
            <input class="form-check-input" type="checkbox" id="accountExpired" v-model="showDataEmpresa.accountExpired">
            Cuenta Expiarada
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="accountLocked">
            <input class="form-check-input" type="checkbox" id="accountLocked" v-model="showDataEmpresa.accountLocked">
            Cuenta Bloqueada
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="passwordExpired">
            <input class="form-check-input" type="checkbox" id="passwordExpired" v-model="showDataEmpresa.passwordExpired">
            Contraseña Expirada
          </label>
        </div>
<!--        <div class="mb-3">-->
<!--          <label class="form-label" id="direccionEmpresa"><strong>Direccion Empresa</strong></label>-->
<!--          &lt;!&ndash; Input se muestra si direccionEmpresa está definido &ndash;&gt;-->
<!--          <input class="form-control"-->
<!--                 type="text"-->
<!--                 v-if="showDataEmpresa.direccionEmpresa"-->
<!--                 v-model="showDataEmpresa.direccionEmpresa.direccion" />-->
<!--          &lt;!&ndash; Input se muestra vacío si direccionEmpresa no está definido &ndash;&gt;-->
<!--          <input class="form-control"-->
<!--                 type="text"-->
<!--                 v-else />-->
<!--        </div>-->
        <div class="mb-3">
          <label class="form-label" id="direccionEmpresa"><strong>Direccion Empresa</strong></label>
          <input class="form-control" type="text" v-model="direccionIngresada" />
        </div>

        <div class="mb-3">
          <label for="ciudadEmpresa" class="form-label"><strong>Ciudad Empresa</strong></label>
          <select class="form-control" id="ciudadEmpresa" v-model="selectedCiudad">
            <option v-for="ciudad in ciudades" :value="ciudad.id" :key="ciudad.id">{{ ciudad.nombre }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="tipoEmpresa" class="form-label"><strong>Tipo de Empresa*</strong></label>
          <select class="form-control" id="tipoEmpresa" v-model="selectedTipoEmpresa">
            <option v-for="tipo in tiposEmpresa" :value="tipo.id" :key="tipo.id">{{ tipo.nombre }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label class="form-label" id="kmMantencionProgramadaAdvertenciaEmpresa"><strong>Mantención Programada (KM)</strong> </label>
          <input class="form-control" type="number" v-model="showDataEmpresa.kmMantencionProgramadaAdvertenciaEmpresa"/>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-secondary" v-on:click="cancelar()">Cancelar</button>
          </div>
          <div class="col-sm-8"></div>
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-primary" v-on:click="editarEmpresa()">Editar</button>
          </div>
        </div>
      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ShowEmpresa",
  data(){
    return{
      formatCharacters: {
        'A': {
          validate: char => /[0-9kK]/.test(char),
          transform: char => char.toUpperCase()
        }
      },
      ciudades: [],
      tiposEmpresa: [],
      selectedCiudad: '',
      selectedTipoEmpresa: '',
      direccionIngresada: '',
      form:{
        "razonSocial": "",
        "rut": "",
        "codigoVerificador": "",
        "fonoEmpresa": "",
        "emailEmpresa": "",
        "web": "",
        empresaInterna: false,
        enabled: false,
        accountExpired: false,
        accountLocked: false,
        passwordExpired: false,
        "direccionEmpresa": "",
        "kmMantencionProgramadaAdvertenciaEmpresa": "",
        "tipoEmpresa":"",
      },
      showDataEmpresa:{
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods:{
    validateDV(event) {
      const input = event.target.value.toUpperCase();
      const validChars = /^[0-9K]$/;

      if (input.length > 0 && !validChars.test(input)) {
        event.target.value = input.slice(0, -1);
      } else {
        this.form.codigoVerificador = input;
      }
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    async getCiudades() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/ciudad/ciudadList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.ciudades = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async getTiposEmpresa() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/empresa/empresaTipoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.tiposEmpresa = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async getEmpresa(){
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user/'
      const api = process.env.VUE_APP_IP_BACKEND + "/empresa/showEmpresa/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showDataEmpresa = response.data
          if (this.showDataEmpresa.direccionEmpresa && this.showDataEmpresa.direccionEmpresa.id) {
            await this.getDireccionEmpresaDetails(this.showDataEmpresa.direccionEmpresa.id);
          }
          await this.getTipoEmpresaDetails(response.data.id);
          this.direccionIngresada = this.showDataEmpresa.direccionEmpresa ? this.showDataEmpresa.direccionEmpresa.direccion : '';
          this.loader.hide()
        } else {
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
          this.loader.hide()
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }

    },

    async editarEmpresa(){
      this.loadingSpiner()
      if (!this.showDataEmpresa.razonSocial) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene razon Social!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      /*if (!this.showDataEmpresa.rut) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Ingrese un Rut!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.showDataEmpresa.codigoVerificador) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Ingrese el Codigo Verificador!', 'UPS!', this.notificationSystem.options.error);
        return;
      }*/
      if (!this.selectedTipoEmpresa) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Ingrese el Codigo Verificador!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      const api = process.env.VUE_APP_IP_BACKEND + `/empresa/editarEmpresa/`
      // const api = "http://localhost:8181/ta-ms-users/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let dataParaEnviar = {
        ...this.showDataEmpresa,
        kmMantencionProgramadaAdvertenciaEmpresa: isNaN(this.showDataEmpresa.kmMantencionProgramadaAdvertenciaEmpresa) ? null : parseInt(this.showDataEmpresa.kmMantencionProgramadaAdvertenciaEmpresa),
        fonoEmpresa: parseInt(this.showDataEmpresa.fonoEmpresa),
        tipoEmpresa: this.selectedTipoEmpresa,
        ciudadEmpresa: this.selectedCiudad,
        direccionEmpresa: this.direccionIngresada,
      };
      try {
        //PETICION
        let response = await axios.put(apiId, dataParaEnviar, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showDataEmpresa = response.data
          if (this.showDataEmpresa.direccionEmpresa && this.showDataEmpresa.direccionEmpresa.id) {
            await this.getDireccionEmpresaDetails(this.showDataEmpresa.direccionEmpresa.id);
          }
          // this.selectedTipoEmpresa = response.data.empresasTipoPorEmpresa.empresaTipo.id;
          this.showToastSuccess('Empresa ' + this.showDataEmpresa.razonSocial + " editada.", '¡Éxito!', this.notificationSystem.options.success);
          this.loader.hide();
        } else {
          this.loader.hide();
          this.showToastError('¡Algo salió mal!', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.loader.hide();
        this.error_msg = error;
        if (this.error_msg.request.status == 401) {
          this.showToastError('¡Sesión expirada, ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error);
          this.$expirarToken();
        } else {
          this.showToastError('¡Intente nuevamente más tarde!', 'UPS!', this.notificationSystem.options.error);
        }
      }
    },
    async getDireccionEmpresaDetails(direccionEmpresaId) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/ciudad/direccionEmpresaShow/${direccionEmpresaId}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });

        if (response.status == 200 || response.status == 201) {
          // Suponiendo que la respuesta incluya los detalles de la ciudad
          this.selectedCiudad = response.data.ciudadEmpresa.id;
          this.showDataEmpresa.direccionEmpresa = response.data;
          this.loader.hide();
        }
      } catch (error) {
        console.error("Error al cargar detalles de direccionEmpresa:", error);
        this.loader.hide();
      }
    },
    async getTipoEmpresaDetails(empresaId) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const api = process.env.VUE_APP_IP_BACKEND + `/empresa/showEmpresaTipoPorEmpresa/${empresaId}`;

      try {
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.data && response.data.empresaTipo) {
          this.selectedTipoEmpresa = response.data.empresaTipo.id;
        } else {
          // Si la respuesta no contiene un tipo de empresa, se asigna null
          this.selectedTipoEmpresa = null;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Si el error es 404, se maneja asignando null al tipo de empresa seleccionado
          this.selectedTipoEmpresa = null;
        } else {
          // Para otros errores, se muestra en la consola
          console.error("Error al cargar los tipos de empresa:", error);
        }
      }
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    cancelar(){
      this.$router.push({path: '/agendamiento/listaEmpresa'})
    },
    async loadInitialData() {
      await this.getCiudades();
      await this.getTiposEmpresa();
      await this.getEmpresa();
      await this.getTipoEmpresaDetails(this.$route.params.id);
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.loadInitialData()
  },
}

</script>
<style scoped>

</style>