<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div class="login-cover-img" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')'}"></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="row d-flex justify-content-center">
<!--          <div class="col-5"></div>
          <div class="col-2">-->
            <img src="/assets/img/logo/als.png" alt="Logo ALS" style="width: 300px;">
<!--          </div>
          <div class="col-5"></div>-->
        </div>
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center">
               <b>Agendamiento</b>
            </div>
            <small>Ingrese al sistema</small>
          </div>
          <div class="icon">
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <form v-on:submit.prevent="checkForm" method="POST">
            <div class="form-floating mb-20px">
              <input type="text" class="form-control fs-13px h-45px border-0" placeholder="Usuario" id="Usuario" v-model="usuario"/>
              <label for="emailAddress" class="d-flex align-items-center text-gray-600 fs-13px">Usuario</label>
            </div>
            <div class="form-floating mb-20px">
              <input type="password" class="form-control fs-13px h-45px border-0" placeholder="Contraseña" v-model="password"/>
              <label for="emailAddress" class="d-flex align-items-center text-gray-600 fs-13px">Contraseña</label>
            </div>
            <div class="form-check mb-20px">
              <input class="form-check-input border-0" type="checkbox" value="1" id="rememberMe"/>
              <label class="form-check-label fs-13px text-gray-500" for="rememberMe">
                Recordarme
              </label>
            </div>
            <div class="mb-20px">
              <button type="submit" class="btn btn-success d-block w-100 h-45px btn-lg">Entrar</button>
            </div>

            <!--            //Alert-->
            <div class="alert alert-danger alert-dismissible fade show" v-if="error">
              <strong>Error!</strong>
              {{ error_msg }}
            </div>
            <!--						<div class="text-gray-500">
                          Not a member yet? Click <a href="javascript:;" class="text-white">here</a> to register.
                        </div>-->
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->

    <!-- BEGIN login-bg -->
    <div class="login-bg-list clearfix">
<!--      <div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg1.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg1')" style="background-image: url(/assets/img/login-bg/login-bg-17.jpg)"></a></div>
      <div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg2.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg2')" style="background-image: url(/assets/img/login-bg/login-bg-18.jpg)"></a></div>
      <div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg3.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg3')" style="background-image: url(/assets/img/login-bg/login-bg-15.jpg)"></a></div>
      <div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg4.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg4')" style="background-image: url(/assets/img/login-bg/login-bg-14.jpg)"></a></div>
      <div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg5.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg5')" style="background-image: url(/assets/img/login-bg/login-bg-4.jpg)"></a></div>
      <div class="login-bg-list-item" v-bind:class="{ 'active': bg.bg6.active }"><a href="javascript:;" class="login-bg-list-link" v-on:click="select('bg6')" style="background-image: url(/assets/img/login-bg/login-bg-12.jpg)"></a></div>-->
      <h6 style="margin-top: 60px">Version 1.3.0</h6>
    </div>
    <!-- END login-bg -->
  </div>
</template>

<script>
import AppOptions from '../config/AppOptions.vue'
import axios from "axios";

export default {
  created() {
    AppOptions.appEmpty = true;
    this.imgDefault()
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  mounted() {

  },
  data() {
    return {
      usuario: "",
      password: "",
      error: false,
      error_msg: "",
      e:"",
      bg: {
        activeImg: localStorage.getItem("imgFondo"),
        bg1: {
          active: true,
          img: '/assets/img/login-bg/login-bg-18.jpg'
        },
        bg2: {
          active: false,
          img: '/assets/img/login-bg/login-bg-18.jpg'
        },
        bg3: {
          active: false,
          img: '/assets/img/login-bg/login-bg-15.jpg'
        },
        bg4: {
          active: false,
          img: '/assets/img/login-bg/login-bg-14.jpg'
        },
        bg5: {
          active: false,
          img: '/assets/img/login-bg/login-bg-4.jpg'
        },
        bg6: {
          active: false,
          img: '/assets/img/login-bg/login-bg-12.jpg'
        },
      }
    }
  },
  methods: {

    checkForm() {
      let json = {
        "username": this.usuario,
        "password": this.password
      }
      const api = process.env.VUE_APP_IP_BACKEND + '/api/login'
      axios.post(api, json).then(async (response) => {
        if (response && response.status === 200) {
          let data = response.data
          const datosUser = JSON.stringify(data)
          localStorage.setItem('saveUser',datosUser)

          await this.$getUserId(data.access_token, data.username)

          this.$router.push({path: '/agendamiento/dashboard/programadas'})
          location.reload()
        }
      }).catch(() => {
        this.error = true;
        this.error_msg = "Usuario o contraseña incorrecto";
      })
    },

    imgDefault(){
      if (localStorage.imgFondo== null){
        this.bg.activeImg = this.bg.bg1.img;
        localStorage.imgFondo = this.bg.activeImg;
      }
    },

    select: function (x) {
      this.bg.bg1.active = false;
      this.bg.bg2.active = false;
      this.bg.bg3.active = false;
      this.bg.bg4.active = false;
      this.bg.bg5.active = false;
      this.bg.bg6.active = false;

      switch (x) {
        case 'bg1':
          this.bg.bg1.active = true;
          this.bg.activeImg = this.bg.bg1.img;
          localStorage.imgFondo = this.bg.activeImg;
          break;
        case 'bg2':
          this.bg.bg2.active = true;
          this.bg.activeImg = this.bg.bg2.img;
          localStorage.imgFondo = this.bg.activeImg;
          break;
        case 'bg3':
          this.bg.bg3.active = true;
          this.bg.activeImg = this.bg.bg3.img;
          localStorage.imgFondo = this.bg.activeImg;
          break;
        case 'bg4':
          this.bg.bg4.active = true;
          this.bg.activeImg = this.bg.bg4.img;
          localStorage.imgFondo = this.bg.activeImg;
          break;
        case 'bg5':
          this.bg.bg5.active = true;
          this.bg.activeImg = this.bg.bg5.img;
          localStorage.imgFondo = this.bg.activeImg;
          break;
        case 'bg6':
          this.bg.bg6.active = true;
          this.bg.activeImg = this.bg.bg6.img;
          localStorage.imgFondo = this.bg.activeImg;
          break;
      }
    }
  }
}
</script>
