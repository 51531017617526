<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Fotos Checklist</a></li>
      <li class="breadcrumb-item active">Inspeccion</li>
    </ol>
    <panel style="width: 100%">
      <h1 class="page-header">Registros fotográficos de la unidad<small></small></h1>
      <div>
        <!-- ...otros elementos... -->
        <div class="row">
          <div class="col-md-4" v-for="item in listaItemsConFotos" :key="item.nombre">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  Fotos {{ item.nombre }} Faltantes: {{ calcularFotosFaltantes(item) }}
                </h5>
                <button style="margin-right: 10px" class="btn btn-primary" @click="abrirModalSubida(item)"
                        :disabled="item.fotosSubidas >= item.fotosMax">
                  <i class="fa fa-plus" style="margin-right: 5px;"></i>Subir Foto
                </button>
                <button class="btn btn-primary" @click="abrirModalFotos(item.id, item.nombre)"
                        :disabled="item.fotosSubidas === 0">
                  Ver Fotos
                </button>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-success mt-5 mb-3" @click="volverInspeccion">
          <i class="fa fa-arrow-left" style="margin-right: 5px;"></i>Volver a la Inspección
        </button>
      </div>
      <modal name="subidaFotosChecklist-modal"  @before-close="antesDeCerrarModal" height="auto">
        <div class="modal-container-subidaFotos">
          <h2 class="mb-4">Subir Foto {{this.nombreItemActual}}</h2>
            <div class="form-group" v-if="itemFotosMax">
              <input type="file" accept="image/jpg, image/jpeg, image/png" @change="handleFileUpload" :multiple="itemFotosMax > 1"/>
              <div v-for="(vistaPrevia, index) in imagenVistaPrevia" :key="index" class="form-group">
                <img :src="vistaPrevia" alt="Vista previa de la imagen" style="max-width: 130px; height: auto; margin-top: 20px;">
              </div>
            </div>
            <div class="modal-footer">
              <button style="margin-right: 7px" class="btn btn-danger" @click="closeModal">Cancelar</button>
              <button class="btn btn-primary" @click="guardarFoto">Subir Foto</button>
            </div>
        </div>
      </modal>
      <modal name="verFotos-modal" height="auto">
        <div class="modal-container-verFoto">
          <h2 class="mb-4">Ver Fotos Subidas {{this.nombreItem}}</h2>
          <div class="form-group">
            <div v-for="imagen in imagenesSubidas" :key="imagen.id">
              <img :src="imagen.url" :alt="imagen.nombre" style="max-width: 200px; height: auto; margin-top: 20px;">
              <div class="mt-2">
                <button class="btn btn-danger" @click="abrirModalEliminar(imagen)">Eliminar</button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="cerrarModalVerFotos">Cerrar</button>
          </div>
        </div>
      </modal>
      <modal name="eliminarFoto-modal" height="auto">
        <div class="modal-container-eliminarFoto">
          <h2 class="mb-4">Eliminar Foto</h2>
          <h4>Esta accion es irreversible, ¿está seguro?</h4>
          <div class="imagen-contenedor" v-if="imagen">
            <img :src="imagen.url" :alt="imagen.nombre" style="max-width: 200px; height: auto; margin-top: 20px;">
          </div>
          <div class="modal-footer-eliminarFoto">
            <button class="btn btn-danger" style="margin-right: 10px" @click="eliminarFoto(imagen)">Eliminar</button>
            <button class="btn btn-secondary" @click="cerrarModalEliminarFoto">Cerrar</button>
          </div>
        </div>
      </modal>
    </panel>
  </div>
</template>
<script>
import axios from "axios";
import imageCompression from 'browser-image-compression';

export default {
  name: "GuardarFotosChecklist",
  data() {
    return {
      imagen: null,
      itemFotosMax: null,
      listaItemsConFotos:[],
      imagenesSubidas:[],
      fotoSubida:null,
      fotosSubidas:[],
      nombreFotoSubida: null,
      itemCategoriaIdActual: null,
      nombreItemActual: '',
      nombreItem: '',
      imagenVistaPrevia: null,
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods: {
    convertirColorRGBA(color, opacidad) {
      const colores = {
        red: '255, 0, 0',
        green: '0, 128, 0',
        orange: '255, 165, 0',
        // Agrega más colores según sea necesario
      };
      return `rgba(${colores[color] || '0, 0, 0'}, ${opacidad})`; // color negro por defecto
    },
    reiniciarEstadoModal() {
      this.nombreItemActual = '';
      this.itemFotosMax = 0;
      this.itemCategoriaIdActual = '';
      this.itemActual = {};
      this.imagenVistaPrevia = [];
      this.fotosSubidas = [];
    },
    volverInspeccion() {
      const id = this.$route.params.id; // O la variable donde tienes almacenado el ID
      this.$router.push({ path: `/showInspeccion/${id}`})
    },
    calcularFaltantes(subidas, minimo) {
      // Asumiendo que `subidas` puede ser null o undefined si no hay fotos subidas
      const fotosSubidas = subidas || 0;
      return Math.max(minimo - fotosSubidas, 0);
    },
    closeModal() {
      this.$modal.hide('subidaFotosChecklist-modal');
    },
    cerrarModalVerFotos() {
      this.$modal.hide('verFotos-modal');
    },
    cerrarModalEliminarFoto() {
      this.$modal.hide('eliminarFoto-modal');
    },
    antesDeCerrarModal() {
      this.imagenVistaPrevia = null;
    },
    calcularFotosFaltantes(item) {
      return Math.max(item.fotosMin - item.fotosSubidas, 0);
    },
    handleFileUpload(event) {
      const files = event.target.files;
      const fotosRestantes = this.calcularFotosFaltantes(this.itemActual);
      if (files.length > fotosRestantes) {
        alert(`Solo puedes subir hasta ${fotosRestantes} fotos más.`);
        event.target.value = ''; // Restablece la selección del input de archivo
        this.imagenVistaPrevia = []; // Restablece las vistas previas
        return; // Detiene la ejecución si se seleccionan demasiados archivos
      }

      if (files.length > 0) {
        this.imagenVistaPrevia = [];
        this.fotosSubidas = [];

        Array.from(files).forEach((file) => {
          if (file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imagenVistaPrevia.push(e.target.result);
              this.fotosSubidas.push({
                base64: e.target.result,
                nombre: file.name,
                file: file
              });
            };
            reader.readAsDataURL(file);
          }
        });
      }
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    async fetchData(url) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    abrirModalSubida(item) {
      this.$emit('reiniciarModal');
      this.nombreItemActual = item.nombre;
      this.itemFotosMax = item.fotosMax;
      this.itemCategoriaIdActual = item.id;
      this.itemActual = {...item};
      this.$modal.show('subidaFotosChecklist-modal');
    },
    async abrirModalFotos(categoriaId, nombreItem) {
      this.loadingSpiner();
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/obtenerFotosPorCategoria/'

      try {
        let response = await axios.get(api, {
          headers: { "Authorization": `Bearer ${tokenHeader}` },
          params: {
            inspeccionId: this.$route.params.id,
            categoriaId: categoriaId
          }
        });

        if (response.status === 200) {
          this.imagenesSubidas = response.data;
          this.nombreItem = nombreItem;
          this.$modal.show('verFotos-modal');
        } else {
          this.showToastError('No se pudieron cargar las fotos.', 'Error', this.notificationSystem.options.error);
        }
      } catch (error) {
        console.error('Error al obtener las fotos:', error);
        this.showToastError('Error al cargar las fotos.', 'Error', this.notificationSystem.options.error);
      } finally {
        this.loader.hide();
      }
    },
    abrirModalEliminar(imagen) {
      this.imagen = imagen;
      console.log(imagen)
      this.$modal.show('eliminarFoto-modal');
      this.$modal.hide('verFotos-modal');
    },
    async itemsConFotos() {
      this.loadingSpiner();
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/imagenesFaltantes/'
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listaItemsConFotos = response.data.map(item => ({
            ...item,
            fotosMax: item.fotosMax,
            fotosSubidas: item.fotosSubidas
          }));
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },

    async guardarFoto() {
      this.$modal.hide('subidaFotosChecklist-modal');
      // this.loadingSpiner();
      if (this.fotosSubidas.length === 0) {
        this.showToastError('No se seleccionó ninguna imagen.', 'Error', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/guardarFotosInspeccion/';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        for (let foto of this.fotosSubidas) {
          const imageFile = foto.file; // Asume que tienes acceso al archivo de imagen, no solo a la base64
          const options = {
            maxSizeMB: 1, // Max size in MB
            maxWidthOrHeight: 300, // Compress the image if it's larger than this
            useWebWorker: true
          };
          const compressedFile = await imageCompression(imageFile, options);

          let formData = new FormData();
          formData.append('imagen', compressedFile);
          formData.append('nombreFotoSubida', foto.nombre);
          formData.append('inspeccionId', this.$route.params.id);
          formData.append('nombreItem', this.nombreItemActual);
          formData.append('itemCategoriaId', this.itemCategoriaIdActual);
          formData.append('fotosMax', this.itemFotosMax);

          let response = await axios.post(api, formData, {
            headers: {
              "Authorization": `Bearer ${tokenHeader}`,
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.status === 200 || response.status === 201) {
            const item = this.listaItemsConFotos.find(i => i.nombre === this.nombreItemActual);
            if (item) {
              const fotosSubidasAhora = this.fotosSubidas.length; // Asumiendo que fotosSubidas contiene las fotos actualmente subidas
              const nuevoValor = (item.fotosSubidas || 0) + fotosSubidasAhora; // Si fotosSubidas no está inicializado, empieza de 0
              this.$set(item, 'fotosSubidas', nuevoValor);
              this.listaItemsConFotos = [...this.listaItemsConFotos];
            }
            // this.loader.hide();
            await this.itemsConFotos()
            this.loader.hide();
            this.showToastSuccess('Foto Subida Exitósamente!', 'Genial!', this.notificationSystem.options.success);
            // this.$modal.hide('subidaFotosChecklist-modal');
          } else {
            this.loader.hide();
            this.showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
          }
        }
      } catch (error) {
        console.error(error);
        this.showToastError('Ha ocurrido un error al subir la foto.', 'Error', this.notificationSystem.options.error);
      } finally {
        if (this.loader) {
          this.loader.hide();
        }
      }
    },

    async eliminarFoto(imagen) {
      if (!imagen) {
        this.showToastError('No hay imagen para borrar.', 'Error', this.notificationSystem.options.error);
        return;
      }

      this.loadingSpiner();
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/eliminarFoto/';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.$route.params.id, // Asegúrate de que esto coincide con el nombre de campo esperado en el backend
        imagen: this.imagen,
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          this.$modal.hide('eliminarFoto-modal')
          this.loader.hide()
          await this.itemsConFotos()
          this.showToastSuccess('Foto Eliminada Exitósamente!', 'Genial!', this.notificationSystem.options.success);
        } else {
          this.showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.showToastError('Ha ocurrido un error al eliminar la foto.', 'Error', this.notificationSystem.options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide();
        }
      }
    },

    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  },
  mounted() {
    this.itemsConFotos();
    this.id = this.$route.params.id;
  },
  created() {
    this.$validarLogin()
    this.$on('reiniciarModal', this.reiniciarEstadoModal);
  }
}
</script>

<style scoped>

.modal-container {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}

.modal-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #6c757d;
  border-radius: 4px;
  margin-top: 5px;
}

.form-text.text-danger {
  color: #d9534f;
}

.modal-footer {
  position: relative; /* Permitir que el contenido se desplace verticalmente */
  margin-top: 10px; /* Ajustar según sea necesario */
  bottom: 0;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.imagen-contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modal-footer-eliminarFoto {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .modal-container-subidaFotos {
    background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
    color: #000000;
    border: solid 1px;
    max-height: calc(100vh - 100px); /* Ajustar la altura máxima para dejar espacio suficiente para los botones */
    border-color: #495057;
    padding: 10px;
    position: fixed;
    top: 55%;
    left: 50%; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(-50%, -50%);
    width: 90%;
  }


  .modal-container-verFoto {
    background-color: #ffffff;
    color: #000000;
    border: solid 1px;
    border-color: #495057;
    padding: 20px;
    position: fixed;
    top: 20%; /* Ajustamos la posición vertical del modal */
    left: 50%;
    transform: translateX(-50%);
    max-height: calc(80vh - 40px); /* Reducimos la altura máxima para dejar espacio para los botones */
    overflow-y: auto;
  }

  .form-group {
    margin-bottom: 10px; /* Reducimos el espacio entre las imágenes */
  }

  .modal-footer {
    margin-top: 10px; /* Ajustamos el margen superior de los botones */
  }

  .modal-container-eliminarFoto {
    background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
    color: #000000;
    border: solid 1px;
    border-color: #495057;
    padding: 20px;
    position: fixed;
    top: -50px;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, 50%);
  }
}
.modal-container-subidaFotos {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
.modal-container-verFoto {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
.modal-container-eliminarFoto {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
</style>
