<template>
  <div>
    <div class="row m-3">
      <h4 class="m-3"><u>Especificaciones Prueba de Hermeticidad</u></h4>
      <table class="table table-striped">
        <tr v-for="item in itemListInput" :key="item.itemId">
          <td class="col-sm-3">
            <strong>
              {{item.nombre}}
            </strong>
          </td>
          <td class="col-sm-9">
            <input type="text" class="form-control" v-model="item.valor" :disabled="readOnly">
          </td>
        </tr>
        <tr v-if="itemDescripcion">
          <td class="col-sm-3">
            <strong>
              {{itemDescripcion.nombre}}
            </strong>
          </td>
          <td class="col-sm-9">
            <textarea class="form-control" rows="4" v-model="itemDescripcion.valor" :disabled="readOnly"></textarea>
          </td>
        </tr>
      </table>
    </div>
    <hr>
    <div class="row m-3" v-if="itemConclusion">
      <div class="col-sm-6">
        <h4 class="m-3"><u>Conclusión Prueba</u></h4>
        <div class="form-check form-switch">
          <label class="form-label" for="itemConclusion"><strong>Prueba Aprobada</strong></label>
          <input class="form-check-input" id="itemConclusion" type="checkbox" v-model="itemConclusion.valorEnabled" :disabled="readOnly">
        </div>
        <label class="form-label" for="observacionesConclusion">Observaciones de Prueba</label>
        <textarea id="observacionesConclusion" class="form-control" rows="4" v-model="itemConclusion.observaciones" :disabled="readOnly"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponenteFormHermeticidad',
  props: {
    'itemListHermeticidad': {
      type: Array,
      default: function() {
        return [];
      }
    },
    'readOnly': {
      type: Boolean,
      default: true
    },
  },
  data(){
    return{
      itemList: [],
      itemListInput:[],
      itemDescripcion:{},
      itemConclusion:{},
    }
  },
  mounted(){
    this.itemListHermeticidadProcesado()
  },
  methods:{
    itemListHermeticidadProcesado() {
      if(this.itemListHermeticidad){
        this.itemList = this.itemListHermeticidad.map((item) => {
          return {
            itemId:        item.itemId,
            index:         item.index,
            nombre:        item.texto,
            codigo:        item.codigo,
            enabled:       item.enabled,
            noAplica:      item.noAplica,
            conclusion:    item.conclusion,
            textArea:      item.textArea,
            valor:         item.valor,
            valorEnabled:  item.valorBooleano,
            observaciones: item.observaciones,
            guardado:      item.guardado,
          };
        });
        this.itemListInput   = this.itemList.filter((item) => item?.conclusion === false && item?.textArea === false)
        this.itemConclusion  = this.itemList.find((item)   => item?.conclusion === true)
        this.itemDescripcion = this.itemList.find((item)   => item?.textArea === true)
      }
    },
  },
}
</script>
