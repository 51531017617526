<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Evaluacion</a></li>
      <li class="breadcrumb-item active">Empresa</li>
    </ol>
    <h1 class="page-header">Evaluacion Empresa<small></small></h1>
    <panel title="Evaluación Empresas Transportistas">
      <table class="table">
        <thead>
        <tr>
          <th>Empresa Transportista</th>
          <th>Evaluación</th>
          <th>Observación</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="empresa in listEmpresa" :key="empresa.id">
          <td>
            <!-- Usar empresa.id para la navegación -->
            <router-link :to="{ name: 'EvaluarEmpresa', params: { id: empresa.id } }">
              {{ empresa.empresa }}
            </router-link>
          </td>
          <td>{{ empresa.evaluacion }}</td>
          <td>{{ empresa.observacion }}</td>
        </tr>
        </tbody>
      </table>
    </panel>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EvaluacionEmpresa",
    data() {
      return {
        listEmpresa: [],
      }
    },
  methods: {
    async listaEmpresa() {
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + '/empresa/evaluacionEmpresa'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listEmpresa = response.data
          this.asignarData()
        } else {
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
  },
  mounted() {
    this.listaEmpresa()
  },
}
</script>