<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Cuestionario Programada</li>
    </ol>
    <h1 class="page-header">INSPECCION a EQUIPO de TRANSPORTE</h1>
    <panel title="RESUMEN ENSAYOS NO DESTRUCTIVOS">
      <template>
        <div>
          <table class="styled-table">
            <thead>
            <tr>
              <th>ENSAYOS NO DESTRUCTIVOS APLICADOS :</th>
              <th>UBICACIÓN</th>
              <th>EXTENSION (% del total)</th>
              <th>RESULTADOS</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-only">Ensayo por Líquidos Penetrantes</td>
              <td data-label="UBICACIÓN"><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input0"></td>
              <td data-label="EXTENSION (% del total)">
                <select v-model="selectA1" v-on:change="inputCodigo()" id="input4" >
                  <option value=""></option>
                  <option v-for="percentage in percentages" :key="percentage" :value="percentage">
                    {{ percentage }}
                  </option>
                </select>
              </td>
              <td data-label="RESULTADOS">
                <select v-model="selectB1" v-on:change="inputCodigo()" id="input8">
                  <option value=""></option>
                  <option value="APROBADO">APROBADO</option>
                  <option value="REPROBADO">REPROBADO</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-only">Ensayo por Partículas Magnéticas</td>
              <td  data-label="UBICACIÓN"><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input1"></td>
              <td data-label="EXTENSION (% del total)">
                <select v-model="selectA2" v-on:change="inputCodigo()" id="input5">
                  <option value=""></option>
                  <option v-for="percentage in percentages" :key="percentage" :value="percentage">
                    {{ percentage }}
                  </option>
                </select>
              </td>
              <td data-label="RESULTADOS">
                <select v-model="selectB2" v-on:change="inputCodigo()" id="input9">
                  <option value=""></option>
                  <option value="APROBADO">APROBADO</option>
                  <option value="REPROBADO">REPROBADO</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-only">Ensayo Radiográfico</td>
              <td data-label="UBICACIÓN"><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input2"></td>
              <td data-label="EXTENSION (% del total)">
                <select v-model="selectA3" v-on:change="inputCodigo()" id="input6">
                  <option value=""></option>
                  <option v-for="percentage in percentages" :key="percentage" :value="percentage">
                    {{ percentage }}
                  </option>
                </select>
              </td>
              <td data-label="RESULTADOS">
                <select v-model="selectB3" v-on:change="inputCodigo()" id="input10">
                  <option value=""></option>
                  <option value="APROBADO">APROBADO</option>
                  <option value="REPROBADO">REPROBADO</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-only">Ensayo por Ultrasonidos</td>
              <td data-label="UBICACIÓN"><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input3"></td>
              <td  data-label="EXTENSION (% del total)">
                <select v-model="selectA4" v-on:change="inputCodigo()" id="input7">
                  <option value=""></option>
                  <option v-for="percentage in percentages" :key="percentage" :value="percentage">
                    {{ percentage }}
                  </option>
                </select>
              </td>
              <td data-label="RESULTADOS">
                <select v-model="selectB4" v-on:change="inputCodigo()" id="input11">
                  <option value=""></option>
                  <option value="APROBADO">APROBADO</option>
                  <option value="REPROBADO">REPROBADO</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="text-only">Control Dimensional por Ultrasonidos</td>
              <td class="text-only">King Pin</td>
              <td data-label="EXTENSION (% del total)">
                <select v-model="selectA5" :disabled="selectA5 === '100%'">
                  <option value=""></option>
                  <option v-for="percentage in percentages" :key="percentage" :value="percentage">
                    {{ percentage }}
                  </option>
                </select>
              </td>
              <td data-label="RESULTADOS">
                <select v-model="selectB5" v-on:change="inputCodigo()" id="input12">
                  <option value=""></option>
                  <option value="APROBADO">APROBADO</option>
                  <option value="REPROBADO">REPROBADO</option>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </panel>
    <panel title="CUMPLIMIENTO DE COTAS y DIAMETROS DE KING PIN ( PERNO REY DE ACOPLAMIENTO )">
      <template>
        <div>
          <table class="styled-table2">
            <thead>
            <tr>
              <th >KING PIN DIAMETRO <input type="text" style="width: 30px; height: 19px" v-on:change="inputCodigo()" id="input13"> pulgadas</th>
              <th colspan="2"><input type="text" v-on:change="inputCodigo()" id="input14"></th>
              <th class="d-none d-sm-table-cell" colspan="3">Valores de Referencia</th>
              <th class="d-none d-sm-table-cell" style="text-align: center; border-bottom: 0 !important;"><strong>VALORES<br>MEDIDOS</strong></th>
            </tr>

            </thead>
            <tbody>
            <tr>
              <td rowspan="6"><img :src="urlKingPin" class="king-pin-image"></td>
              <td class="d-none d-sm-table-cell" colspan="2" rowspan="2"></td>
              <td class="d-none d-sm-table-cell">Máxima:</td>
              <td class="d-none d-sm-table-cell">Mínima:</td>
              <td class="d-none d-sm-table-cell">Media:</td>
              <td class="d-none d-sm-table-cell"></td>
            </tr>
            <tr>
              <td class="d-none d-sm-table-cell" style="text-align: center;">[mm]</td>
              <td class="d-none d-sm-table-cell" style="text-align: center;">[mm]</td>
              <td class="d-none d-sm-table-cell" style="text-align: center;">[mm]</td>
              <td class="d-none d-sm-table-cell" style="text-align: center;">[mm]</td>
            </tr>
            <tr>
              <td colspan="2">Medidas para Dimensión "C"</td>
              <td data-label="Máxima [mm]" style="color: green">73,00</td>
              <td data-label="Mínima [mm]" style="color: red">71,00</td>
              <td data-label="Media [mm]" style="color: blue">72,00</td>
              <td><input type="text"  v-on:change="inputCodigo()" id="input15"></td>
            </tr>
            <tr>
              <td colspan="2">Medidas para Dimensión "D"</td>
              <td data-label="Máxima [mm]" style="color: green">50,80</td>
              <td data-label="Mínima [mm]" style="color: red">49,00</td>
              <td data-label="Media [mm]" style="color: blue">49,90</td>
              <td><input type="text"  v-on:change="inputCodigo()" id="input16"></td>
            </tr>
            <tr>
              <td colspan="2">Medidas para Dimensión "E"</td>
              <td data-label="Máxima [mm]" style="color: green">74,00</td>
              <td data-label="Mínima [mm]" style="color: red">72,00</td>
              <td data-label="Media [mm]"  style="color: blue">73,00</td>
              <td><input type="text"  v-on:change="inputCodigo()" id="input17"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </panel>
    <panel title="ENSAYOS NO DESTRUCTIVOS APLICADOS">
      <template>
        <div>
          <table class="styled-table3">
            <tbody>
              <tr>
                <td style="font-weight: bold">Tipo de Instrumento:</td>
                <td><input type="text" style="width: 100%"  v-on:change="inputCodigo()" id="input18"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Marca:</td>
                <td><input type="text" style="width: 100% " v-on:change="inputCodigo()" id="input19"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Modelo Específico:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input20"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Método de Medición</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input21"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Palpadores:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input22"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Rango de Frecuencia:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input23"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Calibre:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input24"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Cable Transmisor:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input25"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Lugares de Aplicación:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input26"></td>
              </tr>
              <tr>
                <td style="font-weight: bold">Normas de Referencia:</td>
                <td><input type="text" style="width: 100%" v-on:change="inputCodigo()" id="input27"></td>
              </tr>
              <tr>
                <td colspan="2" style="font-weight: bold">RESULTADO</td>
              </tr>
              <tr>
                <td colspan="2"><textarea style="width: 100%; height: 200px" v-on:change="inputCodigo()" id="input28"></textarea></td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </panel>
    <panel title=" RECOMENDACIONES DE MEJORA / REPARACIONES PENDIENTES :">
      <template>
        <div>
          <table  class="styled-table4">
            <tbody>
              <textarea style="width: 100%; height: 400px" v-on:change="inputCodigo()" id="input29"></textarea>
              <div style="font-weight: bold" class="mt-3">MANUTENCIONES PREVENTIVAS RECOMENDADAS :</div>
              <textarea style="width: 100%; height: 400px" v-on:change="inputCodigo()" id="input30"></textarea>
            </tbody>
          </table>
        </div>
      </template>
    </panel>
    <button type="button" class="btn btn-primary" style="margin-right: 10px" v-on:click="guardarYContinuar()"> Guardar y continuar >></button>
    <button type="button" class="btn btn-secondary">Reset</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CuestionarioGeneral",
  data() {
    return {
      urlKingPin:'',
      itemList: [],
      selectA1: '', // Valor inicial para el primer select
      selectB1: '', // Valor inicial para el segundo select
      selectA2: '', // Valor inicial para el primer select
      selectB2: '', // Valor inicial para el segundo select
      selectA3: '', // Valor inicial para el primer select
      selectB3: '', // Valor inicial para el segundo select
      selectA4: '', // Valor inicial para el primer select
      selectB4: '', // Valor inicial para el segundo select
      selectA5: '100%', // Valor inicial para el primer select
      selectB5: '', // Valor inicial para el segundo select
      percentages: Array.from({ length: 10 }, (_, index) => (index + 1) * 10).map(item=> item + '%'), // Array de porcentajes del 10% al 100%
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods: {
    inputCodigo() {
      for(let i= 0; i < this.itemList.itemDataWithIndex.length; i++){
        let hola= document.getElementById("input"+ i)
        if(hola){
          this.itemList.itemDataWithIndex[i].valor = hola.value
        }
      }
    },
    async cuestionarioProgramadaOriginal() {
      this.$loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/itemFormularioCuestionarioOriginal/`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.itemList = response.data;
          this.urlKingPin = response.data.urlKingPin;
          this.loader.hide()
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },

    async guardarYContinuar() {
      this.$loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/guardarYcontinuarProgramadaOriginal`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const datosParaEnviar = {
        items: this.itemList.itemDataWithIndex.map(item => ({
          id: item.id,
          valor: item.valor ? item.valor : ""
        })),
        inspeccionId: this.$route.params.inspeccionId,
      };
      try {
        //PETICION
        let response = await axios.post(api,datosParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.$router.push({name: 'ShowInspeccionProgramada', params: {
              id: this.$route.params.inspeccionId,
            }})
          this.$showToastSuccess('Cuestionario General Creado Exitósamente !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.cuestionarioProgramadaOriginal();
    this.inspeccionId = this.$route.params.inspeccionId;
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table thead tr th {
  background-color: #e9ecef;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table tbody tr td {
  padding: 12px 15px;
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}

.styled-table2 {
  width: 100%;
  border-collapse: collapse;
}
.styled-table2 thead tr th {
  background-color: #e9ecef;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table2 tbody tr td {
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}
.styled-table3 {
  width: 100%;
  border-collapse: collapse;
}
.styled-table3 thead tr th {
  background-color: #e9ecef;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table3 tbody tr td {
  padding: 12px 15px;
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}
.styled-table4 {
  width: 100%;
  border-collapse: collapse;
}
@media (max-width: 768px) {
  .styled-table thead {
    display: none; /* Oculta los encabezados en móviles */
  }

  .styled-table,
  .styled-table tbody,
  .styled-table tr,
  .styled-table td {
    display: block;
    width: 100%;
    clear: both; /* Asegura que cada celda comienza en una nueva línea */
    box-sizing: border-box;
  }

  .styled-table td {
    text-align: left; /* Alinea el texto a la izquierda */
    padding: 10px; /* Añade algo de padding para separar texto y etiqueta */
    position: relative; /* Permite posicionar las etiquetas de manera absoluta dentro de la celda */
    margin-bottom: 15px; /* Añade más espacio entre celdas */
    border-bottom: 1px solid #ccc; /* Añade una línea entre celdas para mejorar la separación */
  }
  .styled-table td.text-only:before {
    display: none; /* Oculta la etiqueta en las celdas de solo texto */
  }

  .styled-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px;
    font-weight: bold;
    background-color: #f3f3f3; /* Fondo para las etiquetas para mejorar legibilidad */
    color: #333;
    width: 100%; /* Hace que la etiqueta use todo el ancho disponible */
    box-sizing: border-box;
    text-align: left; /* Asegura que el texto de la etiqueta está alineado a la izquierda */
  }

  /* Asegura que los campos de texto y los selectores no se superpongan con las etiquetas */
  .styled-table input[type="text"],
  .styled-table select {
    width: 100%; /* Ocupa el 100% del espacio disponible */
    padding: 8px;
    margin-top: 30px; /* Aumenta el espacio por encima del campo para evitar superposición */
    box-sizing: border-box; /* Incluye padding y border en el ancho definido */
  }








  .styled-table2 thead,
  .styled-table2 th,
  .styled-table2 td {
    display: block;
    width: 100%;  /* Asegura que ocupan todo el ancho */
    box-sizing: border-box;  /* Incluye padding y border en el ancho total */
    padding: 8px 10px;  /* Establece un padding uniforme */
    margin-bottom: 10px;  /* Espacio entre cada celda */
    border-bottom: 1px solid #ccc;  /* Línea divisoria para claridad */
  }

  .styled-table2 th,
  .styled-table2 td {
    text-align: left;
    padding: 10px;
    margin-bottom: 10px; /* Añade espacio entre celdas */
    border-bottom: 1px solid #ccc;
  }

  .styled-table2 th:before,
  .styled-table2 td:before {
    content: attr(data-label);
    font-weight: bold;
    display: block; /* Asegúrate de que la etiqueta se muestre como un bloque */
    margin-bottom: 5px; /* Espacio entre la etiqueta y el contenido de la celda */
  }

  /* Ajuste específico para inputs dentro de th para alinear con el diseño */
  .styled-table2 th input[type="text"] {
    width: auto;  /* Permite que el input ajuste su tamaño según el contenido */
    max-width: calc(100% - 20px);  /* Reduce el tamaño máximo para ajustarse dentro del th */
    margin: 0;  /* Elimina márgenes adicionales */
  }
  .king-pin-image {
    max-height: 90px;  /* Puedes ajustar este valor según necesites para dispositivos móviles */
  }

}

</style>
