<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
      <li class="breadcrumb-item active">Programadas</li>
    </ol>
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <router-link class="nav-link" :class="{ active: $route.path === '/agendamiento/dashboard/diarias' }" to="/agendamiento/dashboard/diarias">Diarias</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :class="{ active: $route.path === '/agendamiento/dashboard/programadas' }" to="/agendamiento/dashboard/programadas">Programadas</router-link>
      </li>
      <!-- Agrega más pestañas según sea necesario -->
    </ul>
    <!-- END breadcrumb -->

    <!-- BEGIN page-header -->
    <h1 class="page-header">Inspecciones Programadas <small>vista general</small></h1>

    <div class="row">
      <div class="col">
        <h6>
          <button id="botonMostrarFiltros" class="btn btn-info btn-sm" style="cursor: pointer;" type="button" @click="mostrarDatePickersFiltros">
            <i class="fa fa-filter"></i>
          </button>
          <label class="form-label mx-2" style="cursor: pointer;" for="botonMostrarFiltros">
            Filtrar Inspecciones Por Fechas
          </label>
        </h6>
      </div>
    </div>

    <div id="divFiltros" class="collapse">
      <div class="row">
        <div class="col-6 col-lg-3 col-xl-2 datepicker">
          <label class="form-label" for="datePickerIni">Fecha Inicio</label>
          <Datepicker id="datePickerIni" :language="es" mondayFirst v-model="fechaIniInspProgramadas" :format="customFormatter"></Datepicker>
        </div>
        <div class="col-6 col-lg-3 col-xl-2 datepicker">
          <label class="form-label" for="datePickerFin">Fecha Fin</label>
          <Datepicker id="datePickerFin" :language="es" mondayFirst v-model="fechaFinInspProgramadas" :format="customFormatter"></Datepicker>
        </div>
        <div class="col-12 col-lg-2 col-xl-2 mb-1 d-flex justify-content-center" style="padding-top: 10px;">
          <button class="btn btn-primary btn-sm align-self-center mx-1" type="button" @click="inspeccionesProgramadasRango()"><i class="fa fa-search"></i></button>
          <button class="btn btn-danger btn-sm align-self-center mx-1" type="button" @click="borrarFiltro()"><i class="fa fa-x"></i></button>
        </div>
      </div>
    </div>

    <!-- END page-header -->

    <!--    <div class="d-sm-flex align-items-center mb-3">-->
    <!--      <date-range-picker ref="picker" class="btn btn-dark me-2"-->
    <!--                         :opens="dateRange.opens"-->
    <!--                         :locale-data="{-->
    <!--                           firstDay: 1,-->
    <!--                           format: 'DD-MM-YYYY',-->
    <!--                           daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],-->
    <!--                           monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],-->
    <!--                           applyLabel: 'Aplicar',-->
    <!--                           cancelLabel: 'Cancelar',-->
    <!--                           fromLabel: 'Desde',-->
    <!--                           toLabel: 'Hasta',-->
    <!--                         }"-->
    <!--                         :ranges="dateRange.ranges"-->
    <!--                         :singleDatePicker="dateRange.singleDatePicker"-->
    <!--                         :timePicker="dateRange.timePicker"-->
    <!--                         :timePicker24Hour="dateRange.timePicker24Hour"-->
    <!--                         :showWeekNumbers="dateRange.showWeekNumbers"-->
    <!--                         :showDropdowns="dateRange.showDropdowns"-->
    <!--                         :autoApply="dateRange.autoApply"-->
    <!--                         v-model="dateRange.range"-->
    <!--                         @update="updateValues"-->
    <!--                         :linkedCalendars="dateRange.linkedCalendars">-->
    <!--        <template v-slot:input="picker">-->
    <!--          <i class="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1"></i>-->
    <!--          {{ picker.startDate | date }} - {{ picker.endDate | date }}-->
    <!--          <b class="caret ms-1 opacity-5"></b>-->
    <!--        </template>-->
    <!--      </date-range-picker>-->
    <!--    </div>-->

    <!-- BEGIN row -->
    <div class="row">
      <!-- BEGIN col-3 -->
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg"><i class="fa fa-truck fa-fw"></i></div>
          <div class="stats-content">
            <div class="stats-title">EQUIPOS VENCIDOS</div>
            <div class="stats-number">{{inspProgramadasRango.equiposVencidos.length}}</div>
            <div class="stats-progress progress">
              <div class="progress-bar" :style="{ width: inspProgramadasRango.porcentajeEquiposVencidos + '%' }"></div>
            </div>
            <div class="stats-desc">{{inspProgramadasRango.empresa.nombre}}</div>
          </div>
        </div>
      </div>
      <!-- END col-3 -->
      <!-- BEGIN col-3 -->
      <div class="col-xl-3 col-md-6">
        <router-link
            :to="{ name: 'ListarEquipo', params: { sello: 'azul', equipoIds: inspProgramadasRango.equiposSelloAzul.map(equipo => equipo.id).join(',') } }"
            class="widget-link">
          <div class="widget widget-stats bg-teal">
            <div class="stats-icon stats-icon-lg"><i class="fa fa-check fa-fw"></i></div>
            <div class="stats-content">
              <div class="stats-title">EQUIPOS SELLO AZUL</div>
              <div class="stats-number">{{ inspProgramadasRango.equiposSelloAzul.length }}</div>
              <div class="stats-progress progress">
                <div class="progress-bar" :style="{ width: inspProgramadasRango.porcentajeEquiposSelloAzul + '%' }"></div>
              </div>
              <div class="stats-desc">{{inspProgramadasRango.empresa.nombre}}</div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- END col-3 -->
      <!-- BEGIN col-3 -->
      <div class="col-xl-3 col-md-6">
        <router-link
            :to="{ name: 'ListarEquipo', params: { sello: 'rojo', equipoIds: inspProgramadasRango.equiposSelloRojo.map(equipo => equipo.id).join(',') } }"
            class="widget-link">
          <div class="widget widget-stats bg-red">
            <div class="stats-icon stats-icon-lg"><i class="fa fa-triangle-exclamation fa-fw"></i></div>
            <div class="stats-content">
              <div class="stats-title">EQUIPOS SELLO ROJO</div>
              <div class="stats-number">{{ inspProgramadasRango.equiposSelloRojo.length }}</div>
              <div class="stats-progress progress">
                <div class="progress-bar" :style="{ width: inspProgramadasRango.porcentajeEquiposSelloRojo + '%' }"></div>
              </div>
              <div class="stats-desc">{{inspProgramadasRango.empresa.nombre}}</div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- END col-3 -->
      <!-- BEGIN col-3 -->
      <div class="col-xl-3 col-md-6">
        <router-link
            :to="{ name: 'ListarEquipo', params: { sello: 'sinSello', equipoIds: inspProgramadasRango.equiposSinSello.map(equipo => equipo.id).join(',') } }"
            class="widget-link">
          <div class="widget widget-stats bg-gray">
            <div class="stats-icon stats-icon-lg"><i class="fa fa-exclamation-circle fa-fw"></i></div>
            <div class="stats-content">
              <div class="stats-title">EQUIPOS SIN SELLO</div>
              <div class="stats-number">{{inspProgramadasRango.equiposSinSello.length}}</div>
              <div class="stats-progress progress">
                <div class="progress-bar" :style="{ width: inspProgramadasRango.porcentajeEquiposSinSello + '%' }"></div>
              </div>
              <div class="stats-desc">{{inspProgramadasRango.empresa.nombre}}</div>
            </div>
          </div>
        </router-link>
      </div>

      <!-- END col-3 -->
    </div>
    <!-- END row -->
    <div class="row">
      <div class="col-12">
        <panel title="Items rechazados por mes en el año actual" noButton="true" bodyClass="p-0">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <apexchart ref="barChart" type="bar" :options="barChart.options" :series="barChart.series"></apexchart>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <pie-chart v-if="showPieChart && isDataLoaded" ref="myChart" :data="pieChart.data" :options="pieChart.options" class="h-350px"></pie-chart>
            </div>
          </div>
        </panel>
      </div>
    </div>
    <!-- BEGIN row -->
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <div class="card border-0 mb-3 text-black">
          <panel title="ITEMES CON MÁS INCUMPLIMIENTOS EN EL AÑO ACTUAL" noButton="true">
            <div class="card-body scrolleeed"> <!--aqui aplicar overflow-x: scroll para mover hacia el lado-->
              <!-- BEGIN product -->
              <div v-for="(item, index) in inspProgramadasRango.topIncumplimientos" :key="index" class="align-items-center mb-15px" style="display: -webkit-box !important;">
                <div class="widget-img rounded-3 me-10px bg-black p-3px w-30px">
                  <div class="h-100 w-100" style="background: url(/assets/img/flag/truck_red.jpg) center no-repeat; background-size: auto 100%;"></div>
                </div>
                <div class="text-truncate">
                  <div>{{ item.itemName }}</div>
                  <div class="text-gray-600">{{ item.formName }}</div>
                </div>
                <div class="ms-auto text-center">
                  <div class="fs-13px">{{ item.count }}</div>
                  <div class="text-gray-600 fs-10px">no cumple</div>
                </div>
              </div>
              <!-- END product -->
            </div>
          </panel>
        </div>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
    </div>
    <!-- END row -->
    <div class="row">
      <!-- BEGIN col-4 -->
      <div class="col-xl-6 col-lg-6">
        <!-- BEGIN card -->
        <panel :title="`${inspProgramadasRango.equiposVencidos.length} Equipos Vencidos en el año actual`" noButton="true" bodyClass="p-0">
          <!-- Contenedor de lista con desplazamiento -->
          <!--            <div style="height: 500px; overflow-y: scroll; background-color: white; color: black;">&lt;!&ndash; aqui se pone el overflow-y: scroll&ndash;&gt;-->
          <!-- Lista de equipos vencidos -->
          <div v-for="equipo in inspProgramadasRango.equiposVencidos" :key="equipo.id" class="widget-list-item rounded-0 pt-3px">
            <div class="widget-list rounded-bottom inverse-mode">
              <a href="#" class="widget-list-item rounded-0 pt-3px">
                <div class="widget-list-media icon">
                  <i class="fa fa-truck bg-red text-white"></i>
                </div>
                <div class="widget-list-content">
                  <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id }}" style="color: black; text-decoration: none;">
                    <div class="widget-list-title">{{ equipo.patente }}</div>
                  </router-link>
                </div>
                <div class="widget-list-action text-nowrap text-gray-500">
                  {{ equipo.fechaInspeccionProxima }}
                </div>
              </a>
            </div>
            <!--              </div>-->
          </div>
        </panel>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
      <div class="col-xl-6 col-lg-6">
        <!-- BEGIN card -->
        <panel :title="`${inspProgramadasRango.equiposPorVencerEnTresMeses.length} Equipos por vencer hasta 3 meses más`" noButton="true" bodyClass="p-0">
          <!-- Contenedor de lista con desplazamiento -->
          <!--            <div style="height: 500px; overflow-y: scroll; background-color: white; color: black;">-->
          <!-- Lista de equipos vencidos -->
          <div v-for="equipo in inspProgramadasRango.equiposPorVencerEnTresMeses" :key="equipo.id" class="widget-list-item rounded-0 pt-3px">
            <div class="widget-list rounded-bottom inverse-mode">
              <a href="#" class="widget-list-item rounded-0 pt-3px">
                <div class="widget-list-media icon">
                  <i class="fa fa-truck bg-yellow text-white"></i>
                </div>
                <div class="widget-list-content">
                  <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id }}" style="color: black; text-decoration: none;">
                    <div class="widget-list-title">{{ equipo.patente }}</div>
                  </router-link>
                </div>
                <div class="widget-list-action text-nowrap text-gray-500">
                  {{ equipo.fechaInspeccionEnTresMeses }}
                </div>
              </a>
            </div>
          </div>
          <!--            </div>-->
        </panel>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
      <!-- BEGIN col-4 -->
      <div class="col-xl-6">
        <!-- BEGIN panel -->
        <panel :title="`Equipos rechazados por transportista (Analítica Global)`"  noButton="true" bodyClass="p-0">
          <!--            <div style="height: 500px; overflow-y: scroll; background-color: white; color: black;">-->
          <!-- Encabezado de las columnas -->
          <div style="display: flex; justify-content: space-between; padding: 10px; font-size: 12px; font-weight: bold; border-bottom: 1px solid #eaeaea;">
            <div>Empresa Transportista</div>
            <div>Total</div>
          </div>
          <!-- Listado de empresas y totales -->
          <div v-for="transportista in inspProgramadasRango.equiposTransportistasRechazados" :key="transportista.empresaTransportista" class="empresa-transportista" style="display: flex;
              justify-content: space-between; margin-bottom: 20px; padding: 10px; margin-top: 10px;">
            <div style="flex: 1;">
              <label class="badge bg-info" style="cursor: pointer; font-size: 14px;" @click="toggleVisibility(transportista.empresaTransportista)">
                {{ transportista.empresaTransportista }}
              </label>
              <div v-show="isVisible(transportista.empresaTransportista)" style="margin-top: 15px;">
                <div v-for="equipo in transportista.equipos" :key="equipo.patente" class="widget-list rounded-bottom inverse-mode">
                  <div class="widget-list-item rounded-0 pt-3px" style="border-bottom: 1px solid #eaeaea;">
                    <div class="widget-list-media icon">
                      <i class="fa fa-truck bg-red text-white"></i>
                    </div>
                    <div class="widget-list-content">
                      <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id , estado: 4}}" style="color: black; text-decoration: none;">
                        {{ equipo.patente }}
                      </router-link>
                    </div>
                    <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id , estado: 4}}" class="btn btn-primary btn-sm" style="margin-right: -10px; margin-bottom: 7px; margin-top: 7px">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div style="align-self: flex-start; font-size: 14px; margin-right: 10px">
              {{ transportista.equipos.length }}
            </div>
          </div>
          <!--            </div>-->
        </panel>
      </div>
      <!-- END col-4 -->
      <div class="col-xl-6 col-lg-6">
        <!-- BEGIN card -->
        <!-- BEGIN sales -->
        <panel :title="`${inspProgramadasRango.equiposReparadosFiltrados.length} Equipos que repararon sus fallas (Analítica Global)`" noButton="true" bodyClass="p-0">
          <!-- Contenedor de lista con desplazamiento -->
          <!--            <div style="height: 500px; overflow-y: scroll; background-color: white; color: black;">-->
          <!-- Lista de equipos vencidos -->
          <div v-for="equipo in inspProgramadasRango.equiposReparadosFiltrados" :key="equipo.id" class="widget-list-item rounded-0 pt-3px">
            <div class="widget-list rounded-bottom inverse-mode">
              <a href="#" class="widget-list-item rounded-0 pt-3px">
                <div class="widget-list-media icon">
                  <i class="fa fa-truck bg-green text-white"></i>
                </div>
                <div class="widget-list-content">
                  <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id }}" style="color: black; text-decoration: none;">
                    <div class="widget-list-title">{{ equipo.patente }}</div>
                  </router-link>
                </div>
                <div class="widget-list-action text-nowrap text-gray-500">
                  {{ equipo.tiempoReparacion }}
                </div>
              </a>
            </div>
          </div>
          <!--            </div>-->
        </panel>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
    </div>
    <!-- END row -->


  </div>
</template>

<script>
import axios from "axios";
import AppOptions from "@/config/AppOptions.vue";
import PieChart from "../components/vue-chartjs/PieChart"
import moment from "moment/moment";
import {es} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    PieChart,
    Datepicker
  },
  name: 'DashboardProgramadas',
  data() {
    return {
      filtrarPorFechas: false,
      usandoFiltroFechas: false,
      es:es,
      fechaIniInspProgramadas:'',
      fechaFinInspProgramadas:'',
      inspProgramadasRango: {
        fechaIniUsada: '', //Las fechas usadas para los filtros.
        fechaFinUsada: '',
        totalInspeccionesRango: 0,
        totalInspecciones: 0,
        equiposReparadosFiltrados: [],
        equiposTransportistasRechazados: [],
        equiposPorVencerEnTresMeses: [],
        topIncumplimientos: [],
        rechazosPorMes: [],
        conteoPorFormulario: [],
        equiposVencidos: [],
        equiposSelloAzul: [],
        equiposSelloRojo: [],
        equiposSinSello: [],
        porcentajeEquiposSinSello:0,
        porcentajeEquiposVencidos:0,
        porcentajeEquiposSelloAzul:0,
        porcentajeEquiposSelloRojo:0,
      },
      isDataLoaded: false,
      showPieChart: true,
      dateRange: {
        ranges:false,
        opens: 'right',
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: true,
        linkedCalendars: false,
        range: {
          startDate: moment().subtract(7, 'days'),
          endDate: moment(),
        },
      },
      empresa: '',
      visibleTransportistas: {}, // Para mantener el estado de qué listas están visibles
      equiposRechazados: {
        equiposRechazados: [] // Asegúrate de que esto se actualice con los datos del backend
      },
      barChart: {
        options: {
          chart: {
            height: 350,
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false, // Cambiar a `false` para un gráfico de barras vertical
              columnWidth: '56%', // Ajustar el ancho de las barras
            }
          },
          dataLabels: {
            enabled: true,
            offsetY: -6, // Ajusta la posición de las etiquetas si es necesario
            style: {
              fontSize: '12px',
              colors: [AppOptions.color.componentBg]
            }
          },
          colors: [AppOptions.color.orange, AppOptions.color.componentColor],
          stroke: {
            show: true,
            width: 1,
            colors: [AppOptions.color.componentBg]
          },
          xaxis: {
            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'], // Meses en el eje X
            axisBorder: {
              show: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .25)',
              height: 1,
              width: '100%',
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: true,
              color: 'rgba(' + AppOptions.color.componentColorRgb + ', .25)',
            },
            labels: {
              style: {
                colors: AppOptions.color.componentColor,
                fontSize: AppOptions.font.size,
                fontFamily: AppOptions.font.family,
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              }
            }
          },
          yaxis: {
            title: {
              text: 'Número de rechazos',
              style: {
                color: AppOptions.color.componentColor,
                fontSize: '14px',
                fontWeight: 600
              }
            },
            labels: {
              style: {
                colors: AppOptions.color.componentColor,
                fontSize: AppOptions.font.size,
                fontFamily: AppOptions.font.family,
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              }
            }
          },
          legend: {
            labels: {
              colors: AppOptions.color.componentColor
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        series: [{
          name: 'Rechazos por mes',
          data: [44, 55, 41, 64, 22, 43, 21, 10, 77, 54, 33, 89] // Datos de ejemplo, debes actualizarlos dinámicamente
        }]
      },
      pieChart: {
        data: {
          labels: ['King Ping', 'Checklist', 'Hermeticidad', 'Válvulas de alivio', 'Ultrasonido','Línea de vida'],
          datasets: [{
            data: [300, 50, 100, 40, 120,76],
            backgroundColor: [
              'rgba(' + AppOptions.color.redRgb + ', 0.7)',
              'rgba(' + AppOptions.color.orangeRgb + ', 0.7)',
              'rgba(' + AppOptions.color.blueRgb + ', 0.7)',
              'rgba(' + AppOptions.color.cyanRgb + ', 0.7)',
              'rgba(' + AppOptions.color.indigoRgb + ', 0.7)',
              'rgba(' + AppOptions.color.blackRgb + ', 0.7)'
            ],
            borderColor: [AppOptions.color.gray, AppOptions.color.gray, AppOptions.color.gray, AppOptions.color.gray, AppOptions.color.gray,AppOptions.color.gray],
            borderWidth: 2,
            label: 'My dataset'
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false
        }
      },
    }
  },
  watch:{
    // rechazosPorFormulario() {
    //   this.actualizarPieChart();
    // }
  },
  methods: {
    mostrarDatePickersFiltros(){
      let divFiltros = document.getElementById('divFiltros')
      if(!divFiltros.classList.contains('collapse')){ //si está mostrándose, limpiar los inputs y mostrar los datos por defecto
        this.borrarFiltro()
      }
      document.getElementById('botonMostrarFiltros').classList.toggle('active')
      divFiltros.classList.toggle('collapse')
    },
    updateBarChart(rechazosPorMes, rechazosPorDia, fechaIni, fechaFin, rechazosPorFechaExacta) {
      const meses = {
        1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May',
        6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'
      };

      let diasFiltrados = [];
      let datosFiltrados = [];

      // Si no se proporcionan fechaIni o fechaFin, mostrar los 12 meses del año
      if (!fechaIni || !fechaFin) {
        diasFiltrados = Object.values(meses); // Los nombres de los meses (Ene, Feb, etc.)
        datosFiltrados = Object.keys(meses).map(mes => rechazosPorMes[mes] || 0); // Los valores de rechazos por mes

        this.barChart.options.xaxis.categories = diasFiltrados;
        this.barChart.series = [{
          name: 'Rechazos por mes',
          data: datosFiltrados
        }];
      } else {
        // Si hay un rango de fechas, calcular según días o meses
        const startMonth = moment(fechaIni, 'DD-MM-YYYY').month() + 1;
        const endMonth = moment(fechaFin, 'DD-MM-YYYY').month() + 1;

        if (startMonth === endMonth) {
          // Si el rango de fechas está en un solo mes, mostrar los días del mes
          const startDay = moment(fechaIni, 'DD-MM-YYYY').date();
          const endDay = moment(fechaFin, 'DD-MM-YYYY').date();

          for (let day = startDay; day <= endDay; day++) {
            // Cambiamos el formato de las etiquetas de los días a "DD-MM-YYYY" para que coincidan con las claves de rechazosPorFechaExacta
            const dayLabel = moment(`${day}-${startMonth}-${moment().year()}`, 'D-M-YYYY').format('DD-MM-YYYY');
            diasFiltrados.push(dayLabel); // Etiqueta del día (por ejemplo, "03-09-2024")

            datosFiltrados.push(rechazosPorFechaExacta[dayLabel] || 0); // Valor del día, usando rechazosPorFechaExacta
          }

          this.barChart.options.xaxis.categories = diasFiltrados;
          this.barChart.series = [{
            name: 'Rechazos por día',
            data: datosFiltrados
          }];

        } else {
          // Si el rango es mayor a un mes, mostrar por meses
          let mesesFiltrados = [];
          let datosMesesFiltrados = [];

          for (let mes = startMonth; mes <= endMonth; mes++) {
            mesesFiltrados.push(meses[mes]); // Etiqueta del mes
            datosMesesFiltrados.push(rechazosPorMes[mes] || 0); // Valor del mes
          }

          this.barChart.options.xaxis.categories = mesesFiltrados;
          this.barChart.series = [{
            name: 'Rechazos por mes',
            data: datosMesesFiltrados
          }];
        }
      }

      // Forzar la actualización del gráfico sin destruirlo
      this.$nextTick(() => {
        this.$refs.barChart.updateOptions({
          xaxis: {
            categories: this.barChart.options.xaxis.categories
          }
        });
        this.$refs.barChart.updateSeries(this.barChart.series);
      });
    },


    updatePieChart(conteoPorFormulario) {
      this.pieChart.data.labels = Object.keys(conteoPorFormulario); // Actualizar etiquetas del gráfico de torta
      this.pieChart.data.datasets[0].data = Object.values(conteoPorFormulario); // Actualizar datos del gráfico de torta

      // Asegúrate de que el componente del gráfico se re-renderice correctamente
      this.$nextTick(() => {
        this.$refs.myChart.update(); // Este comando es para Chart.js, ajusta si usas otra librería
      });
    },
    async inspeccionesProgramadasRango() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username;

      let fechaIni = (this.fechaIniInspProgramadas) ? moment(this.fechaIniInspProgramadas).format('DD-MM-YYYY') : null;
      let fechaFin = (this.fechaFinInspProgramadas) ? moment(this.fechaFinInspProgramadas).format('DD-MM-YYYY') : null;

      // Si las fechas están al revés, las intercambiamos
      if (fechaIni && fechaFin && moment(fechaIni, 'DD-MM-YYYY').isAfter(moment(fechaFin, 'DD-MM-YYYY'))) {
        // Intercambiar las fechas
        [fechaIni, fechaFin] = [fechaFin, fechaIni];
      }

      this.usandoFiltroFechas = (fechaIni != null || fechaFin != null);

      try {
        const response = await axios.post(
            `${process.env.VUE_APP_IP_BACKEND}/inspeccion/inspeccionesProgramadasRango`,
            {},
            {
              params: {
                username: username,
                fechaIni: fechaIni,
                fechaFin: fechaFin,
              },
              headers: {
                "Authorization": `Bearer ${tokenHeader}`
              }
            }
        );

        if (response.status == 200 || response.status == 201) {
          this.$set(this, 'inspProgramadasRango', response.data);

          console.log(response.data.rechazosPorFechaExacta);

          this.showPieChart = false;
          this.$nextTick(() => {
            this.showPieChart = true;
          });

          this.updatePieChart(response.data.conteoPorFormulario);
          this.updateBarChart(response.data.rechazosPorMes, response.data.rechazosPorDia, fechaIni, fechaFin, response.data.rechazosPorFechaExacta);

          this.isDataLoaded = true;
        } else {
          this.$showToastError('Error al cargar los datos!', 'UPS!', this.$notificationSystem().options.error);
        }

      } catch (error) {
        console.error("Error al cargar los datos", error);
      }
    },


    borrarFiltro() {
      this.fechaIniInspProgramadas = '';
      this.fechaFinInspProgramadas = '';
      this.inspeccionesProgramadasRango();  // <-- Esta línea se asegura de recargar los datos y actualizar el PieChart

      this.filtrarPorFechas = false;
      this.usandoFiltroFechas = false;
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    toggleVisibility(empresaTransportista) {
      this.$set(this.visibleTransportistas, empresaTransportista, !this.visibleTransportistas[empresaTransportista]);
    },
    isVisible(empresaTransportista) {
      return !!this.visibleTransportistas[empresaTransportista];
    },
    updateValues(d) {
      var startDate = moment(d.startDate);
      var endDate = moment(d.endDate);
      var gap = endDate.diff(startDate, 'days');

      this.dateRange.range.prevStartDate = moment(startDate).subtract('days', gap).format('D MMMM');
      this.dateRange.range.prevEndDate = moment(startDate).subtract('days', 1).format('D MMMM YYYY');
    },
  },
  filters: {
    date: function(value) {
      if (value) {
        return moment(String(value)).format('D MMMM YYYY')
      }
    }
  },
  created(){
    this.inspeccionesProgramadasRango()
  }
}
</script>
<style scoped>
@media (max-width: 768px) {
  .scrolleeed{
    overflow-x: scroll;
  }
}
.widget-link {
  display: block;
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Usa el color del texto heredado */
}
.widget-link:hover {
  text-decoration: none; /* Asegura que no se subraye al pasar el mouse */
  color: inherit; /* Asegura que el color no cambie al pasar el mouse */
}
</style>