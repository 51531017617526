<template>
  <table class="table table-striped text-center">
    <thead>
    <tr>
      <th class="d-none d-sm-table-cell">N°</th>
      <th class="p-1">ITEM/COMPONENTE INSPECCIONADO</th>
      <th class="px-0" style="width: 3%">
        Cumple<br>
        <button v-if="!readOnly" class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
      </th>
      <th class="px-0" style="width: 3%">
        Requiere Mejora<br>
        <button v-if="!readOnly" class="btn btn-warning btn-sm" type="button" @click="marcarColumna('mejora')"><i class="fa fa-check"></i></button>
      </th>
      <th class="px-0" style="width: 3%">
        No Cumple<br>
        <button v-if="!readOnly" class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
      </th>
      <th class="px-0" style="width: 3%">
        No Aplica<br>
        <button v-if="!readOnly" class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in itemListChecklistProcesado" :key="item.itemId" >
      <td class="d-none d-sm-table-cell"> {{item.numero}} </td>
      <td class="p-1">
        <strong :style="{backgroundColor: item.color, cursor:'pointer'}">
          {{item.nombre}}
        </strong>
        <div v-if="item.mostrarObservaciones">
                 <textarea v-if="item.mostrarObservaciones"
                           v-model="item.observaciones"
                           class="observaciones-textarea"
                           placeholder="Escriba sus observaciones aquí..."
                           :disabled="readOnly">
                </textarea>
        </div>
      </td>
      <td class="px-0">
        <input type="checkbox" v-model="item.valorCumple"   class="checkbox cumple" :disabled="readOnly">
      </td>
      <td class="px-0">
        <input type="checkbox" v-model="item.valorMejora"   class="checkbox mejora" :disabled="readOnly">
      </td>
      <td class="px-0">
        <input type="checkbox" v-model="item.valorNoCumple" class="checkbox noCumple" :disabled="readOnly">
      </td>
      <td class="px-0">
        <input type="checkbox" v-model="item.valorNoAplica" class="checkbox noAplica" :disabled="readOnly">
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ComponenteFormChecklist',
  props: {
    'itemListChecklist': {
      type: Array,
      default: function() {
        return [];
      }
    },
    'readOnly': {
      type: Boolean,
      default: true
    },
  },
  computed: {
    itemListChecklistProcesado() {
      let itemChecklist = []
      if(this.itemListChecklist){
        itemChecklist = this.itemListChecklist.map((item) => {
          let valorCumple   = false
          let valorMejora   = false
          let valorNoCumple = false
          let valorNoAplica = false
          if(item.valorNoAplica === true) valorNoAplica = true
          if(item.ponderacionId){
            if     (item.ponderacionId === 1) valorCumple   = true
            else if(item.ponderacionId === 2) valorMejora   = true
            else if(item.ponderacionId === 3) valorNoCumple = true
          }

          return {
            itemId: item.itemId,
            numero: item.index,
            nombre: item.texto,
            valorCumple:   valorCumple  ,
            valorMejora:   valorMejora  ,
            valorNoAplica: valorNoAplica,
            valorNoCumple: valorNoCumple,
            color: 'unset',
            mostrarObservaciones: (item.observaciones), // Inicialmente oculto
            observaciones:        item.observaciones
          };
        });
      }
      return itemChecklist
    }
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}
input[type="checkbox"]:checked {
  background: #0060DEFF;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
.fila-verde {
  background-color: #d6ffd6; /* Fondo verde para la fila */
}
.fila-amarilla {
  background-color: #fdb31a; /* Fondo verde para la fila */
}
.fila-roja {
  background-color: #ff0a0a; /* Fondo verde para la fila */
}

</style>
