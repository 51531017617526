<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">CheckList</li>
    </ol>
    <h1 class="page-header">INSPECCION a EQUIPO de TRANSPORTE</h1>
    <h2>CHECK LIST GENERAL & REGISTRO DE OBSERVACIONES  <small>haga click en el item para agregar observaciones</small></h2>
    <panel title="">
      <table class="table table-striped text-center">
        <thead>
          <tr>
            <th class="d-none d-sm-table-cell">N°</th>
            <th class="p-1">ITEM/ COMPONENTE INSPECCIONADO</th>
            <th class="px-0" style="width: 3%">
              Cumple<br>
              <button class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
            </th>
            <th class="px-0" style="width: 3%">
              Requiere Mejora<br>
              <button class="btn btn-warning btn-sm" type="button" @click="marcarColumna('mejora')"><i class="fa fa-check"></i></button>
            </th>
            <th class="px-0" style="width: 3%">
              No Cumple<br>
              <button class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
            </th>
            <th class="px-0" style="width: 3%">
              No Aplica<br>
              <button class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
            </th>
          </tr>
        </thead>
        <tbody v-for="item in checkListGeneral" :key="item.itemId">
          <tr>
            <td class="d-none d-sm-table-cell"> {{item.numero}} </td>
            <td class="p-1">
              <strong @click="toggleObservaciones(item)" :style="{backgroundColor: item.color, cursor:'pointer'}">
                {{item.nombre}}
              </strong>
              <div v-if="item.numero === 23">
                  <div class="hubodometro d-flex justify-content-center">
                    <input class="form-control" placeholder="Valor Hubodómetro" type="number" v-model="form.hubodometro" style="text-align: center;width: 100%; max-width: 250px;"/>
                  </div>
              </div>
<!--              <tr v-if="item.numero === 23">-->
<!--                <td colspan="6" style="text-align: center;"> &lt;!&ndash; Alinea el contenido al centro &ndash;&gt;-->
<!--                </td>-->
<!--              </tr>-->
              <div v-if="item.mostrarObservaciones">
                 <textarea v-if="item.mostrarObservaciones"
                           v-model="item.observaciones"
                           @blur="guardarObservaciones(item)"
                           class="observaciones-textarea"
                           placeholder="Escriba sus observaciones aquí...">
                </textarea>
              </div>
            </td>
            <td class="px-0">
              <input type="checkbox" class="checkbox cumple mx-0" v-model="item.valorCumple" v-if="item.valorMejora === false && item.valorNoCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,1)">
            </td>
            <td class="px-0">
              <input type="checkbox" class="checkbox mejora mx-0" v-model="item.valorMejora" v-if="item.valorNoCumple === false && item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,2)">
            </td>
            <td class="px-0">
              <input type="checkbox" class="checkbox noCumple mx-0" v-model="item.valorNoCumple" v-if="item.valorMejora === false && item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item, 3)">
            </td>
            <td class="px-0">
              <input type="checkbox" class="checkbox noAplica mx-0" v-model="item.valorNoAplica" v-if="item.valorMejora === false && item.valorCumple === false && item.valorNoCumple === false" @change="handleCheckboxChange($event,item, 4)">
            </td>
          </tr>
        </tbody>
      </table>
      <button type="button" class="btn btn-secondary m-3" v-on:click="volver()"><i class="fa fa-caret-left"></i> Volver</button>
      <button type="button" class="btn btn-primary m-3" v-on:click="guardarYContinuar()">Guardar y continuar <i class="fa fa-caret-right"></i><i class="fa fa-caret-right"></i></button>
    </panel>
  </div>
</template>
<script>

export default {
  name: "FormularioChecklist",
  data() {
    return {
      nombreFormulario: '',
      checkListGeneral: [],
      form: {
        hubodometro: '',
      },
    }
  },
  methods: {
    marcarColumna(claseCheck){
      let checks = document.querySelectorAll('.'+claseCheck)
      let event = new Event('change');
      if(checks){
        checks.forEach(check => {
          check.checked = !check.checked
          check.dispatchEvent(event);
        })
      }
    },
    handleCheckboxChange(event,item,tipo) { //cumple = 1, mejora = 2, noCumple = 3, noAplica = 4
      let estadoCheck = event.srcElement.checked
      const index = this.checkListGeneral.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        let valorCumple   = (tipo === 1 && estadoCheck)
        let valorMejora   = (tipo === 2 && estadoCheck)
        let valorNoCumple = (tipo === 3 && estadoCheck)
        let valorNoAplica = (tipo === 4 && estadoCheck)
        let color
        if(estadoCheck === true){
          if      (tipo === 1) color = '#ccffcc'
          else if (tipo === 2) color = '#fff6b3'
          else if (tipo === 3) color = '#ffbbbb'
          else if (tipo === 4) color = '#d2d2d2'
        }else color = 'unset'
        this.$set(this.checkListGeneral, index, {
          ...this.checkListGeneral[index],
          valorCumple:   valorCumple,
          valorMejora:   valorMejora,
          valorNoCumple: valorNoCumple,
          valorNoAplica: valorNoAplica,
          color: color
        });
      }
    },
    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },
    guardarObservaciones(item) {
      const index = this.checkListGeneral.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        this.$set(this.checkListGeneral[index], 'observaciones', item.observaciones);
      }
    },
    async volver(){
      this.$irVistaInspeccionProgramada(this.inspeccionId, 'Checklist', true)
    },
    async guardarYContinuar() {
      let itemListEnvio = []
      for(let i = 0; i < this.checkListGeneral.length; i++){
        let item = this.checkListGeneral[i]
        let ponderacionId = null
        if      (item.valorCumple)   ponderacionId = 1
        else if (item.valorMejora)   ponderacionId = 2
        else if (item.valorNoCumple) ponderacionId = 3
        let noAplica = item.valorNoAplica
        if(ponderacionId == null) noAplica = true
        let itemProcesado = {
            itemId:        item.itemId,
            valor:         null,
            valorBooleano: null,
            noAplica:      noAplica,
            observaciones: item.observaciones,
            ponderacionId: ponderacionId,
        }
        itemListEnvio.push(itemProcesado)
      }
      let exito = await this.$guardarItemsInspeccionProgramada(itemListEnvio,this.inspeccionId, 'Checklist', this.form.hubodometro)
      if(exito){
        this.$irVistaInspeccionProgramada(this.inspeccionId, 'Checklist', false)
      }
    },
    async itemListChecklist(inspeccionId) {
      let data = await this.$itemListMasInfoFormularioInspProgramada(inspeccionId, 'Checklist')
      if(data){
        this.checkListGeneral = data.itemList.map((item) => {
          let valorCumple   = false
          let valorMejora   = false
          let valorNoCumple = false
          let valorNoAplica = false
          if(item.valorNoAplica === true) valorNoAplica = true
          if(item.ponderacionId){
            if     (item.ponderacionId === 1) valorCumple   = true
            else if(item.ponderacionId === 2) valorMejora   = true
            else if(item.ponderacionId === 3) valorNoCumple = true
          }

          return {
            itemId: item.itemId,
            numero: item.index,
            nombre: item.texto,
            valorCumple:   valorCumple  ,
            valorMejora:   valorMejora  ,
            valorNoAplica: valorNoAplica,
            valorNoCumple: valorNoCumple,
            color: 'unset',
            mostrarObservaciones: (item.observaciones), // Inicialmente oculto
            observaciones:        item.observaciones
          };
        });
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.inspeccionId;
    if (this.inspeccionId) {
      this.itemListChecklist(this.inspeccionId);
    }
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
.fila-verde {
  background-color: #d6ffd6; /* Fondo verde para la fila */
}
.fila-amarilla {
  background-color: #fdb31a; /* Fondo verde para la fila */
}
.fila-roja {
  background-color: #ff0a0a; /* Fondo verde para la fila */
}

@media (max-width: 768px) {
  .panel {
    overflow-x: auto; /* Asegura que la tabla sea desplazable horizontalmente si es necesario */
  }
  .hubodometro {
    width: 100%; /* Ajustar el ancho según necesidad */
  }
  .table.table-striped td.p-1 {
    white-space: normal; /* Permite que el texto se ajuste dentro de la celda */
    text-align: left; /* Alinea el texto a la izquierda para una mejor lectura */
    padding: 5px; /* Asegura un poco de espacio alrededor del texto para evitar que se pegue a los bordes */
    font-size: 8px; /* Ajusta el tamaño del texto según necesidad */
  }

  .table.table-striped th, .table.table-striped td {
    padding: 2px 5px; /* Ajusta el padding general para todas las celdas */
  }

  .table.table-striped th {
    font-size: 10px; /* Ajusta el tamaño del texto de los encabezados si es necesario */
  }

  .table.table-striped .btn {
    padding: 3px 6px; /* Reduce el padding para hacer los botones más compactos */
    margin: 2px 0; /* Añade un margen vertical para separar los botones entre sí */
    width: auto; /* Permite que el ancho del botón se ajuste al contenido */
    font-size: 9px; /* Reduce un poco el tamaño de la fuente dentro de los botones */
  }

  .table.table-striped input[type="checkbox"] {
    transform: scale(0.90); /* Reduce la escala de los checkboxes */
    margin: auto; /* Ajusta el margen automático para centrar los checkboxes visualmente */
  }
}
</style>
