<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Ver</a></li>
      <li class="breadcrumb-item active">Conductor</li>
    </ol>
    <h1 class="page-header">Ver Conductor <small></small></h1>
    <panel title="Formulario de edición de conductor">
      <form v-on:submit.prevent="" method="POST" autocomplete="off">
        <div class="mb-3 col-md-12">
          <label class="form-label" id="nombre"><strong>Nombre</strong> </label>
          <input class="form-control" type="text" v-model="showDataConductor.nombre"/>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="apellido"><strong>Apellido</strong> </label>
          <input class="form-control" type="text" v-model="showDataConductor.apellido" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="licencia"><strong>Licencia</strong> </label>
          <input class="form-control" type="text" v-model="showDataConductor.licencia" />
        </div>
        <div class="row">
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-secondary" v-on:click="cancelar()">Cancelar</button>
          </div>
          <div class="col-sm-8"></div>
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-primary" v-on:click="editarConductor()">Editar</button>
          </div>
        </div>
      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ShowConductor",
  data(){
    return{
      form:{
        "nombre": "",
        "apellido": "",
        "licencia": "",
      },
      showDataConductor:{

      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods:{
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },

    async getConductor(){
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user/'
      const api = process.env.VUE_APP_IP_BACKEND + "/conductor/showConductor/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showDataConductor = response.data
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }

    },

    async editarConductor(){
      this.loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + "/conductor/editarConductor/"
      // const api = "http://localhost:8181/ta-ms-users/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      try {
        //PETICION
        let response = await axios.put(apiId,this.showDataConductor, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showDataConductor = response.data
          this.showToastSuccess('Conductor '+" "+ this.showDataConductor.nombre+ " editado.", 'Exito!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    cancelar(){
      this.$router.push({path: '/listaConductor'})
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.getConductor()
  }


}

</script>




<style scoped>

</style>