<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Empresa</a></li>
      <li class="breadcrumb-item active">Crear</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Nueva Empresa <small></small></h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <panel>
        <div class="mb-3">
          <label class="form-label" id="razonSocial"><strong>Razon Social*</strong> </label>
          <input class="form-control" type="text" v-model="form.razonSocial"/>
        </div>
      <div class="row">
        <div class="mb-3 col-md-6">
          <label class="form-label">Rut*</label>
          <div>
            <masked-input pattern="11111111" mask="11111111" type="tel" v-model="form.rut" class="form-control" placeholder="00000000" ></masked-input>
          </div>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label">DV*</label>
          <div>
            <input
                type="text"
                v-model="form.codigoVerificador"
                @input="validateDV"
                class="form-control"
                placeholder="0-K">
          </div>
        </div>
      </div>
        <div class="mb-3">
          <label class="form-label" id="fonoEmpresa"><strong>Fono Empresa</strong> </label>
          <input class="form-control" type="number" v-model="form.fonoEmpresa"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="emailEmpresa"><strong>Email Empresa</strong> </label>
          <input class="form-control" type="text" v-model="form.emailEmpresa"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="web"><strong>Web</strong> </label>
          <input class="form-control" type="text" v-model="form.web"/>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="empresaInterna">
            Empresa Interna
            <input class="form-check-input" type="checkbox" id="empresaInterna" v-model="form.empresaInterna">
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="empresaHabilitada">
            Empresa Habilitada
            <input class="form-check-input" type="checkbox" id="empresaHabilitada" v-model="form.empresaHabilitada">
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="cuentaExpirada">
            Cuenta Expiarada
            <input class="form-check-input" type="checkbox" id="cuentaExpirada" v-model="form.cuentaExpirada">
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="cuentaBloqueada">
            Cuenta Bloqueada
            <input class="form-check-input" type="checkbox" id="cuentaBloqueada" v-model="form.cuentaBloqueada">
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="contrasenaExpirada">
            Contraseña Expirada
            <input class="form-check-input" type="checkbox" id="contrasenaExpirada" v-model="form.contrasenaExpirada">
          </label>
        </div>
        <div class="mb-3">
          <label class="form-label" id="direccionEmpresa"><strong>Direccion Empresa</strong> </label>
          <input class="form-control" type="text" v-model="form.direccionEmpresa"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="ciudadEmpresa"><strong>Ciudad Empresa</strong> </label>
          <select class="form-control" v-if="ciudadEmpresaList.length > 0" v-model="form.ciudadEmpresa">
            <option v-for="ciudadEmpresa in ciudadEmpresaList" :value="ciudadEmpresa.id" :key="ciudadEmpresa.id">
              {{ciudadEmpresa.nombre }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" id="tipoEmpresa"><strong>Tipo de Empresa*</strong> </label>
          <select class="form-control" v-if="tipoEmpresaList.length > 0" v-model="form.tipoEmpresa">
            <option v-for="tipoEmpresa in tipoEmpresaList" :value="tipoEmpresa.id" :key="tipoEmpresa.id">
              {{tipoEmpresa.nombre }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" id="kmMantencion"><strong>Mantención Programada (KM)</strong> </label>
          <input class="form-control" type="number" v-model="form.kmMantencion"/>
        </div>
        <button type="button" class="btn btn-primary" v-on:click="crearEmpresa()">Crear</button>
    </panel>
    <!--		 END panel-->
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      formatCharacters: {
        'A': {
          validate: char => /[0-9kK]/.test(char),
          transform: char => char.toUpperCase()
        }
      },
      ciudadEmpresaList:[],
      tipoEmpresaList:[],
      form: {
        "razonSocial":"",
        "rut":"",
        "codigoVerificador": '',
        "fonoEmpresa": "",
        "emailEmpresa": "",
        "web": "",
        empresaInterna: false,
        empresaHabilitada: false,
        cuentaExpirada: false,
        cuentaBloqueada: false,
        contrasenaExpirada: false,
        "direccionEmpresa": "",
        "ciudadEmpresa": null,
        "tipoEmpresa": 1,
        "kmMantencion": "",
      },
      jsonDataEmpresa: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  created: function () {
    this.$validarLogin()
  },
  async mounted(){
    try {
      this.ciudadEmpresaList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/ciudad/ciudadList');
      this.tipoEmpresaList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaTipoList');
    } catch (error) {
      console.error(error);
      // Manejar errores
    }
  },
  methods: {
    validateDV(event) {
      const input = event.target.value.toUpperCase();
      const validChars = /^[0-9K]$/;

      if (input.length > 0 && !validChars.test(input)) {
        event.target.value = input.slice(0, -1);
      } else {
        this.form.codigoVerificador = input;
      }
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    async fetchData(url) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        // Manejar errores
        return []; // Devolver un array vacío en caso de error
      }
    },

    async crearEmpresa() {
      this.loadingSpiner()
      if (!this.form.razonSocial) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene razon Social!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.rut) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Ingrese un Rut!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.codigoVerificador) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Ingrese el Codigo Verificador!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.tipoEmpresa) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Ingrese el Codigo Verificador!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      // const api = "http://localhost:8181/ta-ms-agente/agenteVirtual/saveAgente";
      const api = process.env.VUE_APP_IP_BACKEND + "/empresa/saveEmpresa";
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      try {
        //PETICION
        let response = await axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.jsonDataEmpresa = response.data
          this.$router.push({path: '/showEmpresa/' + this.jsonDataEmpresa.id})
          this.showToastSuccess('Empresa creada !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },

  }
}
</script>