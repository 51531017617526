<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Líneas de Vida</li>
    </ol>
    <h1 class="page-header">Inspección Líneas de Vida </h1>
    <panel title="">
      <!--   Líneas de Vida   -->
      <template>
        <h4 class="mx-5"><u>Líneas de Vida</u></h4>
        <div class="row mx-3">
<!--          Tabla que se ve en vista de escritorio-->
          <table class="table table-striped d-none d-sm-block" v-if="(itemListLineaInp.length > 0 && itemListLineaBool.length > 0)">
            <thead>
              <tr>
                <th style="width: 15%"></th>
                <th style="width: 20%"></th>
                <th></th>
                <th>Si</th>
                <th>No</th>
                <th style="white-space: nowrap;">N/A</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x,index) in itemListLineaBool.length" :key="x">
                <td>
                  <strong v-if="itemListLineaInp[index]">
                    {{itemListLineaInp[index].nombre}}
                  </strong>
                </td>
                <td>
                  <span v-if="itemListLineaInp[index]">
                    <input type="text" class="form-control" v-model="itemListLineaInp[index].valor">
                  </span>
                </td>
                <td>
                  <strong >
                    {{itemListLineaBool[index].nombre}}
                  </strong>
                </td>
                <td>
                  <input type="checkbox" v-model="itemListLineaBool[index].valorSi" :disabled="!(itemListLineaBool[index].valorNo === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 1)" class="checkbox check-inspeccion">
                </td>
                <td>
                  <input type="checkbox" v-model="itemListLineaBool[index].valorNo" :disabled="!(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 2)" class="checkbox check-inspeccion">
                </td>
                <td>
                  <input type="checkbox" v-model="itemListLineaBool[index].valorNoAplica" :disabled="!(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 3)" class="checkbox check-inspeccion">
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Tablas que se ven en vista de celular-->
          <table class="table table-striped d-block d-sm-none" v-if="(itemListLineaInp.length > 0)">
            <tbody>
              <tr v-for="(x,index) in itemListLineaInp.length" :key="x">
                <td style="width: 25%">
                  <strong v-if="itemListLineaInp[index]">
                    {{itemListLineaInp[index].nombre}}
                  </strong>
                </td>
                <td style="width: 25%">
                  <span v-if="itemListLineaInp[index]">
                    <input type="text" class="form-control" v-model="itemListLineaInp[index].valor">
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-striped d-block d-sm-none" v-if="(itemListLineaBool.length > 0)">
            <thead>
            <tr>
              <th></th>
              <th style="width: 15%">Si</th>
              <th style="width: 15%">No</th>
              <th style="width: 15%;white-space: nowrap;">N/A</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(x,index) in itemListLineaBool.length" :key="x">
                <td>
                  <strong v-if="itemListLineaBool[index]">
                    {{itemListLineaBool[index].nombre}}
                  </strong>
                </td>
                <td>
                    <input type="checkbox" v-model="itemListLineaBool[index].valorSi" :disabled="!(itemListLineaBool[index].valorNo === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 1)" class="checkbox check-inspeccion">
                </td>
                <td>
                    <input type="checkbox" v-model="itemListLineaBool[index].valorNo" :disabled="!(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 2)" class="checkbox check-inspeccion">
                </td>
                <td>
                    <input type="checkbox" v-model="itemListLineaBool[index].valorNoAplica" :disabled="!(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 3)" class="checkbox check-inspeccion">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <hr>
      <!--   Pilar   -->
      <div class="row m-3">
        <h4 class="m-3"><u>Pilar</u></h4>
        <template>
          <table class="table table-striped d-none d-sm-block" v-if="(itemListPilarInp.length > 0 && itemListPilarBool.length > 0)">
            <thead>
            <tr>
              <th style="width: 15%"></th>
              <th style="width: 20%"></th>
              <th></th>
              <th>Si</th>
              <th>No</th>
              <th style="white-space: nowrap;">N/A</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(x,index) in itemListPilarInp.length" :key="x">
                <td style="width: 25%">
                  <strong v-if="itemListPilarInp[index]">
                    {{itemListPilarInp[index].nombre}}
                  </strong>
                </td>
                <td style="width: 25%">
                  <span v-if="itemListPilarInp[index]">
                    <input type="text" class="form-control" v-model="itemListPilarInp[index].valor">
                  </span>
                </td>
                <td v-if="itemListPilarBool[index]">
                  <strong>
                    {{itemListPilarBool[index].nombre}}
                  </strong>
                </td>
                <td v-if="itemListPilarBool[index]">
                  <input type="checkbox" v-model="itemListPilarBool[index].valorSi" :disabled="!(itemListPilarBool[index].valorNo === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 1)" class="checkbox check-inspeccion">
                </td>
                <td v-if="itemListPilarBool[index]">
                    <input type="checkbox" v-model="itemListPilarBool[index].valorNo" :disabled="!(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 2)" class="checkbox check-inspeccion">
                </td>
                <td v-if="itemListPilarBool[index]">
                    <input type="checkbox" v-model="itemListPilarBool[index].valorNoAplica" :disabled="!(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 3)" class="checkbox check-inspeccion">
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Tablas que se ven en vista de celular-->
          <table class="table table-striped d-block d-sm-none" v-if="(itemListPilarInp.length > 0)">
            <tbody>
            <tr v-for="(x,index) in itemListPilarInp.length" :key="x">
              <td style="width: 25%">
                <strong v-if="itemListPilarInp[index]">
                  {{itemListPilarInp[index].nombre}}
                </strong>
              </td>
              <td style="width: 25%">
                  <span v-if="itemListPilarInp[index]">
                    <input type="text" class="form-control" v-model="itemListPilarInp[index].valor">
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table table-striped d-block d-sm-none" v-if="(itemListPilarBool.length > 0)">
            <thead>
            <tr>
              <th></th>
              <th style="width: 15%">Si</th>
              <th style="width: 15%">No</th>
              <th style="width: 15%;white-space: nowrap;">N/A</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(x,index) in itemListPilarBool.length" :key="x">
              <td>
                <strong v-if="itemListPilarBool[index]">
                  {{itemListPilarBool[index].nombre}}
                </strong>
              </td>
              <td>
                <input type="checkbox" v-model="itemListPilarBool[index].valorSi" :disabled="!(itemListPilarBool[index].valorNo === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 1)" class="checkbox check-inspeccion">
              </td>
              <td>
                <input type="checkbox" v-model="itemListPilarBool[index].valorNo" :disabled="!(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 2)" class="checkbox check-inspeccion">
              </td>
              <td>
                <input type="checkbox" v-model="itemListPilarBool[index].valorNoAplica" :disabled="!(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 3)" class="checkbox check-inspeccion">
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <hr>
      <!--   Pletina   -->
      <div class="row m-3">
        <h4 class="m-3"><u>Pletina</u></h4>
        <template>
          <table class="table table-striped" v-if="(itemListPletinaInp.length > 0)">
            <tbody>
              <tr v-for="(x,index) in itemListPletinaInp.length" :key="x">
                <td style="width: 25%">
                  <strong v-if="itemListPletinaInp[index]">
                    {{itemListPletinaInp[index].nombre}}
                  </strong>
                </td>
                <td style="width: 25%">
                  <span v-if="itemListPletinaInp[index]">
                    <input type="text" class="form-control" v-model="itemListPletinaInp[index].valor">
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
      <hr>
<!--   Inspección Visual   -->
      <template>
        <div class="row text-center">
          <h4><u>Inspección Visual</u></h4>
        </div>
        <!-- Tabla que se ven en vista de escritorio-->
        <table class="table table-striped d-none d-sm-block">
          <thead>
          <tr>
            <th>ITEM/COMPONENTE INSPECCIONADO</th>
            <th style="width: 7%">
              Cumple<br>
              <button class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
            </th>
            <th style="width: 7%">
              No Cumple<br>
              <button class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
            </th>
            <th style="width: 7%">
              No Aplica<br>
              <button class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
            </th>
            <th>ITEM/COMPONENTE INSPECCIONADO</th>
            <th style="width: 7%">
              Cumple<br>
              <button class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
            </th>
            <th style="width: 7%">
              No Cumple<br>
              <button class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
            </th>
            <th style="width: 7%">
              No Aplica<br>
              <button class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
            </th>
          </tr>
          </thead>
          <tbody v-if="itemListInspeccion.length > 0">
          <tr v-for="(x,index) in itemListInspeccion.length/2" :key="x" >
            <td>
              <strong :style="{backgroundColor: itemListInspeccion[index].color}">
                {{itemListInspeccion[index].nombre}}
              </strong>
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index].valorCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorNoCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index],1)" class="checkbox cumple check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index].valorNoCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 3)" class="checkbox noCumple check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index].valorNoAplica" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoCumple === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 4)" class="checkbox noAplica check-inspeccion">
            </td>
<!--            segunda columna-->
            <td>
              <strong :style="{backgroundColor: itemListInspeccion[index +4].color}">
                {{itemListInspeccion[index +4].nombre}}
              </strong>
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index +4].valorCumple" v-if="itemListInspeccion[index +4].valorMejora === false && itemListInspeccion[index +4].valorNoCumple === false && itemListInspeccion[index +4].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index +4],1)" class="checkbox cumple check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index +4].valorNoCumple" v-if="itemListInspeccion[index +4].valorMejora === false && itemListInspeccion[index +4].valorCumple === false && itemListInspeccion[index +4].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index +4], 3)" class="checkbox noCumple check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index +4].valorNoAplica" v-if="itemListInspeccion[index +4].valorMejora === false && itemListInspeccion[index +4].valorCumple === false && itemListInspeccion[index +4].valorNoCumple === false" @change="handleCheckboxChange($event,itemListInspeccion[index +4], 4)" class="checkbox noAplica check-inspeccion">
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Tablas que se ven en vista de celular-->
        <table class="table table-striped d-block d-sm-none">
          <thead>
          <tr>
            <th>ITEM/COMPONENTE INSPECCIONADO</th>
            <th style="width: 7%">
              Cumple<br>
              <button class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
            </th>
            <th style="width: 7%">
              No Cumple<br>
              <button class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
            </th>
            <th style="width: 7%">
              No Aplica<br>
              <button class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
            </th>
          </tr>
          </thead>
          <tbody v-if="itemListInspeccion.length > 0">
          <tr v-for="(x,index) in itemListInspeccion.length" :key="x" >
            <td>
              <strong :style="{backgroundColor: itemListInspeccion[index].color}">
                {{itemListInspeccion[index].nombre}}
              </strong>
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index].valorCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorNoCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index],1)" class="checkbox cumple check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index].valorNoCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 3)" class="checkbox noCumple check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListInspeccion[index].valorNoAplica" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoCumple === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 4)" class="checkbox noAplica check-inspeccion">
            </td>
          </tr>
          </tbody>
        </table>
      </template>
      <!--   FOTOS   -->
      <hr>
      <div class="row mb-3 text-center">
        <h4><u>Fotos</u></h4>
        <div class="col-sm-4" v-for="(foto,index) in fotoList" :key="index">
          <div style="height: 60px;">
            <strong>
              {{foto.prefijo}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="foto.tieneFotoSubida" type="button" class="btn btn-danger btn-sm mt-2" @click="modalEliminarFoto(foto.archivoId)">
            <i class="fa fa-trash"></i>
          </button>
          <button v-else type="button" class="btn btn-primary btn-sm mt-2" @click="modalSubirFoto('modal-subir-foto', index)">
            <i class="fa fa-camera"></i>
          </button>
        </div>
      </div>
    <!-- Item Conclusión-->
      <div class="row m-3" v-if="itemConclusion">
        <div class="col-sm-6">
          <h4 class="m-3"><u>Conclusión Prueba</u></h4>
          <div class="form-check form-switch">
            <label class="form-label" for="itemConclusion"><strong>Prueba Aprobada</strong></label>
            <input class="form-check-input" id="itemConclusion" type="checkbox" v-model="itemConclusion.valorEnabled">
          </div>
          <label class="form-label" for="observacionesConclusion">Observaciones de Prueba</label>
          <textarea id="observacionesConclusion" class="form-control" rows="4" v-model="itemConclusion.observaciones"></textarea>
        </div>
      </div>

      <button type="button" class="btn btn-secondary m-3" v-on:click="volver()"><i class="fa fa-caret-left"></i> Volver</button>
      <button type="button" class="btn btn-primary m-3" v-on:click="guardarYContinuar()">Guardar y continuar <i class="fa fa-caret-right"></i><i class="fa fa-caret-right"></i></button>
      <!--      MODAL   -->
      <modal name="modal-subir-foto"  @before-close="antesDeCerrarModal" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">Subir Foto</h2>
          <div class="form-group">
            <input type="file" accept="image/*" @change="handleFileUpload()" />
            <img v-if="imagenVistaPrevia" :src="imagenVistaPrevia" alt="Vista previa de la imagen" style="max-width: 200px; height: auto; margin-top: 20px;">
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" @click="toggleModal('modal-subir-foto')">Cancelar</button>
            <button class="btn btn-primary" @click="subirFoto">Subir Foto</button>
          </div>
        </div>
      </modal>
      <modal name="modal-borrar-foto" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">¿Eliminar Foto?</h2>
          <div class="form-group">
            <h4>Esta acción es irreversible.</h4>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" style="margin-right: 7px" @click="toggleModal('modal-borrar-foto')">Cancelar</button>
            <button class="btn btn-danger" @click="borrarFoto">Borrar</button>
          </div>
        </div>
      </modal>
    </panel>
  </div>
</template>
<script>
import imageCompression from "browser-image-compression";
import axios from "axios";

export default {
  name: "FormularioLineasPilarPletina",
  data() {
    return {
      itemList: [],
      fotoList: [],
      itemListLineaInp: [],
      itemListLineaBool: [],
      itemListPilarInp: [],
      itemListPilarBool: [],
      itemListPletinaInp: [],
      itemListInspeccion: [],
      itemConclusion: {},
      indiceFotoListSubir: 0,
      nombreFotoSubida: null,
      itemCategoriaIdActual: null,
      nombreItemActual: '',
      imagenVistaPrevia: null,
      fotoIdAEliminar: null,
      fotoEquipo: '',
    }
  },
  methods: {
    storeCurrentValues() {
      this.currentValues = this.itemList.reduce((acc, item) => {
        acc[item.itemId] = item.valor;
        return acc;
      }, {});

      this.currentLineaInpValues = this.itemListLineaInp.map(item => item.valor);
      this.currentLineaBoolValues = this.itemListLineaBool.reduce((acc, item) => {
        acc[item.itemId] = {
          valorSi: item.valorSi,
          valorNo: item.valorNo,
          valorNoAplica: item.valorNoAplica
        };
        return acc;
      }, {});

      this.currentPilarInpValues = this.itemListPilarInp.map(item => item.valor);
      this.currentPilarBoolValues = this.itemListPilarBool.reduce((acc, item) => {
        acc[item.itemId] = {
          valorSi: item.valorSi,
          valorNo: item.valorNo,
          valorNoAplica: item.valorNoAplica
        };
        return acc;
      }, {});

      this.currentPletinaInpValues = this.itemListPletinaInp.map(item => item.valor);

      this.currentInspeccionValues = this.itemListInspeccion.reduce((acc, item) => {
        acc[item.itemId] = {
          valorCumple: item.valorCumple,
          valorNoCumple: item.valorNoCumple,
          valorNoAplica: item.valorNoAplica,
          observaciones: item.observaciones
        };
        return acc;
      }, {});

      if (this.itemDescripcion) {
        this.currentDescriptionValue = this.itemDescripcion.valor;
      }
      if (this.itemConclusion) {
        this.currentConclusionValues = {
          valorEnabled: this.itemConclusion.valorEnabled,
          observaciones: this.itemConclusion.observaciones
        };
      }
    },
    restoreCurrentValues() {
      if (this.currentValues) {
        this.itemList.forEach(item => {
          if (this.currentValues[item.itemId] !== undefined) {
            item.valor = this.currentValues[item.itemId];
          }
        });
      }
      if (this.currentLineaInpValues) {
        this.itemListLineaInp.forEach((item, index) => {
          if (this.currentLineaInpValues[index] !== undefined) {
            item.valor = this.currentLineaInpValues[index];
          }
        });
      }
      if (this.currentLineaBoolValues) {
        this.itemListLineaBool.forEach(item => {
          if (this.currentLineaBoolValues[item.itemId]) {
            item.valorSi = this.currentLineaBoolValues[item.itemId].valorSi;
            item.valorNo = this.currentLineaBoolValues[item.itemId].valorNo;
            item.valorNoAplica = this.currentLineaBoolValues[item.itemId].valorNoAplica;
          }
        });
      }
      if (this.currentPilarInpValues) {
        this.itemListPilarInp.forEach((item, index) => {
          if (this.currentPilarInpValues[index] !== undefined) {
            item.valor = this.currentPilarInpValues[index];
          }
        });
      }
      if (this.currentPilarBoolValues) {
        this.itemListPilarBool.forEach(item => {
          if (this.currentPilarBoolValues[item.itemId]) {
            item.valorSi = this.currentPilarBoolValues[item.itemId].valorSi;
            item.valorNo = this.currentPilarBoolValues[item.itemId].valorNo;
            item.valorNoAplica = this.currentPilarBoolValues[item.itemId].valorNoAplica;
          }
        });
      }
      if (this.currentPletinaInpValues) {
        this.itemListPletinaInp.forEach((item, index) => {
          if (this.currentPletinaInpValues[index] !== undefined) {
            item.valor = this.currentPletinaInpValues[index];
          }
        });
      }
      if (this.currentInspeccionValues) {
        this.itemListInspeccion.forEach(item => {
          if (this.currentInspeccionValues[item.itemId]) {
            item.valorCumple = this.currentInspeccionValues[item.itemId].valorCumple;
            item.valorNoCumple = this.currentInspeccionValues[item.itemId].valorNoCumple;
            item.valorNoAplica = this.currentInspeccionValues[item.itemId].valorNoAplica;
            item.observaciones = this.currentInspeccionValues[item.itemId].observaciones;
          }
        });
      }
      if (this.itemDescripcion && this.currentDescriptionValue !== undefined) {
        this.itemDescripcion.valor = this.currentDescriptionValue;
      }
      if (this.itemConclusion && this.currentConclusionValues) {
        this.itemConclusion.valorEnabled = this.currentConclusionValues.valorEnabled;
        this.itemConclusion.observaciones = this.currentConclusionValues.observaciones;
      }
    },

    async volver(){
      this.$irVistaInspeccionProgramada(this.inspeccionId, 'lineaPilarPletina', true)
    },

    async guardarYContinuar(){
      //Lineas Input
      let itemListEnvio = this.itemListLineaInp.map(item => ({
        itemId:        item?.itemId,
        valor:         item?.valor,
        valorEnabled:  item?.valorEnabled,
        noAplica:      item?.valorNoAplica,
        observaciones: item?.observaciones
      }))
      //Lineas Bool
      for(let i = 0; i < this.itemListLineaBool.length; i++){
        let item = this.itemListLineaBool[i]
        let valorBooleano = (item.valorSi === true)
        if(item.valorSi === false && item.valorNo === false) item.valorNoAplica = true
        if(item.valorNoAplica === true) valorBooleano = null
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: valorBooleano,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Pilar Input
      for(let i = 0; i < this.itemListPilarInp.length; i++){
        let item = this.itemListPilarInp[i]
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: item?.valorEnabled,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Pilar Bool
      for(let i = 0; i < this.itemListPilarBool.length; i++){
        let item = this.itemListPilarBool[i]
        let valorBooleano = (item.valorSi === true)
        if(item.valorSi === false && item.valorNo === false) item.valorNoAplica = true
        if(item.valorNoAplica === true) valorBooleano = null
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: valorBooleano,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Pletina Input
      for(let i = 0; i < this.itemListPletinaInp.length; i++){
        let item = this.itemListPletinaInp[i]
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: item?.valorEnabled,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Checks inspección visual
      for(let i = 0; i < this.itemListInspeccion.length; i++){
        let item = this.itemListInspeccion[i]
        let ponderacionId = null
        if      (item.valorCumple)   ponderacionId = 1
        else if (item.valorMejora)   ponderacionId = 2
        else if (item.valorNoCumple) ponderacionId = 3
        let noAplica = item.valorNoAplica
        if(ponderacionId == null) noAplica = true
        let itemProcesado = {
          itemId:        item.itemId,
          valor:         null,
          valorBooleano: null,
          noAplica:      noAplica,
          observaciones: item.observaciones,
          ponderacionId: ponderacionId
        }
        itemListEnvio.push(itemProcesado)
      }
      //Item Conclusión
      itemListEnvio.push(
          {
            itemId:        this?.itemConclusion?.itemId,
            valor:         this?.itemConclusion?.valor,
            valorBooleano: this?.itemConclusion?.valorEnabled,
            noAplica:      this?.itemConclusion?.valorNoAplica,
            observaciones: this?.itemConclusion?.observaciones
          }
      )
      let exito = await this.$guardarItemsInspeccionProgramada(itemListEnvio,this.inspeccionId, 'lineaPilarPletina')
      if(exito){
        this.$irVistaInspeccionProgramada(this.inspeccionId, 'lineaPilarPletina', false)
      }
    },

    async itemListLineas(inspeccionId) {
      this.storeCurrentValues();
      let data = await this.$itemListMasInfoFormularioInspProgramada(inspeccionId, 'lineaPilarPletina')
      if(data){
        this.itemList = data.itemList.map((item) => {
          let valorCumple   = false
          let valorMejora   = false
          let valorNoCumple = false
          let valorNoAplica = false
          if(item.valorNoAplica === true) valorNoAplica = true
          if(item.ponderacionId){
            if     (item.ponderacionId === 1) valorCumple   = true
            else if(item.ponderacionId === 2) valorMejora   = true
            else if(item.ponderacionId === 3) valorNoCumple = true
          }

          return {
            valor:                item.valor,
            guardado:             item.guardado,
            valorEnabled:         item.valorBooleano,
            observaciones:        item.observaciones,
            itemId:               item.itemId,
            index:                item.index,
            nombre:               item.texto,
            codigo:               item.codigo,
            enabled:              item.enabled,
            noAplica:             item.noAplica,
            conclusion:           item.conclusion,
            textArea:             item.textArea,
            numero:               item.index,
            valorCumple:          valorCumple  ,
            valorMejora:          valorMejora  ,
            valorNoAplica:        valorNoAplica,
            valorNoCumple:        valorNoCumple,
            color:                'unset',
            mostrarObservaciones: (item.observaciones),
            valorSi:              (item.valorBooleano === true),
            valorNo:              (item.valorBooleano === false),
          };
        });
        this.fotoList   = data.fotoList
        this.asignarData()
        this.restoreCurrentValues();
      }
    },
    modalEliminarFoto(fotoId) {
      this.fotoIdAEliminar = fotoId;
      this.toggleModal('modal-borrar-foto');
    },

    async borrarFoto() {
      this.storeCurrentValues();
      this.$loadingSpiner(); // Muestra el spinner de carga

      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/borrarFoto/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId, // Asegúrate de que inspeccionId está definido en tu componente
        imagen: {
          id: this.fotoIdAEliminar // Usa directamente el ID de la foto a eliminar
        },
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          // Encuentra el índice usando el ID para eliminarlo de la lista
          const index = this.fotoList.findIndex(f => f.id === this.fotoIdAEliminar);
          if (index !== -1) this.fotoList.splice(index, 1);
          this.itemListLineas(this.inspeccionId)
          this.toggleModal('modal-borrar-foto'); // Cierra el modal
          this.$showToastSuccess('Foto Eliminada Exitósamente!', 'Genial!', this.notificationSystem.options.success);
        } else {
          this.$showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la foto.', 'Error', this.notificationSystem.options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide(); // Oculta el spinner de carga
        }
      }
    },

    asignarData() {
      this.itemListLineaInp   = this.itemList.filter((item) => item?.codigo.includes('linea_inp'))
      this.itemListLineaBool  = this.itemList.filter((item) => item?.codigo.includes('linea_bool'))
      this.itemListPilarInp   = this.itemList.filter((item) => item?.codigo.includes('pilar_inp'))
      this.itemListPilarBool  = this.itemList.filter((item) => item?.codigo.includes('pilar_bool'))
      this.itemListPletinaInp = this.itemList.filter((item) => item?.codigo.includes('pletina_inp'))
      this.itemListInspeccion = this.itemList.filter((item) => item?.codigo.includes('inspeccion_visual'))
      this.itemConclusion     = this.itemList.find((item) => item?.conclusion === true)
    },

    antesDeCerrarModal() {
      this.imagenVistaPrevia = null;
    },

    async handleFileUpload() {
      let indiceFoto = this.indiceFotoListSubir
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.fotoList[indiceFoto].nombreFotoSubida = file.name

        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 300, // Compress the image if it's larger than this
          useWebWorker: true
        };
        // this.nombreFotoSubida = file.name
        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagenVistaPrevia = e.target.result; // URL para vista previa
            this.$set(this.fotoList[indiceFoto], 'fotoSubidaBase64', e.target.result); // Asignación reactiva
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error during image compression:', error);
        }
      }
    },

    async subirFoto(){
      this.storeCurrentValues();
      let foto = this.fotoList[this.indiceFotoListSubir]
      const datosFoto = {
        inspeccionId:     this.inspeccionId,
        fotoSubidaBase64: foto.fotoSubidaBase64,
        nombreFotoSubida: foto.nombreFotoSubida,
        categoria:        foto.categoria,
      };
      this.toggleModal('modal-subir-foto')
      let urlImagen = await this.$subirFotoInspeccion(datosFoto)
      if(urlImagen){
        this.$set(this.fotoList[this.indiceFotoListSubir],'tieneFotoSubida', true)
        this.itemListLineas(this.inspeccionId);
      }
    },

    modalSubirFoto(modalId, indiceFotoList){
      this.indiceFotoListSubir = indiceFotoList
      this.toggleModal(modalId)
    },

    toggleModal(modalId){
      this.$modal.toggle(modalId)
    },

    marcarColumna(claseCheck){
      let checks = document.querySelectorAll('.'+claseCheck)
      let event = new Event('change');
      if(checks){
        checks.forEach(check => {
          check.checked = !check.checked
          check.dispatchEvent(event);
        })
      }
    },

    handleCheckboxChange(event,item,tipo) { //cumple = 1, mejora = 2, noCumple = 3, noAplica = 4
      let estadoCheck = event.srcElement.checked
      const index = this.itemListInspeccion.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        let valorCumple   = (tipo === 1 && estadoCheck)
        let valorMejora   = (tipo === 2 && estadoCheck)
        let valorNoCumple = (tipo === 3 && estadoCheck)
        let valorNoAplica = (tipo === 4 && estadoCheck)
        let color
        if(estadoCheck === true){
          if      (tipo === 1) color = '#ccffcc'
          else if (tipo === 2) color = '#fff6b3'
          else if (tipo === 3) color = '#ffbbbb'
          else if (tipo === 4) color = '#d2d2d2'
        }else color = 'unset'
        this.$set(this.itemListInspeccion, index, {
          ...this.itemListInspeccion[index],
          valorCumple:   valorCumple,
          valorMejora:   valorMejora,
          valorNoCumple: valorNoCumple,
          valorNoAplica: valorNoAplica,
          color: color
        });
      }
    },

    handleCheckboxSiNo(event,item,tipo) { //1 = si, 2 = no, 3 = noAplica
      let estadoCheck = event.srcElement.checked
      const index = this.itemListInspeccion.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        let valorSi       = (tipo === 1 && estadoCheck)
        let valorNo       = (tipo === 2 && estadoCheck)
        let valorNoAplica = (tipo === 3 && estadoCheck)
        this.$set(this.itemListInspeccion, index, {
          ...this.itemListInspeccion[index],
          valorSi:   valorSi,
          valorNo:   valorNo,
          valorNoAplica: valorNoAplica
        });
      }
    },

    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },

    guardarObservaciones(item) {
      const index = this.itemListInspeccion.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        this.$set(this.itemListInspeccion[index], 'observaciones', item.observaciones);
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.inspeccionId;
    if (this.inspeccionId) {
      this.itemListLineas(this.inspeccionId);
    }
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>
.check-inspeccion {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}

.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
.modal-container{
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
@media (max-width: 768px) {
  .modal-container {
    position: fixed;
    top: 150px;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, 0%);
  }
  .table.d-block.d-sm-none {
    font-size: 10px; /* Reducción del tamaño de fuente */
    width: 100%;
  }

  .table.d-block.d-sm-none th, .table.d-block.d-sm-none td {
    padding: 4px; /* Reducción del padding para ahorrar espacio */
    white-space: normal; /* Permite que el texto de las celdas se ajuste según el ancho disponible */
    word-break: break-word; /* Asegura que las palabras no superen el ancho de la celda */
  }

  .table.d-block.d-sm-none .btn {
    padding: 3px 6px; /* Reduce el tamaño del padding en los botones */
    font-size: 8px; /* Mantiene el tamaño del texto en los botones pequeño */
  }

  .table.d-block.d-sm-none .checkbox {
    transform: scale(1); /* No amplía los checkboxes */
    margin: 1px auto; /* Minimiza el espacio alrededor de los checkboxes */
  }
  .table.d-block.d-sm-none th {
    font-size: 8px; /* Disminuye aún más el tamaño de la fuente para los títulos */
    padding: 2px 4px; /* Reduce el espacio alrededor del texto para ahorrar espacio */
    white-space: nowrap; /* Evita que los títulos se ajusten a líneas múltiples */
  }
}
</style>
