<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Ensayo no destructivo</li>
    </ol>
    <h1 class="page-header">Ensayo no destructivo - Ultrasonido </h1>
    <panel title="">
      <div class="row m-3">
        <h4 class="m-3"><u>Especificaciones Ensayo No Destructivo</u></h4>
        <template>
          <table class="table table-striped">
            <tr v-for="item in itemListInput" :key="item.itemId">
              <td class="col-sm-3">
                <strong>
                  {{item.nombre}}
                </strong>
              </td>
              <td class="col-sm-9">
                <input type="text" class="form-control" v-model="item.valor">
              </td>
            </tr>
            <tr v-if="itemDescripcion">
              <td class="col-sm-3">
                <strong>
                  {{itemDescripcion.nombre}}
                </strong>
              </td>
              <td class="col-sm-9">
                <textarea class="form-control" rows="5" v-model="itemDescripcion.valor"></textarea>
              </td>
            </tr>
          </table>
        </template>
      </div>
      <hr>
<!--   MEDICIONES   -->
      <template>
        <div class="row text-center">
          <h4><u>Control de Mediciones</u></h4>
        </div>
        <div class="row text-center">
          <img :src="fotoEquipo" alt="Diagrama Equipo">
        </div>
        <table class="table table-striped text-center">
          <thead>
          <tr>
            <th class="d-none d-sm-table-cell">ITEM</th>
            <th>PUNTO DE MEDICION</th>
            <th>ESPESOR NOMINAL</th>
            <th>ESPESOR MÍNIMO ADMISIBLE</th>
            <th>ESPESOR MEDIDO</th>
          </tr>
          </thead>
          <tr v-for="(item, index) in itemListMedicion" :key="item.itemId">
            <td class="d-none d-sm-table-cell"> {{index + 1 }} </td>
            <td>
              <input type="text" class="form-control text-center" v-model="itemListPuntoMedicion[index].valor" disabled>
            </td>
            <td>
              <input type="text" class="form-control text-center" v-model="itemListEspesorNominal[index].valor" disabled>
            </td>
            <td>
              <input type="text" class="form-control text-center" v-model="itemListEspesorMinimo[index].valor" disabled>
            </td>
            <td>
              <input type="text" class="form-control text-center" v-model="item.valor">
            </td>
          </tr>
        </table>
      </template>
      <hr>
<!--   INSPECCIÓN VISUAL   -->
      <template>
        <div class="row text-center">
          <h4><u>Inspección Visual</u></h4>
        </div>
        <!--   Se muestra en tamaño mayor a xs-->
        <table class="table table-striped text-center d-none d-sm-block">
          <thead>
          <tr>
            <th class="d-none d-sm-table-cell">N°</th>
            <th class="p-1">ITEM/COMPONENTE INSPECCIONADO</th>
            <th class="px-0" style="width: 3%">
              Cumple<br>
              <button class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
            </th>
<!--            <th class="px-0" style="width: 3%">-->
<!--              Requiere Mejora<br>-->
<!--              <button class="btn btn-warning btn-sm" type="button" @click="marcarColumna('mejora')"><i class="fa fa-check"></i></button>-->
<!--            </th>-->
            <th class="px-0" style="width: 3%">
              No Cumple<br>
              <button class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
            </th>
            <th class="px-0" style="width: 3%">
              No Aplica<br>
              <button class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in itemListInspeccion" :key="item.itemId" >
            <td class="d-none d-sm-table-cell"> {{index + 1}} </td>
            <td class="p-1">
              <strong @click="toggleObservaciones(item)" :style="{backgroundColor: item.color, cursor:'pointer'}">
                {{item.nombre}}
              </strong>
              <div v-if="item.mostrarObservaciones">
                 <textarea v-if="item.mostrarObservaciones"
                           v-model="item.observaciones"
                           @blur="guardarObservaciones(item)"
                           class="observaciones-textarea"
                           placeholder="Escriba sus observaciones aquí...">
                </textarea>
              </div>
            </td>
            <td class="px-0">
              <input type="checkbox" v-model="item.valorCumple" v-if="item.valorNoCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,1)" class="checkbox cumple">
            </td>
<!--            <td class="px-0">-->
<!--              <input type="checkbox" v-model="item.valorMejora" v-if="item.valorNoCumple === false && item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,2)" class="checkbox mejora">-->
<!--            </td>-->
            <td class="px-0">
              <input type="checkbox" v-model="item.valorNoCumple" v-if="item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item, 3)" class="checkbox noCumple">
            </td>
            <td class="px-0">
              <input type="checkbox" v-model="item.valorNoAplica" v-if="item.valorCumple === false && item.valorNoCumple === false" @change="handleCheckboxChange($event,item, 4)" class="checkbox noAplica">
            </td>
          </tr>
          </tbody>
        </table>
        <!--   Solo se muestra en tamaño xs-->
        <table class="table table-striped d-block d-sm-none">
          <thead>
          <tr>
            <th class="d-none d-sm-table-cell">N°</th>
            <th class="p-1">ITEM/COMPONENTE INSPECCIONADO</th>
            <th class="px-0" style="width: 3%">
              Cumple<br>
              <button class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
            </th>
<!--            <th class="px-0" style="width: 3%">-->
<!--              Requiere Mejora<br>-->
<!--              <button class="btn btn-warning btn-sm" type="button" @click="marcarColumna('mejora')"><i class="fa fa-check"></i></button>-->
<!--            </th>-->
            <th class="px-0" style="width: 3%">
              No Cumple<br>
              <button class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
            </th>
            <th class="px-0" style="width: 3%">
              No Aplica<br>
              <button class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in itemListInspeccion" :key="item.itemId" >
            <td class="d-none d-sm-table-cell"> {{index + 1}} </td>
            <td class="p-1">
              <strong @click="toggleObservaciones(item)" :style="{backgroundColor: item.color, cursor:'pointer'}">
                {{item.nombre}}
              </strong>
              <div v-if="item.mostrarObservaciones">
                 <textarea v-if="item.mostrarObservaciones"
                           v-model="item.observaciones"
                           @blur="guardarObservaciones(item)"
                           class="observaciones-textarea"
                           placeholder="Escriba sus observaciones aquí...">
                </textarea>
              </div>
            </td>
            <td class="px-0">
              <input type="checkbox" v-model="item.valorCumple" v-if="item.valorNoCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,1)" class="checkbox cumple">
            </td>
<!--            <td class="px-0">-->
<!--              <input type="checkbox" v-model="item.valorMejora" v-if="item.valorNoCumple === false && item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,2)" class="checkbox mejora">-->
<!--            </td>-->
            <td class="px-0">
              <input type="checkbox" v-model="item.valorNoCumple" v-if="item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item, 3)" class="checkbox noCumple">
            </td>
            <td class="px-0">
              <input type="checkbox" v-model="item.valorNoAplica" v-if="item.valorCumple === false && item.valorNoCumple === false" @change="handleCheckboxChange($event,item, 4)" class="checkbox noAplica">
            </td>
          </tr>
          </tbody>
        </table>
      </template>
<!--   FOTOS   -->
      <hr>
      <div class="row mb-3 text-center">
        <h4><u>Fotos</u></h4>
        <div class="col-sm-4" v-for="(foto,index) in fotoList" :key="index">
          <div style="height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="foto.tieneFotoSubida" type="button" class="btn btn-danger btn-sm mt-2" @click="modalEliminarFoto(foto.archivoId)">
            <i class="fa fa-trash"></i>
          </button>
          <button v-else type="button" class="btn btn-primary btn-sm mt-2" @click="modalSubirFoto('modal-subir-foto', index)">
            <i class="fa fa-camera"></i>
          </button>
        </div>
      </div>

      <button type="button" class="btn btn-secondary m-3" v-on:click="volver()"><i class="fa fa-caret-left"></i> Volver</button>
      <button type="button" class="btn btn-primary m-3" v-on:click="guardarYContinuar()">Guardar y continuar <i class="fa fa-caret-right"></i><i class="fa fa-caret-right"></i></button>
<!--      MODAL   -->
      <modal name="modal-subir-foto"  @before-close="antesDeCerrarModal" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">Subir Foto</h2>
          <div class="form-group">
            <input type="file" accept="image/*" @change="handleFileUpload()" />
            <img v-if="imagenVistaPrevia" :src="imagenVistaPrevia" alt="Vista previa de la imagen" style="max-width: 200px; height: auto; margin-top: 20px;">
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" @click="toggleModal('modal-subir-foto')">Cancelar</button>
            <button class="btn btn-primary" @click="subirFoto">Subir Foto</button>
          </div>
        </div>
      </modal>
      <modal name="modal-borrar-foto" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">¿Eliminar Foto?</h2>
          <div class="form-group">
            <h4>Esta acción es irreversible.</h4>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" style="margin-right: 7px" @click="toggleModal('modal-borrar-foto')">Cancelar</button>
            <button class="btn btn-danger" @click="borrarFoto">Borrar</button>
          </div>
        </div>
      </modal>
    </panel>
  </div>
</template>
<script>
import imageCompression from "browser-image-compression";
import axios from "axios";

export default {
  name: "FormularioEnsayoNoDestructivo",
  data() {
    return {
      itemList: [],
      fotoList: [],
      itemListInput:   [],
      itemListPuntoMedicion: [],
      itemListEspesorNominal: [],
      itemListEspesorMinimo: [],
      itemListMedicion:   [],
      itemListInspeccion: [],
      itemDescripcion: {},
      ensayoNoDestructivoEspesorPorFormato: {
        "cal_2_ejes":[
            {"punto":"Calota Delantera","nominal": "3,35", "minimo":"2,84"},
            {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
            {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
            {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
            {"punto":"Calota Trasera",  "nominal": "3,35", "minimo":"2,84"},
            {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
            {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
            {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
        ],
        "cal_3_ejes":[
          {"punto":"Calota Delantera","nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Calota Trasera",  "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
        ],
        "emulsion_matriz":[
          {"punto":"Calota Delantera","nominal": "4,75",        "minimo":"4,03"},
          {"punto":"Derecho",         "nominal": "3,75 - 4,75", "minimo":"3,18"},
          {"punto":"Derecho",         "nominal": "3,75 - 4,75", "minimo":"3,18"},
          {"punto":"Derecho",         "nominal": "3,75 - 4,75", "minimo":"3,18"},
          {"punto":"Calota Trasera",  "nominal": "4,75",        "minimo":"4,03"},
          {"punto":"Izquierdo",       "nominal": "3,75 - 4,75", "minimo":"3,18"},
          {"punto":"Izquierdo",       "nominal": "3,75 - 4,75", "minimo":"3,18"},
          {"punto":"Izquierdo",       "nominal": "3,75 - 4,75", "minimo":"3,18"},
        ],
        "nitrato_amonio":[
          {"punto":"Calota Delantera","nominal": "3,35", "minimo":"2,84"},
          {"punto":"Derecho",         "nominal": "3,35", "minimo":"2,84"},
          {"punto":"Derecho",         "nominal": "3,35", "minimo":"2,84"},
          {"punto":"Derecho",         "nominal": "3,35", "minimo":"2,84"},
          {"punto":"Calota Trasera",  "nominal": "3,35", "minimo":"2,84"},
          {"punto":"Izquierdo",       "nominal": "3,35", "minimo":"2,84"},
          {"punto":"Izquierdo",       "nominal": "3,35", "minimo":"2,84"},
          {"punto":"Izquierdo",       "nominal": "3,35", "minimo":"2,84"},
        ],
        "nitrato_amonio_2_ejes":[
          {"punto":"Calota Delantera","nominal": "3,5",  "minimo":"2,97"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Calota Trasera",  "nominal": "3,5",  "minimo":"2,97"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
        ],
        "nitrato_amonio_inoxidable":[
          {"punto":"Calota Delantera","nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Calota Trasera",  "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
        ],
        "solucion_ms_templo":[
          {"punto":"Calota Delantera","nominal": "4,00", "minimo":"3,4"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Calota Trasera",  "nominal": "4,00", "minimo":"3,4"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
        ],
        "solucion_randon":[
          {"punto":"Calota Delantera","nominal": "4,00", "minimo":"3,4"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Derecho",         "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Calota Trasera",  "nominal": "4,00", "minimo":"3,4"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
          {"punto":"Izquierdo",       "nominal": "3,00", "minimo":"2,55"},
        ],
      },
      espesorUsar: [],
      especificacionesEnsayoNoDestructivoPorFormato: {
        "cal_2_ejes":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "cal_3_ejes":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "emulsion_matriz":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "nitrato_amonio":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "nitrato_amonio_2_ejes":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "nitrato_amonio_inoxidable":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "solucion_ms_templo":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
        "solucion_randon":[
          {"texto": "Espesores de mantos laterales y calotas."},
          {"texto": "Acero al carbono"},
          {"texto": "UT 342"},
          {"texto": "1PCS 5MHz"},
          {"texto": "1,2 mm a 225 mm"},
          {"texto": "5,00 mm"},
          {"texto": "Coupling agent-1PCS(50ml)"},
        ],
      },
      especificacionDescripcionPorFormato: {
        "cal_2_ejes":               'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (Izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán varios puntos de medición (aprox. 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente, no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "cal_3_ejes":               'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (Izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán varios puntos de medición (aprox. 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente, no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "emulsion_matriz":          'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (Izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán varios puntos de medición (aprox. 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "nitrato_amonio":           'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán varios puntos de medición (aprox. 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "nitrato_amonio_2_ejes":    'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (Izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán varios puntos de medición (aprox. 4), y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "nitrato_amonio_inoxidable":'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán, varios puntos de medición (aprox 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "solucion_ms_templo":       'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán, varios puntos de medición (aprox 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
        "solucion_randon":          'En esta prueba se realizará una separación imaginaria al silo de 8 partes, la que contempla 3 sectores por lados (izquierdo y derecho) y calota delantera y trasera. De cada sector se tomarán, varios puntos de medición (aprox 4) y se sacará un promedio. Las mediciones siempre se tomarán en la mitad superior del equipo y en sectores que presenten desgaste y/o corrosión visual. \n' +
            'A solicitud del cliente no se lijará la pintura al momento de la prueba, este paso solo se realizará si se detecta corrosión en algún sector del equipo.',
      },
      indiceFotoListSubir: 0,
      nombreFotoSubida: null,
      itemCategoriaIdActual: null,
      fotoIdAEliminar: null,
      nombreItemActual: '',
      imagenVistaPrevia: null,
      fotoEquipo: '',
    }
  },
  methods: {
    storeCurrentValues() {
      this.currentValues = this.itemList.reduce((acc, item) => {
        acc[item.itemId] = item.valor;
        return acc;
      }, {});
      this.currentMedicionValues = this.itemListMedicion.map(item => item.valor);
      this.currentInspeccionValues = this.itemListInspeccion.reduce((acc, item) => {
        acc[item.itemId] = {
          valorCumple: item.valorCumple,
          // valorMejora: item.valorMejora,
          valorNoCumple: item.valorNoCumple,
          valorNoAplica: item.valorNoAplica,
          observaciones: item.observaciones
        };
        return acc;
      }, {});
      if (this.itemDescripcion) {
        this.currentDescriptionValue = this.itemDescripcion.valor;
      }
      if (this.itemConclusion) {
        this.currentConclusionValues = {
          valorEnabled: this.itemConclusion.valorEnabled,
          observaciones: this.itemConclusion.observaciones
        };
      }
    },
    restoreCurrentValues() {
      if (this.currentValues) {
        this.itemList.forEach(item => {
          if (this.currentValues[item.itemId] !== undefined) {
            item.valor = this.currentValues[item.itemId];
          }
        });
      }
      if (this.currentMedicionValues) {
        this.itemListMedicion.forEach((item, index) => {
          if (this.currentMedicionValues[index] !== undefined) {
            item.valor = this.currentMedicionValues[index];
          }
        });
      }
      if (this.currentInspeccionValues) {
        this.itemListInspeccion.forEach(item => {
          if (this.currentInspeccionValues[item.itemId]) {
            item.valorCumple = this.currentInspeccionValues[item.itemId].valorCumple;
            // item.valorMejora = this.currentInspeccionValues[item.itemId].valorMejora;
            item.valorNoCumple = this.currentInspeccionValues[item.itemId].valorNoCumple;
            item.valorNoAplica = this.currentInspeccionValues[item.itemId].valorNoAplica;
            item.observaciones = this.currentInspeccionValues[item.itemId].observaciones;
          }
        });
      }
      if (this.itemDescripcion && this.currentDescriptionValue !== undefined) {
        this.itemDescripcion.valor = this.currentDescriptionValue;
      }
      if (this.itemConclusion && this.currentConclusionValues) {
        this.itemConclusion.valorEnabled = this.currentConclusionValues.valorEnabled;
        this.itemConclusion.observaciones = this.currentConclusionValues.observaciones;
      }
    },
    async volver(){
      this.$irVistaInspeccionProgramada(this.inspeccionId, 'ensayoNoDestructivo', true)
    },

    async guardarYContinuar(){
      //Especificaciones
      let itemListEnvio = this.itemListInput.map(item => ({
        itemId:        item?.itemId,
        valor:         item?.valor,
        valorEnabled:  item?.valorEnabled,
        noAplica:      item?.valorNoAplica,
        observaciones: item?.observaciones
      }))
      //Item Descripción
      itemListEnvio.push(
          {
            itemId:        this?.itemDescripcion?.itemId,
            valor:         this?.itemDescripcion?.valor,
            valorBooleano: this?.itemDescripcion?.valorEnabled,
            noAplica:      this?.itemDescripcion?.valorNoAplica,
            observaciones: this?.itemDescripcion?.observaciones
          }
      )
      //Punto Medición
      for(let i = 0; i < this.itemListPuntoMedicion.length; i++){
        let item = this.itemListPuntoMedicion[i]
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: item?.valorEnabled,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Espesor Nominal
      for(let i = 0; i < this.itemListEspesorNominal.length; i++){
        let item = this.itemListEspesorNominal[i]
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: item?.valorEnabled,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Espesor Mínimo
      for(let i = 0; i < this.itemListEspesorMinimo.length; i++){
        let item = this.itemListEspesorMinimo[i]
        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: item?.valorEnabled,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones
            }
        )
      }
      //Mediciones
      for(let i = 0; i < this.itemListMedicion.length; i++){
        let item = this.itemListMedicion[i]
        let ponderacionId = null
        if(item.valor){
          let valorMedido = this.$parseFloatFormatoEspanolIngles(item.valor)
          let valorMinimo = this.$parseFloatFormatoEspanolIngles(this.itemListEspesorMinimo[i].valor)
          if(!isNaN(valorMedido) && !isNaN(valorMinimo)){
            if(valorMedido < valorMinimo) ponderacionId = 3
          }
        }

        itemListEnvio.push(
            {
              itemId:        item?.itemId,
              valor:         item?.valor,
              valorBooleano: null,
              noAplica:      item?.valorNoAplica,
              observaciones: item?.observaciones,
              ponderacionId: ponderacionId
            }
        )
      }
      //Checks inspección visual
      for(let i = 0; i < this.itemListInspeccion.length; i++){
        let item = this.itemListInspeccion[i]
        let valorBooleano = (item.valorCumple === true)
        if(item.valorCumple === false && item.valorNoCumple === false) item.valorNoAplica = true
        if(item.valorNoAplica === true) valorBooleano = null
        let itemProcesado = {
          itemId:        item.itemId,
          valor:         null,
          valorBooleano: valorBooleano,
          noAplica:      item.valorNoAplica,
          observaciones: item.observaciones
        }
        itemListEnvio.push(itemProcesado)
      }
      let exito = await this.$guardarItemsInspeccionProgramada(itemListEnvio,this.inspeccionId, 'ensayoNoDestructivo')
      if(exito){
        this.$irVistaInspeccionProgramada(this.inspeccionId, 'ensayoNoDestructivo', false)
      }
    },

    async itemListEnsayoNoDestructivo(inspeccionId) {
      this.storeCurrentValues();
      let data = await this.$itemListMasInfoFormularioInspProgramada(inspeccionId, 'ensayoNoDestructivo')
      if(data){
        this.itemList = data.itemList.map((item) => {
          let valorCumple   = false
          let valorNoCumple = false
          let valorNoAplica = false
          // let valorMejora   = false
          if(item.valorNoAplica === true) valorNoAplica = true
          if(item.valorBooleano !== null){
            if(item.valorBooleano === true) valorCumple   = true
            else                            valorNoCumple = true
          }
          // if(item.ponderacionId){
            // if     (item.ponderacionId === 1) valorCumple   = true
            // else if(item.ponderacionId === 2) valorMejora   = true
            // else if(item.ponderacionId === 3) valorNoCumple = true
          // }

          return {
            valor:         item.valor,
            guardado:      item.guardado,
            valorEnabled:  item.valorBooleano,
            observaciones: item.observaciones,
            itemId:     item.itemId,
            index:      item.index,
            nombre:     item.texto,
            codigo:     item.codigo,
            enabled:    item.enabled,
            noAplica:   item.noAplica,
            conclusion: item.conclusion,
            textArea:   item.textArea,
            numero: item.index,
            valorCumple:   valorCumple  ,
            valorNoCumple: valorNoCumple,
            // valorMejora:   valorMejora  ,
            valorNoAplica: valorNoAplica,
            color: 'unset',
            mostrarObservaciones: (item.observaciones),
          };
        });
        this.espesorUsar = this.ensayoNoDestructivoEspesorPorFormato[data.formularioTipoCodigo]
        this.especificacionUsar = this.especificacionesEnsayoNoDestructivoPorFormato[data.formularioTipoCodigo]
        this.especificacionDescripcionUsar = this.especificacionDescripcionPorFormato[data.formularioTipoCodigo]
        this.fotoList   = data.fotoList
        this.fotoEquipo = data.urlImagen
        this.asignarData()
        this.restoreCurrentValues();
      }
    },

    asignarData() {
      this.itemListMedicion   = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_medicion'))
      this.itemListInspeccion = this.itemList.filter((item) => item?.codigo.includes('inspeccion'))
      this.itemListInput = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_espec_'))
      this.itemListPuntoMedicion  = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_med_punto'))
      this.itemListEspesorNominal = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_med_nominal'))
      this.itemListEspesorMinimo  = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_med_minimo'))
      this.itemDescripcion  = this.itemList.find((item) => item?.textArea === true)
      if(!this.itemDescripcion.guardado) this.$set(this.itemDescripcion,'valor', this.especificacionDescripcionUsar)
      // this.itemConclusion   = this.itemList.find((item) => item?.conclusion === true)
      //
      for(let i= 0; i < this.itemListInput.length; i++){
        if(this.especificacionUsar[i]){
          if(!this.itemListInput[i].guardado) this.$set(this.itemListInput[i],'valor', this.especificacionUsar[i].texto)
        }
      }

      for(let i= 0; i < this.itemListPuntoMedicion.length; i++){
        if(this.espesorUsar[i]){
          if(!this.itemListPuntoMedicion[i].guardado) this.$set(this.itemListPuntoMedicion[i],'valor', this.espesorUsar[i].punto)
        }
      }

      for(let i= 0; i < this.itemListEspesorNominal.length; i++){
        if(this.espesorUsar[i]){
          if(!this.itemListEspesorNominal[i].guardado) this.$set(this.itemListEspesorNominal[i],'valor', this.espesorUsar[i].nominal)
        }
      }

      for(let i= 0; i < this.itemListEspesorMinimo.length; i++){
        if(this.espesorUsar[i]){
          if(!this.itemListEspesorMinimo[i].guardado) this.$set(this.itemListEspesorMinimo[i],'valor', this.espesorUsar[i].minimo)
        }
      }
    },
    modalEliminarFoto(fotoId) {
      this.fotoIdAEliminar = fotoId;
      this.toggleModal('modal-borrar-foto');
    },

    async borrarFoto() {
      this.storeCurrentValues();
      this.$loadingSpiner(); // Muestra el spinner de carga

      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/borrarFoto/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId, // Asegúrate de que inspeccionId está definido en tu componente
        imagen: {
          id: this.fotoIdAEliminar // Usa directamente el ID de la foto a eliminar
        },
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          // Encuentra el índice usando el ID para eliminarlo de la lista
          const index = this.fotoList.findIndex(f => f.id === this.fotoIdAEliminar);
          if (index !== -1) this.fotoList.splice(index, 1);
          this.itemListEnsayoNoDestructivo(this.inspeccionId);
          this.toggleModal('modal-borrar-foto'); // Cierra el modal
          this.$showToastSuccess('Foto Eliminada Exitósamente!', 'Genial!', this.notificationSystem.options.success);
        } else {
          this.$showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la foto.', 'Error', this.notificationSystem.options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide(); // Oculta el spinner de carga
        }
      }
    },


    antesDeCerrarModal() {
      this.imagenVistaPrevia = null;
    },

   async handleFileUpload() {
      let indiceFoto = this.indiceFotoListSubir
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.fotoList[indiceFoto].nombreFotoSubida = file.name

        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 300, // Compress the image if it's larger than this
          useWebWorker: true
        };
        // this.nombreFotoSubida = file.name
        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagenVistaPrevia = e.target.result; // URL para vista previa
            this.$set(this.fotoList[indiceFoto], 'fotoSubidaBase64', e.target.result); // Asignación reactiva
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error during image compression:', error);
        }
      }
    },

    async subirFoto(){
      this.storeCurrentValues();
      let foto = this.fotoList[this.indiceFotoListSubir]
      const datosFoto = {
        inspeccionId:     this.inspeccionId,
        fotoSubidaBase64: foto.fotoSubidaBase64,
        nombreFotoSubida: foto.nombreFotoSubida,
        categoria:        foto.categoria,
      };
      this.toggleModal('modal-subir-foto')
      let urlImagen = await this.$subirFotoInspeccion(datosFoto)
      if(urlImagen){
        this.$set(this.fotoList[this.indiceFotoListSubir],'tieneFotoSubida', true)
        this.itemListEnsayoNoDestructivo(this.inspeccionId);
      }
    },

    modalSubirFoto(modalId, indiceFotoList){
      this.indiceFotoListSubir = indiceFotoList
      this.toggleModal(modalId)
    },

    toggleModal(modalId){
      this.$modal.toggle(modalId)
    },

    marcarColumna(claseCheck){
      let checks = document.querySelectorAll('.'+claseCheck)
      let event = new Event('change');
      if(checks){
        checks.forEach(check => {
          check.checked = !check.checked
          check.dispatchEvent(event);
        })
      }
    },

    handleCheckboxChange(event,item,tipo) { //cumple = 1, mejora = 2, noCumple = 3, noAplica = 4
      let estadoCheck = event.srcElement.checked
      const index = this.itemListInspeccion.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        let valorCumple   = (tipo === 1 && estadoCheck)
        // let valorMejora   = (tipo === 2 && estadoCheck)
        let valorNoCumple = (tipo === 3 && estadoCheck)
        let valorNoAplica = (tipo === 4 && estadoCheck)
        let color
        if(estadoCheck === true){
          if      (tipo === 1) color = '#ccffcc'
          // else if (tipo === 2) color = '#fff6b3'
          else if (tipo === 3) color = '#ffbbbb'
          else if (tipo === 4) color = '#d2d2d2'
        }else color = 'unset'
        this.$set(this.itemListInspeccion, index, {
          ...this.itemListInspeccion[index],
          valorCumple:   valorCumple,
          // valorMejora:   valorMejora,
          valorNoCumple: valorNoCumple,
          valorNoAplica: valorNoAplica,
          color: color
        });
      }
    },

    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },

    guardarObservaciones(item) {
      const index = this.itemListInspeccion.findIndex(i => i.itemId === item.itemId);
      if (index !== -1) {
        this.$set(this.itemListInspeccion[index], 'observaciones', item.observaciones);
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.inspeccionId;
    if (this.inspeccionId) {
      this.itemListEnsayoNoDestructivo(this.inspeccionId);
    }
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}

.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}

@media (max-width: 768px) {
  .panel {
    overflow-x: auto; /* Permite el desplazamiento horizontal si el contenido es más ancho que el contenedor */
  }
}
.modal-container{
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
@media (max-width: 768px) {
  .modal-container {
    position: fixed;
    top: 150px;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, 0%);
  }
  .table.d-block.d-sm-none {
    font-size: 9px; /* Reducción del tamaño de fuente */
    width: 100%;
  }

  .table.d-block.d-sm-none th, .table.d-block.d-sm-none td {
    padding: 3px; /* Reducción del padding para ahorrar espacio */
    white-space: normal; /* Permite que el texto de las celdas se ajuste según el ancho disponible */
    word-break: break-word; /* Asegura que las palabras no superen el ancho de la celda */
  }

  .table.d-block.d-sm-none .btn {
    padding: 2px 11px; /* Reduce el tamaño del padding en los botones */
    font-size: 10px; /* Mantiene el tamaño del texto en los botones pequeño */
    margin: 0 2px;
  }

  .table.d-block.d-sm-none .checkbox {
    transform: scale(1); /* No amplía los checkboxes */
    margin: 1px auto; /* Minimiza el espacio alrededor de los checkboxes */
  }
  .table.d-block.d-sm-none th {
    font-size: 8px; /* Disminuye aún más el tamaño de la fuente para los títulos */
    padding: 2px 4px; /* Reduce el espacio alrededor del texto para ahorrar espacio */
    white-space: normal; /* Evita que los títulos se ajusten a líneas múltiples */
  }
}
</style>
