<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Show</a></li>
      <li class="breadcrumb-item active">Inspeccion</li>
    </ol>
    <h1 class="page-header">Show Inspecciones <small></small></h1>
    <panel title="">
      <template>
        <div>
          <!-- ... Otro contenido ... -->
          <table>
            <thead>
            <tr>
              <th colspan="2" class="ficha-inspeccion-header">Ficha Inspección</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="showInspeccion.numeroInforme">
              <td>N°INFORME</td>
              <td>{{ showInspeccion.numeroInforme.toUpperCase() }}</td>
            </tr>
            <tr>
              <td>TIPO INSPECCION</td>
              <td>{{ capitalizeFirstLetter(showInspeccion.tipoInspeccion.toUpperCase()) }}</td>
            </tr>
            <tr>
              <td>SOLICITANTE</td>
              <td>{{ showInspeccion.solicitanteNombre.toUpperCase() + (showInspeccion.solicitanteApellido ? ' ' + showInspeccion.solicitanteApellido.toUpperCase() : "") }}</td>
            </tr>
            <tr>
              <td>LUGAR DE INSPECCIÓN</td>
              <td>{{ showInspeccion.lugarDeInspeccion }}</td>
            </tr>
            <tr>
              <td>EQUIPO</td>
              <td>{{ showInspeccion.equipoPatente + " " + "(" + showInspeccion.equipoEmpresa + ")" }}</td>
            </tr>
            <tr>
              <td>TRACTO</td>
              <td>{{ showInspeccion.tractoPatente + " " + "(" + showInspeccion.tractoEmpresa + ")" }}</td>
            </tr>
            <tr>
              <td>TIPO DE CARGA</td>
              <td>{{ showInspeccion.tipoEquipamiento}}</td>
            </tr>
            <tr>
              <td>ORIGEN</td>
              <td>{{ showInspeccion.origenCarga.toUpperCase()}}</td>
            </tr>
            <tr>
              <td>DESTINO</td>
              <td>{{ showInspeccion.destinoCarga.toUpperCase()}}</td>
            </tr>
            <tr>
              <td>FECHA DE INSPECCIÓN SOLICITADA</td>
              <td>{{ formatFecha(showInspeccion.fechaDeInspeccionSolicitada)}}</td>
            </tr>
            <tr>
              <td>FECHA DE INSPECCION ACTUAL</td>
              <td>{{ formatFecha(showInspeccion.fechaDeInspeccionActual) }}</td>
            </tr>
            <tr>
              <td>ESTADO</td>
              <td :style="{ backgroundColor: convertirColorRGBA(showInspeccion.estadoColor, 0.7) }"> <!-- Opacidad al 50% -->
                {{ showInspeccion.estado }}
              </td>
            </tr>
            <tr v-if="showInspeccion.observaciones">
              <td>OBSERVACIONES</td>
              <td>{{ showInspeccion.observaciones}}</td>
            </tr>
            </tbody>
          </table>
          <div style="display: flex; justify-content: center;">
            <button type="button" class="btn btn-warning mt-5 mb-3" v-if="!isCliente && !showInspeccion.numeroInforme" @click="toggleModal('evaluar-modal')">
              <i class="fa fa-sign-in" style="margin-right: 5px"></i>Evaluar
            </button>
            <button type="button" class="btn btn-warning mt-5 mb-3" v-if="!isCliente && showInspeccion.numeroInforme && !firmaConductorGuardada" @click="toggleModal('firmaConductor-modal')">
              <i class="fa fa-sign-in" style="margin-right: 5px"></i>Firma Conductor
            </button>
            <button type="button" class="btn btn-green mt-5 mb-3" v-if="!isCliente && showInspeccion.numeroInforme && !firmaInspectorGuardada && firmaConductorGuardada" @click="toggleModal('firmaInspector-modal')">
              <i class="fa fa-lock" style="margin-right: 5px"></i>Cerrar Inspección
            </button>
            <button type="button" class="btn btn-green mt-5 mb-3" v-if="firmaInspectorGuardada" @click="reenviarEmail">
              <i class="fa fa-paper-plane" style="margin-right: 5px"></i>Reenviar Email
            </button>
            <button type="button" class="btn btn-primary mt-5 mb-3" v-if="firmaInspectorGuardada" @click="metodoGenerarInforme">
              <i class="fa fa-download" style="margin-right: 5px"></i>Ver Informe
            </button>
            <button v-if="!firmaInspectorGuardada" type="button" class="btn btn-danger mt-5 mb-3" @click="toggleModal('eliminarInspeccion-modal')">
              <i class="fa fa-times" style="margin-right: 5px;"></i>Eliminar
            </button>
            <button type="button" class="btn btn-success mt-5 mb-3" v-if="!isCliente" @click="navegarASubirFotos">
              <i class="fa fa-image" style="margin-right: 5px;"></i>Subir Fotos
            </button>
          </div>

          <modal name="eliminarInspeccion-modal" height="auto">
            <div class="modal-container">
              <h2 class="mb-4">¿Eliminar Inspeccion?</h2>
              <div class="form-group">
                <h4>Esta acción es irreversible.</h4>
              </div>
              <div class="modal-footer">
                <button class="btn btn-secondary" style="margin-right: 7px" @click="toggleModal('eliminarInspeccion-modal')">Cancelar</button>
                <button class="btn btn-danger" @click="eliminarInspeccion">Borrar</button>
              </div>
            </div>
          </modal>
          <modal name="detalles-inspeccion-modal" height="auto" class="modal-inspeccion" @opened="disableScroll" @closed="enableScroll">
            <div class="modal-container-inspeccion">
              <h2 style="text-align: center;">Detalles de la Inspección Anterior</h2>
              <div v-if="detalleInspeccion">
                <p><strong>Número de Informe:</strong> {{ detalleInspeccion.numeroInforme }}</p>
                <p style="margin-top: -15px"><strong>Fecha Inspección:</strong> {{ formatFecha(detalleInspeccion.fechaDeInspeccionActual) }}</p>
              </div>
              <div class="inspeccion-container">
                <!-- Contenedor para ítems de equipo -->
                <div class="equipo-inspeccion">
                  <h3>Ítems de Equipo que <span class="no-cumplen">No Cumplen</span>:</h3>
                  <div v-if="itemesMalos.length > 0" class="formulario-container">
                    <section class="formulario-group" v-for="(items, formularioNombre) in agrupadosPorFormularioEquipo" :key="formularioNombre">
                      <h4>{{ formularioNombre }}</h4>
                      <ul>
                        <li v-for="item in items" :key="item.itemForId">
                          <strong>{{ item.texto }}</strong>
                          <div v-if="item.observaciones && item.observaciones.trim() !== ''">Observaciones: {{ item.observaciones }}</div>
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div v-else>
                    <p>No hay ítems malos de equipo en la inspección anterior.</p>
                  </div>
                </div>
                <!-- Contenedor para ítems de tracto -->
                <div class="tracto-inspeccion">
                  <h3>Ítems de Tracto que <span class="no-cumplen">No Cumplen</span>:</h3>
                  <div v-if="itemesMalosTracto.length > 0" class="formulario-container">
                    <section class="formulario-group" v-for="(items, formularioNombre) in agrupadosPorFormularioTracto" :key="formularioNombre">
                      <h4>{{ formularioNombre }}</h4>
                      <ul>
                        <li v-for="item in items" :key="item.itemForId">
                          <strong>{{ item.texto }}</strong>
                          <div v-if="item.observaciones && item.observaciones.trim() !== ''">Observaciones: {{ item.observaciones }}</div>
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div v-else>
                    <p>No hay ítems malos de tracto en la inspección anterior.</p>
                  </div>
                </div>
                <div class="nulos-inspeccion">
                  <h3>Ítems Generales que <span class="no-cumplen">No Cumplen</span>:</h3>
                  <div v-if="itemesMalosGenerales.length > 0" class="formulario-container">
                    <section class="formulario-group" v-for="(items, formularioNombre) in agrupadosPorFormularioGenerales" :key="formularioNombre">
                      <h4>{{ formularioNombre }}</h4>
                      <ul>
                        <li v-for="item in items" :key="item.itemForId">
                          <strong>{{ item.texto }}</strong>
                          <div v-if="item.observaciones && item.observaciones.trim() !== ''">Observaciones: {{ item.observaciones }}</div>
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div v-else>
                    <p>No hay ítems malos de general en la inspección anterior.</p>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-secondary" @click="closeModal('detalles-inspeccion-modal')">Cerrar</button>
            </div>
          </modal>


        </div>
        <div v-if="itemsFaltantes.length > 0">
          <h4>Imagenes Faltantes:</h4>
          <table class="table table-bordered mt-4">
            <thead>
            <tr>
              <th>Categoría</th>
              <th>Subidas</th>
              <th>Mínimo</th>
              <th>Falta(n)</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in itemsFaltantes" :key="item.nombre">
              <td>{{ item.nombre }}</td>
              <td>{{ item.fotosSubidas || '-' }}</td>
              <td>{{ item.fotosMin }}</td>
              <td>{{ calcularFaltantes(item.fotosSubidas, item.fotosMin) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </panel>
    <modal name="evaluar-modal" height="auto">
      <div class="modal-container">
        <h2>Evaluación</h2>
        <form class="modal-form" @submit.prevent="guardarEvaluacion">
          <div class="form-group">
            <label for="numeroInforme">Número *</label>
            <input type="text" id="numeroInforme" class="form-control" :placeholder="prefijoInformeActual" v-model="form.numeroInforme">
          </div>
          <div class="form-group">
            <label class="form-label" for="evaluacion"><strong>Evaluación*</strong></label>
            <select id="evaluacion" class="form-control" v-if="estadoInspeccionList.length > 0" v-model="form.evaluacion">
              <option value=""  selected disabled>-- SELECCIONE UNA OPCIÓN --</option>
              <option v-for="evaluacion in estadoInspeccionList" :value="evaluacion.id" :key="evaluacion.id">
                {{ evaluacion.nombre }}
              </option>
            </select>

          </div>
          <div class="form-group">
            <label for="observaciones">Observaciones Generales</label>
            <textarea id="observaciones" class="form-control" rows="4" v-model="form.observaciones"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('evaluar-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="firmaConductor-modal" height="auto" @opened="onModalOpened" class="firma-conductor-modal">
      <div class="modal-container firma-conductor-container">
        <h2 style="text-align: center;">Firma Conductor</h2>
        <div class="signature-instructions" style="text-align: center;">
          Conductor debe firmar dentro del área delimitada:
        </div>
        <form class="modal-form" @submit.prevent="guardarFirma(true)" style="text-align: center;">
          <div class="canvas-container" style="position: relative; margin: auto;">
            <canvas ref="signatureCanvas" id="signature-canvas" class="signature-canvas" style="border: 1px solid black; margin-bottom: 10px;"></canvas>
            <button type="button" class="btn btn-secondary btn-limpiar" @click="clearCanvas" style="position: absolute; right: 10px; top: 10px; padding: 5px; border: none; line-height: 1;">
              <i class="fa fa-eraser" style="font-size: 16px;"></i> <!-- Ajusta el tamaño aquí -->
            </button>
            <div class="signature-line" style="position: absolute; bottom: 30px; left: 0; right: 0; border-top: 1px dashed #000;"></div>
          </div>
          <div class="button-group">
            <button type="button" class="btn btn-danger" @click="toggleModal('firmaConductor-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar Firma</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="firmaInspector-modal" height="auto" @opened="onModalOpenedInspector" class="firma-inspector-modal">
      <div class="modal-container firma-inspector-container">
        <h2 style="text-align: center;">Firma Inspector</h2>
        <div class="signature-instructions" style="text-align: center;">
          Inspector debe firmar dentro del área delimitada:
        </div>
        <form class="modal-form" @submit.prevent="guardarFirma(false)" style="text-align: center;">
          <div class="canvas-container" style="position: relative; margin: auto;">
            <canvas ref="signatureCanvas" id="signature-canvas" class="signature-canvas" style="border: 1px solid black; margin-bottom: 10px;"></canvas>
            <button type="button" class="btn btn-secondary btn-limpiar" @click="clearCanvas" style="position: absolute; right: 10px; top: 10px; padding: 5px; border: none; line-height: 1;">
              <i class="fa fa-eraser" style="font-size: 16px;"></i> <!-- Ajusta el tamaño aquí -->
            </button>
            <div class="signature-line" style="position: absolute; bottom: 30px; left: 0; right: 0; border-top: 1px dashed #000;"></div>
          </div>
          <div class="button-group">
            <button type="button" class="btn btn-danger" @click="toggleModal('firmaInspector-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar Firma</button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import SignaturePad from 'signature_pad';

export default {
  name: "ShowInspeccion",
  data() {
    return {
      detalleInspeccion: [],
      itemesMalos: [],
      itemesMalosTracto: [],
      itemesMalosGenerales: [],
      prefijoInformeActual:"",
      firmaConductorGuardada: false,
      firmaInspectorGuardada: false,
      signaturePad: null,
      listaItemsConFotos:[],
      form:{
        evaluacion:'',
        observaciones:'',
        numeroInforme:'',
      },
      itemListChecklist:            [],
      estadoInspeccionList:[],
      showInspeccion: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  computed: {
    itemsFaltantes() {
      return this.listaItemsConFotos.filter(item => {
        // Filtra los elementos que no son complementarios y que tienen menos fotos de las necesarias
        return !item.esComplementaria && item.fotosSubidas < item.fotosMin;
      });
    },
    isCliente() {
      let usuario = JSON.parse(localStorage.getItem('saveUser'));
      let roles = usuario ? usuario["roles"] : [];
      return roles.includes("ROLE_CLIENTE");
    },
    agrupadosPorFormularioEquipo() {
      const grupos = {};
      for (const item of this.itemesMalos) {
        const grupoKey = item.formularioNombre; // Utiliza el nombre del formulario como clave
        if (!grupos[grupoKey]) {
          grupos[grupoKey] = [];
        }
        grupos[grupoKey].push(item);
      }
      return grupos;
    },
    agrupadosPorFormularioTracto() {
      const grupos = {};
      for (const item of this.itemesMalosTracto) {
        const grupoKey = item.formularioNombre; // Utiliza el nombre del formulario como clave
        if (!grupos[grupoKey]) {
          grupos[grupoKey] = [];
        }
        grupos[grupoKey].push(item);
      }
      return grupos;
    },
    agrupadosPorFormularioGenerales() {
      const grupos = {};
      for (const item of this.itemesMalosGenerales) {
        const grupoKey = item.formularioNombre; // Utiliza el nombre del formulario como clave
        if (!grupos[grupoKey]) {
          grupos[grupoKey] = [];
        }
        grupos[grupoKey].push(item);
      }
      return grupos;
    },
    // Puedes añadir más propiedades computadas aquí si lo necesitas
  },
  methods: {
    async eliminarInspeccion() {

      this.$loadingSpiner(); // Muestra el spinner de carga

      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/eliminarInspeccion/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId, // Asegúrate de que inspeccionId está definido en tu componente
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          this.$router.push({ path: '/agendamiento/dashboard/diarias'});
          this.toggleModal('eliminarInspeccion-modal'); // Cierra el modal
          this.$showToastSuccess('Inspeccion Eliminada!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la inspeccion.', 'Error', this.$notificationSystem().options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide(); // Oculta el spinner de carga
        }
      }
    },
    disableScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      document.body.style.overflow = '';
    },
    toggleModal(idModal) {
      this.$modal.show(idModal);
      /*this.disableScroll();*/
    },
    closeModal(idModal) {
      this.$modal.hide(idModal);
      this.enableScroll();
    },
    async reenviarEmail() {
      this.$loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/reenvioCorreoDiarias'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
      };
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.loader.hide()
          this.$showToastSuccess('Email Reenviado !', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async abrirModalDetallesInspeccion() {
      await this.fetchDetalleInspeccionEquipo(); // Espera hasta que los datos sean cargados
      await this.fetchDetalleInspeccionTracto(); // Espera hasta que los datos sean cargados
      await this.fetchDetalleInspeccionGeneral(); // Espera hasta que los datos sean cargados

      // Verifica si hay ítems malos en alguna de las dos listas
      if (this.itemesMalos.length > 0 || this.itemesMalosTracto.length > 0 || this.itemesMalosGenerales.length > 0) {
        this.toggleModal('detalles-inspeccion-modal'); // Abre el modal si hay ítems malos
      } else {
        console.log("No hay ítems malos, no se abrirá el modal.");
      }
    },
    capitalizeFirstLetter(value) {
      if (!value) return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    async fetchDetalleInspeccionTracto() {
      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/obtenerItemesMalosCheckListDiarioAnterior`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      let buscarParaTracto = this.$route.params.id
      let inspeccionId = this.$route.params.id

      try {
        // Cambio aquí: usa params para enviar inspeccionId
        let response = await axios.get(api, {
          params: { buscarParaTracto: buscarParaTracto, inspeccionId: inspeccionId },
          headers: { "Authorization": `Bearer ${tokenHeader}` }
        });
        if (response.status === 200 || response.status === 201) {
          console.log(response.data);
          this.detalleInspeccion = response.data.inspeccionAnterior;
          this.itemesMalosTracto = response.data.itemesMalosTracto
        } else {
          this.showToastError('Algo salio mal !', 'UPS!');
        }
      } catch (error) {
        console.error("Error al obtener detalles: ", error);
        this.showToastError('Intente nuevamente mas tarde !', 'UPS!');
      }
    },
    async fetchDetalleInspeccionGeneral() {
      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/obtenerItemesMalosCheckListDiarioAnterior`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      let buscarParaNulos = this.$route.params.id
      let inspeccionId = this.$route.params.id

      try {
        // Cambio aquí: usa params para enviar inspeccionId
        let response = await axios.get(api, {
          params: { buscarParaNulos: buscarParaNulos, inspeccionId: inspeccionId },
          headers: { "Authorization": `Bearer ${tokenHeader}` }
        });
        if (response.status === 200 || response.status === 201) {
          console.log(response.data);
          this.detalleInspeccion = response.data.inspeccionAnterior;
          this.itemesMalosGenerales = response.data.itemesMalosGenerales
        } else {
          this.showToastError('Algo salio mal !', 'UPS!');
        }
      } catch (error) {
        console.error("Error al obtener detalles: ", error);
        this.showToastError('Intente nuevamente mas tarde !', 'UPS!');
      }
    },
    async fetchDetalleInspeccionEquipo() {
      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/obtenerItemesMalosCheckListDiarioAnterior`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      let buscarParaEquipo = this.$route.params.id
      let inspeccionId = this.$route.params.id

      try {
        // Cambio aquí: usa params para enviar inspeccionId
        let response = await axios.get(api, {
          params: { buscarParaEquipo: buscarParaEquipo, inspeccionId: inspeccionId },
          headers: { "Authorization": `Bearer ${tokenHeader}` }
        });
        if (response.status === 200 || response.status === 201) {
          this.detalleInspeccion = response.data.inspeccionAnterior;
          this.itemesMalos = response.data.itemesMalos;  // Asegúrate de que esta propiedad existe en data()
          console.log(response.data);
        } else {
          this.showToastError('Algo salio mal !', 'UPS!');
        }
      } catch (error) {
        console.error("Error al obtener detalles: ", error);
        this.showToastError('Intente nuevamente mas tarde !', 'UPS!');
      }
    },

    asignarData() {
      this.rows = this.showInspeccion;
    },
    convertirColorRGBA(color, opacidad) {
      const colores = {
        red: '255, 0, 0',
        green: '0, 128, 0',
        orange: '255, 165, 0',
        // Agrega más colores según sea necesario
      };
      return `rgba(${colores[color] || '0, 0, 0'}, ${opacidad})`; // color negro por defecto
    },
    calcularFaltantes(subidas, minimo) {
      // Asumiendo que `subidas` puede ser null o undefined si no hay fotos subidas
      const fotosSubidas = subidas || 0;
      return Math.max(minimo - fotosSubidas, 0);
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    async fetchData(url) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        // Manejar errores
        return []; // Devolver un array vacío en caso de error
      }
    },
    async getEstadoInspeccionList() {
      try {
        const data = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/inspeccion/estadoInspeccionList');
        this.estadoInspeccionList = data; // Asegúrate de que el backend devuelve la lista correctamente
      } catch (error) {
        console.error(error);
        // Manejar errores
      }
    },
    navegarASubirFotos() {
      const id = this.$route.params.id; // O la variable donde tienes almacenado el ID
      this.$router.push({ name: 'GuardarFotosChecklist', params: { id: id } });
    },
    metodoGenerarInforme() {
      const id = this.$route.params.id; // O la variable donde tienes almacenado el ID
      const url = process.env.VUE_APP_IP_BACKEND +`/inspeccion/abrePdfReport/${id}`;
      window.open(url, '_blank');
    },

    // metodoEvaluar() {
    //   this.$modal.show('evaluar-modal');
    // },
    // metodoFirmaConductor() {
    //   this.$modal.show('firmaConductor-modal');
    // },
    // metodoFirmaInspector() {
    //   this.$modal.show('firmaInspector-modal');
    // },
    // cancelarFirma() {
    //   this.$modal.hide('firmaConductor-modal');
    // },
    // cancelarFirmaInspector() {
    //   this.$modal.hide('firmaInspector-modal');
    // },
    onModalOpened() {
      // Ahora puedes estar seguro de que el modal está abierto y el canvas está en el DOM
      let canvas = this.$refs.signatureCanvas;
      // Asegúrate de que el canvas tiene dimensiones asignadas antes de crear la instancia de SignaturePad
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.signaturePad = new SignaturePad(canvas);
    },
    onModalOpenedInspector() {
      // Ahora puedes estar seguro de que el modal está abierto y el canvas está en el DOM
      let canvas = this.$refs.signatureCanvas;
      // Asegúrate de que el canvas tiene dimensiones asignadas antes de crear la instancia de SignaturePad
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.signaturePad = new SignaturePad(canvas);
    },
    clearCanvas() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },
     formatFecha(fechaISO) {
      const fecha = new Date(fechaISO);
      const opciones = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
       const fechaFormateada = fecha.toLocaleString('es-ES', opciones);
       // Devuelve la cadena en mayúsculas
       return fechaFormateada.toUpperCase();
      },
    async ShowInspeccion() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/showInspeccion/'
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token


      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccion = response.data
          this.prefijoInformeActual = response.data.prefijoInformeActual
          this.asignarData()
          this.firmaConductorGuardada = response.data.tieneFirmaConductor
          this.firmaInspectorGuardada = response.data.tieneFirmaInspector
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async itemsConFotos() {
      // this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/imagenesFaltantes/'
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listaItemsConFotos = response.data;
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async guardarEvaluacion() {
      this.loadingSpiner()
      if (!this.form.numeroInforme) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Número Informe!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.evaluacion) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Evaluación!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/guardarEvaluacion/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccion = response.data
          this.loader.hide()
          this.$modal.hide('evaluar-modal');
          this.showToastSuccess('Inspección Evaluada Exitósamente!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async guardarFirma(firmaConductor) {
      let tipoFirma = 'firmaInspector'
      if(firmaConductor === true) tipoFirma = 'firmaConductor'
      this.loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/guardarFirma/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let canvas = this.$refs.signatureCanvas; // Asegúrate de que este es el ref correcto al canvas
      let firmaBase64 = canvas.toDataURL('image/png');
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form,
        firma: firmaBase64,  // Agrega la firma aquí
        tipoFirma: tipoFirma
      };
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.loader.hide()
          if(firmaConductor === true) {
            this.$modal.hide('firmaConductor-modal');
            this.firmaConductorGuardada = true;
          }
          else {
            this.firmaInspectorGuardada = true;
            this.showInspeccion = response.data
          }
          this.$modal.hide('firmaInspector-modal');
          let cargo = "Inspector"
          if(firmaConductor === true) cargo = "Conductor"
          this.showToastSuccess('Firma '+ cargo +' Exitosa!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  },
  mounted() {
    this.getEstadoInspeccionList();
    this.ShowInspeccion();
    this.itemsConFotos();
    this.abrirModalDetallesInspeccion();
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.id
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 8px;
  border: 1px solid #6c757d; /* Ajusta o elimina el borde según tus necesidades */
}
 .ficha-inspeccion-header {
   padding-bottom: 20px;
   font-size: 24px; /* Ajusta el tamaño de la fuente según tus preferencias */
 }

.modal-container {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;

}
.modal-container-inspeccion {
  background-color: #ffffff; /* Color de fondo */
  color: #000000; /* Color del texto */
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  width: 200%; /* Ancho del modal como porcentaje del ancho de la pantalla */
  max-width: 1200px; /* Máximo ancho que el modal puede tener */
  min-width: 300px; /* Mínimo ancho para asegurar legibilidad en dispositivos pequeños */
  max-height: 80vh; /* Máxima altura del modal */
  overflow-y: auto; /* Habilita el scroll vertical si el contenido excede la altura */
}
.modal-inspeccion{
  position: center;
  margin-left: -200px;
}

@media (max-width: 768px) {
  .modal-container-inspeccion, .modal-container {
    position: fixed; /* Fija el modal sobre el contenido */
    top: 5%; /* Espacio desde el top, puedes ajustar según necesites */
    left: 5%; /* Espacio desde el lado izquierdo */
    width: 90%; /* Anchura del modal como porcentaje del ancho de la pantalla */
    max-width: none; /* Sobreescribe cualquier max-width previo para dispositivos móviles */
    height: auto; /* Altura automática basada en el contenido */
    max-height: 80vh; /* Altura máxima para evitar que se salga de la pantalla */
    overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido es muy largo */
    transform: none; /* Elimina cualquier transformación aplicada en otros estilos */
    box-sizing: border-box; /* Asegura que el padding y border están incluidos en el ancho/altura */
    margin-top: 10vh;
  }

  .modal-form {
    width: 100%; /* Asegura que el formulario no se desborde del modal */
  }

  .signature-canvas {
    width: 100%; /* Ajusta el ancho del canvas al del modal */
    height: 150px; /* Altura fija para el canvas */
  }

  .btn-limpiar {
    top: 130px; /* Ajusta la posición del botón limpiar para evitar que se superponga */
  }
  .inspeccion-container {
    flex-direction: column; /* Alinea los contenedores uno debajo del otro */
  }

  .equipo-inspeccion, .tracto-inspeccion, .nulos-inspeccion {
    width: 100%; /* Ocupa todo el ancho disponible del modal */
    margin-bottom: 20px; /* Espacio adicional entre los contenedores para mejor claridad */
  }

}

.modal-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #6c757d;
  border-radius: 4px;
  margin-top: 5px;
}

.form-text.text-danger {
  color: #d9534f;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.btn {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
.table-bordered th {
  border: 1px solid #ffffff; /* Color del borde */
  border-bottom: 2px solid #ffffff; /* Asegura que el borde inferior sea definitivamente blanco y un poco más grueso */
}
.table th, .table td {
  background-color: #d0706d;
}
.firma-conductor-modal .canvas-container {
  position: relative;
  width: 300px; /* Ajusta al ancho deseado */
  margin: auto; /* Centra el contenedor */
}

.firma-conductor-modal .signature-canvas {
  background-color: white;
  border: 1px solid #000;
  height: 150px; /* Ajusta a la nueva altura deseada */
}

/* El botón Limpiar se posiciona en la esquina del canvas */
.firma-conductor-modal .btn-limpiar {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.firma-inspector-modal .canvas-container {
  position: relative;
  width: 300px; /* Ajusta al ancho deseado */
  margin: auto; /* Centra el contenedor */
}

.firma-inspector-modal .signature-canvas {
  background-color: white;
  border: 1px solid #000;
  height: 150px; /* Ajusta a la nueva altura deseada */
}

/* El botón Limpiar se posiciona en la esquina del canvas */
.firma-inspector-modal .btn-limpiar {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.no-cumplen {
  color: red;
}
.inspeccion-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Alinea los contenedores al inicio para que comiencen a la misma altura */
  margin-top: 20px;
}

.equipo-inspeccion,
.tracto-inspeccion,
.nulos-inspeccion{
  flex: 1; /* Cada contenedor tomará una parte igual del espacio disponible */
  min-width: 0; /* Evita que flexbox sobrepase los límites del modal */
  padding: 0 10px; /* Añade algo de espacio entre los contenedores */
}
/*.no-scroll {
  overflow: hidden;
}*/
</style>
