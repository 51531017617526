<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Prueba de Hermeticidad</li>
    </ol>
    <h1 class="page-header">Prueba de Hermeticidad</h1>
    <panel title="">
      <div class="row m-3">
        <h4 class="m-3"><u>Especificaciones Prueba de Hermeticidad</u></h4>
          <template>
            <table class="table table-striped">
              <tr v-for="item in itemListInput" :key="item.itemId">
                  <td class="col-sm-3">
                    <strong>
                      {{item.nombre}}
                    </strong>
                  </td>
                  <td class="col-sm-9">
                    <input type="text" class="form-control" v-model="item.valor">
                  </td>
              </tr>
              <tr v-if="itemDescripcion">
                <td class="col-sm-3">
                  <strong>
                    {{itemDescripcion.nombre}}
                  </strong>
                </td>
                <td class="col-sm-9">
                  <textarea class="form-control" rows="4" v-model="itemDescripcion.valor"></textarea>
                </td>
              </tr>
            </table>
          </template>
      </div>
      <hr>
      <div class="row m-3" v-if="itemConclusion">
        <div class="col-sm-6">
          <h4 class="m-3"><u>Conclusión Prueba</u></h4>
            <div class="form-check form-switch">
              <label class="form-label" for="itemConclusion"><strong>Prueba Aprobada</strong></label>
              <input class="form-check-input" id="itemConclusion" type="checkbox" v-model="itemConclusion.valorEnabled">
            </div>
            <label class="form-label" for="observacionesConclusion">Observaciones de Prueba</label>
            <textarea id="observacionesConclusion" class="form-control" rows="4" v-model="itemConclusion.observaciones"></textarea>
        </div>
      </div>
      <div class="row m-3">
        <h4 class="m-3"><u>Fotos</u></h4>
        <div class="col-sm-4 text-center" v-for="(foto,index) in fotoList" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="foto.tieneFotoSubida" type="button" class="btn btn-danger btn-sm mt-2" @click="modalEliminarFoto(foto.archivoId)">
            <i class="fa fa-trash"></i>
          </button>
          <button v-else type="button" class="btn btn-primary btn-sm mt-2" @click="modalSubirFoto('modal-subir-foto', index)">
            <i class="fa fa-camera"></i>
          </button>
        </div>
      </div>
      <button type="button" class="btn btn-secondary m-3" v-on:click="volver()"><i class="fa fa-caret-left"></i> Volver</button>
      <button type="button" class="btn btn-primary m-3" v-on:click="guardarYContinuar()">Guardar y continuar <i class="fa fa-caret-right"></i><i class="fa fa-caret-right"></i></button>
      <modal name="modal-subir-foto"  @before-close="antesDeCerrarModal" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">Subir Foto</h2>
          <div class="form-group">
            <input type="file" accept="image/*" @change="handleFileUpload()" />
            <img v-if="imagenVistaPrevia" :src="imagenVistaPrevia" alt="Vista previa de la imagen" style="max-width: 200px; height: auto; margin-top: 20px;">
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" style="margin-right: 10px" @click="toggleModal('modal-subir-foto')">Cancelar</button>
            <button class="btn btn-primary" @click="subirFoto">Subir Foto</button>
          </div>
        </div>
      </modal>
      <modal name="modal-borrar-foto" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">¿Eliminar Foto?</h2>
          <div class="form-group">
            <h4>Esta acción es irreversible.</h4>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" style="margin-right: 7px" @click="toggleModal('modal-borrar-foto')">Cancelar</button>
            <button class="btn btn-danger" @click="borrarFoto">Borrar</button>
          </div>
        </div>
      </modal>
    </panel>
  </div>
</template>
<script>
import imageCompression from 'browser-image-compression';
import axios from "axios";

export default {
  name: "FormularioHermeticidad",
  data() {
    return {
      itemList: [],
      fotoList: [],
      itemListInput: [],
      itemConclusion: {},
      itemDescripcion: {},
      indiceFotoListSubir: 0,
      nombreFotoSubida: null,
      itemCategoriaIdActual: null,
      nombreItemActual: '',
      imagenVistaPrevia: null,
      fotoIdAEliminar: null,
      especificacionesHermeticidadPorFormato: {
        "cal_2_ejes":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "8   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "cal_3_ejes":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "8   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "emulsion_matriz":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "8   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "nitrato_amonio":[
          {"texto": "Presión Neumática"},
          {"texto": "35.000 Kg"},
          {"texto": "8   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "nitrato_amonio_2_ejes":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "8   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "nitrato_amonio_inoxidable":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "8   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "solucion_ms_templo":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "6   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
        "solucion_randon":[
          {"texto": "Presión Neumática"},
          {"texto": "30.000 Litros"},
          {"texto": "6   PSI"},
          {"texto": "Aire"},
          {"texto": "Manómetro y Solución Jabonosa al 15%"},
          {"texto": "90 Minutos"},
          {"texto": "60 PSI"},
          {"texto": "Si"},
        ],
      },
      especificacionDescripcionPorFormato: {
        "cal_2_ejes":               'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "cal_3_ejes":               'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "emulsion_matriz":          'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "nitrato_amonio":           'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "nitrato_amonio_2_ejes":    'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "nitrato_amonio_inoxidable":'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "solucion_ms_templo":       'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
        "solucion_randon":          'En esta prueba se debe aplicar presión neumática de 8 PSI durante un periodo de 30 minutos, verificando la completa hermeticidad de cuerpo de estanque, tapas manhole, válvulas, todos los mantos, ductos de drenaje y uniones soldadas. Como ayuda al contraste se aplica solución jabonosa al 15 %.',
      },
      especificacionDescripcionUsar:'',
      especificacionUsar: [],
    }
  },
  methods: {
    storeCurrentValues() {
      this.currentValues = this.itemList.reduce((acc, item) => {
        acc[item.itemId] = item.valor;
        return acc;
      }, {});
      if (this.itemDescripcion) {
        this.currentDescriptionValue = this.itemDescripcion.valor;
      }
      if (this.itemConclusion) {
        this.currentConclusionValues = {
          valorEnabled: this.itemConclusion.valorEnabled,
          observaciones: this.itemConclusion.observaciones
        };
      }
    },
    restoreCurrentValues() {
      if (this.currentValues) {
        this.itemList.forEach(item => {
          if (this.currentValues[item.itemId] !== undefined) {
            item.valor = this.currentValues[item.itemId];
          }
        });
      }
      if (this.itemDescripcion && this.currentDescriptionValue !== undefined) {
        this.itemDescripcion.valor = this.currentDescriptionValue;
      }
      if (this.itemConclusion && this.currentConclusionValues) {
        this.itemConclusion.valorEnabled = this.currentConclusionValues.valorEnabled;
        this.itemConclusion.observaciones = this.currentConclusionValues.observaciones;
      }
    },
    async volver(){
      this.$irVistaInspeccionProgramada(this.inspeccionId, 'hermeticidad', true)
    },

    async guardarYContinuar(){
      let itemListEnvio = this.itemListInput.map(item => ({
          itemId:        item?.itemId,
          valor:         item?.valor,
          valorEnabled:  item?.valorEnabled,
          noAplica:      item?.valorNoAplica,
          observaciones: item?.observaciones
      }))
      itemListEnvio.push(
          {
            itemId:        this?.itemDescripcion?.itemId,
            valor:         this?.itemDescripcion?.valor,
            valorBooleano: this?.itemDescripcion?.valorEnabled,
            valorNoAplica: this?.itemDescripcion?.valorNoAplica,
            observaciones: this?.itemDescripcion?.observaciones
          }
      )
      let ponderacionId = null
      if (!this.itemConclusion.valorEnabled) {
        ponderacionId = 3
      } else if (this.itemConclusion.valorEnabled) {
        ponderacionId = 1
      }
      console.log(ponderacionId)
      itemListEnvio.push(
          {
              itemId:        this?.itemConclusion?.itemId,
              valor:         this?.itemConclusion?.valor,
              valorBooleano: this?.itemConclusion?.valorEnabled,
              valorNoAplica: this?.itemConclusion?.valorNoAplica,
              observaciones: this?.itemConclusion?.observaciones,
              ponderacionId: ponderacionId
          }
      )
      let exito = await this.$guardarItemsInspeccionProgramada(itemListEnvio,this.inspeccionId, 'hermeticidad')
      if(exito){
        this.$irVistaInspeccionProgramada(this.inspeccionId, 'hermeticidad', false)
      }
    },

    async itemListHermeticidad(inspeccionId) {
      this.storeCurrentValues();
      let data = await this.$itemListMasInfoFormularioInspProgramada(inspeccionId, 'hermeticidad')
      if(data){
        this.itemList = data.itemList.map((item) => {
          return {
            itemId:        item.itemId,
            index:         item.index,
            nombre:        item.texto,
            codigo:        item.codigo,
            enabled:       item.enabled,
            noAplica:      item.noAplica,
            conclusion:    item.conclusion,
            textArea:      item.textArea,
            valor:         item.valor,
            valorEnabled:  item.valorBooleano,
            observaciones: item.observaciones,
            guardado:      item.guardado,
          };
        });
        this.especificacionUsar = this.especificacionesHermeticidadPorFormato[data.formularioTipoCodigo]
        this.especificacionDescripcionUsar = this.especificacionDescripcionPorFormato[data.formularioTipoCodigo]
        this.fotoList = data.fotoList
        this.asignarData()
        this.restoreCurrentValues();
      }
    },
    modalEliminarFoto(fotoId) {
      this.storeCurrentValues();
      this.fotoIdAEliminar = fotoId;
      this.toggleModal('modal-borrar-foto');
    },
    async borrarFoto() {
      this.$loadingSpiner(); // Muestra el spinner de carga

      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/borrarFoto/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId, // Asegúrate de que inspeccionId está definido en tu componente
        imagen: {
          id: this.fotoIdAEliminar // Usa directamente el ID de la foto a eliminar
        },
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          // Encuentra el índice usando el ID para eliminarlo de la lista
          const index = this.fotoList.findIndex(f => f.id === this.fotoIdAEliminar);
          if (index !== -1) this.fotoList.splice(index, 1);
          this.itemListHermeticidad(this.inspeccionId)
          this.toggleModal('modal-borrar-foto'); // Cierra el modal
          this.$showToastSuccess('Foto Eliminada Exitósamente!', 'Genial!', this.notificationSystem.options.success);
        } else {
          this.$showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la foto.', 'Error', this.notificationSystem.options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide(); // Oculta el spinner de carga
        }
      }
    },


    asignarData() {
      this.itemListInput = this.itemList.filter((item) => item?.conclusion === false && item?.textArea === false)
      this.itemDescripcion  = this.itemList.find((item) => item?.textArea === true)
      if(!this.itemDescripcion.guardado) this.$set(this.itemDescripcion,'valor', this.especificacionDescripcionUsar)
      this.itemConclusion   = this.itemList.find((item) => item?.conclusion === true)

      for(let i= 0; i < this.itemListInput.length; i++){
        if(this.especificacionUsar[i]){
          if(!this.itemListInput[i].guardado) this.$set(this.itemListInput[i],'valor', this.especificacionUsar[i].texto)
        }
      }
    },

    antesDeCerrarModal() {
      this.imagenVistaPrevia = null;
    },

   async handleFileUpload() {
      let indiceFoto = this.indiceFotoListSubir
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.fotoList[indiceFoto].nombreFotoSubida = file.name

        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 300, // Compress the image if it's larger than this
          useWebWorker: true
        };
        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagenVistaPrevia = e.target.result; // URL para vista previa
            this.$set(this.fotoList[indiceFoto], 'fotoSubidaBase64', e.target.result); // Asignación reactiva
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error during image compression:', error);
        }
      }
    },

    async subirFoto(){
      this.storeCurrentValues();
      let foto = this.fotoList[this.indiceFotoListSubir]
      const datosFoto = {
        inspeccionId:     this.inspeccionId,
        fotoSubidaBase64: foto.fotoSubidaBase64,
        nombreFotoSubida: foto.nombreFotoSubida,
        categoria:        foto.categoria,
      };
      this.toggleModal('modal-subir-foto')
      let urlImagen = await this.$subirFotoInspeccion(datosFoto)
      if(urlImagen){
        this.$set(this.fotoList[this.indiceFotoListSubir],'tieneFotoSubida', true)
        this.itemListHermeticidad(this.inspeccionId)
      }
    },

    modalSubirFoto(modalId, indiceFotoList){
      this.indiceFotoListSubir = indiceFotoList
      this.toggleModal(modalId)
    },

    toggleModal(modalId){
      this.$modal.toggle(modalId)
    }
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.inspeccionId;
    if (this.inspeccionId) {
      this.itemListHermeticidad(this.inspeccionId);
    }
    window.scrollTo(0, 0);
  }
}
</script>
<style >
.modal-container{
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
  max-height: 80vh; /* Ajusta a lo que necesites */
  overflow-y: auto;
}
@media (max-width: 768px) {
  .modal-container {
    position: fixed;
    top: 150px;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, 0%);
  }
}
</style>