<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Fotos Inspección</li>
    </ol>
    <panel style="width: 100%">
      <h1 class="page-header">Fotos Inspección</h1>
      <div class="row text-center" v-if="fotoListHermeticidad.length > 0">
        <h4><u>Pruebas de Hermeticidad</u></h4>
        <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListHermeticidad" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm" v-on:click="modalSubirFoto('fotoListHermeticidad', foto)"><i class="fa fa-camera"></i></button>
          <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListHermeticidad', foto)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <div class="row text-center" v-if="fotoListValvulaAlivio.length > 0">
        <h4><u>Pruebas de Válvula de Alivio</u></h4>
        <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListValvulaAlivio" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm" v-on:click="modalSubirFoto('fotoListValvulaAlivio', foto)"><i class="fa fa-camera"></i></button>
          <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListValvulaAlivio', foto)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <div class="row text-center" v-if="fotoListEnsayoNoDestructivo.length > 0">
        <h4><u>Fotos Ensayo No Destructivo - Ultrasonido</u></h4>
        <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListEnsayoNoDestructivo" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button  v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm" v-on:click="modalSubirFoto('fotoListEnsayoNoDestructivo', foto)"><i class="fa fa-camera"></i></button>
          <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListEnsayoNoDestructivo', foto)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <div class="row text-center" v-if="fotoListLineasPilarPletina.length > 0">
        <h4><u>Inspeccion Líneas de Vida</u></h4>
        <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListLineasPilarPletina" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style=" height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 300px;max-width: 300px;">
          </div>
          <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm" v-on:click="modalSubirFoto('fotoListLineasPilarPletina', foto)"><i class="fa fa-camera"></i></button>
          <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListLineasPilarPletina',foto)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <div class="row text-center" v-if="fotoListEquipo.length > 0">
        <h4 class="mb-4"><u>Componentes evaluados</u></h4>
        <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListEquipo" :key="index">
          <div style="max-height: 60px;">
            <strong>
              {{foto.prefijo}}{{foto.nombre}}
            </strong>
          </div>
          <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
            <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 270px;max-width: 270px;">
            <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 270px;max-width: 270px;">
          </div>
          <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListEquipo', foto)"><i class="fa fa-camera"></i></button>
          <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListEquipo', foto)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <div class="row text-center" v-if="fotoListChecklist.length > 0">
        <h4 class="mb-4">
          <u>Fotos Checklist (máximo 24)</u><br>
<!--          <button type="button" class="btn btn-primary btn-sm mt-3" v-on:click="toggleModal('modal-subir-multi-fotos')">Subir múltiples fotos</button>-->
        </h4>
          <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListChecklist" :key="index">
<!--            Siempre se muestra la primera-->
            <div v-if="index === 0">
              <div style="max-height: 60px;">
                <strong>
                  {{foto.prefijo}}{{foto.nombre}}
                </strong>
              </div>
              <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
                <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
                <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain; max-height: 270px;max-width: 270px;">
              </div>
              <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListChecklist', foto)"><i class="fa fa-camera"></i></button>
              <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListChecklist', foto)"><i class="fa fa-trash"></i></button>
            </div>
<!--            Siempre se muestra los que ya tienen fotos-->
            <div v-else-if="foto.tieneFotoSubida">
              <div style="max-height: 60px;">
                <strong>
                  {{foto.prefijo}}{{foto.nombre}}
                </strong>
              </div>
              <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
                <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
                <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
              </div>
              <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListChecklist', foto)"><i class="fa fa-camera"></i></button>
              <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListChecklist', foto)"><i class="fa fa-trash"></i></button>
            </div>
<!--            Se muestra la posterior si la anterior tiene foto subida-->
            <div v-else-if="fotoListChecklist[index-1]">
              <div v-if="fotoListChecklist[index-1].tieneFotoSubida">
                <div style="max-height: 60px;">
                  <strong>
                    {{foto.prefijo}}{{foto.nombre}}
                  </strong>
                </div>
                <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
                  <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
                  <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
                </div>
                <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListChecklist', foto)"><i class="fa fa-camera"></i></button>
                <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListChecklist', foto)"><i class="fa fa-trash"></i></button>
              </div>
            </div>
        </div>
      </div>

      <div class="row text-center" v-if="fotoListEnsayos.length > 0">
        <h4><u>Fotos Ensayo (máximo 4)</u></h4>
        <div class="col-sm-3 mb-3" v-for="(foto,index) in fotoListEnsayos" :key="index">
          <!--            Siempre se muestra la primera-->
          <div v-if="index === 0">
            <div style="max-height: 60px;">
              <strong>
                {{foto.prefijo}}{{foto.nombre}}
              </strong>
            </div>
            <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
              <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
              <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
            </div>
            <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListEnsayos', foto)"><i class="fa fa-camera"></i></button>
            <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListEnsayos', foto)"><i class="fa fa-trash"></i></button>
          </div>
          <!--            Siempre se muestra los que ya tienen fotos-->
          <div v-else-if="foto.tieneFotoSubida">
            <div style="max-height: 60px;">
              <strong>
                {{foto.prefijo}}{{foto.nombre}}
              </strong>
            </div>
            <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
              <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
              <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
            </div>
            <button v-if="!foto.tieneFotoSubida" type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListEnsayos', foto)"><i class="fa fa-camera"></i></button>
            <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListEnsayos', foto)"><i class="fa fa-trash"></i></button>
          </div>
          <!--            Se muestra la posterior si la anterior tiene foto subida-->
          <div v-else-if="fotoListEnsayos[index-1]">
            <div v-if="fotoListEnsayos[index-1].tieneFotoSubida">
              <div style="max-height: 60px;">
                <strong>
                  {{foto.prefijo}}{{foto.nombre}}
                </strong>
              </div>
              <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 300px; border: 1px solid black">
                <img v-if="foto.urlImagen" :src="foto.urlImagen" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
                <img v-else-if="foto.tieneFotoSubida" :src="foto.fotoSubidaBase64" alt="Vista previa de la imagen" style="object-fit: contain;  max-height: 270px;max-width: 270px;">
              </div>
              <button type="button" class="btn btn-primary btn-sm mt-3" v-on:click="modalSubirFoto('fotoListEnsayos', foto)"><i class="fa fa-camera"></i></button>
              <button v-if="foto.tieneFotoSubida"  type="button" class="btn btn-danger btn-sm mt-3"  v-on:click="modalBorrarFoto('fotoListEnsayos', foto)"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-3" v-on:click="goToShowInspeccion">Volver</button>
      <modal name="modal-subir-foto"  @before-close="antesDeCerrarModal" height="auto">
        <div class="modal-container-subirFoto">
          <h2 class="mb-4">Subir Foto</h2>
          <div class="form-group">
            <input type="file" accept="image/jpg, image/jpeg, image/png" @change="handleFileUpload()" />
            <img v-if="imagenVistaPrevia" :src="imagenVistaPrevia" alt="Vista previa de la imagen" style="max-width: 200px; height: auto; margin-top: 20px;">
          </div>
          <div class="modal-footer">
            <button style="margin-right: 7px" class="btn btn-danger" @click="toggleModal('modal-subir-foto')">Cancelar</button>
            <button class="btn btn-primary" @click="subirFoto">Subir Foto</button>
          </div>
        </div>
      </modal>
      <modal name="modal-borrar-foto" height="auto">
        <div class="modal-container">
          <h2 class="mb-4">¿Eliminar Foto?</h2>
          <div class="form-group">
            <h4>Esta acción es irreversible</h4>
          </div>
          <div class="modal-footer">
            <button style="margin-right: 7px" class="btn btn-secondary" @click="toggleModal('modal-borrar-foto')">Cancelar</button>
            <button class="btn btn-danger" @click="borrarFoto">Borrar</button>
          </div>
        </div>
      </modal>
<!--      <modal name="modal-subir-multi-fotos" height="auto">-->
<!--        <div class="modal-container">-->
<!--          <div class="modal-header">-->
<!--            <h2 class="mb-4">Subir Fotos</h2>-->
<!--          </div>-->
<!--          <div class="modal-body">-->
<!--            <input type="file" accept="image/jpg, image/jpeg, image/png" @change="handleFileUploadMultiple" :multiple="true"/>-->
<!--          </div>-->
<!--          <div class="modal-footer">-->
<!--            <button style="margin-right: 7px" class="btn btn-danger" @click="toggleModal('modal-borrar-foto')">Cancelar</button>-->
<!--            <button class="btn btn-primary" @click="guardarFotosMultiples">Subir Foto</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </modal>-->
    </panel>
  </div>
</template>
<script>
import axios from "axios";
import imageCompression from "browser-image-compression";

export default {
  name: "InspeccionProgramadaFotos",
  data() {
    return {
      fotoList: [],
      fotoListEquipo: [],
      fotoListHermeticidad: [],
      fotoListValvulaAlivio: [],
      fotoListEnsayoNoDestructivo: [],
      fotoListLineasPilarPletina: [],
      fotoListChecklist: [],
      fotoListEnsayos: [],
      indiceFotoListSubir: 0,
      nombreFotoSubida: null,
      itemCategoriaIdActual: null,
      nombreItemActual: '',
      imagenVistaPrevia: null,
      nombreListaBorrar: "",
      indiceFotoBorrar: 0,
      nombreListaSubir: "",
      indiceFotoSubir: 0,
    }
  },
  methods: {
    async fotoListInspeccion(inspeccionId) {
      let data = await this.$fotoListInspeccion(inspeccionId)
      if(data){
        this.fotoList                    = data.fotoList
        this.fotoListEquipo              = data.fotoListPorCategoria.equipo
        this.fotoListHermeticidad        = data.fotoListPorCategoria.hermeticidad
        this.fotoListValvulaAlivio       = data.fotoListPorCategoria.valvulaAlivio
        this.fotoListEnsayoNoDestructivo = data.fotoListPorCategoria.ensayoNoDestructivo
        this.fotoListLineasPilarPletina  = data.fotoListPorCategoria.lineasPilarPletina
        this.fotoListChecklist           = data.fotoListPorCategoria.Checklist
        this.fotoListEnsayos             = data.fotoListPorCategoria.Ensayos
      }
    },

    goToShowInspeccion(){
      const id = this.$route.params.id;
      this.$router.push({ name: 'ShowInspeccionProgramada', params: {id: id} });
    },

    antesDeCerrarModal() {
      this.imagenVistaPrevia = null;
    },


    modalSubirFoto(nombreListaSubir, fotoSubir){
      this.indiceFotoSubir = this[nombreListaSubir].findIndex(fot => fot.categoria === fotoSubir.categoria)
      this.nombreListaSubir = nombreListaSubir;
      this.toggleModal('modal-subir-foto')
    },

   async handleFileUpload() {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this[this.nombreListaSubir][this.indiceFotoSubir].nombreFotoSubida = file.name

        const options = {
          maxSizeMB: 1, // Max size in MB
          maxWidthOrHeight: 300, // Compress the image if it's larger than this
          useWebWorker: true
        };

        try {
          const compressedFile = await imageCompression(file, options);

          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagenVistaPrevia = e.target.result; // URL para vista previa
            this.$set(this[this.nombreListaSubir][this.indiceFotoSubir], 'fotoSubidaBase64', e.target.result); // Asigna la imagen comprimida y convertida a Base64
          }
          reader.readAsDataURL(compressedFile); // Usa el archivo comprimido
        } catch (error) {
          console.error('Error during image compression:', error);
        }
      }
    },

    async subirFoto(){
      let foto = this[this.nombreListaSubir][this.indiceFotoSubir]
      /*const options = {
        maxSizeMB: 1, // Max size in MB
        maxWidthOrHeight: 300, // Compress the image if it's larger than this
        useWebWorker: true
      };*/
      /*const compressedFile = await imageCompression(foto.fileFoto, options);*/
      const datosFoto = {
        inspeccionId:     this.inspeccionId,
        fotoSubidaBase64: foto.fotoSubidaBase64,
        /*fileFoto :        compressedFile,*/
        nombreFotoSubida: foto.nombreFotoSubida,
        categoria:        foto.categoria,
      };
      this.toggleModal('modal-subir-foto')
      let urlImagen = await this.$subirFotoInspeccion(datosFoto)
      if(urlImagen){
        this.$set(this.fotoList[this.indiceFotoListSubir],'tieneFotoSubida', true) //Creo se puede eliminar esta linea, verificar después.
        this.$set(this[this.nombreListaSubir][this.indiceFotoSubir], 'tieneFotoSubida', true)
        this.$set(this[this.nombreListaSubir][this.indiceFotoSubir], 'urlImagen', urlImagen)
      }
      this.fotoListInspeccion(this.inspeccionId);
    },

    modalBorrarFoto(nombreListaBorrar, fotoBorrar){
      this.indiceFotoBorrar = this[nombreListaBorrar].findIndex(fot => fot.categoria === fotoBorrar.categoria)
      this.nombreListaBorrar = nombreListaBorrar;
      this.toggleModal('modal-borrar-foto')
    },

    async borrarFoto(){
      let foto = this[this.nombreListaBorrar][this.indiceFotoBorrar]

      this.$loadingSpiner();
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/borrarFoto/';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId,
        imagen: {
          id: foto?.archivoId
        },
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          this.$set(this[this.nombreListaBorrar][this.indiceFotoBorrar], 'tieneFotoSubida', false)
          this.$set(this[this.nombreListaBorrar][this.indiceFotoBorrar], 'urlImagen', "")
          this.toggleModal('modal-borrar-foto')

          this.$showToastSuccess('Foto Eliminada Exitósamente!', 'Genial!', this.$notificationSystem().options.success);
        } else {
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem().options.error);
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la foto.', 'Error', this.$notificationSystem().options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide();
        }
      }
    },

    // handleFileUploadMultiple(){
    //
    // },
    toggleModal(modalId){
      this.$modal.toggle(modalId)
    },

  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.id;
    if (this.inspeccionId) {
      this.fotoListInspeccion(this.inspeccionId);
    }
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}

.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}

.modal-container-subirFoto {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
}
.form-group {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .modal-container-subirFoto {
    background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
    color: #000000;
    border: solid 1px;
    max-height: calc(100vh - 100px); /* Ajustar la altura máxima para dejar espacio suficiente para los botones */
    border-color: #495057;
    padding: 10px;
    position: fixed;
    top: 55%;
    left: 50%; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(-50%, -50%);
    width: 90%;
  }
}
</style>
