<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Ramplas Conocimientos Conductor</li>
    </ol>
<!--    <h1 class="page-header">{{ nombreFormulario }}</h1>-->
    <h2>Conocimientos del Conductor  <small>haga click en el item para agregar observaciones</small></h2>
    <panel :title="nombreFormulario">
      <div class="">
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
      >
        <template v-slot:table-row="{ row, column }">
          <div v-if="column.field === 'acciones'"> <!-- Asume que la columna combinada se llama 'acciones' -->
            <!-- Switch de Cumple -->
            <div v-if="!row.valorNoAplica">
              <label class="switch">
                <input type="checkbox"
                       v-model="row.valorEnabled"
                       @change="handleCheckboxChange(row)">
                <span class="slider"></span>
              </label>
            </div>
            <!-- Checkbox de No Aplica debajo del switch -->
            <div v-if="!row.valorEnabled" style="display: flex; align-items: center; margin-top: 4px;">
              <input type="checkbox"
                     v-model="row.valorNoAplica"
                     @change="handleNoAplicaCheckboxChange(row)">
              <span style="margin-left: 8px;">N/A</span>
            </div>
          </div>
          <div v-else-if="column.field === 'nombre'">
            <div @click="toggleObservaciones(row)"
                 :class="{ 'item-malo': row.esMalo }">
              {{ row.nombre }}
            </div>
            <!-- Campo de texto de observaciones debajo del nombre -->
            <div v-if="row.mostrarObservaciones">
               <textarea v-if="row.mostrarObservaciones"
                         v-model="row.observaciones"
                         @blur="guardarObservaciones(row)"
                         class="observaciones-textarea"
                         placeholder="Escribe tus observaciones aquí...">
              </textarea>
            </div>
          </div>
        </template>
      </vue-good-table>
      <button type="button" class="btn btn-primary mt-3" v-on:click="guardarYContinuar()">Guardar y continuar >></button>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ChecklistRamplasConocimientosConductor",
  data() {
    return {
      nombreFormulario: '',
      columns: [
        {
          label: 'N°',
          field: 'numero',
          width: '50px',
          sortable: false,
        },
        {
          label: 'ITEM/COMPONENTE INSPECCIONADO',
          field: 'nombre',
          sortable: false,
        },
        {
          label: 'Cumple', // Columna combinada
          field: 'acciones', // Este campo no necesita coincidir con un campo en tus datos, ya que lo manejarás personalizadamente en el slot.
          sortable: false, // Asume que no quieres ordenar por esta columna
          width: '80px',
        },
        // Elimina las columnas individuales 'Cumple' y 'No Aplica' si existían previamente
      ],
      ordenActual: null,
      ultimoOrden: null,
      rows: [],
      checkListRamplasConocimientosConductor: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods: {
    asignarData() {
      this.rows = this.checkListRamplasConocimientosConductor;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    handleCheckboxChange(item) {
      const index = this.checkListRamplasConocimientosConductor.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListRamplasConocimientosConductor, index, {
          ...this.checkListRamplasConocimientosConductor[index],
          valorEnabled: !this.checkListRamplasConocimientosConductor[index].valorEnabled,
          // Desactivar 'No Aplica' si 'Habilitado' está activado
          valorNoAplica: this.checkListRamplasConocimientosConductor[index].valorEnabled ? false : this.checkListRamplasConocimientosConductor[index].valorNoAplica,
          mostrarObservaciones: this.checkListRamplasConocimientosConductor[index].mostrarObservaciones
        });
      }
    },
    handleNoAplicaCheckboxChange(item) {
      const index = this.checkListRamplasConocimientosConductor.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListRamplasConocimientosConductor, index, {
          ...this.checkListRamplasConocimientosConductor[index],
          valorNoAplica: !this.checkListRamplasConocimientosConductor[index].valorNoAplica,
          // Desactivar 'Habilitado' si 'No Aplica' está activado
          valorEnabled: this.checkListRamplasConocimientosConductor[index].valorNoAplica ? false : this.checkListRamplasConocimientosConductor[index].valorEnabled,
          mostrarObservaciones: this.checkListRamplasConocimientosConductor[index].mostrarObservaciones,
          ocultarSwitch: this.checkListRamplasConocimientosConductor[index].valorNoAplica ? false : true
        });
      }
    },
    toggleObservaciones(item) {
      item.mostrarObservaciones = !item.mostrarObservaciones;
    },
    guardarObservaciones(item) {
      const index = this.checkListRamplasConocimientosConductor.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.$set(this.checkListRamplasConocimientosConductor[index], 'observaciones', item.observaciones);
      }
    },
    async guardarYContinuar() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/guardarItemsYcontinuar`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let inspeccionId = JSON.parse(localStorage.getItem('inspeccionId'));
      let formularioId = JSON.parse(localStorage.getItem('formularioId'));
      let ordenActual = JSON.parse(localStorage.getItem('ordenActual'));
      let ultimoOrden = JSON.parse(localStorage.getItem('ultimoOrden'));
      const datosUsuario = JSON.parse(localStorage.getItem('saveUser'));
      const datosParaEnviar = {
        items: this.checkListRamplasConocimientosConductor.map(item => ({
          id: item.id,
          valorEnabled: item.valorEnabled,
          valorNoAplica: item.valorNoAplica,
          observaciones: item.observaciones
        })),
        inspeccionId: inspeccionId,
        formularioId: formularioId,
        ordenActual : ordenActual,
        ultimoOrden : ultimoOrden,
        username:     datosUsuario.username,
      };
      try {
        //PETICION
        let response = await axios.post(api,datosParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          localStorage.setItem('ordenActual', response.data.ordenActual);
          localStorage.setItem('formularioId', response.data.formularioId);
          this.asignarData()
          this.$router.push({name: response.data.nombreFormularioSinEspacios, params: {
              formularioId: response.data.formularioId,
              nombreFormularioSinEspacios: response.data.nombreFormularioSinEspacios,
              nombreFormulario: response.data.nombreFormulario,
              ultimoOrden: response.data.ultimoOrden,
              ordenActual: response.data.ordenActual,
              inspeccionId: response.data.inspeccionId,
            }})
          this.showToastSuccess('Checklist Ramplas Conocimientos Conductor Creado Exitósamente !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async checkListRamplasConocimientosConductorData(formularioId) {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + `/formulario/itemFormulario/${formularioId}`
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          const apiMalos = process.env.VUE_APP_IP_BACKEND + '/inspeccion/obtenerItemesMalosCheckListDiarioAnterior/'
          const inspeccionId = this.inspeccionId; // Asume que ya tienes 'inspeccionId' en localStorage
          let responseMalos = await axios.get(apiMalos, {
            params: { formularioId, inspeccionId },
            headers: {"Authorization": `Bearer ${tokenHeader}`}
          });
          const itemsMalos = responseMalos.status === 200 ? responseMalos.data.itemesMalos : [];
          this.checkListRamplasConocimientosConductor = response.data.map((item, index) => {
            const esMalo = itemsMalos.some(malo => malo.itemId === item.id);
            return {
              id: item.id,
              numero: index + 1,
              nombre: item.texto,
              mostrarEnabled: item.enabled, // Determina si mostrar el checkbox
              mostrarNoAplica: item.noAplica, // Determina si mostrar el checkbox
              valorEnabled: false, // Estado inicial desmarcado
              valorNoAplica: false, // Estado inicial desmarcado
              mostrarObservaciones: false, // Inicialmente oculto
              observaciones: '',
              esMalo
            };
          });
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },

    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.nombreFormulario = this.$route.params.nombreFormulario;
    this.inspeccionId = this.$route.params.inspeccionId;
    const formularioId = this.$route.params.formularioId;
    if (formularioId) {
      this.checkListRamplasConocimientosConductorData(formularioId);
    }
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(1.5);
  margin-left: 5px;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff0000;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 13px;

}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2ecc1d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2ecc1d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
.hidden {
  display: none;
}
.item-malo {
  color: red;
}
</style>
