<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Crear</a></li>
      <li class="breadcrumb-item active">Equipo</li>
    </ol>
    <h1 class="page-header">Crear Equipo <small></small></h1>
    <panel title="Formulario de creación de Equipos">
      <div class="row">
        <form v-on:submit.prevent="crearEquipo" method="POST">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="patente"><strong>Patente/Número*</strong> </label>
              <input class="form-control" type="text" v-model="form.patente" @input="validateInput" />
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" id="propietario"><strong>Propietario*</strong> </label>
              <select class="form-control" v-if="empresasPropietarias.length > 0" v-model="form.propietarioDelEquipo">
                <option v-for="propietario in empresasPropietarias" :value="propietario.id" :key="propietario.id">
                  {{propietario.razonSocial }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
                <div class="datepicker" >
                  <label class="form-label" for="vencimientoRevisionTecnica"><strong>Vencimiento</strong></label>
                  <Datepicker  :language="es" mondayFirst v-model="form.vencimientoRevisionTecnica" :format="customFormatter" ></Datepicker>
                </div>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" id="transportista"><strong>Transportista*</strong> </label>
              <select class="form-control" v-if="empresasTransportista.length > 0" v-model="form.empresaDeTransporte">
                <option v-for="transportista in empresasTransportista" :value="transportista.id" :key="transportista.id">
                  {{transportista.razonSocial }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="numeroInterno"><strong>Numero Interno</strong> </label>
              <input class="form-control" type="text" v-model="form.numeroInterno" />
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" id="modelo"><strong>Modelo*</strong></label>
              <input class="form-control" type="text" v-model="form.modelo" />
<!--              <div class="d-flex align-items-center">
                <select class="form-control" v-if="equipoModelo.length > 0" v-model="form.modelo">
                  <option v-for="modelo in equipoModelo" :value="modelo.id" :key="modelo.id">
                    {{ modelo.nombre }}
                  </option>
                </select>
&lt;!&ndash;                <button class="btn btn-primary btn-sm ms-2" @click="toggleModal('crear-modelo-modal')">+</button>&ndash;&gt;
              </div>-->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="numeroChasis"><strong>Numero de Chasis</strong> </label>
              <input class="form-control" type="text" v-model="form.numeroChasis" />
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" id="tipoEquipamiento"><strong>Tipo Equipamiento*</strong> </label>
              <select class="form-control" v-if="equipoTipoEquipamiento.length > 0" v-model="form.tipoEquipamiento">
                <option v-for="tipoEquipamiento in equipoTipoEquipamiento" :value="tipoEquipamiento.id" :key="tipoEquipamiento.id">
                  {{tipoEquipamiento.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div >
                <label class="form-label" id="kilometraje"><strong>Kilometraje</strong> </label>
                <input class="form-control" type="number" v-model="form.kilometraje" />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" id="fabricante"><strong>Fabricante</strong> </label>
              <select class="form-control" v-if="empresasFabricantes.length > 0" v-model="form.fabricante">
                <option v-for="fabricantes in empresasFabricantes" :value="fabricantes.id" :key="fabricantes.id">
                  {{fabricantes.razonSocial }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="anoFabricacion"><strong>Año Fabricacion</strong> </label>
              <input class="form-control" type="text" v-model="form.anoFabricacion" />
            </div>
              <div class="col-md-6 mb-3">
                <label class="form-label" id="faena"><strong>Faena</strong> </label>
                <select class="form-control" v-if="equipoFaenas.length > 0" v-model="form.faena">
                  <option v-for="faena in equipoFaenas" :value="faena.id" :key="faena.id">
                    {{faena.nombre }}
                  </option>
                </select>
              </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="tipoEquipo" class="form-label"><strong>Tipo Equipo</strong></label>
              <select class="form-control" id="tipoEquipo" v-model="form.tipoEquipo">
                <option v-for="tipo in tipoEquipos" :value="tipo.id" :key="tipo.id">{{ tipo.nombre }}</option>
              </select>
            </div>
            <div class="col-md-6 mb-3">
            </div>
          </div>
          <form class="form-horizontal form-bordered">
            <div class="row justify-content-center">
              <div class="col-md-4 mb-3 text-center">
                <label class="form-check-label" for="documentacionAlDia">
                  Documentación al Día*
                  <input class="form-check-input" type="checkbox" id="documentacionAlDia" v-model="form.documentacionAlDia">
                </label>
              </div>
              <div class="col-md-4 mb-3 text-center">
                <label class="form-check-label" for="activo">
                  Activo*
                  <input class="form-check-input" type="checkbox" id="activo" v-model="form.activo">
                </label>
              </div>
            </div>
          </form>
          <modal name="crear-modelo-modal" height="auto">
            <div class="modal-container">
              <h2>Crear Modelo</h2>
              <div class="col-md-6 mb-3">
                <label for="marca" class="form-label"><strong>Marca</strong></label>
                <div class="d-flex align-items-center">
                  <select class="form-control" id="marca" v-model="form.marca">
                    <option v-for="marca in marcas" :value="marca.id" :key="marca.id">{{ marca.nombre }}</option>
                  </select>
                  <button class="btn btn-primary btn-sm ms-2" @click="toggleModal('crear-marca-modal')">+</button>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="marca" class="form-label"><strong>Tipo Vehículo</strong></label>
                <div class="d-flex align-items-center">
                  <select class="form-control" id="tipoVehiculo" v-model="form.tipoVehiculo">
                    <option v-for="tipoVehiculo in tipoVehiculos" :value="tipoVehiculo.id" :key="tipoVehiculo.id">{{ tipoVehiculo.nombre }}</option>
                  </select>
                  <button class="btn btn-primary btn-sm ms-2" @click="toggleModal('crear-tipoVehiculo-modal')">+</button>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label" id="modeloCreado"><strong>Modelo</strong> </label>
                <input class="form-control mb-3" type="text" v-model="form.modeloCreado" />
              </div>
              <div class="modal-footer justify-content-between"> <!-- Añade una clase para flexbox -->
                <button type="button" class="btn btn-secondary" @click="toggleModal('crear-modelo-modal')">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="crearModelo()">Crear</button>
              </div>
            </div>
          </modal>
          <modal name="crear-marca-modal" height="auto">
            <div class="modal-container">
              <h2>Crear Marca</h2>
              <div class="col-md-6 mb-3 mt-3">
                <label class="form-label" id="marcaCreada"><strong>Marca</strong> </label>
                <input class="form-control mb-3" type="text" v-model="form.marcaCreada" />
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" @click="toggleModal('crear-marca-modal')">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="crearMarca()">Crear</button>
              </div>
            </div>
          </modal>
          <modal name="crear-tipoVehiculo-modal" height="auto">
            <div class="modal-container">
              <h2>Crear Tipo Vehiculo</h2>
              <div class="col-md-6 mb-3 mt-3">
                <label class="form-label" id="tipoVehiculo"><strong>Tipo Vehiculo</strong> </label>
                <input class="form-control mb-3" type="text" v-model="form.tipoVehiculoCreado" />
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" @click="toggleModal('crear-tipoVehiculo-modal')">Cerrar</button>
                <button type="button" class="btn btn-primary" @click="crearTipoVehiculo()">Crear</button>
              </div>
            </div>
          </modal>
          <button type="button" class="btn btn-primary" @click="crearEquipo()">Crear</button>
        </form>
      </div>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from 'vuejs-datepicker/dist/locale'
export default {
  components:{
    Datepicker
  },
  data(){
    return{
      es:es,
      mondayFirst:true,
      empresasTransportista:[],
      empresasPropietarias:[],
      empresasFabricantes:[],
      equipoFaenas:[],
      equipoModelo:[],
      equipoTipoEquipamiento:[],
      tipoVehiculos:[],
      marcas:[],
      tipoEquipos:[],
      form:{
        "patente":"",
        "kilometraje":"",
        "vencimientoRevisionTecnica": "",
        "numeroChasis": "",
        "numeroInterno": "",
        "anoFabricacion": "",
        "faena": "",
        "empresaDeTransporte": "",
        "propietarioDelEquipo": "",
        "fabricante": "",
        "modelo": "",
        "tipoEquipamiento": "",
        documentacionAlDia:false,
        activo:false,
        modeloCreado:'',
        marcaCreada:'',
        tipoVehiculoCreado:'',
        tipoVehiculo:'',
        marca:'',
        tipoEquipo:'',
      },
      showDataEquipo:{
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },

    }
  },
  created: function(){
    this.loadInitialData()
    this.$validarLogin()
    this.listarPropietarios()
    this.listarTransportistas()
  },
  async mounted(){
    try {
      // this.empresasTransportista = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaTransportista');
      // this.empresasPropietarias = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaPropietaria');
      this.empresasFabricantes = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaFabricante');
      this.equipoFaenas = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/faenaList');
      this.equipoModelo = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/modeloList');
      this.equipoTipoEquipamiento = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/tipoEquipamientoList');
    } catch (error) {
      console.error(error);
      // Manejar errores
    }
  },

  methods:{
    validateInput(event) {
      const input = event.target.value;
      // Elimina todos los caracteres que no sean letras o números
      const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
      // Actualiza el modelo de datos
      this.form.patente = cleanedInput;
    },
    async listarPropietarios() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaPropietaria?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.empresasPropietarias = response.data
      } catch (error) {
        console.error("Error al cargar propietarios:", error);
        return {};
      }
    },
    async listarTransportistas() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaTransportista?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.empresasTransportista = response.data
      } catch (error) {
        console.error("Error al cargar transportistas:", error);
        return {};
      }
    },
    async getTipoEquipos() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/tipoEquipoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.tipoEquipos = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async loadInitialData() {
      await this.getMarcas();
      await this.getTipoVehiculos();
      await this.getTipoEquipos();
    },
    async getMarcas() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/marcaList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.marcas = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async getTipoVehiculos() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/tipoVehiculos`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.tipoVehiculos = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async crearModelo() {
      this.loadingSpiner();
      if (!this.form.marca || !this.form.modeloCreado || !this.form.tipoVehiculo) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Todos los campos deben estar rellenados!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = `${process.env.VUE_APP_IP_BACKEND}/vehiculo/crearModeloEquipo/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const dataParaEnviar = {
        marca: this.form.marca,
        modeloCreado: this.form.modeloCreado,
        tipoVehiculo: this.form.tipoVehiculo,
      };

      try {
        let response = await axios.post(api, dataParaEnviar, { headers: { "Authorization": `Bearer ${tokenHeader}` } });
        if (response.status == 200 || response.status == 201) {
          this.loader.hide();
          this.$modal.hide('crear-modelo-modal'); // Cerrar el modal
          this.showToastSuccess('Modelo creado exitosamente!', 'Genial!', this.notificationSystem.options.success);
          setTimeout(() => location.reload(), 500); // Recargar la página después de 2 segundos para permitir que el mensaje de éxito sea leído por el usuario
        } else {
          this.loader.hide();
          this.showToastError('Algo salió mal!', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.loader.hide();
        this.error_msg = error;
        if (error.response && error.response.status === 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error);
          this.$expirarToken();
        } else {
          this.showToastError('Intente nuevamente más tarde!', 'UPS!', this.notificationSystem.options.error);
        }
      }
    },

    async crearMarca() {
      this.loadingSpiner()
      if (!this.form.marcaCreada) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Marca!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/crearMarca/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        marcaCreada: this.form.marcaCreada,
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.marca = response.data.marcaCreada.id
          await this.getMarcas()
          this.$modal.hide('crear-marca-modal');
          this.loader.hide()
          this.showToastSuccess('Modelo creado Exitósamente!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async crearTipoVehiculo() {
      this.loadingSpiner()
      if (!this.form.tipoVehiculoCreado) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene TipoVehiculo!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/creartipoVehiculo/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        tipoVehiculo: this.form.tipoVehiculoCreado,
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.marca = response.data.tipoVehiculo.id
          await this.getTipoVehiculos()
          this.$modal.hide('crear-tipoVehiculo-modal');
          this.loader.hide()
          this.showToastSuccess('Modelo creado Exitósamente!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    toggleModal(idModal){
      this.$modal.toggle(idModal)
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async fetchData(url) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        // Manejar errores
        return []; // Devolver un array vacío en caso de error
      }
    },


    async crearEquipo(){
      this.loadingSpiner()
      // const api = 'http://68.183.108.224:8080/ta-ms-users/user'
      if (!this.form.patente) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene la Patente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.propietarioDelEquipo) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene el Propietario!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.empresaDeTransporte) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene el Transportista!', 'UPS!', this.notificationSystem.options.error);
        return;
      }if (!this.form.modelo) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene el Modelo!', 'UPS!', this.notificationSystem.options.error);
        return;
      } if (!this.form.tipoEquipamiento) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene el Tipo de Equipamiento!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/saveEquipo'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token


      try {
        //PETICION
        let response = await axios.post(api, this.form,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.$router.push({path: '/agendamiento/listaEquipo'})
          this.showToastSuccess('Equipo creado !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else if(this.error_msg.request.status == 400) {
        let mensajeError = this.error_msg.response.data.mensajeError
        console.log(mensajeError)
        this.$showToastError(mensajeError, 'UPS!', this.$notificationSystem().options.error)
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  }
}

</script>

<style scoped>
.datepicker input {
  background-color: #ffffff; /* Color de fondo */
  border: 1px solid #cecece; /* Borde */
  border-radius: 4px; /* Redondeo de bordes */
  font-size: 0.75rem; /* Tamaño de fuente */
  padding: 0.4375rem 0.75rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}
.datepicker input:focus{
  border-color: #495057; /* Color del borde cuando está enfocado */
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255); /* Sombra exterior opcional para resaltar el foco */
  outline: none;
}
</style>
