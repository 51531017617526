<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Calendario</a></li>
      <li class="breadcrumb-item active">Agendamiento</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Agendamiento</h1>
    <!-- END page-header -->
    <hr />

    <!-- BEGIN calendar -->
    <FullCalendar :options="calendarOptions"></FullCalendar>
    <!-- END calendar -->
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import esLocale from '@fullcalendar/core/locales/es';
import axios from "axios";

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      // events: [],
      estadosInspeccion: [],
      calendarOptions: {
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, bootstrapPlugin ],
        locale: esLocale,
        headerToolbar: {
          left: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: 'title',
          right: 'prev,next today'
        },
        eventTimeFormat: { // especifica el formato de hora de los eventos
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // Puedes cambiar a true si prefieres formato de 12 horas
        },
        buttonText: {
          today:    'Hoy',
          month:    'Mes',
          week:     'Semana',
          day:      'Día'
        },
        initialView: 'dayGridMonth',
        editable: true,
        droppable: true,
        themeSystem: 'bootstrap',
        views: {
          timeGrid: {
          }
        },
        eventClick: this.manejarClicEnEvento,
      },
    }
  },
  methods: {
    async obtenerInspecciones() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const username = obtenerToken.username
      const api =`${process.env.VUE_APP_IP_BACKEND}/inspeccion/listaInspeccionProgramadas?username=${username}`
      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201) {
          this.actualizarEventosDelCalendario(response.data);
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async  obtenerEstadosInspeccion() {
      const api = process.env.VUE_APP_IP_BACKEND + '/formulario/estadoInspeccionList'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201) {
          this.estadosInspeccion = response.data;
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    obtenerColorPorEstado(idEstado) {
      const estado = this.estadosInspeccion.find(estado => estado.id === idEstado);
      if (estado && estado.color === 'lightred') {
        return 'rgba(210, 145, 178, 1)';
      }
      return estado.color
    },
    actualizarEventosDelCalendario(inspecciones) {
      const eventos = inspecciones.map(inspeccion => {
        let color;
        let textColor = '#ffffff'; // Color de texto predeterminado (blanco)

        if (inspeccion.selloNombre === "Sello Amarillo") {
          color = 'yellow'; // Asigna el color amarillo directamente
          textColor = '#000000'; // Cambia el color de texto a negro para mejor contraste
        } else {
          color = this.obtenerColorPorEstado(inspeccion.estadoId); // Asignación de color basada en el estado
        }
        const fechaInicio = inspeccion.fechaDeInspeccionSolicitada;
        const titulo = `${inspeccion.solicitante} (${inspeccion.equipo}) ${inspeccion.id}`;
        return {
          title: titulo,
          start: fechaInicio,
          display: 'block',
          // allDay: true,
          backgroundColor: color,
          borderColor: color,
          textColor: textColor,
          extendedProps: {
            idInspeccion: inspeccion.id,
          }
        };
      });
      this.$set(this.calendarOptions, 'events', eventos);
    },
    manejarClicEnEvento(clickInfo) {
      const idInspeccion = clickInfo.event.extendedProps.idInspeccion;
      this.$router.push({ path: `/agendamiento/showInspeccionProgramada/${idInspeccion}`, params: { id: idInspeccion } });
    },
  },
  mounted() {
    this.obtenerEstadosInspeccion().then(() => {
      this.obtenerInspecciones();
    });
  }
}
</script>