<template>
  <div class="row">
    <div class="col-sm-2">
      <img :src="urlImagenKingpin" alt="Diagrama Kingpin">
    </div>
    <div class="col-sm-10">
      <table class="table table-striped text-center d-none d-sm-block">
        <thead>
        <tr>
          <th></th>
          <th>MÁXIMO<br>(mm)</th>
          <th>MÍNIMO<br>(mm)</th>
          <th>MEDIA<br>(mm)</th>
          <th>VALOR<br>MEDIDO<br>(mm)</th>
          <th>VALOR INSPECCIÓN<br>ANTERIOR (mm)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{itemKingpin.texto}}</td>
          <td>50,80 mm</td>
          <td>49,00 mm</td>
          <td>49,99 mm</td>
          <td>
            <input type="number" class="form-control" v-model="itemKingpin.valor" :disabled="readOnly">
          </td>
          <td>
            <input type="number" class="form-control" v-model="itemKingpinInspAnterior.valor" :disabled="readOnly">
          </td>
        </tr>
        </tbody>
      </table>
      <!--          Tabla vertical, solo se muestra en tamaño xs-->
      <table class="table table-striped text-center d-block d-sm-none">
        <tbody>
        <tr>
          <th></th>
          <td>{{itemKingpin.texto}}</td>
        </tr>
        <tr>
          <th>MÁXIMO<br>(mm)</th>
          <td>50,80 mm</td>
        </tr>
        <tr>
          <th>MÍNIMO<br>(mm)</th>
          <td>49,00 mm</td>
        </tr>
        <tr>
          <th>MEDIA<br>(mm)</th>
          <td>49,99 mm</td>
        </tr>
        <tr>
          <th>VALOR<br>MEDIDO<br>(mm)</th>
          <td>
            <input type="number" class="form-control" v-model="itemKingpin.valor" :disabled="readOnly">
          </td>
        </tr>
        <tr>
          <th>VALOR INSPECCIÓN<br>ANTERIOR (mm)</th>
          <td>
            <input type="number" class="form-control" v-model="itemKingpinInspAnterior.valor" :disabled="readOnly">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponenteFormKingpin',
  props: {
    'itemListKingpin': {
      type: Array,
      default: function() {
        return [];
      }
    },
    'urlImagenKingpin': {
      type: String,
      default: ""
    },
    'readOnly': {
      type: Boolean,
      default: true
    },
  },
  data(){
    return{
      itemKingpin:             {},
      itemKingpinInspAnterior: {},
    }
  },
  mounted(){
    this.itemListKingpinProcesado()
  },
  methods:{
    // Por ahora es solo uno.
    itemListKingpinProcesado() {
      if(this.itemListKingpin){
        this.itemKingpin             = this.itemListKingpin.find((item) => item?.codigo.includes('king_pin_d_'))
        this.itemKingpinInspAnterior = this.itemListKingpin.find((item) => item?.codigo.includes('king_pin_insp_ant_d_'))
      }
    }
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}
input[type="checkbox"]:checked {
  background: #0060DEFF;
}
.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
.fila-verde {
  background-color: #d6ffd6; /* Fondo verde para la fila */
}
.fila-amarilla {
  background-color: #fdb31a; /* Fondo verde para la fila */
}
.fila-roja {
  background-color: #ff0a0a; /* Fondo verde para la fila */
}

</style>
