<template>
	<div class="d-flex flex-column h-100">
		<!-- BEGIN content-container -->
		<vue-custom-scrollbar class="app-content-padding flex-grow-1 overflow-hidden h-100">
			<!-- BEGIN breadcrumb -->
			<ol class="breadcrumb float-xl-end">
				<li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Page Options</a></li>
				<li class="breadcrumb-item active">Page with Footer</li>
			</ol>
			<!-- END breadcrumb -->
			<!-- BEGIN page-header -->
			<h1 class="page-header">Page with Fixed Footer <small>header small text goes here...</small></h1>
			<!-- END page-header -->
		
			<!-- BEGIN panel -->
			<panel title="Installation Settings">
				<p>
					Add the following app settings to the <code>page.vue</code> that you wish to change 
					<b>OR</b> change it from <code>/config/AppOptions.vue</code> to make it affected to the whole app.
				</p>
				<!-- BEGIN hljs-wrapper -->
				<div class="hljs-wrapper" slot="outsideBody" v-highlight>
					<pre><code class="html">&lt;script&gt;
import AppOptions from '../config/AppOptions.vue'

export default {
  created() {
    AppOptions.appContentFullHeight = true;
    AppOptions.appContentClass = 'p-0';
  },
  beforeRouteLeave (to, from, next) {
    AppOptions.appContentFullHeight = false;
    AppOptions.appContentClass = '';
    next();
  }
}
&lt;/script&gt;</code></pre>
				</div>
				<!-- END hljs-wrapper -->
			</panel>
			<!-- END panel -->
		</vue-custom-scrollbar>
		<!-- END content-container -->
			
			
		<!-- BEGIN #footer -->
		<div id="footer" class="app-footer m-0">
			&copy; 2021 Color Admin Responsive Admin Template - Sean Ngu All Rights Reserved
		</div>
		<!-- END #footer -->
	</div>
</template>


<script>
import AppOptions from '../../config/AppOptions.vue'

export default {
	created() {
		AppOptions.appContentFullHeight = true;
		AppOptions.appContentClass = 'p-0';
	},
	beforeRouteLeave (to, from, next) {
		AppOptions.appContentFullHeight = false;
		AppOptions.appContentClass = '';
		next();
	}
}
</script>