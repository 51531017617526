<template>
  <div>
    <notifications group="bottom-right" position="bottom right" :speed="500"/>
    <!-- BEGIN breadcrumb -->

    <div class="row">
      <div class="col-xl-2">
        <panel title="Sala ">
          <dl class="row">
            <dt  class="col-md-4"><strong> Sala:</strong></dt>
            <dd  class="col-md-8">{{ this.showDataSala.agenteVirtual.nameRoom }}</dd>
          </dl>
          <dl class="row">
            <dt  class="col-md-4"><strong> Motivo:</strong></dt>
            <dd  class="col-md-8">{{this.showDataSala.agenteVirtual.motivo}}</dd>
          </dl>
          <dl class="row">
            <dt  class="col-md-4"><strong> Descripción:</strong></dt>
            <dd  class="col-md-8">{{this.showDataSala.agenteVirtual.descripcion}}</dd>
          </dl>
          <dl class="row">
            <dt  class="col-md-4"><strong> Fecha:</strong></dt>
            <dd  class="col-md-8">{{ this.formatFecha }}</dd>
          </dl>
        </panel>
      </div>
      <div class="col-xl-6">
        <panel title="Temperatura / Humedadad ">
          <line-chart :data="lineChart.data" :options="lineChart.options" class="h-300px"></line-chart>
        </panel>
      </div>
      <div class="col-xl-4">
        <!-- BEGIN panel -->
        <panel title="Invitaciones" bodyClass="p-0" >

          <br>
          <div class="container" >
            <div class="align-items-center">
              <a :href="showDataSala.urlModerador" target="_blank" class="btn btn-outline-primary d-block"><i class="fa fa-video-camera fa-2x me-2 text-black"></i><span class="d-block"><b>Ingresar a sala</b></span></a>


            </div>

          </div>
          <br>
          <div class="container" >
            <div class="align-items-center">
              <a v-b-modal:modal-prevent-closing target="_blank" class="btn btn-outline-secondary d-block"><i class="fa fa-mail-forward fa-2x me-2 text-black"></i><span class="d-block"><b>Enviar correo de invitaciòn</b></span></a>

            </div>

          </div>
          <br>
          <div class="container" >


            <div class="align-items-center" v-if="habilitarBtnWsp">
              <a :href="'https://api.whatsapp.com/send?text='+' Has sido invitado a una sesión de teleasesoría '+ ' '+this.linksGoogle" target="_blank" class="btn btn-outline-success d-block"><i class="fa fa-mail-forward fa-2x me-2 text-black"></i><span class="d-block"><b>Enviar WhatsApp</b></span></a>

            </div>
          </div>
          <br>
          <div class="container" >
            <div class="align-items-center">
              <a  v-clipboard:copy="message"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError" target="_blank" class="btn btn-outline-secondary d-block"><i class="fa fa-clipboard fa-2x me-2 text-black"></i><span class="d-block"><b>Copiar a portapapeles</b></span></a>


            </div>
          </div>
          <br>


        </panel>

      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-gradient">
          <div class="stats-icon"><i class="fa fa-temperature-1"></i></div>
          <div class="stats-info">
            <h4>TEMPERATURA ACTUAL</h4>
            <p>27°</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;">Ver más <i class="fa fa-arrow-alt-circle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon"><i class="fa fa-tint"></i></div>
          <div class="stats-info">
            <h4>HUMEDAD ACTUAL</h4>
            <p>30%</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;">Ver más <i class="fa fa-arrow-alt-circle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-yellow">
          <div class="stats-icon"><i class="fa fa-sun"></i></div>
          <div class="stats-info">
            <h4 style="color: black">INDICE UV</h4>
            <p style="color: black">MODERADO 3</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;">Ver más <i class="fa fa-arrow-alt-circle-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="widget widget-stats bg-">
          <div class="stats-icon"><i class="fa fa-wind"></i></div>
          <div class="stats-info">
            <h4>RAFAGAS DE VIENTO</h4>
            <p>16 KM/H</p>
          </div>
          <div class="stats-link">
            <a href="javascript:;">Ver más <i class="fa fa-arrow-alt-circle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-prevent-closing" ref="modal" title="Ingrese destinatarios" @show="resetModal"
             @hidden="resetModal" @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Correos"
            label-for="name-input"
            invalid-feedback="Ingrese correos separados por ',' Ejemplo:correo@correo.com,correo2@correo.com"
            :state="nameState"
        >
          <b-form-input
              id="name-input"
              v-model="destinatarios"
              :state="nameState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>



  </div>
</template>
<script>
import LineChart from '../components/vue-chartjs/LineChart'
import AppOptions from '../config/AppOptions.vue'
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';

export default {
  components: {
    LineChart,
  },
  data() {
    // eslint-disable-next-line
    Chart.defaults.global.defaultColor = 'rgba(' + window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim() + ', .65)';
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontFamily = window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim();
    // eslint-disable-next-line
    Chart.defaults.global.defaultFontStyle = 600;
    // eslint-disable-next-line
    Chart.defaults.scale.gridLines.color = 'rgba(' + window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim() + ', .15)';
    // eslint-disable-next-line
    Chart.defaults.scale.gridLines.zeroLineColor = 'rgba(' + window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim() + ', 0)';


    let today = new Date()

    var eventsData = [{
      date: `${today.getFullYear()}/${today.getMonth() + 1}/15`,
      title: 'Sales Reporting',
      badge: 'bg-gradient-teal',
      time: '9:00am'
    }, {
      date: `${today.getFullYear()}/${today.getMonth() + 1}/24`,
      title: 'Have a meeting with sales team',
      badge: 'bg-gradient-blue',
      time: '2:45pm'
    }]

    return {
      nameState: null,
      form: {
        "para": "",
        "link":"",
        "tipo": 1,
        "cc":"",
        "bcc":""
      },
      habilitarBtnWsp:false,
      linksGoogle:"",
      linksParaGoogle:{
        "dynamicLinkInfo":{
          "domainUriPrefix": "https://teleasesorias.page.link",
          "link":""
        },
      },
      destinatarios:"",
      showDataSala:[],
      urlinvitados:[],
      lineChart: {
        data: {
          labels: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
          datasets: [{
            label: 'Humedad %',
            backgroundColor: 'rgba(52, 143, 226, 0.2)',
            borderColor: AppOptions.color.blue,
            pointBackgroundColor: AppOptions.color.blue,
            pointRadius: 2,
            borderWidth: 2,
            data: [40, 60, 80, 95, 70, 40, 28]
          }, {
            label: 'Temperatura °C',
            backgroundColor: 'rgba(45, 53, 60, 0.2)',
            borderColor: AppOptions.color.red,
            pointBackgroundColor: AppOptions.color.red,
            pointRadius: 2,
            borderWidth: 2,
            data: [35, 32, 25, 12, 22, 27, 28]
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: 'nearest',
            intersect: true
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                max: 100
              }
            }]
          }
        }
      },
      events: {
        data: eventsData,
        displayData: eventsData
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
      message: ''

    }
  },
  created: function () {
    this.$validarLogin()
    this.validarExisteSala()
  },
  mounted() {
    this.verDatosSala()
    this.crearLinkCorto()
  },
  methods: {
    verDatosSala(){
      this.showDataSala = JSON.parse(localStorage.getItem('showSala'));
      moment.locale('es');
      const fecha = (moment(this.showDataSala.agenteVirtual.dateCreated).format('dddd D MMMM yyyy'));
      this.formatFecha = fecha.toUpperCase()
      this.message = 'Has sido invitado a una sesión de teleasesoría'+ ' '+this.showDataSala.urlInvitado
    },

    crearLinkCorto(){
      const api = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBkDm1ApdCPbQHifcEgGH4ohjYhnueCpoM";
      this.linksParaGoogle.dynamicLinkInfo.link = this.showDataSala.urlInvitado
      axios.post(api, this.linksParaGoogle).then(response => {
        if(response.status == 200){
          this.linksGoogle = response.data.shortLink
          this.habilitarBtnWsp = true
        }
      })
    },
    enviarDestinatarios() {
      const api = process.env.VUE_APP_IP_BACKEND + "/ms-notificaciones/ms-notificaciones/enviarNotificacion";
      this.form.link = this.showDataSala.urlInvitado
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}}).then(response => {
        if(response.data.status == 'ok'){
          this.showToastSuccess('Invitaciones enviadas!', 'Genial!', this.notificationSystem.options.success)
        }
      }).catch((reason) => {
        this.error_msg = reason
        this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        if (this.error_msg.request.status == 401) {
          this.$expirarToken()
        }
      })
    },
    onCopy: function () {
      this.showToastSuccess('Copiado!', 'Genial!', this.notificationSystem.options.success)
    },
    onError: function () {
      this.showToastError('No se pudo copiar !', 'UPS!', this.notificationSystem.options.error)
    },

    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    validarExisteSala() {
      const existe = JSON.parse(localStorage.getItem('showSala'));
      if (existe == null) {
        this.$router.push({path: '/crearSala'})
      }
    },
    showAlert(group, type, title, text) {
      this.$notify({
        group,
        title,
        text,
        type,
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.destinatarios = ''
      this.nameState = null
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      console.log(this.destinatarios)
      this.form.para = this.destinatarios
      this.enviarDestinatarios(),

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    }
  }
}
</script>
