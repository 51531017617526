<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Formulario</a></li>
      <li class="breadcrumb-item active">Kingpin</li>
    </ol>
    <h1 class="page-header">Inspección Kingpin</h1>
    <panel title="">
      <div class="row">
        <div class="col-sm-2">
          <img :src="fotoKingpin" alt="Diagrama Kingpin">
        </div>
        <div class="col-sm-10">
<!--          Tabla horizontal,se muestra en tamaño mayor a xs-->
          <table class="table table-striped text-center d-none d-sm-block">
            <thead>
            <tr>
              <th></th>
              <th>MÁXIMO<br>(mm)</th>
              <th>MÍNIMO<br>(mm)</th>
              <th>MEDIA<br>(mm)</th>
              <th>VALOR<br>MEDIDO<br>(mm)</th>
              <th>VALOR INSPECCIÓN<br>ANTERIOR (mm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{itemKingpin.texto}}</td>
              <td>50,80 mm</td>
              <td>49,00 mm</td>
              <td>49,99 mm</td>
              <td>
                <input type="number" class="form-control" v-model="itemKingpin.valor">
              </td>
              <td>
                <input type="number" class="form-control" v-model="itemKingpinInspAnterior.valor">
              </td>
            </tr>
            </tbody>
          </table>
<!--          Tabla vertical, solo se muestra en tamaño xs-->
          <table class="table table-striped text-center d-block d-sm-none">
            <tbody>
            <tr>
              <th></th>
              <td>{{itemKingpin.texto}}</td>
            </tr>
            <tr>
              <th>MÁXIMO<br>(mm)</th>
              <td>50,80 mm</td>
            </tr>
            <tr>
              <th>MÍNIMO<br>(mm)</th>
              <td>49,00 mm</td>
            </tr>
            <tr>
              <th>MEDIA<br>(mm)</th>
              <td>49,99 mm</td>
            </tr>
            <tr>
              <th>VALOR<br>MEDIDO<br>(mm)</th>
              <td>
                <input type="number" class="form-control" v-model="itemKingpin.valor">
              </td>
            </tr>
            <tr>
              <th>VALOR INSPECCIÓN<br>ANTERIOR (mm)</th>
              <td>
                <input type="number" class="form-control" v-model="itemKingpinInspAnterior.valor">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button type="button" class="btn btn-secondary m-3" v-on:click="volver()"><i class="fa fa-caret-left"></i> Volver</button>
      <button type="button" class="btn btn-primary m-3" v-on:click="guardarYContinuar()">Guardar y continuar <i class="fa fa-caret-right"></i><i class="fa fa-caret-right"></i></button>
    </panel>
  </div>
</template>
<script>

export default {
  name: "FormularioChecklist",
  data() {
    return {
      nombreFormulario: '',
      itemKingpin: {},
      itemKingpinInspAnterior: {},
      fotoKingpin: '',
    }
  },
  methods: {
    async volver(){
      this.$irVistaInspeccionProgramada(this.inspeccionId, 'kingpin', true)
    },

    async guardarYContinuar() {
      let itemListEnvio = []
      itemListEnvio.push(this.itemKingpin)
      itemListEnvio.push(this.itemKingpinInspAnterior)
      let exito = await this.$guardarItemsInspeccionProgramada(itemListEnvio,this.inspeccionId, 'kingpin')
      if(exito){
        this.$irVistaInspeccionProgramada(this.inspeccionId, 'kingpin', false)
      }
    },

    async getItemKingpin(inspeccionId) {
      let data = await this.$itemListMasInfoFormularioInspProgramada(inspeccionId, 'kingpin')
      if(data){
        this.itemKingpin             = data.itemList.find((item) => item?.codigo.includes('king_pin_d_'))
        this.itemKingpinInspAnterior = data.itemList.find((item) => item?.codigo.includes('king_pin_insp_ant_d_'))

        this.fotoKingpin = data.urlImagen
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.inspeccionId = this.$route.params.inspeccionId;
    if (this.inspeccionId) {
      this.getItemKingpin(this.inspeccionId);
    }
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
@media (max-width: 768px) {
  .panel {
    overflow-x: auto; /* Permite el desplazamiento horizontal si el contenido es más ancho que el contenedor */
  }
}
</style>