<template>
  <div>

    <notifications group="bottom-right" position="bottom right" :speed="500"/>

    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Teleasesoria</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Crear</a></li>
      <li class="breadcrumb-item active">Prueba</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Crear Prueba <small></small></h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <panel title="Crea una Sala de Reunión">
      <form v-on:submit.prevent="guardar" method="POST">
        <div class="mb-3">
          <label class="form-label" id="nameRoom" ><strong>Nombre </strong> </label>
          <input class="form-control" type="text" v-model="form.nombre"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="duration"><strong>DNI</strong> </label>
          <input class="form-control" type="number" v-model="form.dni"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="nameRoom"><strong>Correo</strong> </label>
          <input class="form-control" type="email" v-model="form.correo"/>
        </div>

        <button type="button" class="btn btn-primary" v-on:click="guardar()">Crear</button>


      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";


export default {
  data(){
    return{
      form:{
        "nombre":"",
        "dni":"",
        "correo":"",
        "token":""
      }
    }
  },
  methods: {
    guardar(){
      this.form.token = localStorage.getItem("tokenApiPrueba")
      console.log(this.form);
      axios.post("https://api.solodata.es/pacientes",this.form).then(response => {
        console.log(response);
        this.$router.push({path: '/teleasesoria'})
        this.show('bottom-right','success','Exito!','Creado Correctamente')
      })
    },
    show (group, type ,title,text) {
      this.$notify({
        group,
        title,
        text,
        type,
      })
    }

  },

}

</script>

