<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Crear</a></li>
      <li class="breadcrumb-item active">Usuario</li>
    </ol>
    <h1 class="page-header">Crear Usuario <small></small></h1>
    <panel title="Formulario de creación de usuario">
      <form v-on:submit.prevent="crearUsuario" method="POST">
        <div class="mb-3 col-md-12">
          <label class="form-label" id="username"><strong>Nombre de usuario</strong> </label>
          <input class="form-control" type="text" v-model="form.username" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="password"><strong>Contraseña</strong> </label>
          <input class="form-control" type="password" v-model="form.password" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="nombre"><strong>Nombre*</strong> </label>
          <input class="form-control" type="text" v-model="form.nombre" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="apellidoPaterno"><strong>Apellido Paterno</strong> </label>
          <input class="form-control" type="text" v-model="form.apellidoPaterno" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="apellidoMaterno"><strong>Apellido Materno</strong> </label>
          <input class="form-control" type="text" v-model="form.apellidoMaterno" />
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Rut</label>
            <div>
              <masked-input pattern="11111111" mask="11111111" type="tel" v-model="form.rut" class="form-control" placeholder="00000000" ></masked-input>
            </div>
          </div>
          <div class="mb-3 col-md-2">
            <label class="form-label">DV*</label>
            <div>
              <input
                  type="text"
                  v-model="form.codigoVerificador"
                  @input="validateDV"
                  class="form-control"
                  placeholder="0-K">
            </div>
          </div>
        </div>
        <div class="datepicker" >
          <label class="form-label" for="fechaNacimiento"><strong>Fecha de Nacimiento</strong></label>
          <Datepicker  :language="es" mondayFirst v-model="form.fechaNacimiento" :format="customFormatter" ></Datepicker>
        </div>
        <div class="mb-3">
          <label class="form-label" id="sexo"><strong>Sexo</strong> </label>
          <select class="form-control" v-if="sexoList.length > 0" v-model="form.sexo">
            <option v-for="sexo in sexoList" :value="sexo.id" :key="sexo.id">
              {{sexo.sexo}}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="fono"><strong>Fono</strong> </label>
          <input class="form-control" type="number" v-model="form.fono" />
        </div><div class="mb-3 col-md-12">
          <label class="form-label" id="fonoCelular"><strong>Fono Celular</strong> </label>
          <input class="form-control" type="number" v-model="form.fonoCelular" />
        </div>
        <div class="mb-3 col-md-12">
        <label class="form-label" id="email"><strong>Correo</strong> </label>
        <input class="form-control" type="email" v-model="form.email" />
        </div>
        <div class="mb-3">
          <label class="form-label" id="cargo"><strong>Cargo*</strong> </label>
          <select class="form-control" v-if="cargoList.length > 0" v-model="form.cargo">
            <option v-for="cargo in cargoList" :value="cargo.id" :key="cargo.id">
              {{cargo.nombreCargo}}
            </option>
          </select>
        </div>
        <h5 class="mb-3">Lugares de inspección</h5>
        <div v-for="(ubicacion, index) in ubicaciones" :key="ubicacion.id" class="mb-3">
          <label class="form-check-label">
            {{ index + 1 }}. {{ ubicacion.nombre }}
            <input class="form-check-input" style="" type="checkbox" :value="ubicacion.id" v-model="form.lugaresInspeccion">
          </label>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="calleUsuario"><strong>Calle</strong> </label>
          <input class="form-control" type="text" v-model="form.calleUsuario" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="numeroUsuario"><strong>Número</strong> </label>
          <input class="form-control" type="number" v-model="form.numeroUsuario" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="departamentoUsuario"><strong>Departamento</strong> </label>
          <input class="form-control" type="text" v-model="form.departamentoUsuario" />
        </div>
        <div class="mb-3">
          <label class="form-label" id="ciudadUsuario"><strong>Ciudad</strong> </label>
          <select class="form-control" v-if="ciudadUsuarioList.length > 0" v-model="form.ciudadUsuario">
            <option v-for="ciudadUsuario in ciudadUsuarioList" :value="ciudadUsuario.id" :key="ciudadUsuario.id">
              {{ciudadUsuario.nombre }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" id="empresa"><strong>Empresa*</strong> </label>
          <select class="form-control" v-if="empresaList.length > 0" v-model="form.empresa">
            <option v-for="empresa in empresaList" :value="empresa.id" :key="empresa.id">
              {{empresa.razonSocial}}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="enabled">
            Usuario Habilitado
            <input class="form-check-input" type="checkbox" id="enabled" v-model="form.enabled">
          </label>
        </div>
        <form class="form-horizontal form-bordered">
        </form>
        <button type="button" class="btn btn-primary" v-on:click="crearUsuario()">Crear</button>
      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from 'vuejs-datepicker/dist/locale'
export default {
  components:{
    Datepicker
  },
  data(){
    return{
      formatCharacters: {
        'A': {
          validate: char => /[0-9kK]/.test(char),
          transform: char => char.toUpperCase()
        }
      },
      es:es,
      mondayFirst:true,
      sexoList:[],
      cargoList:[],
      ubicaciones:[],
      ciudadUsuarioList:[],
      empresaList:[],
      form:{
        "username":"",
        "password":"",
        "nombre": "",
        "apellidoPaterno": "",
        "apellidoMaterno": "",
        "email": "",
        "fono": "",
        "fonoCelular": "",
        "rut": '',
        "codigoVerificador": '',
        "fechaNacimiento": '',
        "sexo":null,
        "nombreCargo":null,
        "calleUsuario":"",
        "numeroUsuario":"",
        "departamentoUsuario":"",
        "ciudadUsuario":null,
        "empresa":null,
        enabled: false,
        lugaresInspeccion: [],
        cargo: [],
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },

    }
  },
  created: function(){
    this.$validarLogin()
  },
  async mounted(){
    try {
      this.sexoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/sexoList');
      this.cargoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/cargoList');
      this.ubicaciones = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacion');
      this.ciudadUsuarioList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/ciudad/ciudadList');
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const username = obtenerToken.username;
      this.empresaList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/listaEmpresa', { username: username });
    } catch (error) {
      console.error(error);
      // Manejar errores
    }
  },

  methods:{
    validateDV(event) {
      const input = event.target.value.toUpperCase();
      const validChars = /^[0-9K]$/;

      if (input.length > 0 && !validChars.test(input)) {
        event.target.value = input.slice(0, -1);
      } else {
        this.form.codigoVerificador = input;
      }
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async fetchData(url, params = {}) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      // Construir la URL con parámetros
      const query = new URLSearchParams(params).toString();
      const finalUrl = `${url}?${query}`;

      try {
        const response = await axios.get(finalUrl, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return []; // Devolver un array vacío en caso de error
      }
    },


    async crearUsuario(){
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      if (!this.form.nombre) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene el Nombre!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.cargo) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Seleccione un Cargo!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.empresa) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Seleccione una Empresa!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      // }if (!this.form.rut) {
      //   this.loader.hide(); // Detener el spinner
      //   this.showToastError('Ingrese un Rut!', 'UPS!', this.notificationSystem.options.error);
      //   return;
      // }if (!this.form.codigoVerificador) {
      //   this.loader.hide(); // Detener el spinner
      //   this.showToastError('Ingrese el Codigo Verificador!', 'UPS!', this.notificationSystem.options.error);
      //   return;
      // }
      const api = process.env.VUE_APP_IP_BACKEND + '/user/saveUser'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token


      try {
        //PETICION
        let response = await axios.post(api, this.form,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.$router.push({path: '/show/user/'+ response.data.id})
          this.showToastSuccess('Usuario creado !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },

  }
}

</script>

<style>
.datepicker input {
  background-color: #ffffff; /* Color de fondo */
  border: 1px solid #cecece; /* Borde */
  border-radius: 4px; /* Redondeo de bordes */
  font-size: 0.75rem; /* Tamaño de fuente */
  padding: 0.4375rem 0.75rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}
.datepicker input:focus{
  border-color: #495057; /* Color del borde cuando está enfocado */
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255); /* Sombra exterior opcional para resaltar el foco */
  outline: none;
}
.datepicker /deep/ .vdp-datepicker__calendar {
  top: auto; /* Resetea cualquier valor previo */
  bottom: 100%;
}
@media (max-width: 700px) {
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -10%; /* Centra horizontalmente */
  }
}
</style>
