<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">CheckList</a></li>
      <li class="breadcrumb-item active">Crear</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Crear CheckList <small></small></h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <panel>
      <div class="nav-wizards-container">
        <nav class="nav nav-wizards-3 mb-2">
          <div class="nav-item col">
            <a class="nav-link active" id="navPaso1">
              <div class="nav-dot"></div>
              <div class="nav-text">Inicio</div>
            </a>
          </div>
          <div class="nav-item col">
            <a class="nav-link disabled" id="navPaso2">
              <div class="nav-dot"></div>
              <div class="nav-text">Equipo</div>
            </a>
          </div>
          <div class="nav-item col">
            <a class="nav-link disabled" id="navPaso3">
              <div class="nav-dot"></div>
              <div class="nav-text">Tracto</div>
            </a>
          </div>
          <div class="nav-item col">
            <a class="nav-link disabled" id="navPaso4">
              <div class="nav-dot"></div>
              <div class="nav-text">Conductor</div>
            </a>
          </div>
          <div class="nav-item col">
            <a class="nav-link disabled" id="navPaso5">
              <div class="nav-dot"></div>
              <div class="nav-text">Carga</div>
            </a>
          </div>
        </nav>
      </div>
<!--  INICIO    -->
      <b-collapse v-model="paso1">
        <h3 class="mb-3">Iniciar Inspección</h3>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="solicitante"><strong>Solicitante*</strong></label>
            <select class="form-control" v-if="usuarioActual" v-model="form.solicitante">
              <option :value="usuarioActual.idUsuario">
                {{ usuarioActual.nombre + ' ' + (usuarioActual.apellido ? usuarioActual.apellido :"")}}
              </option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="transportista"><strong>Transportista*</strong> </label>
            <select class="form-control" v-if="empresasTipoPorEmpresa.length > 0" v-model="form.transportista">
              <option v-for="transportista in empresasTipoPorEmpresa" :value="transportista.id" :key="transportista.id">
                {{transportista.razonSocial }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="tipo"><strong>Tipo*</strong> </label>
            <select class="form-control" v-model="form.tipo">
              <option v-for="tipo in tipoInspeccion" :value="tipo.id" :key="tipo.id" :style="{textTransform: 'uppercase'}">
                {{ tipo.tipoInspeccion }}
              </option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="lugar"><strong>Lugar*</strong> </label>
            <select class="form-control" v-model="form.lugar">
              <option v-for="lugar in ubicaciones" :value="lugar.id" :key="lugar.id">
                {{ lugar.nombre }}
              </option>
            </select>
          </div>
        </div>
        <button type="button" class="btn btn-primary" v-on:click="irPaso2()">Continuar</button>
      </b-collapse>

      <!--  EQUIPO    -->
      <b-collapse v-model="paso2">
        <h3 class="mt-4 mb-4">Identificación de Equipo</h3>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="equipo"><strong>Equipo*</strong> </label>
            <v-select
                :options="opcionesEquipo"
                label="patente"
                v-model="form.equipo"
                @input="rellenarInfoEquipo"
                >
              <template #no-options>
                Lo sentimos, no hay opciones disponibles.
              </template>
            </v-select>
          </div>
          <div class="col-md-6 mb-3" v-if="form.equipo === 'nuevo'">
            <label class="form-label" id="patente"><strong>Patente/Número*</strong> </label>
            <input class="form-control" type="text" v-model="form.patente" @input="validateInput"/>
          </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="kilometrajeUltimaMantencionEquipo"><strong>Kilometraje Última Mantención*</strong> </label>
              <input class="form-control" type="number" v-model="form.kilometrajeUltimaMantencionEquipo" value="0"/>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label" id="kilometrajeProximaMantencionEquipo"><strong>Kilometraje Próxima Mantención*</strong> </label>
              <input class="form-control" type="number" v-model="form.kilometrajeProximaMantencionEquipo" value="0"/>
            </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="datepicker" >
              <label class="form-label" for="fechaVencimientoPermisoCirculacionEquipo"><strong>Fecha Vencimiento Permiso Circulación*</strong></label>
              <Datepicker  :language="es" mondayFirst v-model="form.fechaVencimientoPermisoCirculacionEquipo" :format="customFormatter" value="hoy"></Datepicker>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="modelo"><strong>Modelo*</strong> </label>
            <input class="form-control" type="text" v-model="form.modelo"/>
<!--            <select class="form-control" v-if="equipoModelo.length > 0" v-model="form.modelo">
              <option v-for="modelo in equipoModelo" :value="modelo.id" :key="modelo.id">
                {{modelo.nombre }}
              </option>
            </select>-->
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-4 mb-3 text-center">
            <label class="form-check-label" for="permisoCirculacion">
              Permiso Circulación*
              <input class="form-check-input" type="checkbox" id="permisoCirculacion" v-model="form.permisoCirculacion">
            </label>
          </div>
          <div class="col-md-4 mb-3 text-center">
            <label class="form-check-label" for="seguroObligatorio">
              Seguro Obligatorio*
              <input class="form-check-input" type="checkbox" id="seguroObligatorio" v-model="form.seguroObligatorio">
            </label>
          </div>
          <div class="col-md-4 mb-3 text-center">
            <label class="form-check-label" for="revisionTecnica">
              Revisión Técnica al Día*
              <input class="form-check-input" type="checkbox" id="revisionTecnica" v-model="form.revisionTecnica">
            </label>
          </div>
        </div>
        <button  style="margin-right: 10px" type="button" class="btn btn-secondary" v-on:click="volverPaso1()">Volver</button>
        <button type="button" class="btn btn-primary" v-on:click="irPaso3()">Continuar</button>
      </b-collapse>
      <!--  TRACTO    -->
      <b-collapse v-model="paso3">
        <h3 class="mt-5 mb-3">Identificación de Tracto</h3>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="tracto"><strong>Tracto*</strong> </label>
            <v-select
                :options="opcionesTracto"
                label="patente"
                v-model="form.tracto"
                @input="rellenarInfoTracto"
            >
              <template #no-options>
                Lo sentimos, no hay opciones disponibles.
              </template>
            </v-select>
          </div>
          <div class="col-md-6 mb-3" v-if="form.tracto === 'nuevo'">
            <label class="form-label" id="patenteTracto"><strong>Patente/Número*</strong> </label>
            <input class="form-control" type="text" v-model="form.patenteTracto" @input="validateInputTracto"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="kilometrajeUltimaMantencionTracto"><strong>Kilometraje Última Mantención*</strong> </label>
            <input class="form-control" type="number" v-model="form.kilometrajeUltimaMantencionTracto"/>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="kilometrajeProximaMantencionTracto"><strong>Kilometraje Próxima Mantención*</strong> </label>
            <input class="form-control" type="number" v-model="form.kilometrajeProximaMantencionTracto"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="hubodometro"><strong>Hubodómetro*</strong> </label>
            <input class="form-control" type="number" v-model="form.hubodometro"/>
          </div>
          <div class="col-md-6 mb-3">
            <div class="datepicker" >
              <label class="form-label" for="fechaVencimientoPermisoCirculacionTracto"><strong>Fecha Vencimiento Permiso Circulación*</strong></label>
              <Datepicker  :language="es" mondayFirst v-model="form.fechaVencimientoPermisoCirculacionTracto" :format="customFormatter" ></Datepicker>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-4 mb-3 text-center">
            <label class="form-check-label" for="permisoCirculacionTracto">
              Permiso Circulación*
              <input class="form-check-input" type="checkbox" id="permisoCirculacionTracto" v-model="form.permisoCirculacionTracto">
            </label>
          </div>
          <div class="col-md-4 mb-3 text-center">
            <label class="form-check-label" for="seguroObligatorioTracto">
              Seguro Obligatorio*
              <input class="form-check-input" type="checkbox" id="seguroObligatorioTracto" v-model="form.seguroObligatorioTracto">
            </label>
          </div>
          <div class="col-md-4 mb-3 text-center">
            <label class="form-check-label" for="revisionTecnicaTracto">
              Revisión Técnica al Día*
              <input class="form-check-input" type="checkbox" id="revisionTecnicaTracto" v-model="form.revisionTecnicaTracto">
            </label>
          </div>
        </div>
        <button style="margin-right: 10px" type="button" class="btn btn-secondary" v-on:click="volverPaso2()">Volver</button>
        <button type="button" class="btn btn-primary" v-on:click="irPaso4()">Continuar</button>
      </b-collapse>
      <!--  CONDUCTOR    -->
      <b-collapse v-model="paso4">
        <h3 class="mt-5 mb-3">Identificación del Conductor</h3>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="conductor"><strong>Conductor*</strong> </label>
            <v-select
                :options="conductorList.map(conductor => ({label: formatConductorName(conductor),value: conductor.id}))"
                label="label"
                v-model="form.conductor"
                >
              <template #no-options>
                Lo sentimos, no hay opciones disponibles.
              </template>
            </v-select>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="sexo"><strong>Sexo*</strong> </label>
            <select class="form-control" v-if="sexoList.length > 0" v-model="form.sexo">
              <option v-for="sexo in sexoList" :value="sexo.id" :key="sexo.id">
                {{sexo.sexo}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Rut*</label>
            <div>
              <masked-input mask="11111111" pattern="11111111" type="tel"  v-model="form.rut" class="form-control" placeholder="00000000" ></masked-input>
            </div>
          </div>
          <div class="mb-3 col-md-2">
            <label class="form-label">DV*</label>
            <div>
              <input
                  type="text"
                  v-model="form.codigoVerificador"
                  @input="validateDV"
                  class="form-control"
                  placeholder="0-K">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label" id="nombre"><strong>Nombre*</strong> </label>
            <input class="form-control" type="text" v-model="form.nombre" />
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label" id="apellidoPaterno"><strong>Apellido Paterno*</strong> </label>
            <input class="form-control" type="text" v-model="form.apellidoPaterno" />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label" id="apellidoMaterno"><strong>Apellido Materno*</strong> </label>
            <input class="form-control" type="text" v-model="form.apellidoMaterno" />
          </div>
          <div class="mb-3 col-md-6">
            <div class="datepicker" >
              <label class="form-label" for="vigenciaLicencia"><strong>Licencia vigente hasta*</strong></label>
              <Datepicker  :language="es" mondayFirst v-model="form.vigenciaLicencia" :format="customFormatter" ></Datepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
              <label class="form-label" id="numeroLicencia"><strong>Licencia de Conducir N°*</strong> </label>
              <input class="form-control" type="number" v-model="form.numeroLicencia"/>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="claseLicencia"><strong>Licencia Clase*</strong> </label>
            <select class="form-control" v-model="form.claseLicencia">
              <option v-for="claseLicencia in claseLicenciaList" :value="claseLicencia.id" :key="claseLicencia.id" :style="{textTransform: 'uppercase'}">
                {{ claseLicencia.nombre}}
              </option>
            </select>
          </div>
        </div>
        <button style="margin-right: 10px" type="button" class="btn btn-secondary" v-on:click="volverPaso3()">Volver</button>
        <button type="button" class="btn btn-primary" v-on:click="irPaso5()">Continuar</button>
      </b-collapse>
<!--      CARGA-->
      <b-collapse  v-model="paso5">
        <h3 class="mt-4 mb-4">Expeditor de la carga</h3>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label class="form-label" id="tipoEquipamiento"><strong>Tipo de carga*</strong> </label>
            <select class="form-control" v-model="form.tipoEquipamiento">
              <option v-for="tipoEquipamiento in tipoEquipamientoList" :value="tipoEquipamiento.id" :key="tipoEquipamiento.id" :style="{textTransform: 'uppercase'}">
                {{ tipoEquipamiento.nombre}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label" id="origenCarga"><strong>Origen Carga*</strong> </label>
            <input class="form-control" type="text" v-model="form.origenCarga"/>
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label" id="destinoCarga"><strong>Destino Carga*</strong> </label>
            <input class="form-control" type="text" v-model="form.destinoCarga"/>
          </div>
        </div>
        <button style="margin-right: 10px" type="button" class="btn btn-secondary" v-on:click="volverPaso4()">Volver</button>
        <button type="button" class="btn btn-primary" v-on:click="crearCheckList()">Guardar</button>
      </b-collapse>
    </panel>
    <!--		 END panel-->
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import {es} from "vuejs-datepicker/dist/locale";
export default {
  components:{
    Datepicker
  },
  data() {
    return {
      paso1: true ,
      paso2: false,
      paso3: false,
      paso4: false,
      paso5: false,
      es:es,
      usuarioActual: null,
      empresasTipoPorEmpresa:[],
      tipoInspeccion:[],
      opcionesEquipo: [],
      opcionesTracto: [],
      ubicaciones:[],
      sexoList:[],
      estadoInspeccionList:[],
      claseLicenciaList:[],
      equipoList:[],
      conductorList:[],
      tipoEquipamientoList:[],
      equipoModelo:[],
      tipoEquipoRampla:[],
      tipoEquipoFurgon:[],
      tipoEquipoFeeder:[],
      tipoEquipoEstanque:[],
      tipoTractoEstanque:[],
      tipoTractoRampla:[],
      tipoTractoFurgon:[],
      tipoTractoFeeder:[],
      form: {
        modelo:"",
        origenCarga:"",
        destinoCarga:"",
        tipoEquipamiento:"",
        numeroLicencia:"",
        nombre: "",
        apellidoPaterno: "",
        patente:"",
        patenteTracto:"",
        // "fechaDeInspeccionProxima":"",
        solicitante:null,
        transportista:null,
        tipo: null,
        lugar: null,
        sexo:null,
        equipo: 'nuevo',
        tracto: 'nuevo',
        conductor: 'nuevo',
        kilometrajeUltimaMantencionEquipo:  0,
        kilometrajeUltimaMantencionTracto:  0,
        kilometrajeProximaMantencionEquipo: 0,
        kilometrajeProximaMantencionTracto: 0,
        hubodometro:0,
        fechaVencimientoPermisoCirculacionEquipo:new Date(),
        fechaVencimientoPermisoCirculacionTracto:new Date(),
        permisoCirculacion:false,
        permisoCirculacionTracto:false,
        seguroObligatorioTracto:false,
        seguroObligatorio:false,
        revisionTecnica:false,
        revisionTecnicaTracto:false,
        claseLicencia:null,
        rut:null,
        codigoVerificador:'',
        apellidoMaterno: "",
        vigenciaLicencia: "",
        // "estadoInspeccion": 1,
      },
      jsonDataCheckList: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },

  watch:{
    'form.conductor': function (nuevoValor) {

      if (nuevoValor === null || nuevoValor.value === 'nuevo') {
        this.form.conductor = 'nuevo';
        this.form.nombre = '';
        this.form.apellidoPaterno = '';
        this.form.apellidoMaterno = '';
        this.form.rut = '';
        this.form.codigoVerificador = '';
        this.form.numeroLicencia = '';
        this.form.vigenciaLicencia = '';
        this.form.sexo = '';
        this.form.claseLicencia = '';
      }
      else if(nuevoValor && nuevoValor.value !== 'nuevo') {
        let conductorSeleccionado = this.conductorList.find(c => c.id === nuevoValor.value);
        if (conductorSeleccionado) {
          this.form.nombre = conductorSeleccionado.nombre;
          this.form.apellidoPaterno = conductorSeleccionado.apellidoPaterno;
          this.form.apellidoMaterno = conductorSeleccionado.apellidoMaterno;
          this.form.rut = conductorSeleccionado.rut.toString();
          this.form.codigoVerificador = conductorSeleccionado.codigoVerificador;
          this.form.numeroLicencia = conductorSeleccionado.numeroLicencia;
          this.form.vigenciaLicencia = conductorSeleccionado.vigenciaLicencia;
          this.form.sexo = conductorSeleccionado.sexo;
          this.form.claseLicencia = conductorSeleccionado.claseLicencia;
        }
      }
    },
    'form.equipo': function (newValue) {
      if (newValue === null || newValue === 'nuevo') {
        this.form.equipo = 'nuevo';
      }
    },
    'form.tracto': function (newValue) {
      if (newValue === null || newValue === 'nuevo') {
        this.form.tracto = 'nuevo';
      }
    },
    'form.tipo': function(newValue) {
      this.actualizarOpcionesEquipo(newValue);
      this.actualizarOpcionesTracto(newValue);
    },
  },
  created: function () {
    this.$validarLogin()
    this.cargarUsuarioActual();
  },
  async mounted(){
    try {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const username = obtenerToken.username;
      this.empresasTipoPorEmpresa = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaTransportista', { username: username });
      this.tipoInspeccion = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/inspeccionTipo/listaInspeccionTipo');
      this.ubicaciones = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacionDiarias');
      this.estadoInspeccionList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/formulario/estadoInspeccionList');
      this.equipoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquipo', { username: username });
      this.conductorList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listaConductor',{ username: username });
      this.sexoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/sexoList');
      this.claseLicenciaList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/claseLicenciaList');
      this.tipoEquipamientoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/tipoEquipamientoList');
      this.equipoModelo = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/modeloList');
      // let inspeccionId = localStorage.getItem('inspeccionId');
      // if (inspeccionId) {
      //   this.form.inspeccionId = inspeccionId;
      //   // Ahora puedes usar inspeccionId para continuar con las operaciones del formulario
      // }

    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    formatConductorName(conductor) {
      let nombreCompleto = conductor.nombre;

      if (conductor.apellidoPaterno) {
        nombreCompleto += ` ${conductor.apellidoPaterno}`;
      }
      if (conductor.apellidoMaterno) {
        nombreCompleto += ` ${conductor.apellidoMaterno}`;
      }

      return nombreCompleto.toUpperCase();
    },
    validateInput(event) {
      const input = event.target.value;
      // Elimina todos los caracteres que no sean letras o números
      const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
      // Actualiza el modelo de datos
      this.form.patente = cleanedInput;
    },
    validateInputTracto(event) {
      const input = event.target.value;
      // Elimina todos los caracteres que no sean letras o números
      const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
      // Actualiza el modelo de datos
      this.form.patenteTracto = cleanedInput;
    },
    validateDV(event) {
      const input = event.target.value.toUpperCase();
      const validChars = /^[0-9K]$/;

      if (input.length > 0 && !validChars.test(input)) {
        event.target.value = input.slice(0, -1);
      } else {
        this.form.codigoVerificador = input;
      }
    },
    async actualizarOpcionesEquipo(tipoInspeccionId) {
      if (!tipoInspeccionId) return;
      switch (tipoInspeccionId) {
        case 1:
          this.tipoEquipoEstanque = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquiposEstanque');
          this.opcionesEquipo = this.tipoEquipoEstanque.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        case 2:
          this.tipoEquipoFurgon = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquiposFurgon');
          this.opcionesEquipo = this.tipoEquipoFurgon.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        case 3:
          this.tipoEquipoRampla = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquiposRampla');
          this.opcionesEquipo = this.tipoEquipoRampla.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        case 4:
          this.tipoEquipoFeeder = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquiposFeeder');
          this.opcionesEquipo = this.tipoEquipoFeeder.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        default:
          this.opcionesEquipo = [];
      }
    },
    async actualizarOpcionesTracto(tipoInspeccionId) {
      if (!tipoInspeccionId) return;
      switch (tipoInspeccionId) {
        case 1:
          this.tipoTractoEstanque = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaTractoEstanque');
          this.opcionesTracto = this.tipoTractoEstanque.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        case 2:
          this.tipoTractoFurgon = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaTractoFurgon');
          this.opcionesTracto = this.tipoTractoFurgon.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        case 3:
          this.tipoTractoRampla = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaTractoRampla');
          this.opcionesTracto = this.tipoTractoRampla.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        case 4:
          this.tipoTractoFeeder = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaTractoFeeder');
          this.opcionesTracto = this.tipoTractoFeeder.map(e => ({ patente: e.patente.toUpperCase(), value: e.id }));
          break;
        default:
          this.opcionesTracto = [];
      }
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async cargarUsuarioActual() {
      const datosUsuario = JSON.parse(localStorage.getItem('saveUser'));
      if (datosUsuario && datosUsuario.username) {
        try {
          const response = await axios.post(process.env.VUE_APP_IP_BACKEND + '/user/obtenerDatos', {
            username: datosUsuario.username
          }, {
            headers: {
              "Authorization": `Bearer ${datosUsuario.access_token}`
            }
          });
          this.usuarioActual = {
            idUsuario: response.data.idUsuario,
            nombre: response.data.nombre,
            apellido: response.data.apellido
          };
          this.form.solicitante = this.usuarioActual.idUsuario;
        } catch (error) {
          console.error('Error al cargar datos del usuario:', error);
        }
      } else {
        console.error('Datos del usuario actual no están disponibles');
      }
    },
    async fetchData(url, params = {}) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const query = new URLSearchParams(params).toString();
      const finalUrl = `${url}?${query}`;

      try {
        const response = await axios.get(finalUrl, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        // Manejar errores
        return []; // Devolver un array vacío en caso de error
      }
    },
    irPaso2() {
      if (!this.form.solicitante || !this.form.transportista || !this.form.tipo || !this.form.lugar) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      this.paso1 = false
      document.getElementById("navPaso1").className = "nav-link completed"
      document.getElementById("navPaso2").className = "nav-link active"
      this.paso2 = true
    },
    irPaso3() {
      if (!this.form.equipo || this.estaVacio(this.form.kilometrajeUltimaMantencionEquipo) || this.estaVacio(this.form.kilometrajeProximaMantencionEquipo)||this.estaVacio(this.form.modelo)||
          !this.form.fechaVencimientoPermisoCirculacionEquipo) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (this.form.equipo === 'nuevo' && !this.form.patente) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      this.paso2 = false
      document.getElementById("navPaso2").className = "nav-link completed"
      document.getElementById("navPaso3").className = "nav-link active"
      this.paso3 = true
    },

    irPaso4() {
      if (!this.form.tracto ||
          this.estaVacio(this.form.kilometrajeUltimaMantencionTracto) ||
          this.estaVacio(this.form.kilometrajeProximaMantencionTracto) ||
          this.estaVacio(this.form.hubodometro) ||
          !this.form.fechaVencimientoPermisoCirculacionTracto) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (this.form.tracto === 'nuevo' && !this.form.patenteTracto) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      this.paso3 = false
      document.getElementById("navPaso3").className = "nav-link completed"
      document.getElementById("navPaso4").className = "nav-link active"
      this.paso4 = true
    },

    irPaso5() {
      if (!this.form.conductor || !this.form.rut || !this.form.codigoVerificador||
          !this.form.sexo || !this.form.nombre || !this.form.apellidoMaterno || !this.form.apellidoPaterno || !this.form.vigenciaLicencia||
          !this.form.numeroLicencia || !this.form.claseLicencia) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      this.paso4 = false
      document.getElementById("navPaso4").className = "nav-link completed"
      document.getElementById("navPaso5").className = "nav-link active"
      this.paso5 = true
    },

    async crearCheckList() {
      if (!this.form.tipoEquipamiento || !this.form.origenCarga || !this.form.destinoCarga ) {
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      const api = process.env.VUE_APP_IP_BACKEND + "/inspeccion/saveInspeccion";
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      this.loadingSpiner()
      this.form.fechaVencimientoPermisoCirculacionEquipo = moment(this.form.fechaVencimientoPermisoCirculacionEquipo).format('YYYY-MM-DD')
      this.form.fechaVencimientoPermisoCirculacionTracto = moment(this.form.fechaVencimientoPermisoCirculacionTracto).format('YYYY-MM-DD')
      this.form.vigenciaLicencia = moment(this.form.vigenciaLicencia).format('YYYY-MM-DD')
      try {
        //PETICION
        let response = await axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.jsonDataCheckList = response.data
          localStorage.setItem('inspeccionId', response.data.inspeccionId);
          localStorage.setItem('formularioId', response.data.formularioId);
          localStorage.setItem('ultimoOrden', response.data.ultimoOrden);
          localStorage.setItem('ordenActual', response.data.ordenActual);
          this.$router.push({name: response.data.nombreFormularioSinEspacios, params: {
              formularioId:                response.data.formularioId,
              nombreFormularioSinEspacios: response.data.nombreFormularioSinEspacios,
              nombreFormulario:            response.data.nombreFormulario,
              ultimoOrden:                 response.data.ultimoOrden,
              ordenActual:                 response.data.ordenActual,
              inspeccionId:                response.data.inspeccionId,
            }})
          this.showToastSuccess('Inspección creada !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else if(this.error_msg.request.status == 400) {
          let mensajeError = this.error_msg.response.data.mensajeError
          console.log(mensajeError)
          this.$showToastError(mensajeError, 'UPS!', this.$notificationSystem().options.error)
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async rellenarInfoEquipo(equipoId){
      if(equipoId && equipoId.value){
        equipoId = equipoId.value
      } else {
        equipoId = null
      }
      const api = process.env.VUE_APP_IP_BACKEND + "/inspeccion/datosEquipoInspeccionAnterior/" + equipoId;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          let resp = response.data
          this.form.kilometrajeUltimaMantencionEquipo        = resp.kilometrajeUltimaMantencionEquipo
          this.form.kilometrajeProximaMantencionEquipo       = resp.kilometrajeProximaMantencionEquipo
          this.form.fechaVencimientoPermisoCirculacionEquipo = resp.fechaVencimientoPermisoCirculacionEquipo
          this.form.permisoCirculacion                       = resp.permisoCirculacionEquipo
          this.form.seguroObligatorio                        = resp.seguroObligatorioEquipo
          this.form.revisionTecnica                          = resp.revisionTecnicaEquipo
          this.form.modelo                                   = resp.modelo
        }
      } catch (error) {
        if (error.response) {
          const errorResponse = error.response.data;
          this.form.kilometrajeUltimaMantencionEquipo        = 0
          this.form.kilometrajeProximaMantencionEquipo       = 0
          this.form.fechaVencimientoPermisoCirculacionEquipo = new Date()
          this.form.permisoCirculacion                       = false
          this.form.seguroObligatorio                        = false
          this.form.revisionTecnica                          = false
          this.form.modelo                                   = errorResponse.modelo
        }
        else if (error.response.status === 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        }
      }
    },
    async rellenarInfoTracto(tractoId){

      if(tractoId && tractoId.value){
        tractoId = tractoId.value
      } else {
        tractoId = null
      }
      const api = process.env.VUE_APP_IP_BACKEND + "/inspeccion/datosTractoInspeccionAnterior/" + tractoId;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          let resp = response.data
          this.form.kilometrajeUltimaMantencionTracto        = resp.kilometrajeUltimaMantencionTracto
          this.form.kilometrajeProximaMantencionTracto       = resp.kilometrajeProximaMantencionTracto
          this.form.fechaVencimientoPermisoCirculacionTracto = resp.fechaVencimientoPermisoCirculacionTracto
          this.form.permisoCirculacionTracto                 = resp.permisoCirculacionTracto
          this.form.seguroObligatorioTracto                  = resp.seguroObligatorioTracto
          this.form.revisionTecnicaTracto                    = resp.revisionTecnicaTracto
          this.form.hubodometro                              = resp.hubodometro
        }
      } catch (error) {
        this.error_msg = error
        if(this.error_msg.request.status == 404){
          this.form.kilometrajeUltimaMantencionTracto        = 0
          this.form.kilometrajeProximaMantencionTracto       = 0
          this.form.fechaVencimientoPermisoCirculacionTracto = new Date()
          this.form.permisoCirculacionTracto                 = false
          this.form.seguroObligatorioTracto                  = false
          this.form.revisionTecnicaTracto                    = false
          this.form.hubodometro                              = 0
        }
        else if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        }
      }
    },
    volverPaso1() {
      this.paso2 = false
      document.getElementById("navPaso2").className = "nav-link disabled"
      this.paso1 = true
      document.getElementById("navPaso1").className = "nav-link active"
    },
    volverPaso2() {
      this.paso3 = false
      document.getElementById("navPaso3").className = "nav-link disabled"
      this.paso2 = true
      document.getElementById("navPaso2").className = "nav-link active"
    },
    volverPaso3() {
      this.paso4 = false
      document.getElementById("navPaso4").className = "nav-link disabled"
      this.paso3 = true
      document.getElementById("navPaso3").className = "nav-link active"
    },
    volverPaso4() {
      this.paso5 = false
      document.getElementById("navPaso5").className = "nav-link disabled"
      this.paso4 = true
      document.getElementById("navPaso4").className = "nav-link active"
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    estaVacio(valor){ //Esto porque 0 y "0" == false
      return (valor === undefined || valor === null || valor === "")
    },
  }
}
</script>
<style scoped>
.datepicker input {
  background-color: #ffffff; /* Color de fondo */
  border: 1px solid #cecece; /* Borde */
  border-radius: 4px; /* Redondeo de bordes */
  font-size: 0.75rem; /* Tamaño de fuente */
  padding: 0.4375rem 0.75rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}
.datepicker input:focus{
  border-color: #495057; /* Color del borde cuando está enfocado */
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255); /* Sombra exterior opcional para resaltar el foco */
  outline: none;
}
.datepicker /deep/ .vdp-datepicker__calendar {
  top: auto; /* Resetea cualquier valor previo */
  bottom: 100%;
}
@media (max-width: 700px) {
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -10%; /* Centra horizontalmente */
  }
}
@media (min-width: 701px) and (max-width: 1200px) {
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -20%; /* Centra horizontalmente */
  }
}
</style>