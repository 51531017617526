<script>
import DashboardV1                  from '../pages/paginas_template/Dashboard-v1.vue'
import DashboardV2                  from '../pages/paginas_template/Dashboard-v2.vue'
import DashboardV3                  from '../pages/paginas_template/Dashboard-v3.vue'
import DashboardProgramadas         from '../pages/Dashboard-Programadas.vue'
import DashboardDiarias             from '../pages/Dashboard-Diarias.vue'
import EmailInbox                   from '../pages/paginas_template/Email-inbox.vue'
import EmailCompose                 from '../pages/paginas_template/Email-compose.vue'
import EmailDetail                  from '../pages/paginas_template/Email-detail.vue'
import Widgets                      from '../pages/paginas_template/Widgets.vue'
import UIGeneral                    from '../pages/paginas_template/UI-general.vue'
import UITypography                 from '../pages/paginas_template/UI-typography.vue'
import UITabsAccordion              from '../pages/paginas_template/UI-tabs-accordion.vue'
import UIModalNotification          from '../pages/paginas_template/UI-modal-notification.vue'
import UIWidgetBoxes                from '../pages/paginas_template/UI-widget-boxes.vue'
import UIMediaObject                from '../pages/paginas_template/UI-media-object.vue'
import UIButtons                    from '../pages/paginas_template/UI-buttons.vue'
import UIIcons                      from '../pages/paginas_template/UI-icons.vue'
import UISimpleLineIcons            from '../pages/paginas_template/UI-simple-line-icons.vue'
import UIIonicons                   from '../pages/paginas_template/UI-ionicons.vue'
import UILanguageBarIcon            from '../pages/paginas_template/UI-language-bar-icon.vue'
import UISocialButtons              from '../pages/paginas_template/UI-social-buttons.vue'
import Bootstrap5                   from '../pages/paginas_template/Bootstrap-5.vue'
import FormElements                 from '../pages/paginas_template/Form-elements.vue'
import FormPlugins                  from '../pages/paginas_template/Form-plugins.vue'
import FormWizards                  from '../pages/paginas_template/Form-wizards.vue'
import PosCounterCheckout           from '../pages/paginas_template/Pos-counter-checkout.vue'
import PosCustomerOrder             from '../pages/paginas_template/Pos-customer-order.vue'
import PosKitchenOrder              from '../pages/paginas_template/Pos-kitchen-order.vue'
import PosMenuStock                 from '../pages/paginas_template/Pos-menu-stock.vue'
import PosTableBooking              from '../pages/paginas_template/Pos-table-booking.vue'
import TableBasic                   from '../pages/paginas_template/Table-basic.vue'
import TableData                    from '../pages/paginas_template/Table-data.vue'
import ChartJs                      from '../pages/paginas_template/Chart-js.vue'
import ChartD3                      from '../pages/paginas_template/Chart-d3.vue'
import ChartApex                    from '../pages/paginas_template/Chart-apex.vue'
import Calendar                     from '../pages/paginas_template/Calendar.vue'
import Map                          from '../pages/paginas_template/Map.vue'
import Gallery                      from '../pages/paginas_template/Gallery.vue'
import PageBlank                    from '../pages/paginas_template/Page-blank.vue'
import PageWithFooter               from '../pages/paginas_template/Page-with-footer.vue'
import PageWithFixedFooter          from '../pages/paginas_template/Page-with-fixed-footer.vue'
import PageWithoutSidebar           from '../pages/paginas_template/Page-without-sidebar.vue'
import PageWithRightSidebar         from '../pages/paginas_template/Page-with-right-sidebar.vue'
import PageWithMinifiedSidebar      from '../pages/paginas_template/Page-with-minified-sidebar.vue'
import PageWithTwoSidebar           from '../pages/paginas_template/Page-with-two-sidebar.vue'
import PageFullHeight               from '../pages/paginas_template/Page-full-height.vue'
import PageWithWideSidebar          from '../pages/paginas_template/Page-with-wide-sidebar.vue'
import PageWithLightSidebar         from '../pages/paginas_template/Page-with-light-sidebar.vue'
import PageWithMegaMenu             from '../pages/paginas_template/Page-with-mega-menu.vue'
import PageWithTopMenu              from '../pages/paginas_template/Page-with-top-menu.vue'
import PageWithBoxedLayout          from '../pages/paginas_template/Page-with-boxed-layout.vue'
import PageWithMixedMenu            from '../pages/paginas_template/Page-with-mixed-menu.vue'
import PageBoxedLayoutWithMixedMenu from '../pages/paginas_template/Page-boxed-layout-with-mixed-menu.vue'
import PageWithTransparentSidebar   from '../pages/paginas_template/Page-with-transparent-sidebar.vue'
import PageWithSearchSidebar        from '../pages/paginas_template/Page-with-search-sidebar.vue'
import ExtraTimeline                from '../pages/paginas_template/Extra-timeline.vue'
import ExtraComingSoon              from '../pages/paginas_template/Extra-coming-soon.vue'
import ExtraSearch                  from '../pages/paginas_template/Extra-search.vue'
import ExtraInvoice                 from '../pages/paginas_template/Extra-invoice.vue'
import ExtraError                   from '../pages/paginas_template/Extra-error.vue'
import ExtraProfile                 from '../pages/paginas_template/Extra-profile.vue'
import ExtraScrumBoard              from '../pages/paginas_template/Extra-scrum-board.vue'
import ExtraCookieAcceptanceBanner  from '../pages/paginas_template/Extra-cookie-acceptance-banner.vue'
import ExtraOrders                  from '../pages/paginas_template/Extra-orders.vue'
import ExtraProducts                from '../pages/paginas_template/Extra-products.vue'
import UserLoginV1                  from '../pages/paginas_template/User-login-v1.vue'
import UserLoginV2                  from '../pages/paginas_template/User-login-v2.vue'
import UserLoginV3                  from '../pages/paginas_template/User-login-v3.vue'
import UserRegisterV3               from '../pages/paginas_template/User-register-v3.vue'
import HelperCss                    from '../pages/paginas_template/Helper-css.vue'

/*MY PAGES*/
import Login from '../pages/Login'
import Home from "@/pages/Home";
import CrearSala from "@/pages/CrearSala"
import CrearConductor from "@/pages/CrearConductor"
import CrearChecklist from "@/pages/crear_checklist/CrearChecklist.vue"
import ChecklistEstanquesConocimientosConductor from "@/pages/formularios/estanque/ChecklistEstanquesConocimientosConductor.vue"
import ChecklistEstanquesConductor from "@/pages/formularios/estanque/ChecklistEstanquesConductor.vue"
import ChecklistEstanquesTracto from "@/pages/formularios/estanque/ChecklistEstanquesTracto.vue"
import ChecklistEstanquesEquipo from "@/pages/formularios/estanque/ChecklistEstanquesEquipo.vue"
import ChecklistEstanquesNeumaticosEquipo from "@/pages/formularios/estanque/ChecklistEstanquesNeumaticosEquipo.vue"
import ChecklistEstanquesTacografo from "@/pages/formularios/estanque/ChecklistEstanquesTacografo.vue"
import ChecklistEstanquesxNeumaticosTractoCamion from "@/pages/formularios/estanque/ChecklistEstanquesxNeumaticosTractoCamion.vue"
import ChecklistFurgonesConocimientosConductor from "@/pages/formularios/furgon/ChecklistFurgonesConocimientosConductor.vue"
import ChecklistFurgonesConductor from "@/pages/formularios/furgon/ChecklistFurgonesConductor.vue"
import ChecklistFurgonesTracto from "@/pages/formularios/furgon/ChecklistFurgonesTracto.vue"
import ChecklistFurgonesNeumaticosTractoCamion from "@/pages/formularios/furgon/ChecklistFurgonesNeumaticosTractoCamion.vue"
import ChecklistFurgonesNeumaticosEquipo from "@/pages/formularios/furgon/ChecklistFurgonesNeumaticosEquipo.vue"
import ChecklistFurgonesTacografo from "@/pages/formularios/furgon/ChecklistFurgonesTacografo.vue"
import ChecklistFurgonesFurgon from "@/pages/formularios/furgon/ChecklistFurgonesFurgon.vue"
import ChecklistRamplasConocimientosConductor from "@/pages/formularios/rampla/ChecklistRamplasConocimientosConductor.vue"
import ChecklistRamplasConductor from "@/pages/formularios/rampla/ChecklistRamplasConductor.vue"
import ChecklistRamplasTracto from "@/pages/formularios/rampla/ChecklistRamplasTracto.vue"
import ChecklistRamplasRampla from "@/pages/formularios/rampla/ChecklistRamplasRampla.vue"
import ChecklistRamplasNeumaticosTractoCamion from "@/pages/formularios/rampla/ChecklistRamplasNeumaticosTractoCamion.vue"
import ChecklistRamplasNeumaticosEquipo from "@/pages/formularios/rampla/ChecklistRamplasNeumaticosEquipo.vue"
import ChecklistRamplasTacografo from "@/pages/formularios/rampla/ChecklistRamplasTacografo.vue"
import ChecklistFeederConocimientosConductor from "@/pages/formularios/feeder/ChecklistFeederConocimientosConductor.vue"
import ChecklistFeederConductor from "@/pages/formularios/feeder/ChecklistFeederConductor.vue"
import ChecklistFeederEquipo from "@/pages/formularios/feeder/ChecklistFeederEquipo.vue"
import ChecklistFeederNeumaticosTractoCamion from "@/pages/formularios/feeder/ChecklistFeederNeumaticosTractoCamion.vue"
import ChecklistFeederTacografo from "@/pages/formularios/feeder/ChecklistFeederTacografo.vue"
import ChecklistGeneral from "@/pages/formularios/programada_original/ChecklistGeneral.vue"
import FormularioKingpin from "@/pages/formularios/inspeccion_programada/FormularioKingpin.vue"
import FormularioChecklist from "@/pages/formularios/inspeccion_programada/FormularioChecklist.vue"
import FormularioHermeticidad from "@/pages/formularios/inspeccion_programada/FormularioHermeticidad.vue"
import FormularioLineasPilarPletina from "@/pages/formularios/inspeccion_programada/FormularioLineasPilarPletina.vue"
import FormularioValvulaAlivio from "@/pages/formularios/inspeccion_programada/FormularioValvulaAlivio.vue"
import FormularioEnsayoNoDestructivo from "@/pages/formularios/inspeccion_programada/FormularioEnsayoNoDestructivo.vue"
import CuestionarioGeneral from "@/pages/formularios/programada_original/CuestionarioGeneral.vue"
import CrearUser from "@/pages/CrearUser";
import CrearEmpresa from "@/pages/CrearEmpresa";
import CrearApiPrueba from "@/pages/CrearApiPrueba"
import ShowSala from "@/pages/ShowSala";
import ShowUser from "@/pages/ShowUser";
import ShowConductor from "@/pages/ShowConductor";
import ShowEmpresa from "@/pages/ShowEmpresa";
import ShowInspeccion from "@/pages/ShowInspeccion";
import ListarUser from "@/pages/ListarUser";
import ListaConductor from "@/pages/ListaConductor";
import ListaEmpresa from "@/pages/ListaEmpresa";
import EmpresaTransportista from "@/pages/EmpresaTransportista";
import EmpresaPropietaria from "@/pages/EmpresaPropietaria";
import EmpresaFabricante from "@/pages/EmpresaFabricante";
import UsuarioTransportista from "@/pages/UsuarioTransportista";
import UsuarioInspector from "@/pages/UsuarioInspector";
import UsuarioConductor from "@/pages/UsuarioConductor";
import ListaSala from "@/pages/ListaSala";
import ListaInspeccion from "@/pages/ListaInspeccion";
import ListaInspeccionesFiltradas from "@/pages/ListaInspeccionesFiltradas";
import ShowSalaHistorial from "@/pages/ShowSalaHistorial";
import Agendamiento from "@/pages/Agendamiento";
import ListaLugar from "@/pages/ListaLugar";
import ListaEquipo from "@/pages/ListarEquipo";
import SolicitarRevision from "@/pages/SolicitarRevision";
import GuardarFotosChecklist from "@/pages/GuardarFotosChecklist";
import ListaInspeccionEstanques from "@/pages/ListaInspeccionEstanques";
import ListaInspeccionFurgones from "@/pages/ListaInspeccionFurgones";
import ListaInspeccionRamplas from "@/pages/ListaInspeccionRamplas";
import ListaInspeccionFeeder from "@/pages/ListaInspeccionFeeder";
import CrearEquipo from "@/pages/CrearEquipo.vue";
import ShowEquipo from "@/pages/ShowEquipo.vue";
import CrearLugar from "@/pages/CrearLugar.vue";
import ShowLugar from "@/pages/ShowLugar.vue";
import ShowInspeccionProgramada from "@/pages/ShowInspeccionProgramada";
import InspeccionProgramadaFotos from "@/pages/InspeccionProgramadaFotos";
import ListaInspeccionProgramada from "@/pages/ListaInspeccionProgramada";
import EvaluacionEmpresa from "@/pages/EvaluacionEmpresa";
import EvaluarEmpresa from "@/pages/EvaluarEmpresa";
import ShowPerfil from "@/pages/paginas_template/ShowPerfil.vue";

const routes = [
  /*MY ROUTES*/
  { path: '/auth', component: Login },
  { path: '/teleasesoria', component: Home },
  { path: '/crearSala', component: CrearSala },
  { path: '/crearConductor', component: CrearConductor },
  { path: '/agendamiento/crearChecklist', component: CrearChecklist },
  { path: '/agendamiento/checkListEstanquesConocimientosConductor/:formularioId', component: ChecklistEstanquesConocimientosConductor, name: 'ChecklistEstanquesConocimientosConductor' },
  { path: '/agendamiento/checkListEstanquesConductor/:formularioId', component: ChecklistEstanquesConductor, name: 'ChecklistEstanquesConductor' },
  { path: '/agendamiento/checkListEstanquesTracto/:formularioId', component: ChecklistEstanquesTracto, name: 'ChecklistEstanquesTracto' },
  { path: '/agendamiento/checkListEstanquesEquipo/:formularioId', component: ChecklistEstanquesEquipo, name: 'ChecklistEstanquesEquipo' },
  { path: '/agendamiento/checkListEstanquesNeumaticosEquipo/:formularioId', component: ChecklistEstanquesNeumaticosEquipo, name: 'ChecklistEstanquesNeumaticosEquipo' },
  { path: '/agendamiento/checkListEstanquesxNeumaticosTractoCamion/:formularioId', component: ChecklistEstanquesxNeumaticosTractoCamion, name: 'ChecklistEstanquesxNeumaticosTractoCamion' },
  { path: '/agendamiento/checkListEstanquesTacografo/:formularioId', component: ChecklistEstanquesTacografo, name: 'ChecklistEstanquesTacografo' },
  { path: '/agendamiento/checkListFurgonesConocimientosConductor/:formularioId', component: ChecklistFurgonesConocimientosConductor, name: 'ChecklistFurgonesConocimientosConductor' },
  { path: '/agendamiento/checkListFurgonesConductor/:formularioId', component: ChecklistFurgonesConductor, name: 'ChecklistFurgonesConductor' },
  { path: '/agendamiento/checkListFurgonesTracto/:formularioId', component: ChecklistFurgonesTracto, name: 'ChecklistFurgonesTracto' },
  { path: '/agendamiento/checkListFurgonesFurgon/:formularioId', component: ChecklistFurgonesFurgon, name: 'ChecklistFurgonesFurgon' },
  { path: '/agendamiento/checkListFurgonesNeumaticosTractoCamion/:formularioId', component: ChecklistFurgonesNeumaticosTractoCamion, name: 'ChecklistFurgonesNeumaticosTractoCamion' },
  { path: '/agendamiento/checkListFurgonesNeumaticosEquipo/:formularioId', component: ChecklistFurgonesNeumaticosEquipo, name: 'ChecklistFurgonesNeumaticosEquipo' },
  { path: '/agendamiento/checkListFurgonesTacografo /:formularioId', component: ChecklistFurgonesTacografo , name: 'ChecklistFurgonesTacografo' },
  { path: '/agendamiento/checkListRamplasConocimientosConductor/:formularioId', component: ChecklistRamplasConocimientosConductor, name: 'ChecklistRamplasConocimientosConductor' },
  { path: '/agendamiento/checkListRamplasConductor/:formularioId', component: ChecklistRamplasConductor, name: 'ChecklistRamplasConductor' },
  { path: '/agendamiento/checkListRamplasTracto/:formularioId', component: ChecklistRamplasTracto, name: 'ChecklistRamplasTracto' },
  { path: '/agendamiento/checkListRamplasRampla/:formularioId', component: ChecklistRamplasRampla, name: 'ChecklistRamplasRampla' },
  { path: '/agendamiento/checkListRamplasNeumaticosTractoCamion/:formularioId', component: ChecklistRamplasNeumaticosTractoCamion, name: 'ChecklistRamplasNeumaticosTractoCamion' },
  { path: '/agendamiento/checkListRamplasNeumaticosEquipo/:formularioId', component: ChecklistRamplasNeumaticosEquipo, name: 'ChecklistRamplasNeumaticosEquipo' },
  { path: '/agendamiento/checkListRamplasTacografo/:formularioId', component: ChecklistRamplasTacografo, name: 'ChecklistRamplasTacografo' },
  { path: '/agendamiento/checkListFeederConocimientosConductor/:formularioId', component: ChecklistFeederConocimientosConductor, name: 'ChecklistFeederConocimientosConductor' },
  { path: '/agendamiento/checkListFeederConductor/:formularioId', component: ChecklistFeederConductor, name: 'ChecklistFeederConductor' },
  { path: '/agendamiento/checkListFeederEquipo/:formularioId', component: ChecklistFeederEquipo, name: 'ChecklistFeederEquipo' },
  { path: '/agendamiento/checkListFeederNeumaticosTractoCamion/:formularioId', component: ChecklistFeederNeumaticosTractoCamion, name: 'ChecklistFeederNeumaticosTractoCamion' },
  { path: '/agendamiento/checkListFeederTacografo/:formularioId', component: ChecklistFeederTacografo, name: 'ChecklistFeederTacografo' },
  { path: '/agendamiento/checkListGeneral', component: ChecklistGeneral, name: 'ChecklistGeneral' },
  { path: '/agendamiento/formularioKingpin/:inspeccionId',      component: FormularioKingpin, name: 'FormularioKingpin' },
  { path: '/agendamiento/formularioChecklist/:inspeccionId',    component: FormularioChecklist, name: 'FormularioChecklist' },
  { path: '/agendamiento/formularioHermeticidad/:inspeccionId', component: FormularioHermeticidad, name: 'FormularioHermeticidad' },
  { path: '/agendamiento/formularioLineasPilarPletina/:inspeccionId', component: FormularioLineasPilarPletina, name: 'FormularioLineasPilarPletina' },
  { path: '/agendamiento/formularioValvulaAlivio/:inspeccionId', component: FormularioValvulaAlivio, name: 'FormularioValvulaAlivio' },
  { path: '/agendamiento/formularioEnsayoNoDestructivo/:inspeccionId', component: FormularioEnsayoNoDestructivo, name: 'FormularioEnsayoNoDestructivo' },
  { path: '/agendamiento/cuestionarioGeneral', component: CuestionarioGeneral, name: 'CuestionarioGeneral' },
  { path: '/agendamiento/solicitarInspeccion', component: SolicitarRevision },
  { path: '/agendamiento/crearUser', component: CrearUser },
  { path: '/agendamiento/crearEquipo', component: CrearEquipo },
  { path: '/agendamiento/crearLugar', component: CrearLugar },
  { path: '/crearPrueba', component: CrearApiPrueba },
  { path: '/agendamiento/crearEmpresa', component: CrearEmpresa },
  { path: '/show/sala/:idRoom', component: ShowSala },
  { path: '/ver/salaHistorial/:idRoom', component: ShowSalaHistorial },
  { path: '/', component: Login },
  { path: '/show/user/:id', component: ShowUser, name:'ShowUser' },
  { path: '/show/perfil/:id', component: ShowPerfil, name:'ShowPerfil' },
  { path: '/show/equipo/:id', component: ShowEquipo },
  { path: '/show/lugar/:id', component: ShowLugar },
  { path: '/showConductor/:id', component: ShowConductor },
  { path: '/showEmpresa/:id', component: ShowEmpresa , name: 'ShowEmpresa'},
  { path: '/showInspeccion/:id', component: ShowInspeccion },
  { path: '/agendamiento/index/user/', component: ListarUser },
  { path: '/listaSala', component: ListaSala },
  { path: '/listaConductor', component: ListaConductor },
  { path: '/agendamiento/listaEmpresa', component: ListaEmpresa },
  { path: '/agendamiento/empresaTransportista', component: EmpresaTransportista },
  { path: '/agendamiento/empresaPropietaria', component: EmpresaPropietaria },
  { path: '/agendamiento/empresaFabricante', component: EmpresaFabricante },
  { path: '/agendamiento/usuarioTransportista', component: UsuarioTransportista },
  { path: '/agendamiento/usuarioInspector', component: UsuarioInspector },
  { path: '/agendamiento/usuarioConductor', component: UsuarioConductor },
  { path: '/agendamiento/listaInspeccion', component: ListaInspeccion },
  { path: '/agendamiento/busquedaAvanzada', component: ListaInspeccionesFiltradas , name:'busquedaAvanzada'},
  { path: '/agendamiento/listaInspeccionProgramada', component: ListaInspeccionProgramada },
  { path: '/agendamiento/inspecciones/estanques', component: ListaInspeccionEstanques },
  { path: '/agendamiento/inspecciones/furgones', component: ListaInspeccionFurgones},
  { path: '/agendamiento/inspecciones/ramplas', component: ListaInspeccionRamplas},
  { path: '/agendamiento/inspecciones/feeder', component: ListaInspeccionFeeder},
  { path: '/agendamiento/calendario', component: Agendamiento },
  { path: '/agendamiento/listaLugar', component: ListaLugar },
  { path: '/agendamiento/listaEquipo', component: ListaEquipo , name: 'ListarEquipo'},
  { path: '/agendamiento/guardarFotosChecklist/:id', component: GuardarFotosChecklist, name: 'GuardarFotosChecklist' },
  { path: '/agendamiento/showInspeccionProgramada/:id', component: ShowInspeccionProgramada, name: 'ShowInspeccionProgramada' },
  { path: '/agendamiento/inspeccionProgramadaFotos/:id', component: InspeccionProgramadaFotos, name: 'InspeccionProgramadaFotos' },
  { path: '/agendamiento/evaluacionEmpresa', component: EvaluacionEmpresa, name: 'EvaluacionEmpresa' },
  { path: '/agendamiento/evaluarEmpresa/:id', component: EvaluarEmpresa, name: 'EvaluarEmpresa' },



  { path: 'home', component: Home },
  { path: '/agendamiento/dashboard/transportista', component: DashboardV1 },
  { path: '/agendamiento/dashboard/dashboard-v2', component: DashboardV2 },
  { path: '/agendamiento/dashboard/dashboard-v3', component: DashboardV3 },
  { path: '/agendamiento/dashboard/programadas', component: DashboardProgramadas },
  { path: '/agendamiento/dashboard/diarias', component: DashboardDiarias },
  { path: '/email/inbox', component: EmailInbox },
  { path: '/email/compose', component: EmailCompose },
  { path: '/email/detail', component: EmailDetail },
  { path: '/widgets', component: Widgets },
  { path: '/ui/general', component: UIGeneral },
  { path: '/ui/typography', component: UITypography },
  { path: '/ui/tabs-accordions', component: UITabsAccordion },
  { path: '/ui/modal-notification', component: UIModalNotification },
  { path: '/ui/widget-boxes', component: UIWidgetBoxes },
  { path: '/ui/media-object', component: UIMediaObject },
  { path: '/ui/buttons', component: UIButtons },
  { path: '/ui/icons', component: UIIcons },
  { path: '/ui/simple-line-icons', component: UISimpleLineIcons },
  { path: '/ui/ionicons', component: UIIonicons },
  { path: '/ui/language-bar-icon', component: UILanguageBarIcon },
  { path: '/ui/social-buttons', component: UISocialButtons },
  { path: '/bootstrap-5', component: Bootstrap5 },
  { path: '/form/elements', component: FormElements },
  { path: '/form/plugins', component: FormPlugins },
  { path: '/form/wizards', component: FormWizards },
  { path: '/pos/counter-checkout', component: PosCounterCheckout },
  { path: '/pos/customer-order', component: PosCustomerOrder },
  { path: '/pos/kitchen-order', component: PosKitchenOrder },
  { path: '/pos/menu-stock', component: PosMenuStock },
  { path: '/pos/table-booking', component: PosTableBooking },
  { path: '/table/basic', component: TableBasic },
  { path: '/table/data', component: TableData },
  { path: '/chart/js', component: ChartJs },
  { path: '/chart/d3', component: ChartD3 },
  { path: '/chart/apex', component: ChartApex },
  { path: '/calendar', component: Calendar },
  { path: '/map', component: Map },
  { path: '/page-option/blank', component: PageBlank },
  { path: '/page-option/with-footer', component: PageWithFooter },
  { path: '/page-option/with-fixed-footer', component: PageWithFixedFooter },
  { path: '/page-option/without-sidebar', component: PageWithoutSidebar },
  { path: '/page-option/with-right-sidebar', component: PageWithRightSidebar },
  { path: '/page-option/with-minified-sidebar', component: PageWithMinifiedSidebar },
  { path: '/page-option/with-two-sidebar', component: PageWithTwoSidebar },
  { path: '/page-option/full-height', component: PageFullHeight },
  { path: '/page-option/with-wide-sidebar', component: PageWithWideSidebar },
  { path: '/page-option/with-light-sidebar', component: PageWithLightSidebar },
  { path: '/page-option/with-mega-menu', component: PageWithMegaMenu },
  { path: '/page-option/with-top-menu', component: PageWithTopMenu },
  { path: '/page-option/with-boxed-layout', component: PageWithBoxedLayout },
  { path: '/page-option/with-mixed-menu', component: PageWithMixedMenu },
  { path: '/page-option/boxed-layout-with-mixed-menu', component: PageBoxedLayoutWithMixedMenu },
  { path: '/page-option/with-transparent-sidebar', component: PageWithTransparentSidebar },
  { path: '/page-option/with-search-sidebar', component: PageWithSearchSidebar },
  { path: '/gallery', component: Gallery },
  { path: '/extra/timeline', component: ExtraTimeline },
  { path: '/extra/coming-soon', component: ExtraComingSoon },
  { path: '/extra/search', component: ExtraSearch },
  { path: '/extra/invoice', component: ExtraInvoice },
  { path: '/extra/error', component: ExtraError },
  { path: '/extra/profile', component: ExtraProfile },
  { path: '/extra/scrum-board', component: ExtraScrumBoard },
  { path: '/extra/orders', component: ExtraOrders },
  { path: '/extra/products', component: ExtraProducts },
  { path: '/extra/cookie-acceptance-banner', component: ExtraCookieAcceptanceBanner },
  { path: '/user/login-v1', component: UserLoginV1 },
  { path: '/user/login-v2', component: UserLoginV2 },
  { path: '/user/login-v3', component: UserLoginV3 },
  { path: '/user/register-v3', component: UserRegisterV3 },
  { path: '/helper/css', component: HelperCss }
]

export default routes;
</script>
