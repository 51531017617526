<template>
  <div>
    <div class="row m-3">
      <h4 class="mx-5"><u>Líneas de Vida</u></h4>
      <div class="row mx-3">
        <!--          Tabla que se ve en vista de escritorio-->
        <table class="table table-striped d-none d-sm-block" v-if="(itemListLineaInp.length > 0 && itemListLineaBool.length > 0)">
          <thead>
          <tr>
            <th style="width: 15%"></th>
            <th style="width: 20%"></th>
            <th></th>
            <th>Si</th>
            <th>No</th>
            <th style="white-space: nowrap;">N/A</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(x,index) in itemListLineaBool.length" :key="x">
            <td>
              <strong v-if="itemListLineaInp[index]">
                {{itemListLineaInp[index].nombre}}
              </strong>
            </td>
            <td>
              <span v-if="itemListLineaInp[index]">
                <input :disabled="readOnly" type="text" class="form-control" v-model="itemListLineaInp[index].valor">
              </span>
            </td>
            <td>
              <strong >
                {{itemListLineaBool[index].nombre}}
              </strong>
            </td>
            <td>
              <input type="checkbox" v-model="itemListLineaBool[index].valorSi" :disabled="readOnly || !(itemListLineaBool[index].valorNo === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 1)" class="checkbox check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListLineaBool[index].valorNo" :disabled="readOnly || !(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 2)" class="checkbox check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListLineaBool[index].valorNoAplica" :disabled="readOnly || !(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 3)" class="checkbox check-inspeccion">
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Tablas que se ven en vista de celular-->
        <table class="table table-striped d-block d-sm-none" v-if="(itemListLineaInp.length > 0)">
          <tbody>
          <tr v-for="(x,index) in itemListLineaInp.length" :key="x">
            <td style="width: 25%">
              <strong v-if="itemListLineaInp[index]">
                {{itemListLineaInp[index].nombre}}
              </strong>
            </td>
            <td style="width: 25%">
              <span v-if="itemListLineaInp[index]">
                <input :disabled="readOnly" type="text" class="form-control" v-model="itemListLineaInp[index].valor">
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-striped d-block d-sm-none" v-if="(itemListLineaBool.length > 0)">
          <thead>
          <tr>
            <th></th>
            <th style="width: 15%">Si</th>
            <th style="width: 15%">No</th>
            <th style="width: 15%;white-space: nowrap;">N/A</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(x,index) in itemListLineaBool.length" :key="x">
            <td>
              <strong v-if="itemListLineaBool[index]">
                {{itemListLineaBool[index].nombre}}
              </strong>
            </td>
            <td>
              <input type="checkbox" v-model="itemListLineaBool[index].valorSi" :disabled="readOnly || !(itemListLineaBool[index].valorNo === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 1)" class="checkbox check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListLineaBool[index].valorNo" :disabled="readOnly || !(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 2)" class="checkbox check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListLineaBool[index].valorNoAplica" :disabled="readOnly || !(itemListLineaBool[index].valorSi === false && itemListLineaBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListLineaBool[index], 3)" class="checkbox check-inspeccion">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <hr>
    </div>
    <!--   Pilar   -->
    <div class="row m-3">
      <h4 class="m-3"><u>Pilar</u></h4>
      <template>
        <!--          Tabla que se ve en vista de escritorio-->
        <table class="table table-striped d-none d-sm-block" v-if="(itemListPilarInp.length > 0 && itemListPilarBool.length > 0)">
          <thead>
          <tr>
            <th style="width: 15%"></th>
            <th style="width: 20%"></th>
            <th></th>
            <th>Si</th>
            <th>No</th>
            <th style="white-space: nowrap;">N/A</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(x,index) in itemListPilarInp.length" :key="x">
            <td style="width: 25%">
              <strong v-if="itemListPilarInp[index]">
                {{itemListPilarInp[index].nombre}}
              </strong>
            </td>
            <td style="width: 25%">
                  <span v-if="itemListPilarInp[index]">
                    <input :disabled="readOnly" type="text" class="form-control" v-model="itemListPilarInp[index].valor">
                  </span>
            </td>
            <td v-if="itemListPilarBool[index]">
              <strong>
                {{itemListPilarBool[index].nombre}}
              </strong>
            </td>
            <td v-if="itemListPilarBool[index]">
              <input type="checkbox" v-model="itemListPilarBool[index].valorSi" :disabled="readOnly || !(itemListPilarBool[index].valorNo === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 1)" class="checkbox check-inspeccion">
            </td>
            <td v-if="itemListPilarBool[index]">
              <input type="checkbox" v-model="itemListPilarBool[index].valorNo" :disabled="readOnly || !(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 2)" class="checkbox check-inspeccion">
            </td>
            <td v-if="itemListPilarBool[index]">
              <input type="checkbox" v-model="itemListPilarBool[index].valorNoAplica" :disabled="readOnly || !(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 3)" class="checkbox check-inspeccion">
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Tablas que se ven en vista de celular-->
        <table class="table table-striped d-block d-sm-none" v-if="(itemListPilarInp.length > 0)">
          <tbody>
          <tr v-for="(x,index) in itemListPilarInp.length" :key="x">
            <td style="width: 25%">
              <strong v-if="itemListPilarInp[index]">
                {{itemListPilarInp[index].nombre}}
              </strong>
            </td>
            <td style="width: 25%">
                  <span v-if="itemListPilarInp[index]">
                    <input :disabled="readOnly" type="text" class="form-control" v-model="itemListPilarInp[index].valor">
                  </span>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-striped d-block d-sm-none" v-if="(itemListPilarBool.length > 0)">
          <thead>
          <tr>
            <th></th>
            <th style="width: 15%">Si</th>
            <th style="width: 15%">No</th>
            <th style="width: 15%;white-space: nowrap;">N/A</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(x,index) in itemListPilarBool.length" :key="x">
            <td>
              <strong v-if="itemListPilarBool[index]">
                {{itemListPilarBool[index].nombre}}
              </strong>
            </td>
            <td>
              <input type="checkbox" v-model="itemListPilarBool[index].valorSi" :disabled="readOnly || !(itemListPilarBool[index].valorNo === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 1)" class="checkbox check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListPilarBool[index].valorNo" :disabled="readOnly || !(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNoAplica === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 2)" class="checkbox check-inspeccion">
            </td>
            <td>
              <input type="checkbox" v-model="itemListPilarBool[index].valorNoAplica" :disabled="readOnly || !(itemListPilarBool[index].valorSi === false && itemListPilarBool[index].valorNo === false)" @change="handleCheckboxSiNo($event,itemListPilarBool[index], 3)" class="checkbox check-inspeccion">
            </td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
    <hr>
    <!--   Pletina   -->
    <div class="row m-3">
      <h4 class="m-3"><u>Pletina</u></h4>
      <table class="table table-striped" v-if="(itemListPletinaInp.length > 0)">
        <tr v-for="(x,index) in itemListPletinaInp.length" :key="x">
          <td style="width: 25%">
            <strong v-if="itemListPletinaInp[index]">
              {{itemListPletinaInp[index].nombre}}
            </strong>
          </td>
          <td style="width: 25%">
              <span v-if="itemListPletinaInp[index]">
                <input :disabled="readOnly" type="text" class="form-control" v-model="itemListPletinaInp[index].valor">
              </span>
          </td>
        </tr>
      </table>
    </div>
    <hr>
    <!--   Inspección Visual   -->
    <div class="row text-center">
      <h4><u>Inspección Visual</u></h4>
    </div>
    <!-- Tabla que se ven en vista de escritorio-->
    <table class="table table-striped d-none d-sm-block">
      <thead>
      <tr>
        <th>ITEM/COMPONENTE INSPECCIONADO</th>
        <th style="width: 7%">
          Cumple<br>
          <button v-if="!readOnly" class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
        </th>
        <th style="width: 7%">
          No Cumple<br>
          <button v-if="!readOnly" class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
        </th>
        <th style="width: 7%">
          No Aplica<br>
          <button v-if="!readOnly" class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
        </th>
        <th>ITEM/COMPONENTE INSPECCIONADO</th>
        <th style="width: 7%">
          Cumple<br>
          <button v-if="!readOnly" class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
        </th>
        <th style="width: 7%">
          No Cumple<br>
          <button v-if="!readOnly" class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
        </th>
        <th style="width: 7%">
          No Aplica<br>
          <button v-if="!readOnly" class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
        </th>
      </tr>
      </thead>
      <tbody v-if="itemListInspeccion.length > 0">
      <tr v-for="(x,index) in itemListInspeccion.length/2" :key="x" >
        <td>
          <strong :style="{backgroundColor: itemListInspeccion[index].color}">
            {{itemListInspeccion[index].nombre}}
          </strong>
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index].valorCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorNoCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index],1)" class="checkbox cumple check-inspeccion">
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index].valorNoCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 3)" class="checkbox noCumple check-inspeccion">
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index].valorNoAplica" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoCumple === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 4)" class="checkbox noAplica check-inspeccion">
        </td>
        <!--            segunda columna-->
        <td>
          <strong :style="{backgroundColor: itemListInspeccion[index +4].color}">
            {{itemListInspeccion[index +4].nombre}}
          </strong>
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index +4].valorCumple" v-if="itemListInspeccion[index +4].valorMejora === false && itemListInspeccion[index +4].valorNoCumple === false && itemListInspeccion[index +4].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index +4],1)" class="checkbox cumple check-inspeccion">
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index +4].valorNoCumple" v-if="itemListInspeccion[index +4].valorMejora === false && itemListInspeccion[index +4].valorCumple === false && itemListInspeccion[index +4].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index +4], 3)" class="checkbox noCumple check-inspeccion">
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index +4].valorNoAplica" v-if="itemListInspeccion[index +4].valorMejora === false && itemListInspeccion[index +4].valorCumple === false && itemListInspeccion[index +4].valorNoCumple === false" @change="handleCheckboxChange($event,itemListInspeccion[index +4], 4)" class="checkbox noAplica check-inspeccion">
        </td>
      </tr>
      </tbody>
    </table>
    <!-- Tablas que se ven en vista de celular-->
    <table class="table table-striped d-block d-sm-none">
      <thead>
      <tr>
        <th>ITEM/COMPONENTE INSPECCIONADO</th>
        <th style="width: 7%">
          Cumple<br>
          <button v-if="!readOnly" class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
        </th>
        <th style="width: 7%">
          No Cumple<br>
          <button v-if="!readOnly" class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
        </th>
        <th style="width: 7%">
          No Aplica<br>
          <button v-if="!readOnly" class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
        </th>
      </tr>
      </thead>
      <tbody v-if="itemListInspeccion.length > 0">
      <tr v-for="(x,index) in itemListInspeccion.length" :key="x" >
        <td>
          <strong :style="{backgroundColor: itemListInspeccion[index].color}">
            {{itemListInspeccion[index].nombre}}
          </strong>
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index].valorCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorNoCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index],1)" class="checkbox cumple check-inspeccion">
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index].valorNoCumple" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoAplica === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 3)" class="checkbox noCumple check-inspeccion">
        </td>
        <td>
          <input :disabled="readOnly"  type="checkbox" v-model="itemListInspeccion[index].valorNoAplica" v-if="itemListInspeccion[index].valorMejora === false && itemListInspeccion[index].valorCumple === false && itemListInspeccion[index].valorNoCumple === false" @change="handleCheckboxChange($event,itemListInspeccion[index], 4)" class="checkbox noAplica check-inspeccion">
        </td>
      </tr>
      </tbody>
    </table>
    <!--   FOTOS   -->
    <hr>
    <!-- Item Conclusión-->
    <div class="row m-3" v-if="itemConclusion">
      <div class="col-sm-6">
        <h4 class="m-3"><u>Conclusión Prueba</u></h4>
        <div class="form-check form-switch">
          <label class="form-label" for="itemConclusion"><strong>Prueba Aprobada</strong></label>
          <input :disabled="readOnly" class="form-check-input" id="itemConclusion" type="checkbox" v-model="itemConclusion.valorEnabled">
        </div>
        <label class="form-label" for="observacionesConclusion">Observaciones de Prueba</label>
        <textarea  :disabled="readOnly" id="observacionesConclusion" class="form-control" rows="4" v-model="itemConclusion.observaciones"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponenteFormLineas',
  props: {
    'itemListLineasPilarPletina': {
      type: Array,
      default: function() {
        return [];
      }
    },
    'readOnly': {
      type: Boolean,
      default: true
    },
  },
  data(){
    return{
      itemList: [],
      itemListLineaInp  :[],
      itemListLineaBool :[],
      itemListPilarInp  :[],
      itemListPilarBool :[],
      itemListPletinaInp:[],
      itemListInspeccion:[],
      itemConclusion:{},
    }
  },
  mounted(){
    this.itemListEnsayoProcesado()
  },
  methods:{
    itemListEnsayoProcesado() {
      if(this.itemListLineasPilarPletina){
        this.itemList = this.itemListLineasPilarPletina.map((item) => {
          let valorCumple   = false
          let valorMejora   = false
          let valorNoCumple = false
          let valorNoAplica = false
          if(item.valorNoAplica === true) valorNoAplica = true
          if(item.ponderacionId){
            if     (item.ponderacionId === 1) valorCumple   = true
            else if(item.ponderacionId === 2) valorMejora   = true
            else if(item.ponderacionId === 3) valorNoCumple = true
          }

          return {
            valor:                item.valor,
            guardado:             item.guardado,
            valorEnabled:         item.valorBooleano,
            observaciones:        item.observaciones,
            itemId:               item.itemId,
            index:                item.index,
            nombre:               item.texto,
            codigo:               item.codigo,
            enabled:              item.enabled,
            noAplica:             item.noAplica,
            conclusion:           item.conclusion,
            textArea:             item.textArea,
            numero:               item.index,
            valorCumple:          valorCumple  ,
            valorMejora:          valorMejora  ,
            valorNoAplica:        valorNoAplica,
            valorNoCumple:        valorNoCumple,
            color:                'unset',
            mostrarObservaciones: (item.observaciones),
            valorSi:              (item.valorBooleano === true),
            valorNo:              (item.valorBooleano === false),
          };
        });
        this.itemListLineaInp   = this.itemList.filter((item) => item?.codigo.includes('linea_inp'))
        this.itemListLineaBool  = this.itemList.filter((item) => item?.codigo.includes('linea_bool'))
        this.itemListPilarInp   = this.itemList.filter((item) => item?.codigo.includes('pilar_inp'))
        this.itemListPilarBool  = this.itemList.filter((item) => item?.codigo.includes('pilar_bool'))
        this.itemListPletinaInp = this.itemList.filter((item) => item?.codigo.includes('pletina_inp'))
        this.itemListInspeccion = this.itemList.filter((item) => item?.codigo.includes('inspeccion_visual'))
        this.itemConclusion     = this.itemList.find((item) => item?.conclusion === true)
      }
    },
  },
}
</script>

<style scoped>
.check-inspeccion {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}

.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
</style>
