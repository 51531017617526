<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb float-xl-end">
			<li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
			<li class="breadcrumb-item active">Dashboard</li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<h1 class="page-header">Dashboard <small>Agendamiento</small></h1>
		<!-- END page-header -->

		<!-- BEGIN row -->
<!--		<div class="row">
			&lt;!&ndash; BEGIN col-3 &ndash;&gt;
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon"><i class="fa fa-desktop"></i></div>
					<div class="stats-info">
						<h4>TOTAL VISITORS</h4>
						<p>3,291,922</p>
					</div>
					<div class="stats-link">
						<a href="javascript:;">View Detail <i class="fa fa-arrow-alt-circle-right"></i></a>
					</div>
				</div>
			</div>
			&lt;!&ndash; END col-3 &ndash;&gt;
			&lt;!&ndash; BEGIN col-3 &ndash;&gt;
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-info">
					<div class="stats-icon"><i class="fa fa-link"></i></div>
					<div class="stats-info">
						<h4>BOUNCE RATE</h4>
						<p>20.44%</p>
					</div>
					<div class="stats-link">
						<a href="javascript:;">View Detail <i class="fa fa-arrow-alt-circle-right"></i></a>
					</div>
				</div>
			</div>
			&lt;!&ndash; END col-3 &ndash;&gt;
			&lt;!&ndash; BEGIN col-3 &ndash;&gt;
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-orange">
					<div class="stats-icon"><i class="fa fa-users"></i></div>
					<div class="stats-info">
						<h4>UNIQUE VISITORS</h4>
						<p>1,291,922</p>
					</div>
					<div class="stats-link">
						<a href="javascript:;">View Detail <i class="fa fa-arrow-alt-circle-right"></i></a>
					</div>
				</div>
			</div>
			&lt;!&ndash; END col-3 &ndash;&gt;
			&lt;!&ndash; BEGIN col-3 &ndash;&gt;
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-red">
					<div class="stats-icon"><i class="fa fa-clock"></i></div>
					<div class="stats-info">
						<h4>AVG TIME ON SITE</h4>
						<p>00:12:23</p>
					</div>
					<div class="stats-link">
						<a href="javascript:;">View Detail <i class="fa fa-arrow-alt-circle-right"></i></a>
					</div>
				</div>
			</div>
			&lt;!&ndash; END col-3 &ndash;&gt;
		</div>-->
		<!-- END row -->

		<!-- BEGIN row -->
		<div class="row">
			<!-- BEGIN col-8 -->
<!--			<div class="col-xl-8">
				&lt;!&ndash; BEGIN panel &ndash;&gt;
				<panel title="Temperatura ">
					<line-chart :data="lineChart.data" :options="lineChart.options" class="h-250px"></line-chart>
				</panel>

			</div>-->
			<!-- END col-8 -->
			<!-- BEGIN col-4 -->
<!--			<div class="col-xl-4">
				&lt;!&ndash; BEGIN panel &ndash;&gt;
				<panel title="Analytics Details" bodyClass="p-0">
					<div class="table-responsive mb-0">
						<table class="table table-panel align-middle mb-0">
							<thead>
								<tr>
									<th>Source</th>
									<th>Total</th>
									<th>Trend</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td nowrap><label class="badge bg-danger">Unique Visitor</label></td>
									<td>13,203 <span class="text-success"><i class="fa fa-arrow-up"></i></span></td>
									<td>
										<sparkline height="23">
											<sparklineLine :data="sparkline1.data" :limit="sparkline1.length" :styles="sparkline1.lineStyle" />
										</sparkline>
									</td>
								</tr>
								<tr>
									<td nowrap><label class="badge bg-warning">Bounce Rate</label></td>
									<td>28.2%</td>
									<td>
										<sparkline height="23">
											<sparklineLine :data="sparkline2.data" :limit="sparkline1.length" :styles="sparkline2.lineStyle" />
										</sparkline>
									</td>
								</tr>
								<tr>
									<td nowrap><label class="badge bg-success">Total Page Views</label></td>
									<td>1,230,030</td>
									<td>
										<sparkline height="23">
											<sparklineLine :data="sparkline3.data" :limit="sparkline1.length" :styles="sparkline3.lineStyle" />
										</sparkline>
									</td>
								</tr>
								<tr>
									<td nowrap><label class="badge bg-blue">Avg Time On Site</label></td>
									<td>00:03:45</td>
									<td>
										<sparkline height="23">
											<sparklineLine :data="sparkline4.data" :limit="sparkline4.length" :styles="sparkline4.lineStyle" />
										</sparkline>
									</td>
								</tr>
								<tr>
									<td nowrap><label class="badge bg-default text-gray-900">% New Visits</label></td>
									<td>40.5%</td>
									<td>
										<sparkline height="23">
											<sparklineLine :data="sparkline5.data" :limit="sparkline5.length" :styles="sparkline5.lineStyle" />
										</sparkline>
									</td>
								</tr>
								<tr>
									<td nowrap><label class="badge bg-inverse">Return Visitors</label></td>
									<td>73.4%</td>
									<td>
										<sparkline height="23">
											<sparklineLine :data="sparkline6.data" :limit="sparkline6.length" :styles="sparkline6.lineStyle" />
										</sparkline>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</panel>
			</div>-->
			<!-- END col-4 -->
		</div>
		<!-- END row -->
	</div>
</template>

<script>
// import LineChart from '../components/vue-chartjs/LineChart'
import AppOptions from '../config/AppOptions.vue'
import axios from "axios";

export default {
	components: {
		// LineChart,
	},
  created: function(){
    this.$validarLogin()
    this.getUserId()
  },
  methods:{
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    async getUserId() {
      this.loadingSpiner()
      //API URL
      // const api = "http://localhost:8181/ta-ms-users/obtenerDatos";
      const api = process.env.VUE_APP_IP_BACKEND + "/user/obtenerDatos";
      // GET TOKEN
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      this.form.username = obtenerToken.username
      try {
        //PETICION
        let response = await axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200) {
          const jsonUserId = response.data
          const userId = JSON.stringify(jsonUserId)
          localStorage.setItem('saveId', userId)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        if (this.error_msg.request.status == 401) {
          this.$expirarToken()
        }
      }
    },




  },
	data() {
		// eslint-disable-next-line
		Chart.defaults.global.defaultColor = 'rgba('+ window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim() + ', .65)';
		// eslint-disable-next-line
		Chart.defaults.global.defaultFontFamily = window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim();
		// eslint-disable-next-line
		Chart.defaults.global.defaultFontStyle = 600;
		// eslint-disable-next-line
		Chart.defaults.scale.gridLines.color = 'rgba('+ window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim() + ', .15)';
		// eslint-disable-next-line
		Chart.defaults.scale.gridLines.zeroLineColor = 'rgba('+ window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim() + ', 0)';

		let today = new Date()
		var eventsData = [{
			date: `${today.getFullYear()}/${today.getMonth() + 1}/15`,
			title: 'Sales Reporting',
			badge: 'bg-gradient-teal',
			time: '9:00am'
		},{
			date: `${today.getFullYear()}/${today.getMonth() + 1}/24`,
			title: 'Have a meeting with sales team',
			badge: 'bg-gradient-blue',
			time: '2:45pm'
		}]

		return {
      form: {
        "username": "",
      },
			lineChart: {
				data: {
					labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
					datasets: [{
						label: 'Page Views',
						backgroundColor: 'rgba(52, 143, 226, 0.2)',
						borderColor: AppOptions.color.blue,
						pointBackgroundColor: AppOptions.color.blue,
						pointRadius: 2,
						borderWidth: 2,
						data: [45, 60, 48, 55, 49, 58, 50, 45, 52, 39, 43, 67]
					}, {
						label: 'Visitors',
						backgroundColor: 'rgba(45, 53, 60, 0.2)',
						borderColor: AppOptions.color.componentColor,
						pointBackgroundColor: AppOptions.color.componentColor,
						pointRadius: 2,
						borderWidth: 2,
						data: [22, 18, 20, 18, 20, 23, 20, 18, 22, 18, 20, 23]
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					hover: {
						mode: 'nearest',
						intersect: true
					},
					tooltips: {
						mode: 'index',
						intersect: false,
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero:true,
								max: 100
							}
						}]
					}
				}
			},


			events: {
				data: eventsData,
				displayData: eventsData
			},
			sparkline1: {
				data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
				length: 12,
				lineStyle: {
					stroke: '#ff5b57',
					strokeWidth: 2
        }
      },
			sparkline2: {
				data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
				length: 12,
				lineStyle: {
					stroke: '#f59c1a',
					strokeWidth: 2
        }
      },
			sparkline3: {
				data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
				length: 12,
				lineStyle: {
					stroke: '#00ACAC',
					strokeWidth: 2
        }
      },
			sparkline4: {
				data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
				length: 12,
				lineStyle: {
					stroke: '#348fe2',
					strokeWidth: 2
        }
      },
			sparkline5: {
				data: [ 50,30,45,40,50,20,35,40,90,90,90,90 ],
				length: 12,
				lineStyle: {
					stroke: '#b6c2c9',
					strokeWidth: 2
        }
      },
			sparkline6: {
				data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
				length: 12,
				lineStyle: {
					stroke: '#2d353c',
					strokeWidth: 2
        }
      }
		}
	}
}
</script>
