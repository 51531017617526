<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Conductor</a></li>
      <li class="breadcrumb-item active">Crear</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Crear conductor <small></small></h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <panel title="Crea un conductor">
      <form v-on:submit.prevent="crearConductor" method="POST">
        <div class="mb-3">
          <label class="form-label" id="nombreConductor"><strong>Nombre Conductor</strong> </label>
          <input class="form-control" type="text" v-model="form.nombre"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="apellidoConductor"><strong>Apellido Conductor</strong> </label>
          <input class="form-control" type="text" v-model="form.apellido"/>
        </div>
        <div class="mb-3">
          <label class="form-label" id="licencia"><strong>Licencia Conductor</strong> </label>
          <input class="form-control" type="text" v-model="form.licencia"/>
        </div>
        <button type="button" class="btn btn-primary" v-on:click="crearConductor()">Crear</button>
      </form>
    </panel>
    <!--		 END panel-->
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        "nombre":"",
        "apellido":"",
        "licencia": "",
      },
      jsonDataConductor: [],
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "topRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  created: function () {
    this.$validarLogin()
  },
  methods: {
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },

    async crearConductor() {
      this.loadingSpiner()
      if (!this.form.nombre || !this.form.apellido || !this.form.licencia) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene todos los campos correctamente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      // const api = "http://localhost:8181/ta-ms-agente/agenteVirtual/saveAgente";
      const api = process.env.VUE_APP_IP_BACKEND + "/conductor/saveConductor";
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token


      const obtenerUserId = JSON.parse(localStorage.getItem('saveId'));
      const userId= obtenerUserId.idUsuario
      const nombreUser = obtenerUserId.nombre +" "+  obtenerUserId.apellido
      this.form.idUsuario = userId
      this.form.nombreUsuario = nombreUser
      console.log(this.form)
      try {
        //PETICION
        let response = await axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.jsonDataConductor = response.data
          // const datosConductor = JSON.stringify(this.jsonDataConductor) //stringify obtiene string del objeto
          // localStorage.setItem('showConductor', datosConductor)
          this.$router.push({path: '/showConductor/' + this.jsonDataConductor.id})
          this.showToastSuccess('Conductor creado !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },

  }
}
</script>