<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Crear</a></li>
      <li class="breadcrumb-item active">Lugar</li>
    </ol>
    <h1 class="page-header">Crear Lugar <small></small></h1>
    <panel title="Formulario de creación de Lugares">
      <div class="row">
        <form v-on:submit.prevent="crearLugar" method="POST">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="nombre"><strong>Nombre*</strong> </label>
              <input class="form-control" type="text" v-model="form.nombre" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="descripcion"><strong>Descripción</strong> </label>
              <textarea class="form-control" v-model="form.descripcion" rows="4"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label" id="latitud"><strong>Latitud</strong> </label>
              <input class="form-control" type="number" v-model="form.latitud" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label" id="longitud"><strong>Longitud</strong> </label>
                <input class="form-control" type="number" v-model="form.longitud" />
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="lugar"><strong>Comuna*</strong> </label>
            <select class="form-control" v-if="ubicacionComuna.length > 0" v-model="form.comuna">
              <option value="" disabled selected>Seleccione una Comuna</option>
              <option v-for="comuna in ubicacionComuna" :value="comuna.id" :key="comuna.id">
                {{ comuna.nombre }}
              </option>
            </select>
          </div>
          <form class="form-horizontal form-bordered">
            <div class="row justify-content-center">
              <h5 class="mb-3">Tipo</h5>
              <div v-for="(tipo, index) in tiposUbicaciones" :key="tipo.id" class="mb-3">
                <label class="form-check-label">
                  {{ index + 1 }}. {{ tipo.nombre }}
                  <input class="form-check-input" style="" type="checkbox" :value="tipo.id" v-model="form.tipoUbicacion">
                </label>
              </div>
            </div>
          </form>
          <button type="button" class="btn btn-primary" @click="crearLugar()">Crear</button>
        </form>
      </div>
    </panel>
    <!-- END panel -->
  </div>
</template>
<script>
import axios from "axios";
import {es} from 'vuejs-datepicker/dist/locale'
export default {

  data(){
    return{
      es:es,
      ubicacionComuna:[],
      tiposUbicaciones:[],
      form:{
        "nombre":"",
        "descripcion":"",
        "latitud": "",
        "longitud": "",
        comuna:null,
        tipoUbicacion:[],
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },

    }
  },
  created: function(){
    this.$validarLogin()
  },
  async mounted(){
    try {
      this.ubicacionComuna = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaComuna');
      this.tiposUbicaciones = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/tipoUbicacionList');
    } catch (error) {
      console.error(error);
      // Manejar errores
    }
  },

  methods:{
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async fetchData(url) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        // Manejar errores
        return []; // Devolver un array vacío en caso de error
      }
    },


    async crearLugar(){
      this.loadingSpiner()
      // const api = 'http://68.183.108.224:8080/ta-ms-users/user'
      if (!this.form.comuna) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene la Comuna!', 'UPS!', this.notificationSystem.options.error);
        return;
      }if (!this.form.nombre) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene el Nombre!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + `/ubicacion/saveLugar/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token


      try {
        //PETICION
        let response = await axios.post(api, this.form,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.$router.push({path: '/agendamiento/listaLugar'})
          this.showToastSuccess('Lugar creado !', 'Genial!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
  }
}

</script>


