<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Ver</a></li>
      <li class="breadcrumb-item active">Usuario</li>
    </ol>
    <h1 class="page-header">Ver Usuario <small></small></h1>
    <panel title="Detalles del usuario">
      <form v-on:submit.prevent="" method="POST" autocomplete="off">
        <div class="mb-3 col-md-12">
          <label class="form-label"><strong>Nombre de usuario:</strong></label>
          <p class="form-control">{{ showDataPerfil.username }}</p>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label"><strong>Contraseña:</strong></label>
          <p class="form-control">{{ '************' }}</p>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label"><strong>Nombre:</strong></label>
          <p class="form-control">{{ showDataPerfil.nombre }}</p>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label"><strong>Apellido Paterno:</strong></label>
          <p class="form-control">{{ showDataPerfil.apellidoPaterno }}</p>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label"><strong>Apellido Materno:</strong></label>
          <p class="form-control">{{ showDataPerfil.apellidoMaterno }}</p>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label"><strong>Rut:</strong></label>
            <p class="form-control">{{ showDataPerfil.rut }}</p>
          </div>
          <div class="mb-3 col-md-2">
            <label class="form-label"><strong>DV:</strong></label>
            <p class="form-control">{{ showDataPerfil.codigoVerificador }}</p>
          </div>
        </div>
        <div class="datepicker">
          <label class="form-label"><strong>Fecha de Nacimiento:</strong></label>
          <p class="form-control">{{ customFormatter(showDataPerfil.fechaNacimiento) }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Sexo:</strong></label>
          <p class="form-control">{{ showDataPerfil.sexo }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Fono:</strong></label>
          <p class="form-control">{{ showDataPerfil.fono }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Celular:</strong></label>
          <p class="form-control">{{ showDataPerfil.fonoCelular }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Correo:</strong></label>
          <p class="form-control">{{ showDataPerfil.email }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Cargo:</strong></label>
          <p class="form-control">{{ showDataPerfil.cargo }}</p>
        </div>
        <div v-for="ubicacion in ubicaciones" :key="ubicacion.id" class="mb-3">
          <label class="form-check-label">
            {{ ubicacion.nombre }}
            <input
                class="form-check-input"
                type="checkbox"
                :value="ubicacion.id"
                v-model="showDataPerfil.lugaresDeInspeccion"
                disabled
            >
          </label>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Calle:</strong></label>
          <p class="form-control">{{ showDataPerfil.domicilio.calleUsuario }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Numero:</strong></label>
          <p class="form-control">{{ showDataPerfil.domicilio.numeroUsuario }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Departamento:</strong></label>
          <p class="form-control">{{ showDataPerfil.domicilio.departamentoUsuario }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label"><strong>Ciudad:</strong></label>
          <p class="form-control">{{ showDataPerfil.domicilio.ciudadUsuario }}</p>
        </div>
        <div class="mb-3 col-md-2">
          <label class="form-label" ><strong>Empresa:</strong></label>
          <p class="form-control" v-if="empresaList.length > 0">{{ showDataPerfil.empresa }}</p>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="enabled">
            Usuario Habilitado
            <input class="form-check-input" type="checkbox" id="enabled" v-model="showDataPerfil.enabled" disabled>
          </label>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-secondary" v-on:click="cancelar()">Salir</button>
          </div>
          <div class="col-sm-8"></div>
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-primary" v-on:click="editarUsuario()">Editar</button>
          </div>
        </div>
      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ShowPerfil",
  data(){
    return{
      sexoList:[],
      cargoList:[],
      ubicaciones:[],
      ciudadUsuarioList:[],
      empresaList:[],
      mondayFirst:true,
      form:{
        "username":"",
        "password":"",
        "nombre": "",
        "apellidoPaterno": "",
        "apellidoMaterno": "",
        "email": "",
        "rut": '',
        "codigoVerificador": '',
        "fechaNacimiento": '',
        "fono": '',
        "fonoCelular": '',
        "calleUsuario": '',
        enabled: false,
      },
      showDataPerfil:{
        sexo:'',
        cargo:'',
        ciudadUsuario:'',
        empresa:'',
        lugaresDeInspeccion:[],
        domicilio: {
          calleUsuario: '',
          numeroUsuario: '',
          departamentoUsuario: '',
          ciudadUsuario: ''
        }
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods:{
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return new Date(date).toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    async cargarTodasUbicaciones() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/ubicacion/listaUbicacion`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarUbicacionesUsuario(userId) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/showLugaresInspeccion/${userId}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data.map(ubicacion => ubicacion.id);
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarSexo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/sexoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarCargo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/cargoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarEmpresa() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/empresa/listaEmpresa`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarDireccionUsuario(domicilioId) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/obtenerDomicilio/${domicilioId}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.showDataPerfil.domicilio = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarCiudades() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/ciudad/ciudadList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },

    async getUser(){
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user/'
      const api = process.env.VUE_APP_IP_BACKEND + "/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          if(response.data.rut) {
            response.data.rut = response.data.rut.toString()
          }
          this.showDataPerfil = response.data
          if (!this.showDataPerfil.domicilio || !this.showDataPerfil.domicilio.id) {
            this.showDataPerfil.domicilio = {
              calleUsuario: '',
              numeroUsuario: '',
              departamentoUsuario: '',
              ciudadUsuario: null,
              id: null
            };
          }
          const userId = this.$route.params.id;
          this.showDataPerfil.lugaresDeInspeccion = await this.cargarUbicacionesUsuario(userId);
          if(this.showDataPerfil.sexo && response.data.sexo.id) {
            this.showDataPerfil.sexo = response.data.sexo.sexo;
          }
          if(this.showDataPerfil.cargo && response.data.cargo.id) {
            this.showDataPerfil.cargo = response.data.cargo.nombreCargo;
          }
          if( this.showDataPerfil.lugaresDeInspeccion && response.data.lugaresDeInspeccion) {
            this.showDataPerfil.lugaresDeInspeccion = response.data.lugaresDeInspeccion;
          }
          if(this.showDataPerfil.empresa && response.data.empresa.id) {
            this.showDataPerfil.empresa = response.data.empresa.razonSocial;
          }
          if (this.showDataPerfil.domicilio && this.showDataPerfil.domicilio.id) {
            const domicilioId = this.showDataPerfil.domicilio.id;
            await this.cargarDireccionUsuario(domicilioId);
            if(this.showDataPerfil.domicilio.ciudadUsuario) {
              this.showDataPerfil.domicilio.ciudadUsuario = response.data.domicilio.ciudadUsuario.nombre;
            }
          } else {
            this.showDataPerfil.domicilio = {
              calleUsuario: null,
              numeroUsuario: null,
              departamentoUsuario: null,
              ciudadUsuario: null
            };
          }
          console.log(response.data)
          this.loader.hide(this.showDataPerfil)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }

    },
    async editarUsuario(){
      this.loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + "/user/editarUsuario/"
      // const api = "http://localhost:8181/ta-ms-users/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      try {
        //PETICION
        let response = await axios.put(apiId,this.showDataPerfil, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          console.log(this.showDataPerfil)
          this.showDataPerfil = response.data
          console.log(response.data)
          if (response.data.sexoId !== undefined) {
            this.showDataPerfil.sexo = response.data.sexoId;
          }
          if (response.data.cargoId !== undefined) {
            this.showDataPerfil.cargo = response.data.cargoId;
          }
          if (response.data.empresaId !== undefined) {
            this.showDataPerfil.empresa = response.data.empresaId;
          }
          if (response.data.lugaresDeInspeccionIds !== undefined) {
            this.showDataPerfil.lugaresDeInspeccion = response.data.lugaresDeInspeccionIds;
          }
          if (response.data.domicilio && response.data.domicilio.ciudadUsuarioId !== undefined) {
            this.showDataPerfil.domicilio.ciudadUsuario = response.data.domicilio.ciudadUsuarioId;
          }
          this.showToastSuccess('Usuario '+" "+ this.showDataPerfil.nombre+ " editado.", 'Exito!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        console.error("Error en editarUsuario:", error)
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    cancelar(){
      this.$router.push({path: '/agendamiento/dashboard/diarias'})
    },
  },
  async mounted() {
    this.sexoList = await this.cargarSexo();
    this.cargoList = await this.cargarCargo();
    this.ubicaciones = await this.cargarTodasUbicaciones();
    this.ciudadUsuarioList = await this.cargarCiudades();
    this.empresaList = await this.cargarEmpresa();
  },
  created() {
    this.$validarLogin()
    this.getUser()
  }


}

</script>

<style>
.datepicker input {
  background-color: white;
  color: black;
  border-color: #c0bfbf;
}
</style>
