<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Listar</a></li>
      <li class="breadcrumb-item active">Inspeccion</li>
      <li class="breadcrumb-item active">Ramplas</li>
    </ol>
    <h1 class="page-header">Lista Inspecciones Ramplas<small></small></h1>
    <panel title="">
      <div class="">
        <vue-excel-xlsx class="btn btn-outline-success"
                        :data="rows"
                        :columns="columns"
                        :file-name="'Inspeccion Agendamiento'"
                        :file-type="'xlsx'"
                        :sheet-name="'Hoja 1'"
        >
          Exportar a excel
        </vue-excel-xlsx>
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true,  position: 'bottom' }"
          :selectOptions="{
					selectOnCheckboxOnly: true,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
					}"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <router-link v-bind:to="'/showInspeccion/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: #5aabd1; text-decoration: underline;">
                {{ props.row.id }}
              </span>
            </router-link>
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ListaInspeccionRamplas",
  data() {
    return {
      columns: [{
        label: 'N°',
        field: 'id',
        width: '10%',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap'
      }, {
        label: 'Equipo',
        field: 'equipo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Transportista',
        field: 'empresaDeTransporte',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
        /*{
        label: 'Propietario',
        field: 'propietarioDelEquipo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },  */
        {
        label: 'Fecha Inspección',
        field: 'fechaDeInspeccionSolicitada',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Lugar',
        field: 'lugarDeInspeccion',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Estado',
        field: 'estado',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Informe',
        field: 'numeroInforme',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },

      ],
      rows: [
        {}
      ],
      listInspeccionRamplas: [],
    }
  },
  methods: {
    asignarData() {
      this.rows = this.listInspeccionRamplas;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    convertirFecha(fechaIso) {
      const fecha = new Date(fechaIso);
      return fecha.toISOString().split('T')[0]; // Esto devuelve la fecha en formato 'YYYY-MM-DD'
    },
    async listaInspeccionRamplas() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/listaInspeccionRamplas'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listInspeccionRamplas = response.data.map(inspeccion => {
            if (inspeccion.fechaDeInspeccionSolicitada) {
              inspeccion.fechaDeInspeccionSolicitada = this.convertirFecha(inspeccion.fechaDeInspeccionSolicitada);
            }
            return inspeccion;
          });
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.listaInspeccionRamplas()
  }
}
</script>

<style scoped>

</style>
