<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Solicitar Inspección</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Solicitar Revisión <small></small></h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <panel title="Solicitar Revisión">
        <div class="col-md-6 mb-3">
          <label for="solicitante-select" class="form-label"><strong>Solicitante</strong></label>
          <v-select id="solicitante-select"
                    label="nombre"
                    :options="solicitanteList"
                    :reduce="solicitante => solicitante.id"
                    v-model="form.solicitante">
          </v-select>
        </div>
      <div class="col-md-6 mb-3">
        <label class="form-label" id="equipo"><strong>Equipo*</strong> </label>
        <v-select
            :options="equipoList.map(equipo => ({ label: equipo.patente.toUpperCase(), value: equipo.id }))"
            label="label"
            v-model="form.equipo"
            @input="rellenarInfoEquipo"
        >
          <template #no-options>
            Lo sentimos, no hay opciones disponibles.
          </template>
        </v-select>
      </div>
      <div class="col-md-6 mb-3" v-if="form.equipo === 'nuevo'">
        <label class="form-label" id="patente"><strong>Patente/Número*</strong> </label>
        <input class="form-control" type="text" v-model="form.patente" @input="validateInput"/>
      </div>
      <div class="col-md-6 mb-3">
        <label class="form-label" id="tipoEquipamiento"><strong>Tipo de carga*</strong> </label>
        <select class="form-control" v-model="form.tipoEquipamiento">
          <option v-for="tipo in tipoEquipamientoList" :value="tipo.id" :key="tipo.id" :style="{textTransform: 'uppercase'}">
            {{ tipo.nombre }}
          </option>
        </select>
      </div>
        <div class="col-md-6 mb-3">
          <label class="form-label" id="lugar"><strong>Lugar*</strong> </label>
          <select class="form-control" v-model="form.lugar">
            <option v-for="lugar in ubicaciones" :value="lugar.id" :key="lugar.id">
              {{ lugar.nombre }}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-6">
          <label class="form-label" for="fechaSoilcitada"><strong>Fecha Solicitada*</strong></label>
          <div class="datepicker d-flex align-items-center">
            <Datepicker :disabled-dates="computedDisabledDates" :language="es" mondayFirst v-model="form.fechaSoilcitada" :format="customFormatter" style="margin-right: 10px"></Datepicker>
            <div class="time-picker-wrapper" style="margin-top: -8px">
              <select class="time-picker" v-model="time.hours" @change="updateTime">
                <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
              </select>
              <span style="margin-left: -5px">:</span>
              <select class="time-picker" v-model="time.minutes" @change="updateTime">
                <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
              </select>
            </div>
          </div>
        </div>
      <div class="alert alert-info mb" align="center">
        <strong>Observación:</strong> La inspección dura Aproximadamente 2 horas.
      </div>
        <button type="button" class="btn btn-primary" v-on:click="solicitarInspeccion()">Crear</button>
    </panel>
    <!--		 END panel-->
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from "vuejs-datepicker/dist/locale";

export default {
  components:{
    Datepicker
  },
  data() {
    const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
    let roles  = obtenerToken["roles"]
    return {
      time: {
        hours: '09',
        minutes: '00'
      },
      hours: Array.from({ length: 8  }, (v, k) => (9 + k).toString().padStart(2, '0')),
      // Minutos se mantiene igual si necesitas rangos completos de minutos para cada hora
      minutes: Array.from({ length: 60 }, (v, k) => k.toString().padStart(2, '0')),
      fecha: new Date(),
      hora: '12:00',
      es:es,
      usuarioActual: null,
      equipoList:[],
      tipoEquipamientoList:[],
      ubicaciones:[],
      formularioTipos:[],
      solicitanteList:[],
      form: {
        "solicitante":null,
        "equipo": 'nuevo',
        "patente":"",
        tipoEquipamiento:'',
        "lugar": null,
        "fechaSoilcitada":"",
        "formularioTipo":""
      },
      roles:roles,
      disabledDates: {
        to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),  // Deshabilita días pasados
        days: [6, 0], // Deshabilita sábados (6) y domingos (0)
        dates: [
          new Date(2024, 0, 1),  // Año Nuevo
          new Date(2024, 2, 29), // Semana Santa
          new Date(2024, 4, 1),  // Día del Trabajo
          new Date(2024, 4, 21), // Día de las Glorias Navales
          // new Date(2024, 4, 30), // Corpus Christi
          new Date(2024, 5, 20), // Día de los pueblos Indígenas
          new Date(2024, 6, 16), // Día de la Virgen del Carmen
          new Date(2024, 7, 15), // La Asunción de la Virgen
          new Date(2024, 8, 18), // Fiestas Patrias
          new Date(2024, 8, 19), // Día de las Glorias del Ejército
          new Date(2024, 8, 20), // Fiestas Patrias
          new Date(2024, 9, 31), // Halloween
          new Date(2024, 10, 1), // Dia de todos los santos
          new Date(2024, 11, 25) // Navidad
          // Agrega aquí más fechas de días feriados
        ]
      },
      jsonDataInspeccion: [],
    }
  },
  watch:{
    'form.equipo': function (newValue) {
      if (newValue === null || newValue === 'nuevo') {
        this.form.equipo = 'nuevo';
      }
    },
  },
  computed: {
    fechaSoilcitada() {
      let [hours, minutes] = this.hora.split(':');
      return new Date(this.fecha.setHours(hours, minutes));
    },
    isAdmin() {
      return this.roles.includes("ROLE_ADMIN");
    },
    computedDisabledDates() {
      if (this.isAdmin) {
        return {}; // Sin deshabilitar ninguna fecha
      }
      return this.disabledDates; // Configuración original de fechas deshabilitadas
    }
  },
  created: function () {
    this.$validarLogin()
    this.cargarUsuarioActual();
  },
  async mounted() {
    const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
    const username = obtenerToken.username;
    this.equipoList           = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquipo',{ username: username });
    this.tipoEquipamientoList = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/tipoEquipamientoList');
    this.ubicaciones          = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacionProgramadas');
    this.formularioTipos      = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/formulario/formularioTipoList');
    this.solicitanteList      = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listSolicitantesSolicitarRevision', { username: username });
  },
  methods: {
    validateInput(event) {
      const input = event.target.value;
      // Elimina todos los caracteres que no sean letras o números
      const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
      // Actualiza el modelo de datos
      this.form.patente = cleanedInput;
    },
    updateTime() {
      if (this.form.fechaSoilcitada) {
        // Asegúrate de que form.fechaSoilcitada sea un objeto Date
        const date = new Date(this.form.fechaSoilcitada);

        // Establece las horas y minutos según lo seleccionado por el usuario
        date.setHours(this.time.hours);
        date.setMinutes(this.time.minutes);

        // Formatea la fecha y hora en la zona horaria de Chile para enviar al backend
        const fechaParaBackend = date.toLocaleString('es-ES', {
          timeZone: 'America/Santiago', // Zona horaria de Chile Continental
          hour12: false
        }).replace(/ /g, 'T'); // Reemplaza espacios con T para seguir un formato similar al ISO pero sin la Z

        // Actualiza el objeto form.fechaSoilcitada con la nueva fecha y hora
        this.form.fechaSolicitada = fechaParaBackend; // Asumiendo que esta es la propiedad a enviar
      }
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async cargarUsuarioActual() {
      const datosUsuario = JSON.parse(localStorage.getItem('saveUser'));
      if (datosUsuario && datosUsuario.username) {
        try {
          const response = await axios.post(process.env.VUE_APP_IP_BACKEND + '/user/obtenerDatos', {
            username: datosUsuario.username
          }, {
            headers: {
              "Authorization": `Bearer ${datosUsuario.access_token}`
            }
          });
          this.usuarioActual = {
            idUsuario: response.data.idUsuario,
            nombre: response.data.nombre,
            apellido: response.data.apellido
          };
          this.form.solicitante = this.usuarioActual.idUsuario;
        } catch (error) {
          console.error('Error al cargar datos del usuario:', error);
        }
      } else {
        console.error('Datos del usuario actual no están disponibles');
      }
    },
    async solicitarInspeccion() {
      this.updateTime()
      this.$loadingSpiner()
      if (!this.form.solicitante || !this.form.lugar || !this.form.fechaSoilcitada) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Rellene todos los campos correctamente!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      if (this.form.equipo === 'nuevo' && !this.form.patente && !this.form.tipoEquipamiento) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Rellene todos los campos correctamente!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const username = obtenerToken.username
      const api =`${process.env.VUE_APP_IP_BACKEND}/inspeccion/solicitudInspeccion?username=${username}`

      try {
        //PETICION
        let response = await axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.jsonDataInspeccion = response.data
          // this.$router.push({path: '/showConductor/' + this.jsonDataConductor.id})
          this.$nextTick(() => {
            this.$router.push({ name: 'ShowInspeccionProgramada', params: { id: response.data.id } });
          });
          this.$showToastSuccess('Solicitud creada !', 'Genial!', this.$notificationSystem().options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        console.log(this.error_msg)
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else if(this.error_msg.request.status == 400) {
          let mensajeError = this.error_msg.response.data.mensajeError
          console.log(mensajeError)
          this.$showToastError(mensajeError, 'UPS!', this.$notificationSystem().options.error)
        }else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async rellenarInfoEquipo(id){
      if(id && id.value){
        id = id.value
      } else {
        id = null
      }
      const api = process.env.VUE_APP_IP_BACKEND + "/vehiculo/showEquipo/" + id;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          let resp = response.data
          this.form.tipoEquipamiento        = resp.tipoEquipamiento
        }
      } catch (error) {
        this.error_msg = error
        if (this.error_msg.request.status == 404){
          this.form.tipoEquipamiento                                   = null
        }
        else if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        }
      }
    },
  }
}
</script>
<style >
.datepicker input {
  background-color: #ffffff; /* Color de fondo */
  border: 1px solid #cecece; /* Borde */
  border-radius: 4px; /* Redondeo de bordes */
  font-size: 0.75rem; /* Tamaño de fuente */
  padding: 0.4375rem 0.75rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}
.datepicker input:focus{
  border-color: #495057; /* Color del borde cuando está enfocado */
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255); /* Sombra exterior opcional para resaltar el foco */
  outline: none;
}
.time-picker-wrapper {
  border: 1px solid #a3a5a6; /* Asumiendo que quieres este color de borde */
  border-radius: 4px;
  display: inline-flex;
  background-color: #ffffff; /* o el color que desees */
  align-items: center; /* Asegura que los elementos internos estén alineados verticalmente */

}

.time-picker {
  padding: 5px;
  background-color: transparent;
  color: #000000;
  border: none; /* Elimina el borde para que parezca un campo unificado */
  -moz-appearance: none; /* Elimina el estilo por defecto en Firefox */
  -webkit-appearance: none; /* Elimina el estilo por defecto en Chrome y Safari */
  appearance: none;
  margin-right: 5px; /* Añade un margen entre los selectores */
}

.time-picker:last-child {
  margin-right: 0; /* Elimina el margen derecho del último selector */
}

.time-picker:focus {
  outline: none;
  border-color: #cecece; /* Si quieres un borde cuando está enfocado, añade aquí */
}
.time-picker option {
  background-color: #ffffff; /* Color de fondo para las opciones */
  color: #000000; /* Color del texto para las opciones */
}

/* Estilos para navegadores basados en WebKit */
.time-picker::-webkit-scrollbar {
  width: 12px;
}

.time-picker::-webkit-scrollbar-track {
  background: #ffffff;
}

.time-picker::-webkit-scrollbar-thumb {
  background-color: #9c9ca6; /* Color de la barra de desplazamiento */
  border-radius: 6px;
}

.time-picker::-webkit-scrollbar-thumb:hover {
  background-color: #9c9ca6; /* Color de la barra de desplazamiento al pasar el mouse */
}
.datepicker .disabled {
  color: #cccccc !important; /* Gris claro para el texto */
  background-color: #f9f9f9 !important; /* Fondo más claro */
  pointer-events: none; /* Evita que se hagan clic */
  cursor: not-allowed; /* Cambia el cursor a "no permitido" */
}
.datepicker /deep/ .vdp-datepicker__calendar {
  top: auto; /* Resetea cualquier valor previo */
  bottom: 1000%;
  left: -10%;
}
</style>