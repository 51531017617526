import { render, staticRenderFns } from "./ListaLugar.vue?vue&type=template&id=4b878761&scoped=true&"
import script from "./ListaLugar.vue?vue&type=script&lang=js&"
export * from "./ListaLugar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b878761",
  null
  
)

export default component.exports