<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Listar</a></li>
      <li class="breadcrumb-item active">Inspeccion</li>
    </ol>
    <h1 class="page-header">Lista Inspecciones <small></small></h1>
    <panel title="">
<!--      <div class="row">-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="numeroInforme"><strong>Número Informe</strong> </label>-->
<!--          <input class="form-control" type="text" v-model="form.numeroInforme"/>-->
<!--        </div>-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="transportista"><strong>Transportista</strong> </label>-->
<!--          <select class="form-control" v-if="empresasTipoPorEmpresa.length > 0" v-model="form.transportista">-->
<!--            <option v-for="transportista in empresasTipoPorEmpresa" :value="transportista.id" :key="transportista.id">-->
<!--              {{transportista.razonSocial }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="solicitante"><strong>Solicitante</strong></label>-->
<!--          <select class="form-control" v-if="solicitanteList.length > 0" v-model="form.solicitante">-->
<!--            <option v-for="solicitante in solicitanteList" :value="solicitante.id" :key="solicitante.id">-->
<!--              {{ solicitante.nombre }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="lugar"><strong>Lugar Inspección</strong> </label>-->
<!--          <select class="form-control" v-model="form.lugar">-->
<!--            <option v-for="lugar in ubicaciones" :value="lugar.id" :key="lugar.id">-->
<!--              {{ lugar.nombre }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="equipo"><strong>Equipo</strong> </label>-->
<!--          <select class="form-control" v-if="equipoList.length > 0" v-model="form.equipo">-->
<!--            <option v-for="equipo in equipoList" :value="equipo.id" :key="equipo.id">-->
<!--              {{equipo.patente }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="inspector"><strong>Inspector</strong></label>-->
<!--          <select class="form-control" v-if="inspectorList.length > 0" v-model="form.inspector">-->
<!--            <option v-for="inspector in inspectorList" :value="inspector.id" :key="inspector.id">-->
<!--              {{ inspector.nombre }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="tipoEquipamiento"><strong>Tipo Equipamiento</strong> </label>-->
<!--          <select class="form-control" v-if="equipoTipoEquipamiento.length > 0" v-model="form.tipoEquipamiento">-->
<!--            <option v-for="tipoEquipamiento in equipoTipoEquipamiento" :value="tipoEquipamiento.id" :key="tipoEquipamiento.id">-->
<!--              {{tipoEquipamiento.nombre }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label class="form-label" id="estadoInspeccion"><strong>Estado Inspección</strong> </label>-->
<!--          <select class="form-control" v-if="estadoInspeccion.length > 0" v-model="form.estado">-->
<!--            <option v-for="estadoInspeccion in estadoInspeccion" :value="estadoInspeccion.id" :key="estadoInspeccion.id">-->
<!--              {{estadoInspeccion.nombre }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="col-md-4 mb-3">-->
<!--          <label for="periodo" class="form-label"><strong>Periodo</strong></label>-->
<!--          <select id="periodo" class="form-control" v-model="selectedPeriod">-->
<!--            <option v-for="option in periodOptions" :value="option.value" :key="option.value">-->
<!--              {{ option.text }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="button-container mb-4 mt-4" style="display: flex; justify-content: center; align-items: center; gap: 10px">-->
<!--        <button class="btn btn-green" @click="buscar">Buscar</button>-->
<!--        <button class="btn btn-primary" @click="limpiarFormulario">Limpiar</button>-->
<!--      </div>-->
      <div class="row">
      </div>
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true,  position: 'bottom' }"
          :selectOptions="{
					selectOnCheckboxOnly: true,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
					}"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <router-link v-bind:to="'/showInspeccion/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: #5aabd1; text-decoration: underline;">
                {{ props.row.id }}
              </span>
            </router-link>
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>
<script>
import axios from "axios";
import usuarioInspector from "@/pages/UsuarioInspector.vue";

export default {
  name: "ListaInspeccion",
  computed: {
    usuarioInspector() {
      return usuarioInspector
    }
  },
  data() {
    return {
      empresasTipoPorEmpresa:[],
      ubicaciones:[],
      // equipoList:[],
      equipoTipoEquipamiento:[],
      estadoInspeccion:[],
      inspectores:[],
      solicitanteList:[],
      inspectorList:[],
      selectedPeriod: 'Todo', // Valor por defecto seleccionado
      periodOptions: [
        { text: 'Todo', value: 'Todo' },
        { text: 'Hoy', value: 'Hoy' },
        { text: 'Ayer', value: 'Ayer' },
        { text: 'Esta semana', value: 'Esta semana' },
        { text: 'La semana pasada', value: 'La semana pasada' },
        { text: 'Este mes', value: 'Este mes' },
        { text: 'El mes pasado', value: 'El mes pasado' },
        { text: 'Este año', value: 'Este año' }
      ],
      form:{
        transportista:null,
        numeroInforme:"",
        lugar: null,
        equipo: "",
        tipoEquipamiento:"",
        estado:"",
        inspector:"",
        solicitante:"",

      },
      columns: [{
        label: 'N°',
        field: 'id',
        width: '10%',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap'
      }, {
        label: 'Equipo',
        field: 'equipo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Transportista',
        field: 'empresaDeTransporte',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
        /*{
        label: 'Propietario',
        field: 'propietarioDelEquipo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      }, */
        {
        label: 'Fecha Inspección',
        field: 'fechaDeInspeccionSolicitada',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Lugar',
        field: 'lugarDeInspeccion',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Estado',
        field: 'estado',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Informe',
        field: 'numeroInforme',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
        {
          label: 'Inspector',
          field: 'revisadoPor',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },

      ],
      rows: [
        {}
      ],
      listInspeccion: [],
    }
  },
  methods: {
    async fetchData(url, params = {}) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      // Construir la URL con parámetros
      const query = new URLSearchParams(params).toString();
      const finalUrl = `${url}?${query}`;

      try {
        const response = await axios.get(finalUrl, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return []; // Devolver un array vacío en caso de error
      }
    },
    // async buscar() {
    //   const filtro = {
    //     numeroInforme: this.form.numeroInforme,
    //     transportista: this.form.transportista,
    //     solicitante: this.form.solicitante,
    //     lugar: this.form.lugar,
    //     equipo: this.form.equipo,
    //     inspector: this.form.inspector,
    //     tipoEquipamiento: this.form.tipoEquipamiento,
    //     estado: this.form.estado,
    //     periodo: this.selectedPeriod
    //   };
    //
    //   await this.enviarFiltros(filtro);
    // },
    // async enviarFiltros(filtro) {
    //   const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
    //   const tokenHeader = obtenerToken.access_token;
    //   const username = obtenerToken.username; // Obtiene el username
    //
    //   // Asegúrate de incluir el username en el objeto de filtro
    //   filtro.username = username;
    //
    //   try {
    //     const response = await axios.post(`${process.env.VUE_APP_IP_BACKEND}/inspeccion/busquedaAvanzada`, filtro, {
    //       headers: {
    //         "Authorization": `Bearer ${tokenHeader}`
    //       }
    //     });
    //     this.rows = response.data;
    //   } catch (error) {
    //     console.error("Error al buscar:", error);
    //   }
    // },
    // limpiarFormulario() {
    //   this.form = {
    //     numeroInforme: '',
    //     transportista: '',
    //     solicitante: '',
    //     lugar: '',
    //     equipo: '',
    //     inspector: '',
    //     tipoEquipamiento: '',
    //     estado: '',
    //     periodo: 'Todo'
    //   };
    // },
    asignarData() {
      this.rows = this.listInspeccion;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    convertirFecha(fechaIso) {
      const fecha = new Date(fechaIso);
      return fecha.toISOString().split('T')[0]; // Esto devuelve la fecha en formato 'YYYY-MM-DD'
    },
    async listaInspeccion() {
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const username = obtenerToken.username
      const api =`${process.env.VUE_APP_IP_BACKEND}/inspeccion/listaInspeccion?username=${username}`

      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listInspeccion = response.data.map(inspeccion => {
            if (inspeccion.fechaDeInspeccionSolicitada) {
              inspeccion.fechaDeInspeccionSolicitada = this.convertirFecha(inspeccion.fechaDeInspeccionSolicitada);
            }
            return inspeccion;
          })
              .sort((a, b) => new Date(b.fechaCreacion) - new Date(a.fechaCreacion)); // Ordenar descendentemente por fechaCreacion
          this.asignarData()
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
  },

  async mounted(){
    try {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const username = obtenerToken.username;
      this.empresasTipoPorEmpresa = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/empresa/empresaTransportista', { username: username });
      this.ubicaciones = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacion');
      // this.equipoList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquipo');
      this.equipoTipoEquipamiento = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/tipoEquipamientoList');
      this.estadoInspeccion = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/formulario/estadoInspeccionList');
      this.inspectores = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/usuarioInspector');
      this.solicitanteList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listSolicitantes', { username: username });
      this.inspectorList = await this.fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listaInspectoresBusqueda', { username: username });

    } catch (error) {
      console.error(error);
    }
  },
  created() {
    this.$validarLogin()
    this.listaInspeccion()
  }
}
</script>

<style scoped>

</style>
