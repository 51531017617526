import axios from "axios";

const globales = {
    install(Vue) {

        //Buena práctica para refrescar el access_token con el refresh_token
        //No se usa, se optó por dejar un access_token con una expiración de 30 días.
        Vue.prototype.$refrescarToken = async function (){
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            if(obtenerToken == null){
                this.$validarLogin()
                return
            }
            const api = process.env.VUE_APP_IP_BACKEND + '/oauth/access_token?grant_type=refresh_token&refresh_token=' + obtenerToken.refresh_token

            try {
                let response = await axios.post(api, {},{});
                if (response.status == 200 || response.status == 201 ) {
                    this.jsonDataUser = response.data
                    const datosUser = JSON.stringify(this.jsonDataUser)
                    localStorage.setItem('saveUser',datosUser)
                } else {
                    this.$showToastError('Algo salio mal !', 'UPS!', Vue.notificationSystem.options.error)
                }
            } catch (error) {
                this.error_msg = error
                if (this.error_msg.request.status == 401) {
                    this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', Vue.notificationSystem.options.error)
                    this.$expirarToken()
                }
            }
        }

        Vue.prototype.$expirarToken = function () {
            localStorage.removeItem('saveUser')
            localStorage.removeItem('saveId')
            localStorage.removeItem('userId')
            this.$validarLogin()
        };

        Vue.prototype.$validarLogin = function () {
            const user = localStorage.getItem('saveUser')
            if (user == null) {
                this.$router.push({path: '/auth'})
            }else{
                const userId = localStorage.getItem('saveId')
                if(userId == null){
                    let saveUser = JSON.parse(user)
                    console.log(saveUser.access_token)
                    this.$getUserId(saveUser.access_token,saveUser.username)
                }
            }
        };

        Vue.prototype.$getUserId = async function (tokenHeader, username) {
            const api = process.env.VUE_APP_IP_BACKEND + "/user/obtenerDatos"
            try {
                //PETICION
                let response = await axios.post(api, {username:username}, {headers: {"Authorization": `Bearer ${tokenHeader}`}})
                if (response.status === 200) {
                    const jsonUserId = response.data
                    const userId = JSON.stringify(jsonUserId)
                    localStorage.setItem('saveId', userId)
                    return true
                }
            } catch (error) {
                console.log("Error creando objeto saveId")
                console.log(error)
            }
            return false
        };

        Vue.prototype.$loadingSpiner = function (){
            this.loader = this.$loading.show({
                // Optional parameters
                //container: this.$refs.loadingContainer,
                canCancel: false,
                loader: 'dots',
                width: 200,
                height: 200,
                color: 'rgb(73,245,214)',
                backgroundColor: 'rgb(152,148,148)',
                opacity: 0.5,
                zIndex: 999,
            });
        };

        Vue.prototype.$showToastError = function (message, title, options) {
            this.$toast.error(
                message,
                title,
                options
            )
        };

        Vue.prototype.$showToastSuccess = function (message, title, options) {
            this.$toast.success(
                message,
                title,
                options
            )
        };

        //Para navegar entre formularios
        Vue.prototype.$irVistaInspeccionProgramada = async function (inspeccionId, formularioActualEntidad, volver){
            const api = process.env.VUE_APP_IP_BACKEND + '/formulario/inspeccionProgramadaVistaFormulario/'+ inspeccionId + '/'

            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader = obtenerToken.access_token

            let datosEnvio = {
                'formularioActualEntidad': formularioActualEntidad,
                'volver':                  volver
            }

            try {
                let response = await axios.post(api,datosEnvio,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
                if (response.status == 200 || response.status == 201 ) {
                    this.$router.push({
                        name: response.data.formularioFront, //Devolverá 'FormlarioChecklist', 'FormularioEnsayoNoDestructivo', 'FormularioHermeticidad', 'FormularioKingpin', 'FormularioLneasPilarPletina', 'FormularioValvulaAlivio'
                        params: {inspeccionId:inspeccionId, id: inspeccionId}
                    })
                    this.loader.hide()
                } else {
                    this.loader.hide()
                    this.$showToastError('Algo salio mal !', 'UPS!', Vue.notificationSystem.options.error)
                }
            } catch (error) {
                this.loader.hide()
                this.error_msg = error
                if (this.error_msg.request.status == 401) {
                    this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', Vue.notificationSystem.options.error)
                    this.$expirarToken()
                } else {
                    this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', Vue.notificationSystem.options.error)
                }
            }
        }

        //Para info formulario, devuelve objeto data con la respuesta de la consulta, nulo si hubo un error.
        //Json data tiene: 'inspeccionId', 'itemList', 'fotoList', 'urlImagen', 'formularioTipoCodigo'
        //item en itemList => id, texto, enabled, noAplica, index, conclusion
        //foto en fotoList => nombre, categoria, prefijo, tieneFotoSubida, urlImagen , archivoId
        Vue.prototype.$itemListMasInfoFormularioInspProgramada = async function (inspeccionId, formularioEntidad) {
            const api          = process.env.VUE_APP_IP_BACKEND + `/formulario/itemListInspeccionProgramada/` +inspeccionId
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader  = obtenerToken.access_token

            let datosEnvio = {
                'formularioEntidad': formularioEntidad
            }

            let data = null
            try {
                //PETICION
                let response = await axios.post(api, datosEnvio, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
                if (response.status == 200 || response.status == 201) {
                    data = response.data
                } else {
                    this.$showToastError('Algo salio mal !', 'UPS!', Vue.notificationSystem.options.error)
                }
            } catch (error) {
                console.log(error)
                this.error_msg = error
                if (this?.error_msg?.request?.status === 401) {
                    this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', Vue.notificationSystem.options.error)
                    this.$expirarToken()
                } else {
                    this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', Vue.notificationSystem.options.error)
                }
            }
            return data
        };

        //Para info formulario, devuelve objeto data con la respuesta de la consulta, nulo si hubo un error.
        //Json data tiene: 'inspeccionId', 'fotoListPorCategoria', 'fotoList', 'formularioTipoCodigo'
        //item en fotoListPorCategoria => "equipo": [],"hermeticidad": [],"valvulaAlivio": [],"ensayoNoDestructivo": [],"lineasPilarPletina": [],"Checklist": [],"Ensayos": [],
        //foto en fotoList => nombre, categoria, prefijo, tieneFotoSubida, urlImagen, archivoId, nombreImagen, indiceFotoList,
        Vue.prototype.$fotoListInspeccion = async function (inspeccionId) {
            const api          = process.env.VUE_APP_IP_BACKEND + `/inspeccion/fotoListInspeccion/` +inspeccionId
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader  = obtenerToken.access_token

            let data = null
            try {
                //PETICION
                let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
                if (response.status == 200 || response.status == 201) {
                    data = response.data
                } else {
                    this.$showToastError('Algo salio mal !', 'UPS!', Vue.notificationSystem.options.error)
                }
            } catch (error) {
                console.log(error)
                this.error_msg = error
                if (this?.error_msg?.request?.status === 401) {
                    this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', Vue.notificationSystem.options.error)
                    this.$expirarToken()
                } else {
                    this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', Vue.notificationSystem.options.error)
                }
            }
            return data
        }

        Vue.prototype.$guardarItemsInspeccionProgramada = async function (itemListEnvio, inspeccionId, formularioEntidad, hubodometro) {
            let exito = false
            this.$loadingSpiner()
            const api = process.env.VUE_APP_IP_BACKEND + '/formulario/guardarItemsInspeccionProgramada'
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader = obtenerToken.access_token
            const datosParaEnviar = {
                itemList:          itemListEnvio,
                inspeccionId:      inspeccionId,
                formularioEntidad: formularioEntidad,
                hubodometro: hubodometro,

            };
            try {
                //PETICION
                let response = await axios.post(api, datosParaEnviar, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
                if (response.status == 200 || response.status == 201) {
                    exito = true
                    this.$showToastSuccess('Progreso guardado !', 'Genial!', Vue.notificationSystem.options.success)
                    this.loader.hide()
                } else {
                    this.loader.hide()
                    this.$showToastError('Algo salio mal !', 'UPS!', Vue.notificationSystem.options.error)
                }
            } catch (error) {
                this.loader.hide()
                this.error_msg = error
                if (this.error_msg.request.status == 401) {
                    this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', Vue.notificationSystem.options.error)
                    this.$expirarToken()
                } else {
                    this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', Vue.notificationSystem.options.error)
                }
            }
            return exito
        }

        // datosFoto:
        // {
        //     inspeccionId:     inspeccionId,
        //     fotoSubidaBase64:       imagenEnBase64,
        //     nombreFotoSubida: nombreOriginalDeLaFoto,
        //     categoria:        categoria obtenida de fotoList o ingresada manualmente.
        // }
        // Devuelve urlImagen con la url amazon de la imagen subida. Es nulo si falló la subida.
        Vue.prototype.$subirFotoInspeccion = async function (datosFoto) {
            let urlImagen = null
            const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/guardarFotosInspeccion2'
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader = obtenerToken.access_token
            try {
                //PETICION
                let response = await axios.post(api, datosFoto, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
                if (response.status == 200 || response.status == 201) {
                    urlImagen = response.data.urlImagen
                    this.$showToastSuccess('Progreso guardado !', 'Genial!', Vue.notificationSystem.options.success)
                } else {
                    this.$showToastError('Algo salio mal !', 'UPS!', Vue.notificationSystem.options.error)
                }
            } catch (error) {
                this.error_msg = error
                if (this.error_msg.request.status == 401) {
                    this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', Vue.notificationSystem.options.error)
                    this.$expirarToken()
                } else {
                    this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', Vue.notificationSystem.options.error)
                }
            }
            return urlImagen
        }

        Vue.prototype.$fetchData = async function (url,params = {}) {
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader = obtenerToken.access_token;

            const query = new URLSearchParams(params).toString();
            const finalUrl = `${url}?${query}`;

            try {
                const response = await axios.get(finalUrl, {
                    headers: {
                        "Authorization": `Bearer ${tokenHeader}`
                    }
                });
                return response.data;
            } catch (error) {
                console.error(error);
                // Manejar errores
                return []; // Devolver un array vacío en caso de error
            }
        }

        Vue.prototype.$pingUrl = async function (url) {
            const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
            const tokenHeader = obtenerToken.access_token;

            try {
                const response = await axios.get(url, {
                    headers: {
                        "Authorization": `Bearer ${tokenHeader}`
                    }
                });
                return response.data;
            } catch (error) {
                console.error(error);
                // Manejar errores
                return []; // Devolver un array vacío en caso de error
            }
        }

        // La config de los toasts de éxito y error.
        // 'Vue.notificationSystem' para usar en este archivo
        // 'this.$notificationSystem()' para usar en cualquier otro componente
        Vue.prototype.$notificationSystem = function (){
            return Vue.notificationSystem
        }
        Vue.notificationSystem = {
            options: {
                show: {
                    theme: "dark",
                    icon: "icon-person",
                    position: "topCenter",
                    progressBarColor: "rgb(0, 255, 184)",
                    buttons: [
                        [
                            "<button>Close</button>",
                            function (instance, toast) {
                                instance.hide(
                                    {
                                        transitionOut: "fadeOutUp",
                                        onClosing: function (instance, toast, closedBy) {
                                            console.info("closedBy: " + closedBy);
                                        }
                                    },
                                    toast,
                                    "buttonName"
                                );
                            }
                        ]
                    ],
                    onClosing: function (instance, toast, closedBy) {
                        console.info("closedBy: " + closedBy);
                    }
                },
                ballon: {
                    balloon: true,
                    position: "bottomCenter"
                },
                info: {
                    position: "bottomLeft"
                },
                success: {
                    position: "topRight"
                },
                warning: {
                    position: "topLeft"
                },
                error: {
                    position: "topRight"
                },
            }
        };

        Vue.prototype.$parseFloatFormatoEspanolIngles = function (stringNumero) {
            if(stringNumero === "") return NaN
            let cantidadComas = stringNumero.split(",").length - 1;
            let cantidadPuntos = stringNumero.split(".").length - 1;
            if(cantidadPuntos > 1 && cantidadComas > 1) return NaN
            if(stringNumero.includes(",") && stringNumero.includes(",")){
                if(stringNumero.indexOf(",") < stringNumero.indexOf(".")){
                    stringNumero = stringNumero.replaceAll(",", "")// 9,000.00 --> 9000.00
                }else{
                    stringNumero = stringNumero.replaceAll(".", "")
                    stringNumero = stringNumero.replaceAll(",", ".")// 8.000,00  --> 8000.00
                }
            }else if(cantidadComas > 0){
                if(cantidadComas === 1){ // 1,2 -->1.2
                    stringNumero = stringNumero.replaceAll(",", ".")
                }else{ // 1,200,300 --> 1200300
                    stringNumero = stringNumero.replaceAll(",", "")
                }
            }else if(cantidadPuntos > 1){ // 1.200.300 --> 1200300
                stringNumero = stringNumero.replaceAll(".", "")
            }
            try{
                return parseFloat(stringNumero)
            }catch (e){
                return NaN
            }
        }
    }
}
export default globales