<template>
  <div>
    <div class="row m-3">
      <h4 class="m-3"><u>Especificaciones Ensayo No Destructivo</u></h4>
      <table class="table table-striped">
        <tr v-for="item in itemListInput" :key="item.itemId">
          <td class="col-sm-3">
            <strong>
              {{item.nombre}}
            </strong>
          </td>
          <td class="col-sm-9">
            <input type="text" class="form-control" v-model="item.valor" :disabled="readOnly">
          </td>
        </tr>
        <tr v-if="itemDescripcion">
          <td class="col-sm-3">
            <strong>
              {{itemDescripcion.nombre}}
            </strong>
          </td>
          <td class="col-sm-9">
            <textarea class="form-control" rows="5" v-model="itemDescripcion.valor" :disabled="readOnly"></textarea>
          </td>
        </tr>
      </table>
    </div>
    <hr>
    <!--   MEDICIONES   -->
    <div class="row text-center">
      <h4><u>Control de Mediciones</u></h4>
    </div>
    <div class="row text-center">
      <img :src="urlImagenEnsayoNoDestructivo" alt="Diagrama Equipo">
    </div>
    <table class="table table-striped text-center">
      <thead>
      <tr>
        <th class="d-none d-sm-table-cell">ITEM</th>
        <th>PUNTO DE MEDICION</th>
        <th>ESPESOR NOMINAL</th>
        <th>ESPESOR MÍNIMO ADMISIBLE</th>
        <th>ESPESOR MEDIDO</th>
      </tr>
      </thead>
      <tr v-for="(item, index) in itemListMedicion" :key="item.itemId">
        <td class="d-none d-sm-table-cell"> {{index + 1 }} </td>
        <td>
          <input type="text" class="form-control text-center" v-model="itemListPuntoMedicion[index].valor" disabled>
        </td>
        <td>
          <input type="text" class="form-control text-center" v-model="itemListEspesorNominal[index].valor" disabled>
        </td>
        <td>
          <input type="text" class="form-control text-center" v-model="itemListEspesorMinimo[index].valor" disabled>
        </td>
        <td>
          <input type="text" class="form-control text-center" v-model="item.valor" :disabled="readOnly">
        </td>
      </tr>
    </table>
    <hr>
    <!--   INSPECCIÓN VISUAL   -->
    <div class="row text-center">
      <h4><u>Inspección Visual</u></h4>
    </div>
    <table class="table table-striped text-center">
      <thead>
      <tr>
        <th class="d-none d-sm-table-cell">N°</th>
        <th class="p-1">ITEM/COMPONENTE INSPECCIONADO</th>
        <th class="px-0" style="width: 3%">
          Cumple<br>
          <button v-if="!readOnly" class="btn btn-primary btn-sm" type="button" @click="marcarColumna('cumple')"><i class="fa fa-check"></i></button>
        </th>
<!--        <th class="px-0" style="width: 3%">-->
<!--          Requiere Mejora<br>-->
<!--          <button v-if="!readOnly" class="btn btn-warning btn-sm" type="button" @click="marcarColumna('mejora')"><i class="fa fa-check"></i></button>-->
<!--        </th>-->
        <th class="px-0" style="width: 3%">
          No Cumple<br>
          <button v-if="!readOnly" class="btn btn-danger btn-sm" type="button" @click="marcarColumna('noCumple')"><i class="fa fa-check"></i></button>
        </th>
        <th class="px-0" style="width: 3%">
          No Aplica<br>
          <button v-if="!readOnly" class="btn btn-secondary btn-sm" type="button" @click="marcarColumna('noAplica')"><i class="fa fa-check"></i></button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in itemListInspeccion" :key="item.itemId" >
        <td class="d-none d-sm-table-cell"> {{index + 1}} </td>
        <td class="p-1">
          <strong @click="toggleObservaciones(item)" :style="{backgroundColor: item.color, cursor:'pointer'}">
            {{item.nombre}}
          </strong>
          <div v-if="item.mostrarObservaciones">
               <textarea v-if="item.mostrarObservaciones"
                         v-model="item.observaciones"
                         @blur="guardarObservaciones(item)"
                         class="observaciones-textarea"
                         placeholder="Escriba sus observaciones aquí..."
                         :disabled="readOnly"
               >
              </textarea>
          </div>
        </td>
        <td class="px-0">
          <input :disabled="readOnly" type="checkbox" v-model="item.valorCumple" v-if="item.valorNoCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,1)" class="checkbox cumple">
        </td>
<!--        <td class="px-0">-->
<!--          <input :disabled="readOnly" type="checkbox" v-model="item.valorMejora" v-if="item.valorNoCumple === false && item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item,2)" class="checkbox mejora">-->
<!--        </td>-->
        <td class="px-0">
          <input :disabled="readOnly" type="checkbox" v-model="item.valorNoCumple" v-if="item.valorCumple === false && item.valorNoAplica === false" @change="handleCheckboxChange($event,item, 3)" class="checkbox noCumple">
        </td>
        <td class="px-0">
          <input :disabled="readOnly" type="checkbox" v-model="item.valorNoAplica" v-if="item.valorCumple === false && item.valorNoCumple === false" @change="handleCheckboxChange($event,item, 4)" class="checkbox noAplica">
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ComponenteFormEnsayoNoDestructivo',
  props: {
    'itemListEnsayoNoDestructivo': {
      type: Array,
      default: function() {
        return [];
      }
    },
    'urlImagenEnsayoNoDestructivo': {
      type: String,
      default: ""
    },
    'readOnly': {
      type: Boolean,
      default: true
    },
  },
  data(){
    return{
      itemList: [],
      itemListInput:[],
      itemDescripcion:{},
      itemListPuntoMedicion:[],
      itemListEspesorMinimo:[],
      itemListEspesorNominal:[],
      itemListMedicion:[],
      itemListInspeccion:[],
    }
  },
  mounted(){
    this.itemListEnsayoProcesado()
  },
  methods:{
    itemListEnsayoProcesado() {
      if(this.itemListEnsayoNoDestructivo){
        this.itemList = this.itemListEnsayoNoDestructivo.map((item) => {
          let valorCumple   = false
          // let valorMejora   = false
          let valorNoCumple = false
          let valorNoAplica = false
          if(item.valorNoAplica === true) valorNoAplica = true
          if(item.valorBooleano !== null){
            if(item.valorBooleano === true) valorCumple   = true
            else                            valorNoCumple = true
          }

          return {
            valor:         item.valor,
            guardado:      item.guardado,
            valorEnabled:  item.valorBooleano,
            observaciones: item.observaciones,
            itemId:     item.itemId,
            index:      item.index,
            nombre:     item.texto,
            codigo:     item.codigo,
            enabled:    item.enabled,
            noAplica:   item.noAplica,
            conclusion: item.conclusion,
            textArea:   item.textArea,
            numero: item.index,
            valorCumple:   valorCumple  ,
            // valorMejora:   valorMejora  ,
            valorNoAplica: valorNoAplica,
            valorNoCumple: valorNoCumple,
            color: 'unset',
            mostrarObservaciones: (item.observaciones),
          };
        });
        this.itemListInput          = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_espec_'))
        this.itemDescripcion        = this.itemList.find((item)   => item?.textArea === true)
        this.itemListPuntoMedicion  = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_med_punto'))
        this.itemListEspesorMinimo  = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_med_minimo'))
        this.itemListEspesorNominal = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_med_nominal'))
        this.itemListMedicion       = this.itemList.filter((item) => item?.codigo.includes('no_destructivo_medicion'))
        this.itemListInspeccion     = this.itemList.filter((item) => item?.codigo.includes('inspeccion'))
        console.log(this.itemListInspeccion)
      }
    },
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
}

.observaciones-textarea {
  width: 100%; /* Hace que el textarea ocupe toda la anchura disponible */
  margin-top: 10px; /* Espacio adicional en la parte superior */
  /* Puedes añadir más estilos aquí según necesites */
}
</style>