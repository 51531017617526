<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Listar</a></li>
      <li class="breadcrumb-item active">Equipo</li>
    </ol>
    <h1 class="page-header">Lista Equipos<small></small></h1>
    <panel title="">
      <div class="col-md-4 mb-3">
        <button class="btn btn-primary" @click="resetSello">Reset</button>
      </div>

      <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true,  position: 'bottom' }"
          :selectOptions="{
					selectOnCheckboxOnly: true,
					selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
					selectionText: 'rows selected',
					clearSelectionText: 'clear',
					}"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'patente'">
            <router-link v-bind:to="'/show/equipo/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: rgb(5 180 237);opacity: 2;">
                {{ props.row.patente }}
              </span>
            </router-link>
          </span>
          <span v-else-if="props.column.field == 'activo'">
                {{ formatEnabled(props.row.activo) }}
          </span>
          <span v-else-if="props.column.field == 'sello'">
            {{ formatSello(props.row.sello) }}
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ListaEquipo",
  data() {
    return {
      columns: [{
        label: 'Patente/Número',
        field: 'patente',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Faena',
        field: 'faena',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Tipo',
        field: 'tipoEquipamiento',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Activo',
        field: 'activo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Sello',
        field: 'sello',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Última Inspección',
        field: 'ultimaInspeccion',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Próx Inspección',
        field: 'proximaInspeccion',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },{
        label: 'Días Permitidos',
        field: 'diasPermitidos',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
        /*{
        label: 'Propietario',
        field: 'propietarioEquipo',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      }*/
        {
        label: 'Transportista',
        field: 'empresaDeTransporte',
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
      ],
      rows: [
        {}
      ],
      listEquipo: [],
    }
  },
  methods: {
    asignarData() {
      this.rows = this.listEquipo;
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    formatEnabled(value) {
      return value ? 'Sí' : 'No';
    },
    formatSello(value) {
      return value === null ? 'Sin Sello' : value;
    },
    resetSello() {
      this.$route.params.sello = null;
      this.listaEquipo();
    },

    async listaEquipo() {
      this.loadingSpiner();
      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquipo';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username;
      const sello = this.$route.params.sello;
      const equipoIds = this.$route.params.equipoIds;  // Los IDs de los equipos filtrados por fecha en Dashboard-Programadas

      try {
        // PETICIÓN
        let response = await axios.get(api, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          },
          params: {
            username: username,
            sello: sello,
            equipoIds: equipoIds  // Se pasan los IDs de los equipos ya filtrados
          }
        });

        if (response.status == 200 || response.status == 201) {
          this.listEquipo = response.data;
          this.asignarData();
          this.loader.hide();
        } else {
          this.loader.hide();
          this.showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.loader.hide();
        this.error_msg = error;
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error);
          this.$expirarToken();
        } else {
          this.showToastError('Intente nuevamente más tarde !', 'UPS!', this.notificationSystem.options.error);
        }
      }
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.listaEquipo()
  }
}
</script>

<style scoped>

</style>
