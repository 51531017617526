<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Show</a></li>
      <li class="breadcrumb-item active">Inspeccion Programada</li>
    </ol>
    <h1 class="page-header">Inspecciones Programadas<small></small></h1>
    <panel title="Datos Generales">
      <template>
        <div class="contenedor" style="display: flex">
          <div class="card">
            <div class="icon_container">
              <i v-if="showInspeccionProgramada.selloColor === 'red'" class="fa fa-times-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.estadoColor === 'red'" class="fa fa-times-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.selloColor === 'green'" class="fa fa-check-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.estadoColor === 'blue'" class="fa fa-question-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.estadoColor === 'orange'" class="fa fa-question-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.estadoColor === 'lightgrey'" class="fa fa-stop-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.selloColor === 'yellow'" class="fa fa-info-circle icono" aria-hidden="true"></i>
              <i v-else-if="showInspeccionProgramada.estadoColor === 'lightred'" class="fa fa-times-circle icono" aria-hidden="true"></i>
            </div>
            <p class="text-before-divider">
              <template v-if="showInspeccionProgramada.selloColor === 'red'">No Apto</template>
              <template v-else-if="showInspeccionProgramada.estadoColor === 'red'">Sin Análisis</template>
              <template v-else-if="showInspeccionProgramada.selloColor === 'green'">Cumple</template>
              <template v-else-if="showInspeccionProgramada.estadoColor === 'blue'">Sin Análisis</template>
              <template v-else-if="showInspeccionProgramada.estadoColor === 'orange'">Sin Análisis</template>
              <template v-else-if="showInspeccionProgramada.estadoColor === 'lightred'">Solicitud Rechazada</template>
              <template v-else-if="showInspeccionProgramada.estadoColor === 'lightgrey'">Cancelada</template>
              <template v-else-if="showInspeccionProgramada.selloColor === 'yellow'">Requiere Mejoras</template>
            </p>
            <div class="divider"></div>
            <div class="message_container">
              <p v-if="showInspeccionProgramada.selloColor === 'red'">En Inspección Técnica realizada en
                dependencias de ENAEX, con fecha
                <span class="fecha" style="font-weight: bold;">{{ formatFecha(showInspeccionProgramada.fechaDeInspeccionActual) }}</span>, se verifica que
                <span class="SINO" style="font-weight: bold;">SI</span> existen puntos en el equipamiento
                individualizado arriba, que requieran
                mejorarse o repararse. El equipo
                individualizado en este documento,
                queda <span class="estado" style="color: #FF0000; font-weight: bold;">{{ showInspeccionProgramada.estado }}</span>,
                  con <span class="sello" style="color: #FF0000; font-weight: bold;">{{ showInspeccionProgramada.sello }}</span></p>

              <p v-else-if="showInspeccionProgramada.estadoColor === 'red'">Esta inspección aún no ha sido realizada
                Por favor complétela para su análisis.</p>

              <p v-else-if="showInspeccionProgramada.selloColor === 'green'">En Inspección Técnica realizada en
                dependencias de ENAEX, con fecha
                <span class="fecha" style="font-weight: bold;"> {{ formatFecha(showInspeccionProgramada.fechaDeInspeccionActual) }}</span>, se verifica que
                <span class="SINO" style="font-weight: bold;"> NO</span> existen puntos en el equipamiento
                individualizado arriba, que requieran
                mejorarse o repararse. El equipo
                individualizado en este documento,
                queda <span class="estado" style="color: #008000; font-weight: bold;"> {{ showInspeccionProgramada.estado }}</span>,
                con <span class="sello" style="color: #008000; font-weight: bold;"> {{ showInspeccionProgramada.sello }}</span>, por un período de <span class="fecha" style="font-weight: bold;"> {{showInspeccionProgramada.selloDias}}</span> DIAS.
                Este Certificado se mantendrá vigente
                hasta <span class="fecha+90" style="font-weight: bold;"> {{ sumarDias(showInspeccionProgramada.fechaDeInspeccionActual, showInspeccionProgramada.selloDias) }}</span> siempre
                que las condiciones del equipo se mantengan sin cambios, lo cual será verificado en las siguientes
                inspecciones: <span class="fecha+90" style="font-weight: bold;"> {{ sumarDias(showInspeccionProgramada.fechaDeInspeccionActual, showInspeccionProgramada.selloDias) }}</span></p>

              <p v-else-if="showInspeccionProgramada.estadoColor === 'blue'">Esta inspección aún no ha sido realizada
                Por favor complétela para su análisis.</p>

              <p v-else-if="showInspeccionProgramada.estadoColor === 'lightgrey'">Esta inspección ha sido Cancelada.</p>

              <p v-else-if="showInspeccionProgramada.estadoColor === 'orange'">Esta inspección aún no ha sido realizada
                Por favor complétela para su análisis.</p>

              <p v-else-if="showInspeccionProgramada.estadoColor === 'lightred'">Se rechazó la solicitud por no disponibilidad</p>

              <p v-else-if="showInspeccionProgramada.selloColor === 'yellow'">En Inspección Técnica realizada en
                dependencias de ENAEX, con fecha
                <span class="fecha" style="font-weight: bold;">{{ formatFecha(showInspeccionProgramada.fechaDeInspeccionActual) }}</span>, se verifica que
                <span class="SINO" style="font-weight: bold;">SI</span> existen puntos en el equipamiento
                individualizado arriba, que requieran
                mejorarse o repararse. El equipo
                individualizado en este documento,
                queda <span class="estado" style="color: #ffe400; font-weight: bold;">{{ showInspeccionProgramada.estado }}</span>,
                  con <span class="sello" style="color: #ffe400; font-weight: bold;">{{ showInspeccionProgramada.sello }}</span>,
                por un período de <span class="fecha" style="font-weight: bold;">{{showInspeccionProgramada.selloDias}}</span> DIAS.
                Este Certificado se mantendrá vigente
                hasta <span class="fecha+90" style="font-weight: bold;">{{ sumarDias(showInspeccionProgramada.fechaDeInspeccionActual, showInspeccionProgramada.selloDias) }}</span> siempre que las
                condiciones del equipo se mantengan sin cambios, lo cual será verificado en
                las siguientes inspecciones:
                <span class="fecha+90" style="font-weight: bold;">{{ sumarDias(showInspeccionProgramada.fechaDeInspeccionActual, showInspeccionProgramada.selloDias) }}.</span></p>

              <!-- Agrega más mensajes según sea necesario -->
              <p v-else>Estado desconocido</p>
            </div>
          </div>
          <table>
            <thead>
            <tr>
              <th colspan="2" class="ficha-inspeccion-header">Ficha Inspección</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="showInspeccionProgramada.numeroInforme">
              <td>N°INFORME</td>
              <td>{{ showInspeccionProgramada.numeroInforme.toUpperCase() }}</td>
            </tr>
            <tr>
              <td>TIPO INSPECCIÓN</td>
              <td>{{ showInspeccionProgramada.formularioTipo ? showInspeccionProgramada.formularioTipo : 'AGENDAMIENTO' }}</td>

            </tr>
            <tr>
              <td>SOLICITANTE</td>
              <td>{{ showInspeccionProgramada.solicitanteNombre.toUpperCase() + (showInspeccionProgramada.solicitanteApellidoPaterno ? ' ' + showInspeccionProgramada.solicitanteApellidoPaterno.toUpperCase() : "" )}}</td>
            </tr>
            <tr>
              <td>LUGAR DE INSPECCIÓN</td>
              <td>{{ showInspeccionProgramada.lugarDeInspeccion }}</td>
            </tr>
            <tr>
              <td>EQUIPO</td>
              <td>{{ showInspeccionProgramada.equipoPatente + " " + "(" + showInspeccionProgramada.equipoEmpresa + ")" }}</td>
            </tr>
            <tr>
              <td>TIPO DE CARGA</td>
              <td>{{ showInspeccionProgramada.tipoEquipamiento ? showInspeccionProgramada.tipoEquipamiento: ""}}</td>
            </tr>
            <tr v-if="showInspeccionProgramada.asignadoA">
              <td>ASIGNADO A</td>
              <td>{{ showInspeccionProgramada.asignadoA ? showInspeccionProgramada.asignadoA.toUpperCase() : ""}}</td>
            </tr>
            <tr v-if="showInspeccionProgramada.revisadoPor">
              <td>REVISADO POR</td>
              <td>{{ showInspeccionProgramada.revisadoPor ? showInspeccionProgramada.revisadoPor.toUpperCase() : ""}}</td>
            </tr>
            <tr>
              <td>FECHA SOLICITADA</td>
              <td>{{ formatFecha(showInspeccionProgramada.fechaDeInspeccionSolicitada)}}</td>
            </tr>
            <tr v-if="showInspeccionProgramada.fechaDeInspeccionAsignada">
              <td>FECHA ASIGNADA</td>
              <td>{{ formatFecha(showInspeccionProgramada.fechaDeInspeccionAsignada)}}</td>
            </tr>
            <tr v-if="showInspeccionProgramada.fechaDeInspeccionActual">
              <td>FECHA DE INSPECCIÓN ACTUAL</td>
              <td>{{ formatFecha(showInspeccionProgramada.fechaDeInspeccionActual) }}</td>
            </tr>
            <tr>
              <td>ESTADO</td>
              <td :style="{ backgroundColor: convertirColorRGBA(showInspeccionProgramada.estadoColor, 0.7) }"> <!-- Opacidad al 50% -->
                {{ showInspeccionProgramada.estado }}
              </td>
            </tr>
            <tr v-if="showInspeccionProgramada.sello">
              <td>SELLO</td>
              <td :style="{ backgroundColor: convertirColorRGBA(showInspeccionProgramada.selloColor, 0.7) }"> <!-- Opacidad al 50% -->
                {{ showInspeccionProgramada.sello.toUpperCase() }}
              </td>
            </tr>
            <tr v-if="showInspeccionProgramada.observaciones">
              <td>OBSERVACIONES</td>
              <td>{{ showInspeccionProgramada.observaciones}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="botones-centrados">
          <!-- Tus botones aquí -->
          <span v-if="!isCliente">
            <button v-if="showInspeccionProgramada.estado !== 'Solicitud Rechazada' && showInspeccionProgramada.estado !== 'Cancelado' && !showInspeccionProgramada.asignadoA && showInspeccionProgramada.estado !== 'RECHAZADO'" type="button" class="btn btn-warning mt-5 mb-3" @click="toggleModal('cancelarInspeccion-modal')">
              <i class="fa fa-stop-circle" style="margin-right: 5px;"></i>Cancelar
            </button>
            <button v-if="!showInspeccionProgramada.asignadoA && showInspeccionProgramada.estado !== 'Solicitud Rechazada' && showInspeccionProgramada.estado !== 'Cancelado' && showInspeccionProgramada.estado !== 'RECHAZADO'" type="button" class="btn btn-success mt-5 mb-3" @click="toggleModal('asignarInspector-modal')">
              <i class="fa fa-user-md" style="margin-right: 5px;"></i>Asignar Inspector
            </button>
            <button v-if="showInspeccionProgramada.estado !== 'Solicitud Rechazada' && showInspeccionProgramada.estado !== 'RECHAZADO' && showInspeccionProgramada.estado !== 'Cancelado' && !showInspeccionProgramada.asignadoA" type="button" class="btn btn-danger mt-5 mb-3" @click="toggleModal('rechazarInspeccion-modal')">
              <i class="fa fa-times" style="margin-right: 5px;"></i>Rechazar
            </button>
            <button v-if="showInspeccionProgramada.estado !== 'Solicitud Rechazada' && showInspeccionProgramada.estado !== 'RECHAZADO' && showInspeccionProgramada.estado !== 'Cancelado' && !showInspeccionProgramada.asignadoA" type="button" class="btn btn-info mt-5 mb-3" @click="toggleModal('modificarInspeccion-modal')">
              <i class="fa fa-refresh" style="margin-right: 5px;"></i>Modificar
            </button>
            <button v-if="showInspeccionProgramada.estado !== 'Solicitud Rechazada' && showInspeccionProgramada.estado !== 'RECHAZADO' && showInspeccionProgramada.estado !== 'Cancelado' && !showInspeccionProgramada.asignadoA" type="button" class="btn btn-warning mt-5 mb-3" @click="toggleModal('asignarOtraFechaInspeccion-modal')">
              <i class="fa fa-calendar" style="margin-right: 5px;"></i>Asignar Otra Fecha
            </button>
            <button v-if="!showInspeccionProgramada.numeroInforme" type="button" class="btn btn-warning mt-5 mb-3" @click="toggleModal('numeroInformeInspeccion-modal')">
              <i class="fa fa-pencil" style="margin-right: 5px;"></i>Número Informe
            </button>
            <button v-if="!firmaInspectorGuardada" type="button" class="btn btn-danger mt-5 mb-3" @click="toggleModal('eliminarInspeccion-modal')">
              <i class="fa fa-times" style="margin-right: 5px;"></i>Eliminar
            </button>
            <button v-if="this.equipoCompleto.incompleto" type="button" class="btn btn-warning mt-5 mb-3" @click="toggleModal('completarEquipo-modal')">
              <i class="fa fa-truck" style="margin-right: 5px;"></i>Completar Equipo
            </button>
          </span>
          <span v-if="isAssignedUser">
              <button v-if="showInspeccionProgramada.formularioTipo && !showInspeccionProgramada.sello" type="button" class="btn btn-green mt-5 mb-3" @click="comenzarInspeccionProgramada()">
                <i class="fa fa-pencil" style="margin-right: 5px;"></i>Comenzar Inspección
              </button>
              <button v-else-if="!showInspeccionProgramada.formularioTipo && !showInspeccionProgramada.sello" type="button" class="btn btn-green mt-5 mb-3" @click="goToChecklistGeneral">
                <i class="fa fa-pencil" style="margin-right: 5px;"></i>Comenzar Inspección
              </button>
              <button v-else-if="showInspeccionProgramada.numeroInforme && !firmaInspectorGuardada && !this.equipoCompleto.incompleto" type="button" class="btn btn-green mt-5 mb-3" @click="toggleModal('firmaInspector-modal')">
                  <i class="fa fa-lock" style="margin-right: 5px"></i>Cerrar Inspección
              </button>
          </span>
          <span v-if="firmaInspectorGuardada">
                <button type="button" class="btn btn-green mt-5 mb-3" @click="reenviarEmail">
                  <i class="fa fa-paper-plane" style="margin-right: 5px"></i>Reenviar Email
                </button>
                <button type="button" v-if="!showInspeccionProgramada.formularioTipo" class="btn btn-primary mt-5 mb-3" @click="metodoGenerarInformeFormatoOriginal">
                  <i class="fa fa-download" style="margin-right: 5px"></i>Ver Informe
                </button>
                <button type="button" v-else class="btn btn-primary mt-5 mb-3" @click="metodoGenerarInforme">
                  <i class="fa fa-download" style="margin-right: 5px"></i>Ver Informe
                </button>
          </span>
          <button v-if="isAssignedUser" type="button" class="btn btn-success mt-5 mb-3" @click="goToFotosInspeccion">
            <i class="fa fa-image" style="margin-right: 5px;"></i>Subir Fotos
          </button>
        </div>
        <div class="checklist-container" v-if="items && items.length > 0">
          <h3>Checklist</h3>
          <table class="checklist-table">
            <thead>
            <tr>
              <th>N°</th>
              <th>ITEM/COMPONENTE INSPECCIONADO</th>
              <th>Cumple</th>
              <th>Requiere Mejora</th>
              <th>No cumple</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td :class="{
              'fondo-verde': item.ponderacion === 1,
              'fondo-amarillo': item.ponderacion === 3,
              'fondo-rojo': item.ponderacion === 10
              }">{{ item.numero }}</td>
              <td :class="{
              'fondo-verde': item.ponderacion === 1,
              'fondo-amarillo': item.ponderacion === 3,
              'fondo-rojo': item.ponderacion === 10
              }">{{ item.texto }}</td>
              <td :class="{
              'fondo-verde': item.ponderacion === 1,
              'fondo-amarillo': item.ponderacion === 3,
              'fondo-rojo': item.ponderacion === 10
              }">
                <input type="checkbox" :checked="item.ponderacion === 1" >
              </td>
              <td :class="{
              'fondo-verde': item.ponderacion === 1,
              'fondo-amarillo': item.ponderacion === 3,
              'fondo-rojo': item.ponderacion === 10
              }">
                <input type="checkbox" :checked="item.ponderacion === 3" >
              </td>
              <td :class="{
              'fondo-verde': item.ponderacion === 1,
              'fondo-amarillo': item.ponderacion === 3,
              'fondo-rojo': item.ponderacion === 10
              }">
                <input type="checkbox" :checked="item.ponderacion === 10" >
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <br>
        <!-- Lista Items Inspección Programada Original-->
        <div v-if="itemsCuestionario && itemsCuestionario.length > 0">
          <h3 class="mt-3">Cuestionario</h3>
          <panel title="RESUMEN ENSAYOS NO DESTRUCTIVOS">
            <template>
              <div>
                <table class="styled-table">
                  <thead>
                  <tr>
                    <th>ENSAYOS NO DESTRUCTIVOS APLICADOS :</th>
                    <th>UBICACIÓN</th>
                    <th>EXTENSION (% del total)</th>
                    <th>RESULTADOS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Ensayo por Líquidos Penetrantes</td>
                    <td><input type="text" style="width: 100%; text-align: center" v-model="itemsCuestionario[0].valor" disabled></td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[4].valor }} </option>
                      </select>
                    </td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[8].valor }}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Ensayo por Partículas Magnéticas</td>
                    <td><input type="text" style="width: 100%; text-align: center" v-model="itemsCuestionario[1].valor" disabled></td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[5].valor }}</option>
                      </select>
                    </td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[9].valor }}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Ensayo Radiográfico</td>
                    <td><input type="text" style="width: 100%; text-align: center" v-model="itemsCuestionario[2].valor" disabled></td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[6].valor }}</option>
                      </select>
                    </td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[10].valor }}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Ensayo por Ultrasonidos</td>
                    <td><input type="text" style="width: 100%; text-align: center" v-model="itemsCuestionario[3].valor" disabled></td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[7].valor }}</option>
                      </select>
                    </td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[11].valor }}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Control Dimensional por Ultrasonidos</td>
                    <td>King Pin</td>
                    <td>
                      100%
                    </td>
                    <td>
                      <select disabled>
                        <option selected>{{ itemsCuestionario[12].valor }}</option>
                      </select>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </panel>
          <panel title="CUMPLIMIENTO DE COTAS y DIAMETROS DE KING PIN ( PERNO REY DE ACOPLAMIENTO )">
            <template>
              <div>
                <table class="styled-table2">
                  <thead>
                  <tr>
                    <th>KING PIN DIAMETRO <input type="text" style="width: 30px; height: 19px" v-model="itemsCuestionario[13].valor" disabled> pulgadas</th>
                    <th colspan="2"><input type="text" v-model="itemsCuestionario[14].valor" disabled></th>
                    <th colspan="3">Valores de Referencia</th>
                    <th style="text-align: center; border-bottom: 0 !important;"><strong>VALORES<br>MEDIDOS</strong></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr>
                    <td rowspan="6"><img :src="urlKingPin"></td>
                    <td colspan="2" rowspan="2"></td>
                    <td>Máxima:</td>
                    <td>Mínima:</td>
                    <td>Media:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">[mm]</td>
                    <td style="text-align: center;">[mm]</td>
                    <td style="text-align: center;">[mm]</td>
                    <td style="text-align: center;">[mm]</td>
                  </tr>
                  <tr>
                    <td colspan="2">Medidas para Dimensión "C"</td>
                    <td style="color: green">73,00</td>
                    <td style="color: red">71,00</td>
                    <td style="color: blue">72,00</td>
                    <td><input type="text" v-model="itemsCuestionario[15].valor" disabled></td>
                  </tr>
                  <tr>
                    <td colspan="2">Medidas para Dimensión "D"</td>
                    <td style="color: green">50,80</td>
                    <td style="color: red">49,00</td>
                    <td style="color: blue">49,90</td>
                    <td><input type="text" v-model="itemsCuestionario[16].valor" disabled></td>
                  </tr>
                  <tr>
                    <td colspan="2">Medidas para Dimensión "E"</td>
                    <td style="color: green">74,00</td>
                    <td style="color: red">72,00</td>
                    <td style="color: blue">73,00</td>
                    <td><input type="text" v-model="itemsCuestionario[17].valor" disabled></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </panel>
          <panel title="ENSAYOS NO DESTRUCTIVOS APLICADOS">
            <template>
              <div>
                <table class="styled-table3">
                  <tbody>
                  <tr>
                    <td style="font-weight: bold">Tipo de Instrumento:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[18].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Marca:</td>
                    <td><input type="text" style="width: 100% " v-model="itemsCuestionario[19].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Modelo Específico:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[20].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Método de Medición</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[21].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Palpadores:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[22].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Rango de Frecuencia:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[23].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Calibre:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[24].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Cable Transmisor:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[25].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Lugares de Aplicación:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[26].valor" disabled></td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Normas de Referencia:</td>
                    <td><input type="text" style="width: 100%" v-model="itemsCuestionario[27].valor" disabled></td>
                  </tr>
                  <tr>
                    <td colspan="2" style="font-weight: bold">RESULTADO</td>
                  </tr>
                  <tr>
                    <td colspan="2"><textarea style="width: 100%; height: 200px" v-model="itemsCuestionario[28].valor" disabled></textarea></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </panel>
          <panel title=" RECOMENDACIONES DE MEJORA / REPARACIONES PENDIENTES :">
            <template>
              <div>
                <table  class="styled-table4">
                  <tbody>
                  <textarea style="width: 100%; height: 400px" v-model="itemsCuestionario[29].valor" disabled></textarea>
                  <div style="font-weight: bold" class="mt-3">MANUTENCIONES PREVENTIVAS RECOMENDADAS :</div>
                  <textarea style="width: 100%; height: 400px" v-model="itemsCuestionario[30].valor" disabled></textarea>
                  </tbody>
                </table>
              </div>
            </template>
          </panel>
        </div>
      </template>
    </panel>
    <!-- Lista Items Inspección Programada Nueva-->
    <div v-if="showInspeccionProgramada.formularioTipo && showInspeccionProgramada.sello">
      <panel title="Checklist">
        <ComponenteFormChecklist
            v-if="itemListChecklist.length > 0"
            :readOnly="true"
            :itemListChecklist="itemListChecklist"
        />
      </panel>

      <panel title="Kingpin">
        <ComponenteFormKingpin
            v-if="itemListKingpin.length > 0"
            :readOnly="true"
            :itemListKingpin="itemListKingpin"
            :urlImagenKingpin="urlImagenKingpin"
        />
      </panel>
      <panel title="Hermeticidad">
        <ComponenteFormHermeticidad
            v-if="itemListHermeticidad.length > 0"
            :readOnly="true"
            :itemListHermeticidad="itemListHermeticidad"
        />
      </panel>
      <panel title="Válvula de Alivio">
        <ComponenteFormValvula
            v-if="itemListValvulaAlivio.length > 0"
            :readOnly="true"
            :itemListValvulaAlivio="itemListValvulaAlivio"
        />
      </panel>
      <panel title="Ensayo No Destructivo">
        <ComponenteFormEnsayoNoDestructivo
            v-if="itemListEnsayoNoDestructivo.length > 0"
            :readOnly="true"
            :itemListEnsayoNoDestructivo="itemListEnsayoNoDestructivo"
            :urlImagenEnsayoNoDestructivo="urlImagenEnsayoNoDestructivo"
        />
      </panel>
      <panel title="Líneas de Vida">
        <ComponenteFormLineas
            v-if="itemListLineasPilarPletina.length > 0"
            :readOnly="true"
            :itemListLineasPilarPletina="itemListLineasPilarPletina"
        />
      </panel>
    </div>
    <modal name="eliminarInspeccion-modal" height="auto">
      <div class="modal-container">
        <h2 class="mb-4">¿Eliminar Inspeccion?</h2>
        <div class="form-group">
          <h4>Esta acción es irreversible.</h4>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" style="margin-right: 7px" @click="toggleModal('eliminarInspeccion-modal')">Cancelar</button>
          <button class="btn btn-danger" @click="eliminarInspeccion">Borrar</button>
        </div>
      </div>
    </modal>
    <modal name="asignarInspector-modal" height="auto">
      <div class="modal-container">
        <h2>Asignar Inspección</h2>
        <form class="modal-form" @submit.prevent="asignarInspector">
          <div class="form-group">
            <label class="form-label" for="inspector"><strong>Inspector*</strong></label>
            <select id="inspector" class="form-control" v-if="inspectorList.length > 0" v-model="form.inspector">
              <option value=""  selected disabled>-- SELECCIONE UNA OPCIÓN --</option>
              <option v-for="inspector in inspectorList" :value="inspector.id" :key="inspector.id">
                {{ inspector.nombre + " " + (inspector.apellidoPaterno ? inspector.apellidoPaterno : "") + " " + (inspector.apellidoMaterno ? inspector.apellidoMaterno : "") }}
              </option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label" id="formularioTipo"><strong>Tipo Inspección</strong> </label>
            <select class="form-control" v-model="form.formularioTipo">
<!--              <option value="">Formato Original</option>-->
              <option v-for="tipo in formularioTipos" :value="tipo.id" :key="tipo.id">
                {{ tipo.nombre }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('asignarInspector-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="cancelarInspeccion-modal" height="auto">
      <div class="modal-container">
        <h2>Cancelar Solicitud</h2>
        <hr>
        <p>Al cancelar la solicitud, ésta pasará de estado "Pendiente" a "Cancelado", lo cual implica que ya no se aceptarán modificaciones, a menos de que ésta sea reabierta por un administrador.</p>
        <form class="modal-form" @submit.prevent="cancelarInspeccion">
          <div class="form-group">
            <label for="observaciones">Observaciones:</label>
            <textarea id="observaciones" class="form-control" v-model="form.observaciones"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('cancelarInspeccion-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Cancelar Solicitud</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="rechazarInspeccion-modal" height="auto">
      <div class="modal-container">
        <h2>Rechazar Solicitud</h2>
        <hr>
        <form class="modal-form" @submit.prevent="rechazarInspeccion">
          <div class="form-group">
            <label for="observaciones">Observaciones:</label>
            <textarea id="observaciones" class="form-control" v-model="form.observaciones"></textarea>
          </div>
          <!-- Checkbox para enviar email de rechazo -->
          <div class="form-group checkbox-container">
            <label for="enviarEmail" class="checkbox-label">Enviar email de rechazo</label>
            <input type="checkbox" id="enviarEmail" class="checkbox-custom" v-model="form.enviarEmailDeRechazo">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('rechazarInspeccion-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Rechazar Solicitud</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="numeroInformeInspeccion-modal" height="auto">
      <div class="modal-container">
        <h2>Número Informe</h2>
        <hr>
        <form class="modal-form" @submit.prevent="numeroInformeInspeccion">
          <div class="form-group">
            <label for="numeroInforme">Número Informe*</label>
            <input type="text" id="numeroInforme" class="form-control" placeholder="-D8760" v-model="form.numeroInforme">
          </div>
          <div class="form-group">
            <label for="numeroSello">Número Sello*</label>
            <input type="text" id="numeroSello" class="form-control" placeholder="Ingrese número sello" v-model="form.numeroSello">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('numeroInformeInspeccion-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="modificarInspeccion-modal" height="auto">
      <div class="modal-container">
        <h2>Detalles Solicitud</h2>
        <hr>
        <form class="modal-form" @submit.prevent="modificarInspeccion">
          <div class="form-group">
            <label class="form-label" for="inspector"><strong>Equipo*</strong></label>
            <select id="inspector" class="form-control" v-if="equipoList.length > 0" v-model="form.equipo">
              <option value=""  selected disabled>-- SELECCIONE UNA OPCIÓN --</option>
              <option v-for="equipo in equipoList" :value="equipo.id" :key="equipo.id">
                {{ equipo.patente + " " + "(" + equipo.empresaDeTransporte + ")" }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label" for="inspector"><strong>Lugar*</strong></label>
            <select id="inspector" class="form-control" v-if="ubicacionList.length > 0" v-model="form.lugar">
              <option value=""  selected disabled>-- SELECCIONE UNA OPCIÓN --</option>
              <option v-for="lugar in ubicacionList" :value="lugar.id" :key="lugar.id">
                {{ lugar.nombre }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('modificarInspeccion-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="asignarOtraFechaInspeccion-modal" height="auto" >
      <div class="modal-container asignarFecha" >
        <h2>Asignar Otra Fecha</h2>
        <hr>
        <form class="modal-form" @submit.prevent="asignarOtraFechaInspeccion">
          <div class="datepicker">
            <label class="form-label" for="fechaSolicitada"><strong>Fecha Original*</strong></label>
            <Datepicker :language="es" :disabled="true" mondayFirst v-model="fechaSolicitadaOriginal" :format="customFormatterConHora"></Datepicker>
          </div>
          <div class="datepicker mb-3" >
            <label class="form-label" for="fechaAisgnada"><strong>Nueva Fecha*</strong></label>
            <div class="datepicker d-flex align-items-center">
              <Datepicker :disabled-dates="disabledDates" :language="es" mondayFirst v-model="form.fechaSolicitada" :format="customFormatterSinHora" @input="fechaAisgnadaChange" style="margin-right: 10px; z-index: 9999"></Datepicker>

              <div class="time-picker-wrapper" style="margin-top: -8px">
                <select class="time-picker" v-model="timeAsignFecha.hours" @change="updateTime">
                  <option v-for="hour in hoursAsignFecha" :key="hour" :value="hour">{{ hour }}</option>
                </select>
                <span style="margin-left: -5px">:</span>
                <select class="time-picker" v-model="timeAsignFecha.minutes" @change="updateTime">
                  <option v-for="minute in minutesAsignFecha" :key="minute" :value="minute">{{ minute }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('asignarOtraFechaInspeccion-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="firmaInspector-modal" height="auto" @opened="onModalOpenedInspector" class="firma-inspector-modal">
      <div class="modal-container firma-inspector-container">
        <h2 style="text-align: center;">Firma Inspector</h2>
        <div class="signature-instructions" style="text-align: center;">
          Inspector debe firmar dentro del área delimitada:
        </div>
        <form class="modal-form" @submit.prevent="guardarFirma(false)" style="text-align: center;">
          <div class="canvas-container" style="position: relative; margin: auto;">
            <canvas ref="signatureCanvas" id="signature-canvas" class="signature-canvas" style="border: 1px solid black; margin-bottom: 10px;"></canvas>
            <button type="button" class="btn btn-secondary btn-limpiar" @click="clearCanvas" style="position: absolute; right: 10px; top: 10px; padding: 5px; border: none; line-height: 1;">
              <i class="fa fa-eraser" style="font-size: 16px;"></i> <!-- Ajusta el tamaño aquí -->
            </button>
            <div class="signature-line" style="position: absolute; bottom: 30px; left: 0; right: 0; border-top: 1px dashed #000;"></div>
          </div>
          <div class="button-group">
            <button type="button" class="btn btn-danger" @click="toggleModal('firmaInspector-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar Firma</button>
          </div>
        </form>
      </div>
    </modal>
    <modal name="completarEquipo-modal" height="auto" class="holaa">
      <div class="modal-container">
        <h2>Completar Equipo</h2>
        <hr>
        <form class="modal-form" @submit.prevent="completarEquipo">
          <div class="form-group-equipo" style="display: flex;">
            <div class="column-equipo">
              <label for="patente">Patente/Número</label>
              <select class="form-control" v-model="equipoCompleto.patente" disabled>
                <option :value="equipoCompleto.patente">
                  {{ equipoCompleto.patente }}
                </option>
              </select>
              <div class="datepicker mt-2" >
                <label class="mb-2" for="vencimientoRevisionTecnica"><strong>Vencimiento Revisión Técnica</strong></label>
                <Datepicker  :language="es" mondayFirst v-model="equipoCompleto.vencimientoRevisionTecnica" :format="customFormatter" ></Datepicker>
              </div>
              <div class="col-md-9 mb-2">
                <label class="form-label" id="numeroInterno"><strong>Número Interno</strong> </label>
                <input class="form-control" type="text" v-model="equipoCompleto.numeroInterno" />
              </div>
              <div class="col-md-9 mb-2">
                <label class="form-label" id="numeroChasis"><strong>Número de Chasis</strong> </label>
                <input class="form-control" type="text" v-model="equipoCompleto.numeroChasis" />
              </div>
              <div class="col-md-9 mb-2">
                  <label class="form-label" id="kilometraje"><strong>Kilometraje</strong> </label>
                  <input class="form-control" type="number" v-model="equipoCompleto.kilometraje" />
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label" id="faena"><strong>Faena</strong> </label>
                <select class="form-control" v-model="equipoCompleto.faena.id">
                  <option v-for="faena in equipoFaenas" :value="faena.id" :key="faena.id">
                    {{faena.nombre }}
                  </option>
                </select>
              </div>
              <label class="form-check-label" for="documentacionAlDia">
                Documentación al Día*
                <input class="form-check-input" type="checkbox" id="documentacionAlDia" v-model="equipoCompleto.documentacionAlDia">
              </label>
            </div>
            <div class="column-equipo">
              <label for="propietario"><strong>Propietario*</strong></label>
              <select class="form-control" v-if="empresasPropietarias.length > 0" v-model="equipoCompleto.propietarioDelEquipo.id">
                <option v-for="propietario in empresasPropietarias" :value="propietario.id" :key="propietario.id">
                  {{ propietario.razonSocial }}
                </option>
              </select>
              <label class="form-label mt-2" for="transportista"><strong>Transportista*</strong></label>
              <select class="form-control" v-if="empresasTransportista.length > 0" v-model="equipoCompleto.empresaDeTransporte.id">
                <option v-for="transportista in empresasTransportista" :value="transportista.id" :key="transportista.id">
                  {{ transportista.razonSocial }}
                </option>
              </select>
              <div class="col-md-9 mb-2">
                <label class="form-label" id="modelo"><strong>Modelo</strong> </label>
                <input class="form-control" type="text" v-model="equipoCompleto.modelo" />
              </div>
              <label class="form-label mt-2" id="tipoEquipamiento">Tipo Equipamiento</label>
              <select class="form-control" v-if="equipoTipoEquipamiento.length > 0" v-model="equipoCompleto.tipoEquipamiento.id">
                <option v-for="tipoEquipamiento in equipoTipoEquipamiento" :value="tipoEquipamiento.id" :key="tipoEquipamiento.id">
                  {{tipoEquipamiento.nombre }}
                </option>
              </select>
              <label class="form-label mt-2" id="fabricante"><strong>Fabricante*</strong> </label>
              <select class="form-control" v-model="equipoCompleto.fabricante.id">
                <option v-for="fabricantes in empresasFabricantes" :value="fabricantes.id" :key="fabricantes.id">
                  {{fabricantes.razonSocial }}
                </option>
              </select>
              <div class="col-md-6 mb-3 mt-2">
                <label class="form-label" id="anoFabricacion">Año Fabricacion</label>
                <input class="form-control" type="number" v-model="equipoCompleto.anoFabricacion" />
              </div>
              <label class="form-check-label" for="activo">
                Activo*
                <input class="form-check-input" type="checkbox" id="activo" v-model="equipoCompleto.activo">
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal('completarEquipo-modal')">Cancelar</button>
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from "vuejs-datepicker/dist/locale";
import SignaturePad from 'signature_pad';
import ComponenteFormChecklist from "@/components/formularios/ComponenteFormChecklist.vue";
import ComponenteFormKingpin from "@/components/formularios/ComponenteFormKingpin.vue";
import ComponenteFormHermeticidad from "@/components/formularios/ComponenteFormHermeticidad.vue";
import ComponenteFormValvula from "@/components/formularios/ComponenteFormValvula.vue";
import ComponenteFormEnsayoNoDestructivo from "@/components/formularios/ComponenteFormEnsayoNoDestructivo.vue";
import ComponenteFormLineas from "@/components/formularios/ComponenteFormLineas.vue";

export default {
  components: {
    ComponenteFormEnsayoNoDestructivo,
    ComponenteFormChecklist,
    ComponenteFormKingpin,
    ComponenteFormHermeticidad,
    ComponenteFormValvula,
    ComponenteFormLineas,
    Datepicker,
  },
  name: "ShowInspeccionProgramada",
  data() {
    return {
      //DATE PICKER ASIGNAR FECHA

      disabledDates: {
        to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),  // Deshabilita días pasados
        days: [6, 0], // Deshabilita sábados (6) y domingos (0)
        dates: [
          new Date(2024, 0, 1),  // Año Nuevo
          new Date(2024, 2, 29), // Semana Santa
          new Date(2024, 4, 1),  // Día del Trabajo
          new Date(2024, 4, 21), // Día de las Glorias Navales
          // new Date(2024, 4, 30), // Corpus Christi
          new Date(2024, 5, 20), // Día de los pueblos Indígenas
          new Date(2024, 6, 16), // Día de la Virgen del Carmen
          new Date(2024, 7, 15), // La Asunción de la Virgen
          new Date(2024, 8, 18), // Fiestas Patrias
          new Date(2024, 8, 19), // Día de las Glorias del Ejército
          new Date(2024, 8, 20), // Fiestas Patrias
          new Date(2024, 9, 31), // Halloween
          new Date(2024, 10, 1), // Dia de todos los santos
          new Date(2024, 11, 25) // Navidad
          // Agrega aquí más fechas de días feriados
        ]
      },

      timeAsignFecha: {
        hours: '09',
        minutes: '00'
      },
      hoursAsignFecha: Array.from({ length: 10 }, (v, k) => (9 + k).toString().padStart(2, '0')),
      // Minutos se mantiene igual si necesitas rangos completos de minutos para cada hora
      minutesAsignFecha: Array.from({ length: 60 }, (v, k) => k.toString().padStart(2, '0')),
      fechaAsignFecha: new Date(),
      horaAsignFecha: '12:00',
      // FIN DATE PICKER ASIGNAR FECHA
      empresasPropietarias:[],
      empresasTransportista:[],
      equipoModelo:[],
      equipoTipoEquipamiento:[],
      equipoFaenas:[],
      empresasFabricantes:[],
      formularioTipos:[],
      equipoCompleto: null,
      inspeccionId: null,
      firmaInspectorGuardada: false,
      urlKingPin:'',
      items: [],
      itemsCuestionario: [],
      time: {
        hours: '12',
        minutes: '00'
      },
      hours: Array.from({ length: 24 }, (v, k) => k.toString().padStart(2, '0')),
      minutes: Array.from({ length: 60 }, (v, k) => k.toString().padStart(2, '0')),
      fecha: new Date(),
      hora: '12:00',
      es:es,
      signaturePad: null,
      form:{
        numeroInforme:'',
        numeroSello:'',
        inspector:'',
        formularioTipo:5,
        observaciones:'',
        enviarEmailDeRechazo:'',
        equipo:'',
        lugar:'',
        fechaAisgnada:'',
        fechaSolicitada:'',
        "username": "",
      },
      fechaSolicitadaOriginal: "",
      estadoInspeccionList:[],
      inspectorList:[],
      equipoList:[],
      ubicacionList:[],
      showInspeccionProgramada: [],
      itemListChecklist:            [],
      itemListKingpin:              [],
      itemListHermeticidad:         [],
      itemListValvulaAlivio:        [],
      itemListEnsayoNoDestructivo:  [],
      itemListLineasPilarPletina:   [],
      urlImagenKingpin:             "",
      urlImagenEnsayoNoDestructivo: "",
    }
  },
  computed: {
    isAssignedUser() {
      const userId = localStorage.getItem('userId');
      return userId && this?.showInspeccionProgramada?.asignadoId?.toString() === userId;
    },
    isCliente() {
      let usuario = JSON.parse(localStorage.getItem('saveUser'));
      let roles = usuario ? usuario["roles"] : [];
      return roles.includes("ROLE_CLIENTE");
    },
  },
  methods: {
    async eliminarInspeccion() {

      this.$loadingSpiner(); // Muestra el spinner de carga

      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/eliminarInspeccion/`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      const dataParaEnviar = {
        inspeccionId: this.inspeccionId, // Asegúrate de que inspeccionId está definido en tu componente
      };

      try {
        let response = await axios.post(api, dataParaEnviar, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });

        if (response.status === 200 || response.status === 201) {
          this.$router.push({ path: '/agendamiento/calendario'});
          this.toggleModal('eliminarInspeccion-modal'); // Cierra el modal
          this.$showToastSuccess('Inspeccion Eliminada!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.$showToastError('Ha ocurrido un error al eliminar la inspeccion.', 'Error', this.$notificationSystem().options.error);
        console.error(error);
      } finally {
        if (this.loader) {
          this.loader.hide(); // Oculta el spinner de carga
        }
      }
    },
    sumarDias(fecha, dias) {
      const date = new Date(fecha);
      date.setDate(date.getDate() + dias);
      return this.formatFecha2(date.toISOString().split('T')[0]);
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    asignarData() {
      this.rows = this.showInspeccionProgramada;
    },
    fechaAisgnadaChange(date) {
      this.form.fechaSolicitada = date; // Actualiza la fecha
      this.updateTime(); // Llama a updateTime para asegurarse de que la hora y minutos sean los correctos
    },
    customFormatterConHora(date) {
      return date.toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Utiliza el formato de 24 horas
      });
    },
    customFormatterSinHora(date) {
      return date.toLocaleDateString('es-ES');
    },
    toggleModal(idModal){
      this.$modal.toggle(idModal)
    },


    async goToChecklistGeneral() {
      const inspeccionId = this.$route.params.id;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let response = await axios.post(process.env.VUE_APP_IP_BACKEND + '/inspeccion/comenzarInspeccion/'+ inspeccionId, { inspeccionId: inspeccionId }, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
            // Si la actualización fue exitosa, redirige al checklist general
            this.$router.push({ name: 'ChecklistGeneral', params: { id: inspeccionId } });
            this.$showToastSuccess('Inspeccion Comenzada!', 'Genial!', this.$notificationSystem().options.success)
            this.loader.hide()
          } else {
            this.loader.hide()
            this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
          }
      },
    goToFotosInspeccion() {
      const id = this.$route.params.id;
      this.$router.push({ name: 'InspeccionProgramadaFotos', params: {inspeccionId: id} });
    },
    convertirColorRGBA(color, opacidad) {
      const colores = {
        red: '255, 0, 0',
        green: '0, 128, 0',
        orange: '255, 165, 0',
        yellow: '255, 255, 0',
        blue: '16, 34, 231', // Color blue agregado
        lightgrey: '211, 211, 211', // Color lightgrey agregado
        lightred: '210, 145, 178',
      };
      return `rgba(${colores[color] || '0, 0, 0'}, ${opacidad})`; // color negro por defecto
    },
    updateTime() {
      if (this.form.fechaSolicitada) {
        // Asegúrate de que form.fechaSoilcitada sea un objeto Date
        const date = new Date(this.form.fechaSolicitada);

        // Establece las horas y minutos según lo seleccionado por el usuario
        date.setHours(this.timeAsignFecha.hours);
        date.setMinutes(this.timeAsignFecha.minutes);

        // Formatea la fecha y hora en la zona horaria de Chile para enviar al backend
        const fechaParaBackend = date.toLocaleString('es-ES', {
          timeZone: 'America/Santiago', // Zona horaria de Chile Continental
          hour12: false
        }).replace(/ /g, 'T'); // Reemplaza espacios con T para seguir un formato similar al ISO pero sin la Z

        // Actualiza el objeto form.fechaSoilcitada con la nueva fecha y hora
        this.form.fechaSolicitadaNueva = fechaParaBackend; // Asumiendo que esta es la propiedad a enviar
      }
    },
    onModalOpenedInspector() {
      // Ahora puedes estar seguro de que el modal está abierto y el canvas está en el DOM
      let canvas = this.$refs.signatureCanvas;
      // Asegúrate de que el canvas tiene dimensiones asignadas antes de crear la instancia de SignaturePad
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.signaturePad = new SignaturePad(canvas);
    },
    clearCanvas() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },
    async getEstadoInspeccionList() {
      try {
        const data = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/inspeccion/estadoInspeccionProgramadaList')
        this.estadoInspeccionList = data; // Asegúrate de que el backend devuelve la lista correctamente
      } catch (error) {
        console.error(error);
        // Manejar errores
      }
    },
    async getInspectoresList() {
      try {
        const data = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/user/listaInspectores');
        this.inspectorList = data; // Asegúrate de que el backend devuelve la lista correctamente
      } catch (error) {
        console.error(error);
        // Manejar errores
      }
    },
    async getEquipoList() {
      try {
        const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
        const username = obtenerToken.username;
        const data = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/vehiculo/listaEquipo', { username: username });
        this.equipoList = data; // Asegúrate de que el backend devuelve la lista correctamente
      } catch (error) {
        console.error(error);
        // Manejar errores
      }
    },
    async getUbicacionList() {
      try {
        const data = await this.$fetchData(process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacionProgramadas');
        this.ubicacionList = data; // Asegúrate de que el backend devuelve la lista correctamente
      } catch (error) {
        console.error(error);
        // Manejar errores
      }
    },
    formatFecha(fechaISO) {
        const fecha = new Date(fechaISO);
        const opciones = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
      const fechaFormateada = fecha.toLocaleString('es-ES', opciones);
      // Devuelve la cadena en mayúsculas
      return fechaFormateada.toUpperCase();

    },
    formatFecha2(fechaISO) {
      const fecha = new Date(fechaISO);
      // Ajustes para incluir el día de la semana completo y el formato de hora
      const opciones = {
        weekday: 'long', // 'long' para el día completo
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false, // Usa formato de 24 horas
        timeZone: 'UTC' // Asegura consistencia en la interpretación de la fecha
      };
      // Usa toLocaleString para incluir fecha y hora
      return fecha.toLocaleString('es-ES', opciones);
    },
    async ShowInspeccionProgramada() {
      this.$loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/showInspeccionProgramada/'
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token


      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccionProgramada = response.data
          this.form.fechaSolicitada = this.showInspeccionProgramada.fechaDeInspeccionSolicitada;
          this.fechaSolicitadaOriginal = this.showInspeccionProgramada.fechaDeInspeccionSolicitada;
          this.form.fechaAisgnada = this.showInspeccionProgramada.fechaDeInspeccionAsignada;
          this.firmaInspectorGuardada = response.data.tieneFirmaInspector
          this.equipoCompleto = response.data.equipo
          if (!this.equipoCompleto.faena) {
            this.equipoCompleto.faena = { id: '' }; // O cualquier otro valor por defecto necesario
          }
          if (!this.equipoCompleto.fabricante) {
            this.equipoCompleto.fabricante = { id: '' }; // O cualquier otro valor por defecto necesario
          }
          if (!this.equipoCompleto.propietarioDelEquipo) {
            this.equipoCompleto.propietarioDelEquipo = { id: '' }; // O cualquier otro valor por defecto necesario
          }
          this.asignarData()
          console.log(response.data)
          this.loader.hide()

          //Para mostrar los resultados de la inspección programada de formatos nuevos. Solo si ya está terminado.
          if(this.showInspeccionProgramada.formularioTipo && this.showInspeccionProgramada.sello){
            this.fetchItemListProgramadaNueva()
          }
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async asignarInspector() {
      this.$loadingSpiner()
      if (!this.form.inspector) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Seleccione un Inspector!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/asignarInspector/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.$set(this, 'showInspeccionProgramada', response.data);
          this.loader.hide()
          this.$modal.hide('asignarInspector-modal');
          this.$showToastSuccess('Inspección Asignada Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async cancelarInspeccion() {
      this.$loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/cancelarInspeccion/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccionProgramada = response.data
          this.loader.hide()
          this.$modal.hide('cancelarInspeccion-modal');
          this.$showToastSuccess('Inspección Cancelada Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async rechazarInspeccion() {
      this.$loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/rechazarInspeccion/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccionProgramada = response.data
          this.loader.hide()
          this.$modal.hide('rechazarInspeccion-modal');
          this.$showToastSuccess('Inspección Rechazada Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async numeroInformeInspeccion() {
      this.$loadingSpiner()
      if (!this.form.numeroInforme) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Rellene Número de Informe!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      if (!this.form.numeroSello) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Rellene Número de Sello!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/numeroInformeInspeccion/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccionProgramada = response.data
          this.loader.hide()
          this.$modal.hide('numeroInformeInspeccion-modal');
          this.$showToastSuccess('Número de Informe Asignado Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async completarEquipo() {
      this.$loadingSpiner()
      const camposRequeridos = [
        { valor: this.equipoCompleto.propietarioDelEquipo.id, mensaje: 'Seleccione un propietario' },
        { valor: this.equipoCompleto.empresaDeTransporte.id, mensaje: 'Seleccione una empresa de transporte' },
        { valor: this.equipoCompleto.fabricante.id, mensaje: 'Seleccione un fabricante' },
        { valor: this.equipoCompleto.anoFabricacion, mensaje: 'Ingrese el año de fabricación' },
        { valor: this.equipoCompleto.vencimientoRevisionTecnica, mensaje: 'Ingrese la fecha de vencimiento de la revisión técnica' },
        { valor: this.equipoCompleto.patente, mensaje: 'Ingrese la patente' },
        { valor: this.equipoCompleto.numeroInterno, mensaje: 'Ingrese el número interno del equipo' },
        { valor: this.equipoCompleto.numeroChasis, mensaje: 'Ingrese el numero del chasis del equipo' },
        { valor: this.equipoCompleto.tipoEquipamiento.id, mensaje: 'Seleccione un tipo de carga' },
        { valor: this.equipoCompleto.modelo, mensaje: 'Ingrese modelo del equipo' },
      ];

      for (const campo of camposRequeridos) {
        if (!campo.valor && campo.valor == null) {
          this.loader.hide(); // Detener el spinner
          this.$showToastError(campo.mensaje, 'UPS!', this.$notificationSystem().options.error);
          return; // Salir de la función si algún campo requerido está vacío
        }
      }
      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/completarEquipo/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        equipo: this.equipoCompleto,
        inspeccionId: this.$route.params.id,
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.loader.hide()
          this.$modal.hide('completarEquipo-modal');
          this.$set(this.equipoCompleto, 'incompleto', response.data.equipo.incompleto);
          this.$showToastSuccess('Equipo Actualizado Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async modificarInspeccion() {
      this.$loadingSpiner()
      if (!this.form.equipo) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Seleccione un Equipo!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      if (!this.form.lugar) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Seleccione un Lugar!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/modificarInspeccion/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccionProgramada = response.data
          this.loader.hide()
          this.$modal.hide('modificarInspeccion-modal');
          this.$showToastSuccess('Inspección Evaluada Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async asignarOtraFechaInspeccion() {
      this.$loadingSpiner()
      if (!this.form.fechaSolicitada) {
        this.loader.hide(); // Detener el spinner
        this.$showToastError('Seleccione una Fecha!', 'UPS!', this.$notificationSystem().options.error);
        return;
      }
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const username = obtenerToken.username
      const api = `${process.env.VUE_APP_IP_BACKEND}/inspeccion/asignarOtraFechaInspeccion?username=${username}`;

      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showInspeccionProgramada = response.data
          this.loader.hide()
          this.$modal.hide('asignarOtraFechaInspeccion-modal');
          this.$showToastSuccess('Nueva fecha asignada Exitósamente!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else if(this.error_msg.request.status == 400) {
          let mensajeError = this.error_msg.response.data.mensajeError
          console.log(mensajeError)
          this.$showToastError(mensajeError, 'UPS!', this.$notificationSystem().options.error)
        }else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async comenzarInspeccionProgramada(){
      let inspeccionId = this.$route.params.id;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let response = await axios.post(process.env.VUE_APP_IP_BACKEND + '/inspeccion/comenzarInspeccion/'+ inspeccionId, { inspeccionId: inspeccionId }, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
      if (response.status == 200 || response.status == 201 ) {
        this.$irVistaInspeccionProgramada(inspeccionId, null, false)
        this.$showToastSuccess('Inspeccion Comenzada!', 'Genial!', this.$notificationSystem().options.success)
        this.loader.hide()
      }else {
        this.loader.hide()
        this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
      }
    },
    async getUserId() {
      //API URL
      const api = process.env.VUE_APP_IP_BACKEND + "/user/obtenerDatos";
      // GET TOKEN
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      this.form.username = obtenerToken.username
      try {
        //PETICION
        let response = await axios.post(api, this.form, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200) {
          const jsonUserId = response.data
          const userId = JSON.stringify(jsonUserId)
          localStorage.setItem('saveId', userId)
          localStorage.setItem('userId', response.data.idUsuario);
          this.loader.hide()
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        if (this.error_msg.request.status == 401) {
          this.$expirarToken()
        }
      }
    },
    async fetchChecklistItems() {
      const inspeccionId = this.$route.params.id; // Obtiene el ID de la inspección de los parámetros de la ruta
      const api = process.env.VUE_APP_IP_BACKEND + '/formulario/mostrarChecklistCompletaOriginal/';
      const apiId = api+inspeccionId
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(apiId, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.items = response.data.map((item, index) => ({
            ...item,
            numero: index + 1,
          }));
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem().options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async fetchCuestionarioItems() {
      const inspeccionId = this.$route.params.id; // Obtiene el ID de la inspección de los parámetros de la ruta
      const api = process.env.VUE_APP_IP_BACKEND + '/formulario/mostrarCuestionarioOriginal/';
      const apiId = api+inspeccionId
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(apiId, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.itemsCuestionario = response.data.itemsConValor
          this.urlKingPin = response.data.urlKingPin;
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async empresasPropietariasEquipo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaPropietaria?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        if (response.status === 200 || response.status === 201) {
          this.empresasPropietarias = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async tipoEquipamientoEquipo() {
      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/tipoEquipamientoList';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(api, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.equipoTipoEquipamiento = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async empresasTransportistaEquipo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaTransportista?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        if (response.status === 200 || response.status === 201) {
          this.empresasTransportista = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async empresasFabricantesEquipo() {
      const api = process.env.VUE_APP_IP_BACKEND + '/empresa/empresaFabricante';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(api, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.empresasFabricantes = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async faenasEquipo() {
      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/faenaList';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(api, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.equipoFaenas = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async modelosEquipo() {
      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/modeloList';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(api, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.equipoModelo = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async fetchFormularioTipos() {
      const api = process.env.VUE_APP_IP_BACKEND + '/formulario/formularioTipoList';
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        let response = await axios.get(api, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status === 200 || response.status === 201) {
          this.formularioTipos = response.data
          this.loader.hide();
        } else {
          this.loader.hide();
          this.$showToastError('Algo salió mal !', 'UPS!', this.$notificationSystem.options.error);
        }
      } catch (error) {
        console.error("Error en ShowInspeccion", error);
        this.loader.hide(); // Oculta el cargador en caso de error
        this.error_msg = error;

        if (this.error_msg && this.error_msg.request && this.error_msg.request.status === 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem.options.error);
          this.$expirarToken(); // Asumiendo que tienes un método para manejar la expiración de tokens
        } else {
          this.$showToastError('Intente nuevamente más tarde !', 'UPS!', this.$notificationSystem.options.error);
        }
      }
    },
    async guardarFirma(firmaConductor) {
      let tipoFirma = 'firmaInspector'
      if(firmaConductor === true) tipoFirma = 'firmaConductor'
      this.$loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/guardarFirmaInspeccionProgramada/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      let canvas = this.$refs.signatureCanvas; // Asegúrate de que este es el ref correcto al canvas
      let firmaBase64 = canvas.toDataURL('image/png');
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
        ...this.form,
        firma: firmaBase64,  // Agrega la firma aquí
        tipoFirma: tipoFirma,
        sello: this.showInspeccionProgramada.sello
      };
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.loader.hide()
          if(firmaConductor === true) {
            this.$modal.hide('firmaConductor-modal');
            this.firmaConductorGuardada = true;
          }
          else {
            this.firmaInspectorGuardada = true;
            this.showInspeccion = response.data
          }
          this.$modal.hide('firmaInspector-modal');
          let cargo = "Inspector"
          if(firmaConductor === true) cargo = "Conductor"
          this.$showToastSuccess('Firma '+ cargo +' Exitosa!', 'Genial!', this.$notificationSystem().options.success)
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async reenviarEmail() {
      this.$loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + '/inspeccion/reenvioCorreoProgramadas'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        inspeccionId: this.$route.params.id,
      };
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.$showToastSuccess('Email Reenviado !', 'Genial!', this.$notificationSystem().options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    async fetchItemListProgramadaNueva(){
      const urlApi = process.env.VUE_APP_IP_BACKEND + '/inspeccion/itemPorFormularioAgrupadoPorFormulario/' + this.inspeccionId
      const data = await this.$fetchData(urlApi)
      if(data){
        this.itemListChecklist            = data.itemListChecklist
        this.itemListKingpin              = data.itemListKingpin
        this.itemListHermeticidad         = data.itemListHermeticidad
        this.itemListValvulaAlivio        = data.itemListValvulaAlivio
        this.itemListEnsayoNoDestructivo  = data.itemListEnsayoNoDestructivo
        this.itemListLineasPilarPletina   = data.itemListLineasPilarPletina
        this.urlImagenKingpin             = data.urlImagenKingpin
        this.urlImagenEnsayoNoDestructivo = data.urlImagenEnsayoNoDestructivo
      }
    },
    metodoGenerarInformeFormatoOriginal() {
      const id = this.$route.params.id; // O la variable donde tienes almacenado el ID
      const url = process.env.VUE_APP_IP_BACKEND +`/inspeccion/abrePdfReportProgramadaOriginal/${id}`;
      window.open(url, '_blank');
    },
    metodoGenerarInforme() {
      const id = this.$route.params.id; // O la variable donde tienes almacenado el ID
      const url = process.env.VUE_APP_IP_BACKEND +`/inspeccion/abrePdfReportProgramada/${id}`;
      window.open(url, '_blank');
    },
    async fetchData(url) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        // Manejar errores
        return []; // Devolver un array vacío en caso de error
      }
    },
  },
  mounted() {
    this.getEstadoInspeccionList();
    this.getInspectoresList();
    this.ShowInspeccionProgramada();
    this.getEquipoList();
    this.getUbicacionList();
    this.fetchChecklistItems();
    this.fetchCuestionarioItems();
    this.empresasPropietariasEquipo();
    this.empresasTransportistaEquipo();
    this.modelosEquipo();
    this.tipoEquipamientoEquipo();
    this.faenasEquipo();
    this.empresasFabricantesEquipo();
    this.fetchFormularioTipos();
  },
  created() {
    this.inspeccionId = this.$route.params.id
    this.$validarLogin()
    this.getUserId()
  }
}
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 8px;
  border: 1px solid #6c757d; /* Ajusta o elimina el borde según tus necesidades */
}
.ficha-inspeccion-header {
  padding-bottom: 20px;
  font-size: 24px; /* Ajusta el tamaño de la fuente según tus preferencias */
}

.modal-container {
  background-color: #ffffff; /* Asumiendo que #333 es el color de tus campos */
  color: #000000;
  border: solid 1px;
  border-color: #495057;
  padding: 20px;
}

.modal-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #6c757d;
  border-radius: 4px;
  margin-top: 5px;
}

.form-text.text-danger {
  color: #d9534f;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.btn {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
.table-bordered th {
  border: 1px solid #ffffff; /* Color del borde */
  border-bottom: 2px solid #ffffff; /* Asegura que el borde inferior sea definitivamente blanco y un poco más grueso */
}
.table th, .table td {
  background-color: #d0706d;
}
.botones-centrados {
  display: flex;
  justify-content: center; /* Alinea los elementos horizontalmente al centro */
  flex-wrap: wrap; /* Permite que los botones se ajusten en varias líneas si no hay espacio suficiente */
  gap: 10px; /* Espaciado entre botones */
}
.estado-inspeccion {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  position: relative;
  margin-top: 20px; /* Ajustar según sea necesario */
}

.estado-icono {
  font-size: 4em; /* Ajusta el tamaño del ícono */
  color: #777; /* Ajusta el color del ícono */
}

.estado-info h3 {
  margin: 20px 0 10px;
  font-weight: bold;
}

.estado-info p {
  color: #666;
  font-size: 0.9em;
}

.estado-cinta {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #777;
  color: white;
  padding: 5px 10px;
  transform: rotate(45deg);
  transform-origin: top right;
  font-size: 0.7em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.estado-cinta span {
  transform: rotate(-45deg);
  display: block;
}
.checkbox-custom {
  width: 25px; /* Tamaño del cuadro del checkbox */
  height: 25px; /* Tamaño del cuadro del checkbox */
  cursor: pointer;
}

.checkbox-label {
  margin-right: 8px; /* Espacio entre el checkbox y el texto */
  cursor: pointer;
}

/* Ajustes para alinear verticalmente el texto con el checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
}

.firma-inspector-modal .canvas-container {
  position: relative;
  width: 300px; /* Ajusta al ancho deseado */
  margin: auto; /* Centra el contenedor */
}

.firma-inspector-modal .signature-canvas {
  background-color: white;
  border: 1px solid #000;
  height: 150px; /* Ajusta a la nueva altura deseada */
}

/* El botón Limpiar se posiciona en la esquina del canvas */
.firma-inspector-modal .btn-limpiar {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.checklist-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

.checklist-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.81rem;
}

.checklist-table th,
.checklist-table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.checklist-table th {
  background-color: #f2f2f2;
  color: black;
}

.checklist-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.checklist-table tr:hover {
  background-color: #f1f1f1;
}

.checklist-table td input[type="checkbox"] {
  transform: scale(2);
  margin-left: 5px;
  margin-top: 5px;
  margin-right: auto;
  display: inline-block;
  pointer-events: none; /* Evita que se hagan clic en los checkboxes */
  user-select: none;

}
.checklist-table th:nth-child(3),
.checklist-table th:nth-child(4),
.checklist-table th:nth-child(5) {
  width: 130px; /* Establece el ancho de estas cabeceras */
}
.checklist-table th:nth-child(1){
  width: 50px;
}
.checklist-table th:first-child {
  text-align: center;
}
.checklist-table td:first-child {
  text-align: center;
}
.fondo-verde {
  background-color: #90EE90; /* Un tono de verde claro */
}

.fondo-amarillo {
  background-color: #FFFF99; /* Un tono de amarillo claro */
}

.fondo-rojo {
  background-color: #FF9999; /* Un tono de rojo claro */
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table thead tr th {
  background-color: #e9ecef;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table tbody tr td {
  padding: 12px 15px;
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}

.styled-table2 {
  width: 100%;
  border-collapse: collapse;
}
.styled-table2 thead tr th {
  background-color: #e9ecef;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table2 tbody tr td {
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}
.styled-table3 {
  width: 100%;
  border-collapse: collapse;
}
.styled-table3 thead tr th {
  background-color: #e9ecef;
  color: #000000;
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #cecece;
}

.styled-table3 tbody tr td {
  padding: 12px 15px;
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}
.styled-table4 {
  width: 100%;
  border-collapse: collapse;
}
.icon_container i {
  font-size: 6em; /* Aumenta el tamaño de los íconos */
  color: #4a4a4a; /* Color por defecto del ícono, ajusta según necesidad */
}

.icon_container .fa-times-circle { color: #FF0000; } /* Rojo para rechazado */
.icon_container .fa-check-circle { color: #008000; } /* Verde para aprobado */
.icon_container .fa-question-circle { color: rgb(91, 90, 90); } /* Azul para no realizado */
.icon_container .fa-info-circle { color: #ffec07; } /* Azul para no realizado */
.icon_container .fa-stop-circle { color: #bbb9b9; } /* Azul para no realizado */

.card {
  border: 1px solid #d3d3d3; /* Borde gris */
  border-radius: 4px; /* Bordes redondeados */
  padding: 20px;
  margin-right: 20px; /* Margen entre la card y la tabla */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido horizontalmente */
  width: 35%;
}

.icon_container {
  text-align: center; /* Centra el ícono */
  width: 100%; /* Ocupa todo el ancho disponible */
  margin-bottom: 20px; /* Espaciado entre el ícono y el texto */
}

.icon_container i {
  font-size: 6em;
  color: #4a4a4a; /* Color por defecto */
}
.message_container p {
  font-size: 16px; /* Aumenta el tamaño del texto */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 20px; /* Espacio entre el ícono y el mensaje */
  text-align: justify;
  color: #262626;
}

.divider{
  height: 1px;
  background-color: #000;
  width: 100%;  /* Ancho fijo del divisor */
  margin: 1px auto;
}
.text-before-divider {
  font-size: 24px; /* Aumenta el tamaño del texto */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: justify;
  color: #262626;
  margin-top: -5px;
}

@media (max-width: 768px) {
  .modal-container {
    position: fixed;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, -4%);
    overflow: auto;
  }
  .panel {
    overflow-x: auto; /* Permite el desplazamiento horizontal si el contenido es más ancho que el contenedor */
  }

  .styled-table, .styled-table2, .styled-table3, .styled-table4 {
    width: 100%; /* Asegura que la tabla no exceda el tamaño del contenedor */
    max-width: 100%; /* Previene que la tabla sea más ancha que el contenedor */
  }
  .card {
    width: 100%;
    margin-bottom: 20px; /* Añade un espacio debajo del bloque de análisis */
  }
  .ficha-inspeccion-header, .message_container, .icon_container, .text-before-divider {
    width: 100%;
  }

  .contenedor {
    flex-direction: column; /* Cambia la dirección de los flex-items de fila a columna */
  }
  .holaa {
    position: fixed;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, -10%) scale(0.8);
  }
  .asignarFecha{
    overflow: visible;
  }

}
.form-group-equipo {
  display: flex;
  gap: 20px; /* Ajusta el espacio entre columnas */
}

.column-equipo {
  flex: 1; /* Hace que cada columna ocupe un espacio igual dentro del contenedor */
}
.btn-green {
  background-color: #28a745; /* Un verde estándar */
  color: white;
}

</style>
