<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Ver</a></li>
      <li class="breadcrumb-item active">Usuario</li>
    </ol>
    <h1 class="page-header">Ver Usuario <small></small></h1>
    <panel title="Formulario de edición de usuario">
      <form v-on:submit.prevent="" method="POST" autocomplete="off">
        <div class="mb-3 col-md-12">
          <label class="form-label" id="username"><strong>Nombre de usuario</strong> </label>
          <input class="form-control" type="text" v-model="showDataUser.username" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="password"><strong>Contraseña</strong> </label>
          <input class="form-control" type="password" v-model="showDataUser.password" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="nombre"><strong>Nombre</strong> </label>
          <input class="form-control" type="text" v-model="showDataUser.nombre"/>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="apellidoPaterno"><strong>Apellido Paterno</strong> </label>
          <input class="form-control" type="text" v-model="showDataUser.apellidoPaterno" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="apellidoMaterno"><strong>Apellido Materno</strong> </label>
          <input class="form-control" type="text" v-model="showDataUser.apellidoMaterno" />
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label class="form-label">Rut</label>
            <div>
              <masked-input pattern="11111111" mask="11111111" type="tel" v-model="showDataUser.rut" class="form-control" placeholder="00000000" ></masked-input>
            </div>
          </div>
          <div class="mb-3 col-md-2">
            <label class="form-label">DV*</label>
            <div>
              <input
                  type="text"
                  v-model="showDataUser.codigoVerificador"
                  @input="validateDV"
                  class="form-control"
                  placeholder="0-K">
            </div>
          </div>
        </div>
        <div class="datepicker" >
          <label class="form-label" for="fechaNacimiento"><strong>Fecha de Nacimiento</strong></label>
          <Datepicker  :language="es" mondayFirst v-model="showDataUser.fechaNacimiento" :format="customFormatter" ></Datepicker>
        </div>
        <div class="mb-3">
          <label class="form-label" for="sexo"><strong>Sexo</strong></label>
          <select class="form-control" v-if="sexoList.length > 0" v-model="showDataUser.sexo">
            <option v-for="sexo in sexoList" :value="sexo.id" :key="sexo.id">
              {{ sexo.sexo }}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="fono"><strong>Fono</strong> </label>
          <input class="form-control" type="number" v-model="showDataUser.fono" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="fonoCelular"><strong>Celular</strong> </label>
          <input class="form-control" type="number" v-model="showDataUser.fonoCelular" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="email"><strong>Correo</strong> </label>
          <input class="form-control" type="email" v-model="showDataUser.email" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="cargo"><strong>Cargo</strong></label>
          <select class="form-control" v-if="cargoList.length > 0" v-model="showDataUser.cargo">
            <option v-for="cargo in cargoList" :value="cargo.id" :key="cargo.id">
              {{ cargo.nombreCargo }}
            </option>
          </select>
        </div>
        <div v-for="ubicacion in ubicaciones" :key="ubicacion.id" class="mb-3">
          <label class="form-check-label">
            {{ ubicacion.nombre }}
            <input
                class="form-check-input"
                type="checkbox"
                :value="ubicacion.id"
                v-model="showDataUser.lugaresDeInspeccion"
            >
          </label>
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="calleUsuario"><strong>Calle</strong> </label>
          <input class="form-control" type="text" v-model="showDataUser.domicilio.calleUsuario" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="numeroUsuario"><strong>Número</strong> </label>
          <input class="form-control" type="number" v-model="showDataUser.domicilio.numeroUsuario" />
        </div>
        <div class="mb-3 col-md-12">
          <label class="form-label" id="departamentoUsuario"><strong>Departamento</strong> </label>
          <input class="form-control" type="text" v-model="showDataUser.domicilio.departamentoUsuario" />
        </div>
        <div class="mb-3">
          <label class="form-label" id="ciudadUsuario"><strong>Ciudad</strong> </label>
          <select class="form-control" v-if="ciudadUsuarioList.length > 0" v-model="showDataUser.domicilio.ciudadUsuario">
            <option v-for="ciudadUsuario in ciudadUsuarioList" :value="ciudadUsuario.id" :key="ciudadUsuario.id">
              {{ciudadUsuario.nombre}}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label" id="empresa"><strong>Empresa</strong> </label>
          <select class="form-control" v-if="empresaList.length > 0" v-model="showDataUser.empresa">
            <option v-for="empresa in empresaList" :value="empresa.id" :key="empresa.id">
              {{empresa.razonSocial}}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-check-label" for="enabled">
            Usuario Habilitado
            <input class="form-check-input" type="checkbox" id="enabled" v-model="showDataUser.enabled">
          </label>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-secondary" v-on:click="cancelar()">Cancelar</button>
          </div>
          <div class="col-sm-8"></div>
          <div class="mb-3 col-sm-2">
            <button type="button" class="btn btn-primary" v-on:click="editarUsuario()">Editar</button>
          </div>
        </div>
      </form>
    </panel>
    <!-- END panel -->
  </div>
</template>

<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from 'vuejs-datepicker/dist/locale'

export default {
  components:{
    Datepicker
  },
  name: "ShowUser",
  data(){
    return{
      formatCharacters: {
        'A': {
          validate: char => /[0-9kK]/.test(char),
          transform: char => char.toUpperCase()
        }
      },
      sexoList:[],
      cargoList:[],
      ubicaciones:[],
      ciudadUsuarioList:[],
      empresaList:[],
      es:es,
      mondayFirst:true,
      form:{
        "username":"",
        "password":"",
        "nombre": "",
        "apellidoPaterno": "",
        "apellidoMaterno": "",
        "email": "",
        "rut": '',
        "codigoVerificador": '',
        "fechaNacimiento": '',
        "fono": '',
        "fonoCelular": '',
        "calleUsuario": '',
        enabled: false,
      },
      showDataUser:{
        sexo:'',
        cargo:'',
        ciudadUsuario:'',
        empresa:'',
        lugaresDeInspeccion:[],
        domicilio: {
          calleUsuario: '',
          numeroUsuario: '',
          departamentoUsuario: '',
          ciudadUsuario: ''
        }
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods:{
    validateDV(event) {
      const input = event.target.value.toUpperCase();
      const validChars = /^[0-9K]$/;

      if (input.length > 0 && !validChars.test(input)) {
        event.target.value = input.slice(0, -1);
      } else {
        this.form.codigoVerificador = input;
      }
    },
    loadingSpiner() {
      this.loader = this.$loading.show({
        // Optional parameters
        //container: this.$refs.loadingContainer,
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async cargarTodasUbicaciones() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/ubicacion/listaUbicacion`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarUbicacionesUsuario(userId) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/showLugaresInspeccion/${userId}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data.map(ubicacion => ubicacion.id);
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarSexo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/sexoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarCargo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/cargoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarEmpresa() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const username = obtenerToken.username
      const api =`${process.env.VUE_APP_IP_BACKEND}/empresa/listaEmpresa?username=${username}`
      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201) {
         this.empresaList = response.data
        } else {
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async cargarDireccionUsuario(domicilioId) {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/user/obtenerDomicilio/${domicilioId}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.showDataUser.domicilio = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async cargarCiudades() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/ciudad/ciudadList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        return response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },

    async getUser(){
      this.loadingSpiner()
      // const api = 'http://localhost:8181/ta-ms-users/user/'
      const api = process.env.VUE_APP_IP_BACKEND + "/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          if(response.data.rut) {
            response.data.rut = response.data.rut.toString()
          }
          this.showDataUser = response.data
          if (!this.showDataUser.domicilio || !this.showDataUser.domicilio.id) {
            this.showDataUser.domicilio = {
              calleUsuario: '',
              numeroUsuario: '',
              departamentoUsuario: '',
              ciudadUsuario: null,
              id: null
            };
          }
          const userId = this.$route.params.id;
          this.showDataUser.lugaresDeInspeccion = await this.cargarUbicacionesUsuario(userId);
          if(this.showDataUser.sexo && response.data.sexo.id) {
            this.showDataUser.sexo = response.data.sexo.id;
          }
          if(this.showDataUser.cargo && response.data.cargo.id) {
            this.showDataUser.cargo = response.data.cargo.id;
          }
          if( this.showDataUser.lugaresDeInspeccion && response.data.lugaresDeInspeccion) {
            this.showDataUser.lugaresDeInspeccion = response.data.lugaresDeInspeccion;
          }
          if(this.showDataUser.empresa && response.data.empresa.id) {
            this.showDataUser.empresa = response.data.empresa.id;
          }
          if (this.showDataUser.domicilio && this.showDataUser.domicilio.id) {
            const domicilioId = this.showDataUser.domicilio.id;
            await this.cargarDireccionUsuario(domicilioId);
            if(this.showDataUser.domicilio.ciudadUsuario) {
              this.showDataUser.domicilio.ciudadUsuario = response.data.domicilio.ciudadUsuario.id;
            }
          } else {
            this.showDataUser.domicilio = {
              calleUsuario: null,
              numeroUsuario: null,
              departamentoUsuario: null,
              ciudadUsuario: null
            };
          }
          console.log(response.data)
          this.loader.hide(this.showDataUser)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }

    },
    async editarUsuario(){
      this.loadingSpiner()
      const api = process.env.VUE_APP_IP_BACKEND + "/user/editarUsuario/"
      // const api = "http://localhost:8181/ta-ms-users/user/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      try {
        //PETICION
        let response = await axios.put(apiId,this.showDataUser, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          console.log(this.showDataUser)
          this.showDataUser = response.data
          console.log(response.data)
          if (response.data.sexoId !== undefined) {
            this.showDataUser.sexo = response.data.sexoId;
          }
          if (response.data.cargoId !== undefined) {
            this.showDataUser.cargo = response.data.cargoId;
          }
          if (response.data.empresaId !== undefined) {
            this.showDataUser.empresa = response.data.empresaId;
          }
          if (response.data.lugaresDeInspeccionIds !== undefined) {
            this.showDataUser.lugaresDeInspeccion = response.data.lugaresDeInspeccionIds;
          }
          if (response.data.domicilio && response.data.domicilio.ciudadUsuarioId !== undefined) {
            this.showDataUser.domicilio.ciudadUsuario = response.data.domicilio.ciudadUsuarioId;
          }
          this.showToastSuccess('Usuario '+" "+ this.showDataUser.nombre+ " editado.", 'Exito!', this.notificationSystem.options.success)
          this.loader.hide()
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        console.error("Error en editarUsuario:", error)
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    cancelar(){
      this.$router.push({path: '/agendamiento/index/user'})
    },
  },
  async mounted() {
    this.sexoList = await this.cargarSexo();
    this.cargoList = await this.cargarCargo();
    this.ubicaciones = await this.cargarTodasUbicaciones();
    this.ciudadUsuarioList = await this.cargarCiudades();
    await this.cargarEmpresa()
  },
  created() {
    this.$validarLogin()
    this.getUser()
  }


}

</script>

<style scoped>
.datepicker input {
  background-color: white;
  color: black;
  border-color: #c0bfbf;
}
.datepicker /deep/ .vdp-datepicker__calendar {
  top: auto; /* Resetea cualquier valor previo */
  bottom: 100%;
}
@media (max-width: 700px) {
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -10%; /* Centra horizontalmente */
  }
}
</style>
