import Vue from 'vue'
import VueX from 'vuex'
import routes from './config/AppRoutes'
import VueAxios from "vue-axios"


// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import hljs from "highlight.js";
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import globales from "@/plugins/panel/globales";

//TOAST
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
Vue.use(VueIziToast);
// LOADING OVERLAY
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(VueLoading)

// EXPORT EXCEL JS
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);
// COPIAR PORTAPAPELES
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//MODALS
import VModal from 'vue-js-modal';
Vue.use(VModal)


// PARSE DATA MOMENT JS
import 'moment/locale/es';
Vue.use(require('vue-moment'));

// plugins css
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/style.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icons.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

// color admin css
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'

import App from './App.vue'
import axios from "axios";

//GOOGLE LINK
import { initializeApp } from 'firebase/app';
const firebaseConfig = {
  apiKey: "AIzaSyBkDm1ApdCPbQHifcEgGH4ohjYhnueCpoM",
  projectId: "teleasesoria-81fba",
};

//Formularios inspección programada nueva
import ComponenteFormChecklist from "@/components/formularios/ComponenteFormChecklist.vue";
import ComponenteFormKingpin from "@/components/formularios/ComponenteFormKingpin.vue";
import ComponenteFormHermeticidad from "@/components/formularios/ComponenteFormHermeticidad.vue";
import ComponenteFormValvula from "@/components/formularios/ComponenteFormValvula.vue";
import ComponenteFormEnsayoNoDestructivo from "@/components/formularios/ComponenteFormEnsayoNoDestructivo.vue";
import ComponenteFormLineas from "@/components/formularios/ComponenteFormLineas.vue";
Vue.use(ComponenteFormChecklist)
Vue.use(ComponenteFormKingpin)
Vue.use(ComponenteFormHermeticidad)
Vue.use(ComponenteFormValvula)
Vue.use(ComponenteFormEnsayoNoDestructivo)
Vue.use(ComponenteFormLineas)

const app = initializeApp(firebaseConfig);

Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(app)

Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueHljs, { hljs })
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component(VueCountdown.name, VueCountdown);

const router = new VueRouter({
	routes
})

//Métodos globales
Vue.use(globales)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')



